import { twMerge } from 'tailwind-merge';

function ComponentInput(props) {
  const { className, register, ...otherProps } = props;

  return (
    <input
      className={twMerge('border px-2 py-1 text-lg placeholder:text-slate-500 focus:outline-none', className)}
      {...register}
      {...otherProps}
    />
  );
}

export default ComponentInput;
