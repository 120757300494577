import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronsLeft } from 'react-icons/fi';

const singleParameters = [
  {
    DataId: 1,
    MainGroupId: 1,
    MainGroup: 'CH2',
    SubGroup: 'C',
    Ri: 0.6325,
    Qi: 0.0,
    ExampleComponent: 'neopentane',
    ExampleFragments: '4 CH3, 1 C',
  },
  {
    DataId: 2,
    MainGroupId: 1,
    MainGroup: 'CH2',
    SubGroup: 'CH',
    Ri: 0.6325,
    Qi: 0.3554,
    ExampleComponent: '2-methylpropane',
    ExampleFragments: '3 CH3, 1 CH',
  },
  {
    DataId: 3,
    MainGroupId: 1,
    MainGroup: 'CH2',
    SubGroup: 'CH2',
    Ri: 0.6325,
    Qi: 0.7081,
    ExampleComponent: 'octane',
    ExampleFragments: '2 CH3, 6 CH2',
  },
  {
    DataId: 4,
    MainGroupId: 1,
    MainGroup: 'CH2',
    SubGroup: 'CH3',
    Ri: 0.6325,
    Qi: 1.0608,
    ExampleComponent: 'hexane',
    ExampleFragments: '2 CH3, 4 CH2',
  },
  {
    DataId: 5,
    MainGroupId: 2,
    MainGroup: 'C=C',
    SubGroup: 'C=C',
    Ri: 1.2832,
    Qi: 0.4582,
    ExampleComponent: '2,3-dimethyl-2-butene',
    ExampleFragments: '4 CH3, 1 C=C',
  },
  {
    DataId: 6,
    MainGroupId: 2,
    MainGroup: 'C=C',
    SubGroup: 'CH=C',
    Ri: 1.2832,
    Qi: 0.8962,
    ExampleComponent: '2-methyl-2-butene',
    ExampleFragments: '3 CH3, 1 CH=C',
  },
  {
    DataId: 7,
    MainGroupId: 2,
    MainGroup: 'C=C',
    SubGroup: 'CH=CH',
    Ri: 1.2832,
    Qi: 1.2489,
    ExampleComponent: 'trans-2-hexene',
    ExampleFragments: '2 CH3, 2 CH2, 1 CH=CH',
  },
  {
    DataId: 8,
    MainGroupId: 2,
    MainGroup: 'C=C',
    SubGroup: 'CH2=C',
    Ri: 1.2832,
    Qi: 1.2489,
    ExampleComponent: '2-methyl-1-butene',
    ExampleFragments: '2 CH3, 1 CH2, 1 CH2=C',
  },
  {
    DataId: 9,
    MainGroupId: 2,
    MainGroup: 'C=C',
    SubGroup: 'CH2=CH',
    Ri: 1.2832,
    Qi: 1.6016,
    ExampleComponent: '1-hexene',
    ExampleFragments: '1 CH3, 3 CH2, 1 CH2=CH',
  },
  {
    DataId: 10,
    MainGroupId: 3,
    MainGroup: 'ACH',
    SubGroup: 'AC',
    Ri: 0.3763,
    Qi: 0.2113,
    ExampleComponent: 'styrene',
    ExampleFragments: '1 CH2=CH, 5 ACH, 1 AC',
  },
  {
    DataId: 11,
    MainGroupId: 3,
    MainGroup: 'ACH',
    SubGroup: 'ACH',
    Ri: 0.3763,
    Qi: 0.4321,
    ExampleComponent: 'naphthalene',
    ExampleFragments: '8 ACH, 2 AC',
  },
  {
    DataId: 12,
    MainGroupId: 4,
    MainGroup: 'ACCH2',
    SubGroup: 'ACC',
    Ri: 0.5847,
    Qi: 0.12,
    ExampleComponent: 'tert-butylbenzene',
    ExampleFragments: '3 CH3, 5 ACH, 1 ACC',
  },
  {
    DataId: 13,
    MainGroupId: 4,
    MainGroup: 'ACCH2',
    SubGroup: 'ACCH',
    Ri: 0.91,
    Qi: 0.3769,
    ExampleComponent: 'isopropylbenzene',
    ExampleFragments: '2 CH3, 5 ACH, 1 ACCH',
  },
  {
    DataId: 14,
    MainGroupId: 4,
    MainGroup: 'ACCH2',
    SubGroup: 'ACCH2',
    Ri: 0.91,
    Qi: 0.7962,
    ExampleComponent: 'ethylbenzene',
    ExampleFragments: '1 CH3, 5 ACH, 1 ACCH2',
  },
  {
    DataId: 15,
    MainGroupId: 4,
    MainGroup: 'ACCH2',
    SubGroup: 'ACCH3',
    Ri: 0.91,
    Qi: 0.949,
    ExampleComponent: 'toluene',
    ExampleFragments: '5 ACH, 1 ACCH3',
  },
  {
    DataId: 16,
    MainGroupId: 5,
    MainGroup: 'OH',
    SubGroup: 'OH tert',
    Ri: 0.6895,
    Qi: 0.8345,
    ExampleComponent: 'tert-butanol',
    ExampleFragments: '3 CH3, 1 C , 1 OH(t)',
  },
  {
    DataId: 17,
    MainGroupId: 5,
    MainGroup: 'OH',
    SubGroup: 'OH sec',
    Ri: 1.063,
    Qi: 0.8663,
    ExampleComponent: '2-propanol',
    ExampleFragments: '2 CH3, 1 CH, 1 OH(s)',
  },
  {
    DataId: 18,
    MainGroupId: 5,
    MainGroup: 'OH',
    SubGroup: 'OH prim',
    Ri: 1.2302,
    Qi: 0.8927,
    ExampleComponent: '1-propanol',
    ExampleFragments: '1 CH3, 2 CH2, 1 OH(p)',
  },
  {
    DataId: 19,
    MainGroupId: 6,
    MainGroup: 'CH3OH',
    SubGroup: 'CH3OH',
    Ri: 0.8585,
    Qi: 0.9938,
    ExampleComponent: 'methanol',
    ExampleFragments: '1 CH3OH',
  },
  {
    DataId: 20,
    MainGroupId: 7,
    MainGroup: 'H2O',
    SubGroup: 'H2O',
    Ri: 1.7334,
    Qi: 2.4561,
    ExampleComponent: 'water',
    ExampleFragments: '1 H2O',
  },
  {
    DataId: 21,
    MainGroupId: 8,
    MainGroup: 'ACOH',
    SubGroup: 'ACOH',
    Ri: 1.08,
    Qi: 0.975,
    ExampleComponent: 'phenol',
    ExampleFragments: '5 ACH, 1 ACOH',
  },
  {
    DataId: 22,
    MainGroupId: 9,
    MainGroup: 'CH2CO',
    SubGroup: 'CCO',
    Ri: 1.7048,
    Qi: 1.5542,
    ExampleComponent: 'methyl tert-butyl ketone',
    ExampleFragments: '1 CCO, 4 CH3',
  },
  {
    DataId: 23,
    MainGroupId: 9,
    MainGroup: 'CH2CO',
    SubGroup: 'CHCO',
    Ri: 1.7048,
    Qi: 1.5542,
    ExampleComponent: 'ethyl isopropyl ketone',
    ExampleFragments: '1 CHCO, 3 CH3, 1 CH2',
  },
  {
    DataId: 24,
    MainGroupId: 9,
    MainGroup: 'CH2CO',
    SubGroup: 'CH2CO',
    Ri: 1.7048,
    Qi: 1.5542,
    ExampleComponent: '3-pentanone',
    ExampleFragments: '2 CH3, 1 CH2, 1 CH2CO',
  },
  {
    DataId: 25,
    MainGroupId: 9,
    MainGroup: 'CH2CO',
    SubGroup: 'CH3CO',
    Ri: 1.7048,
    Qi: 1.67,
    ExampleComponent: '2-butanone',
    ExampleFragments: '1 CH3, 1 CH2, 1 CH3CO',
  },
  {
    DataId: 26,
    MainGroupId: 10,
    MainGroup: 'CHO',
    SubGroup: 'HCHO',
    Ri: 0.7173,
    Qi: 0.771,
    ExampleComponent: 'formaldehyde',
    ExampleFragments: '1 HCHO',
  },
  {
    DataId: 27,
    MainGroupId: 10,
    MainGroup: 'CHO',
    SubGroup: 'CHO',
    Ri: 0.7173,
    Qi: 0.771,
    ExampleComponent: 'propionic aldehyde',
    ExampleFragments: '1 CH3, 1 CH2, 1 CHO',
  },
  {
    DataId: 28,
    MainGroupId: 11,
    MainGroup: 'CCOO',
    SubGroup: 'CH2COO',
    Ri: 1.27,
    Qi: 1.4228,
    ExampleComponent: 'methyl propionate',
    ExampleFragments: '2 CH3, 1 CH2COO',
  },
  {
    DataId: 29,
    MainGroupId: 11,
    MainGroup: 'CCOO',
    SubGroup: 'CH3COO',
    Ri: 1.27,
    Qi: 1.6286,
    ExampleComponent: 'butyl acetate',
    ExampleFragments: '1 CH3, 3 CH2, 1 CH3COO',
  },
  {
    DataId: 30,
    MainGroupId: 12,
    MainGroup: 'HCOO',
    SubGroup: 'HCOO',
    Ri: 1.9,
    Qi: 1.8,
    ExampleComponent: 'ethyl formate',
    ExampleFragments: '1 CH3, 1 CH2, 1 HCOO',
  },
  {
    DataId: 31,
    MainGroupId: 13,
    MainGroup: 'CH2O',
    SubGroup: 'CHO',
    Ri: 1.1434,
    Qi: 0.8968,
    ExampleComponent: 'diisopropyl ether',
    ExampleFragments: '4 CH3, 1 CH, 1 CHO',
  },
  {
    DataId: 32,
    MainGroupId: 13,
    MainGroup: 'CH2O',
    SubGroup: 'CH2O',
    Ri: 1.1434,
    Qi: 1.2495,
    ExampleComponent: 'diethyl ether',
    ExampleFragments: '2 CH3, 1 CH2, 1 CH2O',
  },
  {
    DataId: 33,
    MainGroupId: 13,
    MainGroup: 'CH2O',
    SubGroup: 'CH3O',
    Ri: 1.1434,
    Qi: 1.6022,
    ExampleComponent: 'dimethyl ether',
    ExampleFragments: '1 CH3, 1 CH3O',
  },
  {
    DataId: 34,
    MainGroupId: 14,
    MainGroup: 'CNH2',
    SubGroup: 'CH3NH2',
    Ri: 1.6607,
    Qi: 1.6904,
    ExampleComponent: 'methylamine',
    ExampleFragments: '1 CH3NH2',
  },
  {
    DataId: 35,
    MainGroupId: 14,
    MainGroup: 'CNH2',
    SubGroup: 'CNH2',
    Ri: 1.6607,
    Qi: 0.985,
    ExampleComponent: 'tert-butylamine',
    ExampleFragments: '3 CH3, 1 CNH2',
  },
  {
    DataId: 36,
    MainGroupId: 14,
    MainGroup: 'CNH2',
    SubGroup: 'CHNH2',
    Ri: 1.6607,
    Qi: 0.985,
    ExampleComponent: 'isopropylamine',
    ExampleFragments: '2 CH3, 1 CHNH2',
  },
  {
    DataId: 37,
    MainGroupId: 14,
    MainGroup: 'CNH2',
    SubGroup: 'CH2NH2',
    Ri: 1.6607,
    Qi: 1.3377,
    ExampleComponent: 'ethylamine',
    ExampleFragments: '1 CH3, 1 CH2NH2',
  },
  {
    DataId: 38,
    MainGroupId: 15,
    MainGroup: 'CNH',
    SubGroup: 'CHNH',
    Ri: 1.368,
    Qi: 0.7278,
    ExampleComponent: 'diisopropylamine',
    ExampleFragments: '4 CH3, 1 CH, 1 CHNH',
  },
  {
    DataId: 39,
    MainGroupId: 15,
    MainGroup: 'CNH',
    SubGroup: 'CH2NH',
    Ri: 1.368,
    Qi: 1.0805,
    ExampleComponent: 'diethylamine',
    ExampleFragments: '2 CH3, 1 CH2, 1 CH2NH',
  },
  {
    DataId: 40,
    MainGroupId: 15,
    MainGroup: 'CNH',
    SubGroup: 'CH3NH',
    Ri: 1.368,
    Qi: 1.4332,
    ExampleComponent: 'dimethylamine',
    ExampleFragments: '1 CH3, 1 CH3NH',
  },
  {
    DataId: 41,
    MainGroupId: 16,
    MainGroup: '(C)3N',
    SubGroup: 'CH2N',
    Ri: 1.0746,
    Qi: 0.824,
    ExampleComponent: 'triethylamine',
    ExampleFragments: '3 CH3, 2 CH2, 1 CH2N',
  },
  {
    DataId: 42,
    MainGroupId: 16,
    MainGroup: '(C)3N',
    SubGroup: 'CH3N',
    Ri: 1.0746,
    Qi: 1.176,
    ExampleComponent: 'trimethylamine',
    ExampleFragments: '2 CH3, 1 CH3N',
  },
  {
    DataId: 43,
    MainGroupId: 17,
    MainGroup: 'ACNH2',
    SubGroup: 'ACN',
    Ri: 1.1849,
    Qi: 0.61,
    ExampleComponent: '1,1-dimethylbenzenamine',
    ExampleFragments: '5 ACH, 1 ACN, 2 CH3',
  },
  {
    DataId: 44,
    MainGroupId: 17,
    MainGroup: 'ACNH2',
    SubGroup: 'ACNH',
    Ri: 1.1849,
    Qi: 0.732,
    ExampleComponent: 'n-methylaniline',
    ExampleFragments: '5 ACH, 1 ACNH, 1 CH3',
  },
  {
    DataId: 45,
    MainGroupId: 17,
    MainGroup: 'ACNH2',
    SubGroup: 'ACNH2',
    Ri: 1.1849,
    Qi: 0.8067,
    ExampleComponent: 'aniline',
    ExampleFragments: '5 ACH, 1 ACNH2',
  },
  {
    DataId: 46,
    MainGroupId: 18,
    MainGroup: 'Pyridine',
    SubGroup: 'AC2N',
    Ri: 1.0731,
    Qi: 0.353,
    ExampleComponent: '2,3-dimethylpyridine',
    ExampleFragments: '1 AC2N, 3ACH, 2 CH3',
  },
  {
    DataId: 47,
    MainGroupId: 18,
    MainGroup: 'Pyridine',
    SubGroup: 'AC2HN',
    Ri: 1.2393,
    Qi: 0.633,
    ExampleComponent: '2-methylpyridine',
    ExampleFragments: '1 AC2HN, 3 ACH, 1 CH3',
  },
  {
    DataId: 48,
    MainGroupId: 18,
    MainGroup: 'Pyridine',
    SubGroup: 'AC2H2N',
    Ri: 1.4578,
    Qi: 0.9022,
    ExampleComponent: 'pyridine',
    ExampleFragments: '1 AC2H2N, 3 ACH',
  },
  {
    DataId: 49,
    MainGroupId: 19,
    MainGroup: 'CCN',
    SubGroup: 'CH3CN',
    Ri: 1.5575,
    Qi: 1.5193,
    ExampleComponent: 'acetonitrile',
    ExampleFragments: '1 CH3CN',
  },
  {
    DataId: 50,
    MainGroupId: 19,
    MainGroup: 'CCN',
    SubGroup: 'CCN',
    Ri: 1.5575,
    Qi: 1.1666,
    ExampleComponent: '2,2-dimethylpropionitrile',
    ExampleFragments: '3 CH3, 1 CCN',
  },
  {
    DataId: 51,
    MainGroupId: 19,
    MainGroup: 'CCN',
    SubGroup: 'CHCN',
    Ri: 1.5575,
    Qi: 1.1666,
    ExampleComponent: 'isobutyronitrile',
    ExampleFragments: '2 CH3, 1 CHCN',
  },
  {
    DataId: 52,
    MainGroupId: 19,
    MainGroup: 'CCN',
    SubGroup: 'CH2CN',
    Ri: 1.5575,
    Qi: 1.1666,
    ExampleComponent: 'propionitrile',
    ExampleFragments: '1 CH3, 1 CH2CN',
  },
  {
    DataId: 53,
    MainGroupId: 20,
    MainGroup: 'COOH',
    SubGroup: 'COOH',
    Ri: 0.8,
    Qi: 0.9215,
    ExampleComponent: 'acetic acid',
    ExampleFragments: '1 CH3, 1 COOH',
  },
  {
    DataId: 54,
    MainGroupId: 21,
    MainGroup: 'CCl',
    SubGroup: 'CCl',
    Ri: 0.9919,
    Qi: 0.66,
    ExampleComponent: 'tert-butyl chloride',
    ExampleFragments: '3 CH3, 1 CCl',
  },
  {
    DataId: 55,
    MainGroupId: 21,
    MainGroup: 'CCl',
    SubGroup: 'CHCl',
    Ri: 0.9919,
    Qi: 1.0127,
    ExampleComponent: '2-chloropropane',
    ExampleFragments: '2 CH3, 1 CHCl',
  },
  {
    DataId: 56,
    MainGroupId: 21,
    MainGroup: 'CCl',
    SubGroup: 'CH2Cl',
    Ri: 0.9919,
    Qi: 1.3654,
    ExampleComponent: '1-chlorobutane',
    ExampleFragments: '1 CH3, 2 CH2, 1 CH2Cl',
  },
  {
    DataId: 57,
    MainGroupId: 22,
    MainGroup: 'CCl2',
    SubGroup: 'CH2Cl2',
    Ri: 1.8,
    Qi: 2.5,
    ExampleComponent: 'dichloromethane',
    ExampleFragments: '1 CH2Cl2',
  },
  {
    DataId: 58,
    MainGroupId: 22,
    MainGroup: 'CCl2',
    SubGroup: 'CCl2',
    Ri: 1.8,
    Qi: 1.7946,
    ExampleComponent: '2,2-dichloropropane',
    ExampleFragments: '2 CH3, 1 CCl2',
  },
  {
    DataId: 59,
    MainGroupId: 22,
    MainGroup: 'CCl2',
    SubGroup: 'CHCl2',
    Ri: 1.8,
    Qi: 2.1473,
    ExampleComponent: '1,1-dichloroethane',
    ExampleFragments: '1 CH3, 1 CHCl2',
  },
  {
    DataId: 60,
    MainGroupId: 23,
    MainGroup: 'CCl3',
    SubGroup: 'CCl3',
    Ri: 2.65,
    Qi: 2.3778,
    ExampleComponent: '1,1,1-trichloroethane',
    ExampleFragments: '1 CH3, 1 CCl3',
  },
  {
    DataId: 61,
    MainGroupId: 24,
    MainGroup: 'CCl4',
    SubGroup: 'CCl4',
    Ri: 2.618,
    Qi: 3.1863,
    ExampleComponent: 'tetrachloromethane',
    ExampleFragments: '1 CCl4',
  },
  {
    DataId: 62,
    MainGroupId: 25,
    MainGroup: 'ACCl',
    SubGroup: 'ACCl',
    Ri: 0.5365,
    Qi: 0.3177,
    ExampleComponent: 'chlorobenzene',
    ExampleFragments: '5 ACH, 1 ACCl',
  },
  {
    DataId: 63,
    MainGroupId: 26,
    MainGroup: 'CNO2',
    SubGroup: 'CH3NO2',
    Ri: 2.644,
    Qi: 2.5,
    ExampleComponent: 'nitromethane',
    ExampleFragments: '1 CH3NO2',
  },
  {
    DataId: 64,
    MainGroupId: 26,
    MainGroup: 'CNO2',
    SubGroup: 'CNO2',
    Ri: 2.887,
    Qi: 2.241,
    ExampleComponent: '2-methyl nitropropane',
    ExampleFragments: '3 CH3, 1 CNO2',
  },
  {
    DataId: 65,
    MainGroupId: 26,
    MainGroup: 'CNO2',
    SubGroup: 'CHNO2',
    Ri: 2.887,
    Qi: 2.241,
    ExampleComponent: '2-nitropropane',
    ExampleFragments: '2 CH3, 1 CHNO2',
  },
  {
    DataId: 66,
    MainGroupId: 26,
    MainGroup: 'CNO2',
    SubGroup: 'CH2NO2',
    Ri: 2.5,
    Qi: 2.304,
    ExampleComponent: '1-nitropropane',
    ExampleFragments: '1 CH3, 1 CH2, 1 CH2NO2',
  },
  {
    DataId: 67,
    MainGroupId: 27,
    MainGroup: 'ACNO2',
    SubGroup: 'ACNO2',
    Ri: 0.4656,
    Qi: 0.3589,
    ExampleComponent: 'nitrobenzene',
    ExampleFragments: '5 ACH, 1 ACNO2',
  },
  {
    DataId: 68,
    MainGroupId: 28,
    MainGroup: 'CS2',
    SubGroup: 'CS2',
    Ri: 1.24,
    Qi: 1.068,
    ExampleComponent: 'carbon disulfide',
    ExampleFragments: '1 CS2',
  },
  {
    DataId: 69,
    MainGroupId: 29,
    MainGroup: 'CH2SH',
    SubGroup: 'CH3SH',
    Ri: 1.289,
    Qi: 1.762,
    ExampleComponent: 'methanethiol',
    ExampleFragments: '1 CH3SH',
  },
  {
    DataId: 70,
    MainGroupId: 29,
    MainGroup: 'CH2SH',
    SubGroup: 'ACSH',
    Ri: 1.2887,
    Qi: 1.2,
    ExampleComponent: 'benzenethiol',
    ExampleFragments: '5 ACH, 1 ACSH',
  },
  {
    DataId: 71,
    MainGroupId: 29,
    MainGroup: 'CH2SH',
    SubGroup: 'CSH',
    Ri: 1.1958,
    Qi: 1.1401,
    ExampleComponent: 'tert-butanethiol',
    ExampleFragments: '3 CH3, 1 CSH',
  },
  {
    DataId: 72,
    MainGroupId: 29,
    MainGroup: 'CH2SH',
    SubGroup: 'CHSH',
    Ri: 1.4232,
    Qi: 1.21,
    ExampleComponent: '2-propanethiol',
    ExampleFragments: '2 CH3, 1 CHSH',
  },
  {
    DataId: 73,
    MainGroupId: 29,
    MainGroup: 'CH2SH',
    SubGroup: 'CH2SH',
    Ri: 1.535,
    Qi: 1.316,
    ExampleComponent: 'ethanethiol',
    ExampleFragments: '1 CH2, 1 CH2SH',
  },
  {
    DataId: 74,
    MainGroupId: 30,
    MainGroup: 'Furfural',
    SubGroup: 'Furfural',
    Ri: 1.299,
    Qi: 1.289,
    ExampleComponent: 'furfural',
    ExampleFragments: '1 furfural',
  },
  {
    DataId: 75,
    MainGroupId: 31,
    MainGroup: 'DOH',
    SubGroup: 'CH2(OH)-CH2(OH)',
    Ri: 3.7374,
    Qi: 3.2016,
    ExampleComponent: '1,2-ethanediol',
    ExampleFragments: '1 CH2(OH)-CH2(OH)',
  },
  {
    DataId: 76,
    MainGroupId: 31,
    MainGroup: 'DOH',
    SubGroup: '>C(OH)-C(OH)<',
    Ri: 2.644,
    Qi: 1.669,
    ExampleComponent: '2,3-dimethyl-2,3-butanediol',
    ExampleFragments: '4 CH3, 1 >C(OH)-C(OH)<',
  },
  {
    DataId: 77,
    MainGroupId: 31,
    MainGroup: 'DOH',
    SubGroup: '>C(OH)-CH(OH)-',
    Ri: 3.0175,
    Qi: 2.0562,
    ExampleComponent: '2-methyl-2,3-butanediol',
    ExampleFragments: '3 CH3, 1 >C(OH)-CH(OH)-',
  },
  {
    DataId: 78,
    MainGroupId: 31,
    MainGroup: 'DOH',
    SubGroup: '-CH(OH)-CH(OH)-',
    Ri: 3.391,
    Qi: 2.4434,
    ExampleComponent: '2,3-butanediol',
    ExampleFragments: '2 CH3, 1-CH(OH)-CH(OH)-',
  },
  {
    DataId: 79,
    MainGroupId: 31,
    MainGroup: 'DOH',
    SubGroup: '>C(OH)-CH2(OH)',
    Ri: 3.1847,
    Qi: 2.1144,
    ExampleComponent: '2-methyl-2,3-propanediol',
    ExampleFragments: '2 CH3, 1 >C(OH)-CH2(OH)',
  },
  {
    DataId: 80,
    MainGroupId: 31,
    MainGroup: 'DOH',
    SubGroup: '-CH(OH)-CH2(OH)',
    Ri: 3.5642,
    Qi: 2.8225,
    ExampleComponent: '1,2-propylene glycol',
    ExampleFragments: '1 CH3, 1 -CH(OH)-CH2(OH)',
  },
  {
    DataId: 81,
    MainGroupId: 32,
    MainGroup: 'I',
    SubGroup: 'I',
    Ri: 1.076,
    Qi: 0.9169,
    ExampleComponent: 'ethyl iodide',
    ExampleFragments: '1 CH3, 1 CH2, 1 I',
  },
  {
    DataId: 82,
    MainGroupId: 33,
    MainGroup: 'Br',
    SubGroup: 'Br',
    Ri: 1.209,
    Qi: 1.4,
    ExampleComponent: 'ethyl bromide',
    ExampleFragments: '1 CH3, 1 CH2, 1 Br',
  },
  {
    DataId: 83,
    MainGroupId: 34,
    MainGroup: 'C#C',
    SubGroup: 'C#C',
    Ri: 1.303,
    Qi: 1.132,
    ExampleComponent: '2-hexyne',
    ExampleFragments: '2 CH3, 2 CH2, 1 C#C',
  },
  {
    DataId: 84,
    MainGroupId: 34,
    MainGroup: 'C#C',
    SubGroup: 'CH#C',
    Ri: 0.9214,
    Qi: 1.3,
    ExampleComponent: '1-hexyne',
    ExampleFragments: '1 CH3, 3 CH2, 1 CH#C',
  },
  {
    DataId: 85,
    MainGroupId: 35,
    MainGroup: 'DMSO',
    SubGroup: 'DMSO',
    Ri: 3.6,
    Qi: 2.692,
    ExampleComponent: 'dimethyl sulfoxide',
    ExampleFragments: '1 DMSO',
  },
  {
    DataId: 86,
    MainGroupId: 36,
    MainGroup: 'Acrylonitrile',
    SubGroup: 'Acrylonitrile',
    Ri: 1.0,
    Qi: 0.92,
    ExampleComponent: 'acrylonitrile',
    ExampleFragments: '1 acrylonitrile',
  },
  {
    DataId: 87,
    MainGroupId: 37,
    MainGroup: 'Cl-(C=C)',
    SubGroup: 'Cl—(C=C)',
    Ri: 0.5229,
    Qi: 0.7391,
    ExampleComponent: 'trichloroethylene',
    ExampleFragments: '1 CH=C, 3 Cl(C=C)',
  },
  {
    DataId: 88,
    MainGroupId: 38,
    MainGroup: 'ACF',
    SubGroup: 'ACF',
    Ri: 0.8814,
    Qi: 0.7269,
    ExampleComponent: 'hexafluorobenzene',
    ExampleFragments: '6 ACF',
  },
  {
    DataId: 89,
    MainGroupId: 39,
    MainGroup: 'DMF',
    SubGroup: 'DMF',
    Ri: 2.0,
    Qi: 2.093,
    ExampleComponent: 'N,N-dimethylformamide',
    ExampleFragments: '1 DMF',
  },
  {
    DataId: 90,
    MainGroupId: 39,
    MainGroup: 'DMF',
    SubGroup: 'HCON(CH2)2',
    Ri: 2.381,
    Qi: 1.522,
    ExampleComponent: 'N,N-diethylformamide',
    ExampleFragments: '2 CH3, 1 HCON(CH2)2',
  },
  {
    DataId: 91,
    MainGroupId: 40,
    MainGroup: 'CF2',
    SubGroup: 'CF3',
    Ri: 2.7489,
    Qi: 2.7769,
    ExampleComponent: '1,1,1-trifluoroethane',
    ExampleFragments: '1 CH3, 1 CF3',
  },
  {
    DataId: 92,
    MainGroupId: 40,
    MainGroup: 'CF2',
    SubGroup: 'CF2',
    Ri: 1.4778,
    Qi: 1.4738,
    ExampleComponent: 'perfluorohexane',
    ExampleFragments: '2 CF3, 4 CF2',
  },
  {
    DataId: 93,
    MainGroupId: 40,
    MainGroup: 'CF2',
    SubGroup: 'CF',
    Ri: 0.8215,
    Qi: 0.5135,
    ExampleComponent: 'perfluoromethylcyclohexane',
    ExampleFragments: '1 CF3, 5 CF2, 1 CF',
  },
  {
    DataId: 94,
    MainGroupId: 41,
    MainGroup: 'COO',
    SubGroup: 'COO',
    Ri: 1.6,
    Qi: 0.9,
    ExampleComponent: 'methyl acrylate',
    ExampleFragments: '1 CH3, 1 CH2=CH, 1 COO',
  },
  {
    DataId: 95,
    MainGroupId: 42,
    MainGroup: 'c-CH2',
    SubGroup: 'c-C',
    Ri: 0.347,
    Qi: 0.0,
    ExampleComponent: '1,1-dimethylcyclohexane',
    ExampleFragments: '2 CH3, 5 c-CH2, 1 c-C',
  },
  {
    DataId: 96,
    MainGroupId: 42,
    MainGroup: 'c-CH2',
    SubGroup: 'c-CH',
    Ri: 0.3479,
    Qi: 0.1071,
    ExampleComponent: 'methylcyclohexane',
    ExampleFragments: '1 CH3, 5 c-CH2, 1 c-CH',
  },
  {
    DataId: 97,
    MainGroupId: 42,
    MainGroup: 'c-CH2',
    SubGroup: 'c-CH2',
    Ri: 0.7136,
    Qi: 0.8635,
    ExampleComponent: 'cyclohexane',
    ExampleFragments: '6 c-CH2',
  },
  {
    DataId: 98,
    MainGroupId: 43,
    MainGroup: 'c-CH2O',
    SubGroup: 'CH2-O-CH2',
    Ri: 1.7023,
    Qi: 1.8784,
    ExampleComponent: 'tetrahydrofuran',
    ExampleFragments: '2 c-CH2, 1 c-CH2OCH2',
  },
  {
    DataId: 99,
    MainGroupId: 43,
    MainGroup: 'c-CH2O',
    SubGroup: 'CH2-O-[CH2-O]1/2',
    Ri: 1.4046,
    Qi: 1.4,
    ExampleComponent: '1,3-dioxane',
    ExampleFragments: '1 c-CH2, 2 c-CH2O[CH2]1/2',
  },
  {
    DataId: 100,
    MainGroupId: 43,
    MainGroup: 'c-CH2O',
    SubGroup: '[O-CH2]1/2-O-[CH2-O]1/2',
    Ri: 1.0413,
    Qi: 1.0116,
    ExampleComponent: '1,3,5-trioxane',
    ExampleFragments: '3 c-[CH2]1/2-O-[CH2]1/2',
  },
  {
    DataId: 101,
    MainGroupId: 44,
    MainGroup: 'HCOOH',
    SubGroup: 'HCOOH',
    Ri: 0.8,
    Qi: 1.2742,
    ExampleComponent: 'formic acid',
    ExampleFragments: '1 HCOOH',
  },
  {
    DataId: 102,
    MainGroupId: 45,
    MainGroup: 'CHCl3',
    SubGroup: 'CHCl3',
    Ri: 2.45,
    Qi: 2.8912,
    ExampleComponent: 'chloroform',
    ExampleFragments: '1 CHCl3',
  },
  {
    DataId: 103,
    MainGroupId: 46,
    MainGroup: 'c-CONC',
    SubGroup: 'c-CON-C',
    Ri: 3.2994,
    Qi: 2.352,
    ExampleComponent: 'N-tert-butylpyrrolidone',
    ExampleFragments: '1 c-CON-C, 3 c-CH2, 3 CH3',
  },
  {
    DataId: 104,
    MainGroupId: 46,
    MainGroup: 'c-CONC',
    SubGroup: 'c-CON-CH',
    Ri: 3.5268,
    Qi: 2.58,
    ExampleComponent: 'N-isopropylpyrrolidone',
    ExampleFragments: '1 c-CON-CH, 3 c-CH2, 2 CH3',
  },
  {
    DataId: 105,
    MainGroupId: 46,
    MainGroup: 'c-CONC',
    SubGroup: 'c-CON-CH2',
    Ri: 3.7543,
    Qi: 2.892,
    ExampleComponent: 'N-ethylpyrrolidone',
    ExampleFragments: '1 c-CON-CH2, 3 c-CH2, 1 CH3',
  },
  {
    DataId: 106,
    MainGroupId: 46,
    MainGroup: 'c-CONC',
    SubGroup: 'c-CON-CH3',
    Ri: 3.981,
    Qi: 3.2,
    ExampleComponent: 'N-methylpyrrolidone',
    ExampleFragments: '1 c-CON-CH3, 3 c-CH2',
  },
  {
    DataId: 107,
    MainGroupId: 47,
    MainGroup: 'CONR',
    SubGroup: 'CONHCH2',
    Ri: 1.5,
    Qi: 1.08,
    ExampleComponent: 'N-ethylacetamide',
    ExampleFragments: '2 CH3, 1 CONHCH2',
  },
  {
    DataId: 108,
    MainGroupId: 47,
    MainGroup: 'CONR',
    SubGroup: 'CONHCH3',
    Ri: 1.5,
    Qi: 1.08,
    ExampleComponent: 'N-methylacetamide',
    ExampleFragments: '1 CH3, 1 CONHCH3',
  },
  {
    DataId: 109,
    MainGroupId: 48,
    MainGroup: 'CONR2',
    SubGroup: 'CON(CH2)2',
    Ri: 2.0767,
    Qi: 1.1866,
    ExampleComponent: 'N,N-diethylacetamide',
    ExampleFragments: '3 CH3, 1 CON(CH2)2',
  },
  {
    DataId: 110,
    MainGroupId: 48,
    MainGroup: 'CONR2',
    SubGroup: 'CON(CH3)CH2',
    Ri: 2.2739,
    Qi: 1.5754,
    ExampleComponent: 'N,N-methylethylacetamide',
    ExampleFragments: '2 CH3, 1 CONCH2(CH3)',
  },
  {
    DataId: 111,
    MainGroupId: 48,
    MainGroup: 'CONR2',
    SubGroup: 'CON(CH3)2',
    Ri: 2.4748,
    Qi: 1.9643,
    ExampleComponent: 'N,N-dimethylacetamide',
    ExampleFragments: '1 CH3, 1 CON(CH3)2',
  },
  {
    DataId: 112,
    MainGroupId: 49,
    MainGroup: 'HCONR',
    SubGroup: 'HCONHCH3',
    Ri: 2.4617,
    Qi: 2.192,
    ExampleComponent: 'N-methylformamide',
    ExampleFragments: '1 HCONHCH3',
  },
  {
    DataId: 113,
    MainGroupId: 49,
    MainGroup: 'HCONR',
    SubGroup: 'HCONHCH2',
    Ri: 2.4617,
    Qi: 1.842,
    ExampleComponent: 'N-ethylformamide',
    ExampleFragments: '1 CH3, 1 HCONHCH2',
  },
  {
    DataId: 114,
    MainGroupId: 50,
    MainGroup: 'ACCN',
    SubGroup: 'ACCN',
    Ri: 1.2815,
    Qi: 0.96,
    ExampleComponent: 'benzonitrile',
    ExampleFragments: '1 ACCN,  5 ACH',
  },
  {
    DataId: 115,
    MainGroupId: 51,
    MainGroup: 'NCO',
    SubGroup: 'CH3NCO',
    Ri: 1.9578,
    Qi: 1.58,
    ExampleComponent: 'methyl isocyanate',
    ExampleFragments: '1 CH3NCO',
  },
  {
    DataId: 116,
    MainGroupId: 51,
    MainGroup: 'NCO',
    SubGroup: 'ACNCO',
    Ri: 1.4219,
    Qi: 0.852,
    ExampleComponent: 'phenyl isocyanate',
    ExampleFragments: '1 ACNCO, 5 ACH',
  },
  {
    DataId: 117,
    MainGroupId: 51,
    MainGroup: 'NCO',
    SubGroup: 'CHNCO',
    Ri: 1.5036,
    Qi: 0.96,
    ExampleComponent: 'isopropyl isocyanate',
    ExampleFragments: '1 CHNCO, 2 CH3',
  },
  {
    DataId: 118,
    MainGroupId: 51,
    MainGroup: 'NCO',
    SubGroup: 'CH2NCO',
    Ri: 1.731,
    Qi: 1.272,
    ExampleComponent: 'ethyl isocyanate',
    ExampleFragments: '1 CH2NCO, 1 CH3',
  },
  {
    DataId: 119,
    MainGroupId: 52,
    MainGroup: 'ACS',
    SubGroup: 'AC2S',
    Ri: 1.4621,
    Qi: 0.78,
    ExampleComponent: '2,5-dimethylthiophene',
    ExampleFragments: '2 CH3, 2 ACH, 1 AC2S',
  },
  {
    DataId: 120,
    MainGroupId: 52,
    MainGroup: 'ACS',
    SubGroup: 'AC2HS',
    Ri: 1.6282,
    Qi: 1.06,
    ExampleComponent: '2-methylthiophene',
    ExampleFragments: '1 CH3, 2 ACH, 1 AC2S',
  },
  {
    DataId: 121,
    MainGroupId: 52,
    MainGroup: 'ACS',
    SubGroup: 'AC2H2S',
    Ri: 1.7943,
    Qi: 1.34,
    ExampleComponent: 'thiophene',
    ExampleFragments: '2 ACH, 1 AC2H2S',
  },
  {
    DataId: 122,
    MainGroupId: 53,
    MainGroup: 'Epoxy',
    SubGroup: 'H2COCH',
    Ri: 1.3601,
    Qi: 1.8031,
    ExampleComponent: 'propylene oxide',
    ExampleFragments: '1 H2COCH, 1 CH3',
  },
  {
    DataId: 123,
    MainGroupId: 53,
    MainGroup: 'Epoxy',
    SubGroup: 'HCOCH',
    Ri: 0.9104,
    Qi: 0.6538,
    ExampleComponent: '2,3-epoxybutane',
    ExampleFragments: '1 HCOCH, 2 CH3',
  },
  {
    DataId: 124,
    MainGroupId: 54,
    MainGroup: 'Anhydride',
    SubGroup: 'COOCO',
    Ri: 1.7732,
    Qi: 1.52,
    ExampleComponent: 'acetic anhydride',
    ExampleFragments: '1 COOCO, 2 CH3',
  },
  {
    DataId: 125,
    MainGroupId: 55,
    MainGroup: 'Carbonate',
    SubGroup: '(CH3O)2CO',
    Ri: 3.0613,
    Qi: 2.816,
    ExampleComponent: 'dimethyl carbonate',
    ExampleFragments: '1 (CH3O)2CO',
  },
  {
    DataId: 126,
    MainGroupId: 55,
    MainGroup: 'Carbonate',
    SubGroup: '(ACO)CO(OAC)',
    Ri: 1.9895,
    Qi: 1.36,
    ExampleComponent: 'diphenyl carbonate',
    ExampleFragments: '1 (ACO)COOCH2, 10 ACH',
  },
  {
    DataId: 127,
    MainGroupId: 55,
    MainGroup: 'Carbonate',
    SubGroup: '(ACO)COOCH2',
    Ri: 2.2854,
    Qi: 1.78,
    ExampleComponent: 'ethyl phenyl carbonate',
    ExampleFragments: '1 (ACO)COOCH2, 5 ACH, 1 CH3',
  },
  {
    DataId: 128,
    MainGroupId: 55,
    MainGroup: 'Carbonate',
    SubGroup: '(CH2O)2CO',
    Ri: 2.6078,
    Qi: 2.2,
    ExampleComponent: 'diethyl carbonate',
    ExampleFragments: '1 (CH2O)2CO, 2 CH3',
  },
  {
    DataId: 129,
    MainGroupId: 55,
    MainGroup: 'Carbonate',
    SubGroup: '(CH3O)COOCH2',
    Ri: 2.8214,
    Qi: 2.508,
    ExampleComponent: 'ethyl methyl carbonate',
    ExampleFragments: '1 (CH3O) COOCH2, 1 CH3',
  },
  {
    DataId: 130,
    MainGroupId: 56,
    MainGroup: 'Sulfone',
    SubGroup: 'ACSO2',
    Ri: 1.7034,
    Qi: 1.16,
    ExampleComponent: 'methyl phenyl sulfone',
    ExampleFragments: '1 (AC) SO2, 5 ACH, 1 CH3',
  },
  {
    DataId: 131,
    MainGroupId: 56,
    MainGroup: 'Sulfone',
    SubGroup: 'CH2SuCH ',
    Ri: 2.46,
    Qi: 1.808,
    ExampleComponent: '2,4-dimethylsulfolane',
    ExampleFragments: '1 CH2SuCH, 2 CH3, 1 cy-CH2, 1 cy-CH',
  },
  {
    DataId: 132,
    MainGroupId: 56,
    MainGroup: 'Sulfone',
    SubGroup: 'CH2SuCH2',
    Ri: 2.687,
    Qi: 2.12,
    ExampleComponent: 'sulfolane',
    ExampleFragments: '1 CH2SuCH2, 2 cy-CH2',
  },
  {
    DataId: 133,
    MainGroupId: 57,
    MainGroup: 'ACCHO',
    SubGroup: 'ACCHO',
    Ri: 1.3632,
    Qi: 1.068,
    ExampleComponent: 'benzaldehyde',
    ExampleFragments: '1 ACCHO,  5 ACH',
  },
  {
    DataId: 134,
    MainGroupId: 58,
    MainGroup: 'ACCOOH',
    SubGroup: 'ACCOOH',
    Ri: 1.6664,
    Qi: 1.344,
    ExampleComponent: 'benzoic acid',
    ExampleFragments: '1 ACCOOH, 5 ACH',
  },
  {
    DataId: 135,
    MainGroupId: 59,
    MainGroup: 'AC-O-CO',
    SubGroup: 'AC-O-CO-C',
    Ri: 1.5999,
    Qi: 1.0,
    ExampleComponent: 'phenyl tert-pentanoate',
    ExampleFragments: '1 ACCOOC, 5 ACH, 3 CH3',
  },
  {
    DataId: 136,
    MainGroupId: 59,
    MainGroup: 'AC-O-CO',
    SubGroup: 'AC-O-CO-CH',
    Ri: 1.8273,
    Qi: 1.228,
    ExampleComponent: 'phenyl isobutyrate',
    ExampleFragments: '1 ACCOOCH, 5 ACH, 2 CH3',
  },
  {
    DataId: 137,
    MainGroupId: 59,
    MainGroup: 'AC-O-CO',
    SubGroup: 'AC-O-CO-CH2',
    Ri: 2.0547,
    Qi: 1.54,
    ExampleComponent: 'phenyl propionate',
    ExampleFragments: '1 ACCOOCH2, 5 ACH, 1 CH3',
  },
  {
    DataId: 138,
    MainGroupId: 59,
    MainGroup: 'AC-O-CO',
    SubGroup: 'AC-O-CO-CH3 ',
    Ri: 2.2815,
    Qi: 1.848,
    ExampleComponent: 'phenyl acetate',
    ExampleFragments: '1 ACOOCH3, 5 ACH',
  },
  {
    DataId: 139,
    MainGroupId: 60,
    MainGroup: 'OCCOH',
    SubGroup: '-O-CH-CH2-OH',
    Ri: 1.8952,
    Qi: 1.592,
    ExampleComponent: '2-ethoxy-1-propanol',
    ExampleFragments: '1 -O-CH-CH2-OH, 2 CH3, 1 CH2 ',
  },
  {
    DataId: 140,
    MainGroupId: 60,
    MainGroup: 'OCCOH',
    SubGroup: '-O-CH2-CH-OH',
    Ri: 1.8952,
    Qi: 1.592,
    ExampleComponent: '1-methoxy-2-propanol',
    ExampleFragments: '1 -O-CH2-CH-OH, 2 CH3',
  },
  {
    DataId: 141,
    MainGroupId: 60,
    MainGroup: 'OCCOH',
    SubGroup: '-O-CH2-CH2-OH',
    Ri: 2.1226,
    Qi: 1.904,
    ExampleComponent: '2-ethoxyethanol',
    ExampleFragments: '1 -O-CH2-CH2-OH, 1 CH3, 1 CH2 ',
  },
  {
    DataId: 142,
    MainGroupId: 61,
    MainGroup: 'CH2S',
    SubGroup: '->C-S-',
    Ri: 0.9314,
    Qi: 0.52,
    ExampleComponent: 'methyl tert-butyl sulfide',
    ExampleFragments: '1 ->C-S-, 4 CH3',
  },
  {
    DataId: 143,
    MainGroupId: 61,
    MainGroup: 'CH2S',
    SubGroup: 'ACS',
    Ri: 1.0771,
    Qi: 0.64,
    ExampleComponent: 'methyl phenyl sulfide',
    ExampleFragments: '1 ACS, 5 ACH, 1 CH3',
  },
  {
    DataId: 144,
    MainGroupId: 61,
    MainGroup: 'CH2S',
    SubGroup: '>CH-S-',
    Ri: 1.1589,
    Qi: 0.748,
    ExampleComponent: 'diisopropyl sulfide ',
    ExampleFragments: '1 >CH-S-. 1 CH, 4 CH3',
  },
  {
    DataId: 145,
    MainGroupId: 61,
    MainGroup: 'CH2S',
    SubGroup: '-CH2-S-',
    Ri: 1.3863,
    Qi: 1.06,
    ExampleComponent: 'diethyl sulfide',
    ExampleFragments: '1 -CH2-S-, 1 CH2,  2 CH3',
  },
  {
    DataId: 146,
    MainGroupId: 61,
    MainGroup: 'CH2S',
    SubGroup: 'CH3-S-',
    Ri: 1.6131,
    Qi: 1.368,
    ExampleComponent: 'dimethyl sulfide',
    ExampleFragments: '1 CH3-S-, 1 CH3',
  },
  {
    DataId: 147,
    MainGroupId: 62,
    MainGroup: 'Lactam',
    SubGroup: 'c-CO-NH',
    Ri: 1.3039,
    Qi: 1.036,
    ExampleComponent: 'propiolactam',
    ExampleFragments: '1 c-CONH, 2 c-CH2',
  },
  {
    DataId: 148,
    MainGroupId: 63,
    MainGroup: 'Lactone',
    SubGroup: 'c-CO-O',
    Ri: 1.0152,
    Qi: 0.88,
    ExampleComponent: 'propiolactone',
    ExampleFragments: '1 c-CO-O, 2 c-CH2',
  },
  {
    DataId: 149,
    MainGroupId: 64,
    MainGroup: 'Peroxide',
    SubGroup: 'ACO-(O)',
    Ri: 0.853,
    Qi: 0.6,
    ExampleComponent: 'acetyl benzoyl peroxide',
    ExampleFragments: '1 ACO-(O), C=O, Ph',
  },
  {
    DataId: 150,
    MainGroupId: 64,
    MainGroup: 'Peroxide',
    SubGroup: 'CO-(O)',
    Ri: 1.0152,
    Qi: 0.88,
    ExampleComponent: 'di-tert-butyl peroxide',
    ExampleFragments: '1 CO-(O), 6 CH3, 1 C',
  },
  {
    DataId: 151,
    MainGroupId: 64,
    MainGroup: 'Peroxide',
    SubGroup: 'CHO-(O)',
    Ri: 0.9347,
    Qi: 0.708,
    ExampleComponent: 'diisopropyl peroxide',
    ExampleFragments: '1 CHO-(O), 4 CH3, 1 CH',
  },
  {
    DataId: 152,
    MainGroupId: 64,
    MainGroup: 'Peroxide',
    SubGroup: 'CH2O-(O)',
    Ri: 1.1622,
    Qi: 1.02,
    ExampleComponent: 'diethyl peroxide',
    ExampleFragments: '1 CH2O-(O), 2 CH3, 1 CH2',
  },
  {
    DataId: 153,
    MainGroupId: 64,
    MainGroup: 'Peroxide',
    SubGroup: 'CH3O-(O)',
    Ri: 1.3889,
    Qi: 1.328,
    ExampleComponent: 'dimethyl peroxide',
    ExampleFragments: '1 CH3-O-(O), 1 CH3',
  },
  {
    DataId: 154,
    MainGroupId: 65,
    MainGroup: 'CFH',
    SubGroup: 'CFH',
    Ri: 0.5966,
    Qi: 0.44,
    ExampleComponent: 'fluoromethylene',
    ExampleFragments: '1 CFH',
  },
  {
    DataId: 155,
    MainGroupId: 66,
    MainGroup: 'CFCl',
    SubGroup: 'CFCl',
    Ri: 1.4034,
    Qi: 1.168,
    ExampleComponent: 'chlorofluoromethylene',
    ExampleFragments: '1 CFCl',
  },
  {
    DataId: 156,
    MainGroupId: 67,
    MainGroup: 'CFCl2',
    SubGroup: 'CFCl2',
    Ri: 2.2103,
    Qi: 1.896,
    ExampleComponent: 'tetrachloro-1,2-difluoroethane',
    ExampleFragments: '2 CFCl2',
  },
  {
    DataId: 157,
    MainGroupId: 68,
    MainGroup: 'CF2H',
    SubGroup: 'CF2H',
    Ri: 0.9736,
    Qi: 0.88,
    ExampleComponent: 'difluoromethyl',
    ExampleFragments: '1 CF2H',
  },
  {
    DataId: 158,
    MainGroupId: 69,
    MainGroup: 'CF2ClH',
    SubGroup: 'CF2ClH',
    Ri: 1.7396,
    Qi: 1.6,
    ExampleComponent: 'chlorodifluoromethane',
    ExampleFragments: '1 CF2ClH',
  },
  {
    DataId: 159,
    MainGroupId: 70,
    MainGroup: 'CF2Cl2',
    SubGroup: 'CF2Cl2',
    Ri: 2.5873,
    Qi: 2.336,
    ExampleComponent: 'dichlorodifluoromethane',
    ExampleFragments: '1 CF2Cl2',
  },
  {
    DataId: 160,
    MainGroupId: 71,
    MainGroup: 'CF3H',
    SubGroup: 'CF3H',
    Ri: 1.3507,
    Qi: 1.32,
    ExampleComponent: 'fluoroform',
    ExampleFragments: '1 CHF3',
  },
  {
    DataId: 161,
    MainGroupId: 72,
    MainGroup: 'CF3Cl',
    SubGroup: 'CF3Cl',
    Ri: 2.1575,
    Qi: 2.048,
    ExampleComponent: 'chlorotrifluoromethane',
    ExampleFragments: '1 CF3Cl ',
  },
  {
    DataId: 162,
    MainGroupId: 73,
    MainGroup: 'CF4',
    SubGroup: 'CF4',
    Ri: 1.7278,
    Qi: 1.76,
    ExampleComponent: 'tetrafluoromethane',
    ExampleFragments: '1 CF4',
  },
  {
    DataId: 163,
    MainGroupId: 74,
    MainGroup: 'Acetal',
    SubGroup: 'C(O)2',
    Ri: 0.7073,
    Qi: 0.48,
    ExampleComponent: 'ethane, 1,1-diethoxy-',
    ExampleFragments: '2 C(O)2, 3 CH3',
  },
  {
    DataId: 164,
    MainGroupId: 74,
    MainGroup: 'Acetal',
    SubGroup: 'CH(O)2',
    Ri: 0.9347,
    Qi: 0.708,
    ExampleComponent: 'aspirin',
    ExampleFragments: '2 CH(O)2, 1 CH3, 4 ACH, 2 AC',
  },
  {
    DataId: 165,
    MainGroupId: 74,
    MainGroup: 'Acetal',
    SubGroup: 'CH2(O)2',
    Ri: 0.9347,
    Qi: 0.708,
    ExampleComponent: '',
    ExampleFragments: '',
  },
  {
    DataId: 166,
    MainGroupId: 75,
    MainGroup: 'ACNR2',
    SubGroup: 'ACN(CH2)2',
    Ri: 1.9993,
    Qi: 1.292,
    ExampleComponent: 'n,n-diethylaniline',
    ExampleFragments: '1 ACN(CH2)2, 5 ACH, 2 CH3',
  },
  {
    DataId: 167,
    MainGroupId: 75,
    MainGroup: 'ACNR2',
    SubGroup: 'ACN(CH3)CH2',
    Ri: 2.2261,
    Qi: 1.6,
    ExampleComponent: 'n-ethyl-n-methyl aniline',
    ExampleFragments: '1 ACN(CH3)(CH2), 5 ACH, 1 CH3',
  },
  {
    DataId: 168,
    MainGroupId: 75,
    MainGroup: 'ACNR2',
    SubGroup: 'ACN(CH3)2',
    Ri: 2.4529,
    Qi: 1.908,
    ExampleComponent: 'n,n-dimetylaniline',
    ExampleFragments: '1 ACN(CH3)2, 5 ACH',
  },
  {
    DataId: 169,
    MainGroupId: 76,
    MainGroup: 'ACNR',
    SubGroup: 'ACNHCH',
    Ri: 1.3448,
    Qi: 0.744,
    ExampleComponent: 'n-isopropyanline',
    ExampleFragments: '1 ACNCH, 5 ACH, 2 CH3',
  },
  {
    DataId: 170,
    MainGroupId: 76,
    MainGroup: 'ACNR',
    SubGroup: 'ACNHCH2',
    Ri: 1.5722,
    Qi: 1.056,
    ExampleComponent: 'n-ethylaniline',
    ExampleFragments: '1 ACNCH2, 5 ACH, 1 CH3',
  },
  {
    DataId: 171,
    MainGroupId: 76,
    MainGroup: 'ACNR',
    SubGroup: 'ACNHCH3',
    Ri: 1.7989,
    Qi: 1.364,
    ExampleComponent: 'n-methylaniline',
    ExampleFragments: '1 ACNCH3, 5 5ACH',
  },
  {
    DataId: 172,
    MainGroupId: 77,
    MainGroup: 'Furan',
    SubGroup: 'AC2O',
    Ri: 0.9743,
    Qi: 0.48,
    ExampleComponent: 'dibenzofuran',
    ExampleFragments: '1 AC2O, 8 ACH, 2 AC',
  },
  {
    DataId: 173,
    MainGroupId: 77,
    MainGroup: 'Furan',
    SubGroup: 'AC2HO',
    Ri: 1.1404,
    Qi: 0.76,
    ExampleComponent: 'benzaldehyde,2-(-oxoethenyl)-',
    ExampleFragments: '1 AC2HO, 1 ACHO, 2 AC, 4 ACH',
  },
  {
    DataId: 174,
    MainGroupId: 77,
    MainGroup: 'Furan',
    SubGroup: 'AC2H2O',
    Ri: 1.3065,
    Qi: 1.04,
    ExampleComponent: 'furan',
    ExampleFragments: '1 AC2H2O, 2 ACH',
  },
  {
    DataId: 175,
    MainGroupId: 78,
    MainGroup: 'c-CNH',
    SubGroup: 'c-C-NH',
    Ri: 0.7521,
    Qi: 0.396,
    ExampleComponent: '6-aminohexanoic acid',
    ExampleFragments: '1 C-NH, 1 c-C, 1 CO, 5 c-CH',
  },
  {
    DataId: 176,
    MainGroupId: 78,
    MainGroup: 'c-CNH',
    SubGroup: 'c-CH-NH',
    Ri: 0.9796,
    Qi: 0.624,
    ExampleComponent: '2,6-lupetidine',
    ExampleFragments: '1 c-CH-NH, 2 CH3, 3 c-CH2',
  },
  {
    DataId: 177,
    MainGroupId: 78,
    MainGroup: 'c-CNH',
    SubGroup: 'c-CH2-NH',
    Ri: 1.207,
    Qi: 0.936,
    ExampleComponent: 'piperazine',
    ExampleFragments: '1 c-CH2-NH, 2 c-CH2',
  },
  {
    DataId: 178,
    MainGroupId: 79,
    MainGroup: 'c-CNR',
    SubGroup: 'c-CH-NCH',
    Ri: 1.1793,
    Qi: 0.548,
    ExampleComponent: 'n-isopropyl-2-methyl-piperidine',
    ExampleFragments: '1 c-CH-NCH, 4 c-CH2, 3 CH3',
  },
  {
    DataId: 179,
    MainGroupId: 79,
    MainGroup: 'c-CNR',
    SubGroup: 'c-CH2-NCH',
    Ri: 1.4067,
    Qi: 0.86,
    ExampleComponent: 'n-isopropyl-piperidine',
    ExampleFragments: '1 c-CH2-NCH, 4 c-CH2, 2 CH3',
  },
  {
    DataId: 180,
    MainGroupId: 79,
    MainGroup: 'c-CNR',
    SubGroup: 'c-CH-NCH2',
    Ri: 1.4067,
    Qi: 0.86,
    ExampleComponent: '1-ethyl-2-methyl-piperidine',
    ExampleFragments: '1 c-CH-NCH2, 4 c-CH2, 2 CH3',
  },
  {
    DataId: 181,
    MainGroupId: 79,
    MainGroup: 'c-CNR',
    SubGroup: 'c-CH2-NCH2',
    Ri: 1.6341,
    Qi: 1.172,
    ExampleComponent: 'n-ethylpiperidine',
    ExampleFragments: '1 c-CH2-NCH2, 4 c-CH2, 1 CH3',
  },
  {
    DataId: 182,
    MainGroupId: 79,
    MainGroup: 'c-CNR',
    SubGroup: 'c-CH-NCH3',
    Ri: 1.6335,
    Qi: 1.168,
    ExampleComponent: '1,2-dimethyl-piperidine',
    ExampleFragments: '1 c-CH-NCH3, 4 c-CH2, 1 CH3',
  },
  {
    DataId: 183,
    MainGroupId: 79,
    MainGroup: 'c-CNR',
    SubGroup: 'c-CH2-NCH3',
    Ri: 1.8609,
    Qi: 1.48,
    ExampleComponent: 'n-methylpiperidine',
    ExampleFragments: '1 c-CH2-NCH3, 4 c-CH2',
  },
  {
    DataId: 184,
    MainGroupId: 80,
    MainGroup: 'SiH',
    SubGroup: '>Si<',
    Ri: 1.047,
    Qi: 0.41,
    ExampleComponent: 'hexamethyldisiloxane',
    ExampleFragments: '1 >Si<, 6 CH3, 1 ->SiO-',
  },
  {
    DataId: 185,
    MainGroupId: 80,
    MainGroup: 'SiH',
    SubGroup: '>SiH-',
    Ri: 1.2853,
    Qi: 0.749,
    ExampleComponent: 'heptamethyltrisiloxane',
    ExampleFragments: '1 >SiH-. 7 CH3, 2 ->SiO-',
  },
  {
    DataId: 186,
    MainGroupId: 80,
    MainGroup: 'SiH',
    SubGroup: '-SiH2-',
    Ri: 1.4443,
    Qi: 1.006,
    ExampleComponent: 'diethylsilane',
    ExampleFragments: '1 -SiH2-. 2 CH2, 2 CH3',
  },
  {
    DataId: 187,
    MainGroupId: 80,
    MainGroup: 'SiH',
    SubGroup: 'SiH3-',
    Ri: 1.6035,
    Qi: 1.263,
    ExampleComponent: 'methylsilane',
    ExampleFragments: '1 SiH3-, 1 CH3',
  },
  {
    DataId: 188,
    MainGroupId: 81,
    MainGroup: 'SiO',
    SubGroup: '->Si-O-',
    Ri: 1.1044,
    Qi: 0.466,
    ExampleComponent: 'octamethylcyclotetrasiloxane',
    ExampleFragments: '4 ->Si-O-, 8 CH3',
  },
  {
    DataId: 189,
    MainGroupId: 81,
    MainGroup: 'SiO',
    SubGroup: '>SiH-O-',
    Ri: 1.303,
    Qi: 0.764,
    ExampleComponent: '1,1,3,3-tetramethyldisiloxane',
    ExampleFragments: '1 >SiH-O-, 4 CH3, 1 >SiH-',
  },
  {
    DataId: 190,
    MainGroupId: 81,
    MainGroup: 'SiO',
    SubGroup: '-SiH2-O-',
    Ri: 1.4838,
    Qi: 1.062,
    ExampleComponent: '1,3-dimethyldisiloxane',
    ExampleFragments: '1 -SiH2-O-, 2 CH3, 1 -SiH2-',
  },
  {
    DataId: 191,
    MainGroupId: 82,
    MainGroup: 'Oxime',
    SubGroup: 'C=NOH',
    Ri: 1.499,
    Qi: 1.46,
    ExampleComponent: '2-propanone oxime',
    ExampleFragments: '1 C=NOH, 2 CH3',
  },
  {
    DataId: 192,
    MainGroupId: 82,
    MainGroup: 'Oxime',
    SubGroup: 'CH=NOH',
    Ri: 1.499,
    Qi: 1.46,
    ExampleComponent: 'propanal oxime',
    ExampleFragments: '1 CH=NOH, 1 CH3, 1 CH2',
  },
  {
    DataId: 193,
    MainGroupId: 83,
    MainGroup: 'ACCOO',
    SubGroup: 'ACCO',
    Ri: 1.1365,
    Qi: 0.76,
    ExampleComponent: 'acetophenone',
    ExampleFragments: '1 ACCO, 5 ACH, 1 CH3',
  },
  {
    DataId: 194,
    MainGroupId: 86,
    MainGroup: 'C2Cl4',
    SubGroup: 'C2Cl4',
    Ri: 3.381,
    Qi: 3.5845,
    ExampleComponent: 'tetrachloroethene',
    ExampleFragments: '1 C2Cl4',
  },
  {
    DataId: 195,
    MainGroupId: 92,
    MainGroup: 'c-CHNH2',
    SubGroup: 'c-CHNH2',
    Ri: 1.2261,
    Qi: 1.096,
    ExampleComponent: 'cyclohexylamine',
    ExampleFragments: '1 c-CHNH2, 5 c-CH2',
  },
  {
    DataId: 196,
    MainGroupId: 94,
    MainGroup: 'Pyrrole',
    SubGroup: 'AC2NH',
    Ri: 0.8701,
    Qi: 0.212,
    ExampleComponent: '2,3-dimethylpyrrole',
    ExampleFragments: '1 AC2NH, 2 ACH, 2 CH3',
  },
  {
    DataId: 197,
    MainGroupId: 94,
    MainGroup: 'Pyrrole',
    SubGroup: 'AC2HNH',
    Ri: 1.0976,
    Qi: 0.44,
    ExampleComponent: '2-methylpyrrole',
    ExampleFragments: '1 AC2HNH, 2 ACH, 1 CH3',
  },
  {
    DataId: 198,
    MainGroupId: 94,
    MainGroup: 'Pyrrole',
    SubGroup: 'AC2H2NH',
    Ri: 1.325,
    Qi: 0.752,
    ExampleComponent: 'pyrrole',
    ExampleFragments: '1 AC2H2NH, 2 ACH',
  },
  {
    DataId: 199,
    MainGroupId: 95,
    MainGroup: 'c-C=C',
    SubGroup: 'c-C=C',
    Ri: 0.5498,
    Qi: 0.244,
    ExampleComponent: '1,2-dimethylcyclohexene',
    ExampleFragments: '1 c-C=C, 2 CH3, 4 c-CH2',
  },
  {
    DataId: 200,
    MainGroupId: 95,
    MainGroup: 'c-C=C',
    SubGroup: 'c-CH=C',
    Ri: 0.8616,
    Qi: 0.644,
    ExampleComponent: '1-methylcyclohexene',
    ExampleFragments: '1 c-CH-C, 1 CH3, 4 c-CH2',
  },
  {
    DataId: 201,
    MainGroupId: 95,
    MainGroup: 'c-C=C',
    SubGroup: 'c-CH=CH',
    Ri: 1.0897,
    Qi: 0.832,
    ExampleComponent: 'cyclohexene',
    ExampleFragments: '1 c-CH=CH, 4 c-CH2',
  },
  {
    DataId: 202,
    MainGroupId: 96,
    MainGroup: 'Glycerol',
    SubGroup: 'Glycerol',
    Ri: 5.4209,
    Qi: 4.4227,
    ExampleComponent: 'glycerol',
    ExampleFragments: '1 Glycerol',
  },
];

const interactionParameters = [
  { DataId: 1, MainGroup1: 1, MainGroup2: 2, a1: 257.05, a2: -0.1011, '1000a3': 0.0, Tmin: 277.59, Tmax: 576.96 },
  { DataId: 2, MainGroup1: 2, MainGroup2: 1, a1: -125.99, a2: -0.1271, '1000a3': 0.0, Tmin: 277.59, Tmax: 576.96 },
  { DataId: 3, MainGroup1: 1, MainGroup2: 3, a1: 137.14, a2: -0.32, '1000a3': 1.2503, Tmin: 178.59, Tmax: 463.11 },
  { DataId: 4, MainGroup1: 3, MainGroup2: 1, a1: 65.28, a2: -0.4342, '1000a3': -0.194, Tmin: 178.59, Tmax: 463.11 },
  { DataId: 5, MainGroup1: 1, MainGroup2: 4, a1: -22.55, a2: -0.4158, '1000a3': 0.063473, Tmin: 157.17, Tmax: 553.13 },
  { DataId: 6, MainGroup1: 4, MainGroup2: 1, a1: 169.14, a2: -0.3624, '1000a3': 0.93857, Tmin: 157.17, Tmax: 553.13 },
  { DataId: 7, MainGroup1: 1, MainGroup2: 5, a1: 3119.2, a2: -6.073, '1000a3': 2.3986, Tmin: 202.71, Tmax: 503.15 },
  { DataId: 8, MainGroup1: 5, MainGroup2: 1, a1: 1857.23, a2: -8.7982, '1000a3': 10.757, Tmin: 202.71, Tmax: 503.15 },
  { DataId: 9, MainGroup1: 1, MainGroup2: 6, a1: 1037.92, a2: 0.816, '1000a3': 0.0, Tmin: 263.15, Tmax: 423.09 },
  { DataId: 10, MainGroup1: 6, MainGroup2: 1, a1: 94.61, a2: -0.5052, '1000a3': 0.0, Tmin: 263.15, Tmax: 423.09 },
  { DataId: 11, MainGroup1: 1, MainGroup2: 7, a1: 1391.3, a2: -3.6156, '1000a3': 1.144, Tmin: 273.15, Tmax: 548.1 },
  { DataId: 12, MainGroup1: 7, MainGroup2: 1, a1: -17.25, a2: 0.8389, '1000a3': 0.9021, Tmin: 273.15, Tmax: 548.1 },
  { DataId: 13, MainGroup1: 1, MainGroup2: 8, a1: 1070.74, a2: -0.7685, '1000a3': 0.0, Tmin: 281.35, Tmax: 483.43 },
  { DataId: 14, MainGroup1: 8, MainGroup2: 1, a1: 2597.16, a2: -4.8864, '1000a3': 0.0, Tmin: 281.35, Tmax: 483.43 },
  { DataId: 15, MainGroup1: 1, MainGroup2: 9, a1: 175.24, a2: 1.1209, '1000a3': 0.0, Tmin: 233.2, Tmax: 476.65 },
  { DataId: 16, MainGroup1: 9, MainGroup2: 1, a1: 282.42, a2: -1.2006, '1000a3': 0.0, Tmin: 233.2, Tmax: 476.65 },
  { DataId: 17, MainGroup1: 1, MainGroup2: 10, a1: 864.14, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 423.15 },
  { DataId: 18, MainGroup1: 10, MainGroup2: 1, a1: 569.78, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 423.15 },
  { DataId: 19, MainGroup1: 1, MainGroup2: 11, a1: 113.19, a2: 1.8837, '1000a3': -3.8391, Tmin: 278.15, Tmax: 433.02 },
  { DataId: 20, MainGroup1: 11, MainGroup2: 1, a1: 737.0, a2: -3.0713, '1000a3': 3.1041, Tmin: 278.15, Tmax: 433.02 },
  { DataId: 21, MainGroup1: 1, MainGroup2: 12, a1: 521.38, a2: -0.5881, '1000a3': 0.3446, Tmin: 223.5, Tmax: 440.92 },
  { DataId: 22, MainGroup1: 12, MainGroup2: 1, a1: 237.35, a2: -0.7084, '1000a3': -0.19047, Tmin: 223.5, Tmax: 440.92 },
  { DataId: 23, MainGroup1: 1, MainGroup2: 13, a1: 204.31, a2: -0.5302, '1000a3': 0.0, Tmin: 266.52, Tmax: 423.15 },
  { DataId: 24, MainGroup1: 13, MainGroup2: 1, a1: -0.39, a2: 0.3745, '1000a3': 0.0, Tmin: 266.52, Tmax: 423.15 },
  { DataId: 25, MainGroup1: 1, MainGroup2: 14, a1: -178.35, a2: 5.0636, '1000a3': -10.134, Tmin: 218.15, Tmax: 373.15 },
  { DataId: 26, MainGroup1: 14, MainGroup2: 1, a1: 316.72, a2: -2.6579, '1000a3': 3.3917, Tmin: 218.15, Tmax: 373.15 },
  { DataId: 27, MainGroup1: 1, MainGroup2: 15, a1: 799.62, a2: -1.7341, '1000a3': 0.0, Tmin: 223.15, Tmax: 442.1 },
  { DataId: 28, MainGroup1: 15, MainGroup2: 1, a1: -37.73, a2: -0.1101, '1000a3': 0.0, Tmin: 223.15, Tmax: 442.1 },
  { DataId: 29, MainGroup1: 1, MainGroup2: 16, a1: -664.03, a2: 2.5482, '1000a3': 1.5386, Tmin: 293.15, Tmax: 359.28 },
  { DataId: 30, MainGroup1: 16, MainGroup2: 1, a1: 542.7, a2: -0.2562, '1000a3': -6.0572, Tmin: 293.15, Tmax: 359.28 },
  {
    DataId: 31,
    MainGroup1: 1,
    MainGroup2: 17,
    a1: -5103.18,
    a2: 39.2626,
    '1000a3': -59.394,
    Tmin: 273.15,
    Tmax: 456.33,
  },
  {
    DataId: 32,
    MainGroup1: 17,
    MainGroup2: 1,
    a1: 11279.3,
    a2: -56.4842,
    '1000a3': 71.276,
    Tmin: 273.15,
    Tmax: 456.33,
  },
  { DataId: 33, MainGroup1: 1, MainGroup2: 18, a1: 941.53, a2: -5.9816, '1000a3': 10.003, Tmin: 273.15, Tmax: 465.82 },
  { DataId: 34, MainGroup1: 18, MainGroup2: 1, a1: 180.43, a2: 0.3212, '1000a3': -3.3627, Tmin: 273.15, Tmax: 465.82 },
  { DataId: 35, MainGroup1: 1, MainGroup2: 19, a1: 732.9, a2: 0.0947, '1000a3': 0.0, Tmin: 141.11, Tmax: 393.07 },
  { DataId: 36, MainGroup1: 19, MainGroup2: 1, a1: 92.68, a2: -0.7171, '1000a3': 0.0, Tmin: 141.11, Tmax: 393.07 },
  { DataId: 37, MainGroup1: 1, MainGroup2: 20, a1: 1443.33, a2: -4.902, '1000a3': 11.595, Tmin: 289.15, Tmax: 542.9 },
  { DataId: 38, MainGroup1: 20, MainGroup2: 1, a1: 1874.82, a2: -5.2344, '1000a3': 0.82871, Tmin: 289.15, Tmax: 542.9 },
  { DataId: 39, MainGroup1: 1, MainGroup2: 21, a1: 267.83, a2: 0.1764, '1000a3': 0.0, Tmin: 271.75, Tmax: 400.15 },
  { DataId: 40, MainGroup1: 21, MainGroup2: 1, a1: 26.22, a2: -0.4867, '1000a3': 0.0, Tmin: 271.75, Tmax: 400.15 },
  { DataId: 41, MainGroup1: 1, MainGroup2: 22, a1: -216.89, a2: 1.0618, '1000a3': 0.0, Tmin: 288.15, Tmax: 438.65 },
  { DataId: 42, MainGroup1: 22, MainGroup2: 1, a1: 314.21, a2: -1.0927, '1000a3': 0.0, Tmin: 288.15, Tmax: 438.65 },
  { DataId: 43, MainGroup1: 1, MainGroup2: 23, a1: -699.6, a2: 5.4279, '1000a3': -9.189, Tmin: 223.76, Tmax: 412.95 },
  { DataId: 44, MainGroup1: 23, MainGroup2: 1, a1: 1042.84, a2: -6.849, '1000a3': 10.254, Tmin: 223.76, Tmax: 412.95 },
  { DataId: 45, MainGroup1: 1, MainGroup2: 24, a1: 257.36, a2: -1.6832, '1000a3': 3.3128, Tmin: 205.16, Tmax: 396.72 },
  { DataId: 46, MainGroup1: 24, MainGroup2: 1, a1: -153.06, a2: 1.1513, '1000a3': -2.505, Tmin: 205.16, Tmax: 396.72 },
  { DataId: 47, MainGroup1: 1, MainGroup2: 25, a1: 2672.92, a2: -16.8364, '1000a3': 0.0, Tmin: 283.09, Tmax: 473.16 },
  { DataId: 48, MainGroup1: 25, MainGroup2: 1, a1: 2557.77, a2: -6.9014, '1000a3': 0.0, Tmin: 283.09, Tmax: 473.16 },
  {
    DataId: 49,
    MainGroup1: 1,
    MainGroup2: 26,
    a1: 2330.94,
    a2: -13.2964,
    '1000a3': 21.997,
    Tmin: 293.15,
    Tmax: 398.75,
  },
  { DataId: 50, MainGroup1: 26, MainGroup2: 1, a1: -374.74, a2: 2.987, '1000a3': -6.1657, Tmin: 293.15, Tmax: 398.75 },
  { DataId: 51, MainGroup1: 1, MainGroup2: 27, a1: 3019.92, a2: -2.8981, '1000a3': 0.0, Tmin: 245.64, Tmax: 462.65 },
  { DataId: 52, MainGroup1: 27, MainGroup2: 1, a1: 952.1, a2: -3.8152, '1000a3': 0.0, Tmin: 245.64, Tmax: 462.65 },
  { DataId: 53, MainGroup1: 1, MainGroup2: 28, a1: 40.27, a2: 1.325, '1000a3': 0.0, Tmin: 290.14, Tmax: 358.3 },
  { DataId: 54, MainGroup1: 28, MainGroup2: 1, a1: 67.17, a2: -1.0682, '1000a3': 0.0, Tmin: 290.14, Tmax: 358.3 },
  { DataId: 55, MainGroup1: 1, MainGroup2: 29, a1: 471.83, a2: -0.7861, '1000a3': 0.0, Tmin: 223.16, Tmax: 413.15 },
  { DataId: 56, MainGroup1: 29, MainGroup2: 1, a1: -61.6, a2: -0.0541, '1000a3': 0.0, Tmin: 223.16, Tmax: 413.15 },
  { DataId: 57, MainGroup1: 1, MainGroup2: 30, a1: 719.02, a2: 0.9195, '1000a3': 0.0, Tmin: 293.15, Tmax: 437.51 },
  { DataId: 58, MainGroup1: 30, MainGroup2: 1, a1: 211.34, a2: -1.1229, '1000a3': 0.0, Tmin: 293.15, Tmax: 437.51 },
  { DataId: 59, MainGroup1: 1, MainGroup2: 31, a1: 972.55, a2: -0.3917, '1000a3': 0.0, Tmin: 291.25, Tmax: 470.71 },
  { DataId: 60, MainGroup1: 31, MainGroup2: 1, a1: -241.49, a2: 0.2582, '1000a3': 0.0, Tmin: 291.25, Tmax: 470.71 },
  { DataId: 61, MainGroup1: 1, MainGroup2: 32, a1: 500.72, a2: -0.1323, '1000a3': 0.0, Tmin: 293.1, Tmax: 367.05 },
  { DataId: 62, MainGroup1: 32, MainGroup2: 1, a1: 231.2, a2: -0.9521, '1000a3': 0.0, Tmin: 293.1, Tmax: 367.05 },
  { DataId: 63, MainGroup1: 1, MainGroup2: 33, a1: 178.26, a2: 1.7971, '1000a3': -3.0227, Tmin: 130.65, Tmax: 393.35 },
  { DataId: 64, MainGroup1: 33, MainGroup2: 1, a1: 137.44, a2: -1.7559, '1000a3': 2.0405, Tmin: 130.65, Tmax: 393.35 },
  { DataId: 65, MainGroup1: 1, MainGroup2: 34, a1: 497.24, a2: -0.9034, '1000a3': 0.0, Tmin: 231.94, Tmax: 428.71 },
  { DataId: 66, MainGroup1: 34, MainGroup2: 1, a1: 22.42, a2: 0.023, '1000a3': 0.0, Tmin: 231.94, Tmax: 428.71 },
  { DataId: 67, MainGroup1: 1, MainGroup2: 35, a1: -498.67, a2: 3.6653, '1000a3': 0.0, Tmin: 283.15, Tmax: 393.35 },
  { DataId: 68, MainGroup1: 35, MainGroup2: 1, a1: 460.82, a2: -2.1701, '1000a3': 0.0, Tmin: 283.15, Tmax: 393.35 },
  { DataId: 69, MainGroup1: 1, MainGroup2: 36, a1: 1465.58, a2: -0.9806, '1000a3': 0.0, Tmin: 298.35, Tmax: 393.07 },
  { DataId: 70, MainGroup1: 36, MainGroup2: 1, a1: 157.11, a2: -1.1171, '1000a3': 0.0, Tmin: 298.35, Tmax: 393.07 },
  { DataId: 71, MainGroup1: 1, MainGroup2: 37, a1: -106.73, a2: -0.2841, '1000a3': 0.0, Tmin: 259.05, Tmax: 358.02 },
  { DataId: 72, MainGroup1: 37, MainGroup2: 1, a1: 120.32, a2: 0.711, '1000a3': 0.0, Tmin: 259.05, Tmax: 358.02 },
  { DataId: 73, MainGroup1: 1, MainGroup2: 38, a1: 663.28, a2: -1.8417, '1000a3': -0.15867, Tmin: 298.14, Tmax: 370.3 },
  { DataId: 74, MainGroup1: 38, MainGroup2: 1, a1: -165.35, a2: -0.3578, '1000a3': 1.5565, Tmin: 298.14, Tmax: 370.3 },
  { DataId: 75, MainGroup1: 1, MainGroup2: 39, a1: -69.75, a2: 2.2727, '1000a3': -1.4881, Tmin: 273.1, Tmax: 425.85 },
  { DataId: 76, MainGroup1: 39, MainGroup2: 1, a1: -211.89, a2: 1.5162, '1000a3': -3.7412, Tmin: 273.1, Tmax: 425.85 },
  { DataId: 77, MainGroup1: 1, MainGroup2: 40, a1: 9.24, a2: -0.5011, '1000a3': -0.16331, Tmin: 243.82, Tmax: 393.31 },
  { DataId: 78, MainGroup1: 40, MainGroup2: 1, a1: 137.58, a2: 0.4602, '1000a3': 0.19504, Tmin: 243.82, Tmax: 393.31 },
  { DataId: 79, MainGroup1: 1, MainGroup2: 41, a1: 980.87, a2: -2.718, '1000a3': 0.0, Tmin: 263.15, Tmax: 407.57 },
  { DataId: 80, MainGroup1: 41, MainGroup2: 1, a1: 338.63, a2: -0.3346, '1000a3': 0.0, Tmin: 263.15, Tmax: 407.57 },
  { DataId: 81, MainGroup1: 1, MainGroup2: 42, a1: -183.15, a2: 0.9362, '1000a3': -2.1157, Tmin: 273.15, Tmax: 503.11 },
  { DataId: 82, MainGroup1: 42, MainGroup2: 1, a1: 287.64, a2: -1.5321, '1000a3': 3.1384, Tmin: 273.15, Tmax: 503.11 },
  { DataId: 83, MainGroup1: 1, MainGroup2: 43, a1: 31.36, a2: 0.7889, '1000a3': -2.0935, Tmin: 250.06, Tmax: 413.21 },
  { DataId: 84, MainGroup1: 43, MainGroup2: 1, a1: 213.49, a2: -1.3497, '1000a3': 2.315, Tmin: 250.06, Tmax: 413.21 },
  { DataId: 85, MainGroup1: 1, MainGroup2: 44, a1: -1576.18, a2: 6.6648, '1000a3': 0.0, Tmin: 292.98, Tmax: 444.31 },
  { DataId: 86, MainGroup1: 44, MainGroup2: 1, a1: 803.79, a2: -2.2809, '1000a3': 0.0, Tmin: 292.98, Tmax: 444.31 },
  { DataId: 87, MainGroup1: 1, MainGroup2: 45, a1: 156.66, a2: -0.1689, '1000a3': 0.0, Tmin: 289.15, Tmax: 367.05 },
  { DataId: 88, MainGroup1: 45, MainGroup2: 1, a1: -44.1, a2: -0.0324, '1000a3': 0.0, Tmin: 289.15, Tmax: 367.05 },
  { DataId: 89, MainGroup1: 1, MainGroup2: 46, a1: 486.51, a2: -1.6797, '1000a3': 0.0, Tmin: 253.11, Tmax: 463.8 },
  { DataId: 90, MainGroup1: 46, MainGroup2: 1, a1: -108.75, a2: 1.483, '1000a3': 0.0, Tmin: 253.11, Tmax: 463.8 },
  { DataId: 91, MainGroup1: 1, MainGroup2: 47, a1: 1793.04, a2: 0.2714, '1000a3': 0.0, Tmin: 297.1, Tmax: 413.49 },
  { DataId: 92, MainGroup1: 47, MainGroup2: 1, a1: 186.18, a2: -0.9358, '1000a3': 0.0, Tmin: 297.1, Tmax: 413.49 },
  { DataId: 93, MainGroup1: 1, MainGroup2: 48, a1: 1558.71, a2: -6.2609, '1000a3': 8.895, Tmin: 273.1, Tmax: 333.63 },
  { DataId: 94, MainGroup1: 48, MainGroup2: 1, a1: 96.82, a2: -0.2297, '1000a3': -1.9236, Tmin: 273.1, Tmax: 333.63 },
  { DataId: 95, MainGroup1: 1, MainGroup2: 49, a1: -927.47, a2: 7.1798, '1000a3': -8.6758, Tmin: 297.1, Tmax: 363.15 },
  { DataId: 96, MainGroup1: 49, MainGroup2: 1, a1: 243.79, a2: -1.2401, '1000a3': 0.30939, Tmin: 297.1, Tmax: 363.15 },
  { DataId: 97, MainGroup1: 1, MainGroup2: 51, a1: -432.51, a2: 0.8544, '1000a3': 0.0, Tmin: 313.34, Tmax: 399.12 },
  { DataId: 98, MainGroup1: 51, MainGroup2: 1, a1: -125.13, a2: 0.9312, '1000a3': 0.0, Tmin: 313.34, Tmax: 399.12 },
  { DataId: 99, MainGroup1: 1, MainGroup2: 52, a1: -1254.22, a2: 4.741, '1000a3': 0.0, Tmin: 303.15, Tmax: 338.15 },
  { DataId: 100, MainGroup1: 52, MainGroup2: 1, a1: 762.23, a2: -3.2159, '1000a3': 0.0, Tmin: 303.15, Tmax: 338.15 },
  { DataId: 101, MainGroup1: 1, MainGroup2: 53, a1: 31.15, a2: 0.9618, '1000a3': 0.0, Tmin: 300.39, Tmax: 363.15 },
  { DataId: 102, MainGroup1: 53, MainGroup2: 1, a1: 435.36, a2: -1.6469, '1000a3': 0.0, Tmin: 300.39, Tmax: 363.15 },
  { DataId: 103, MainGroup1: 1, MainGroup2: 54, a1: 1178.99, a2: -1.0258, '1000a3': 0.0, Tmin: 298.14, Tmax: 398.2 },
  { DataId: 104, MainGroup1: 54, MainGroup2: 1, a1: 656.12, a2: -1.3615, '1000a3': 0.0, Tmin: 298.14, Tmax: 398.2 },
  { DataId: 105, MainGroup1: 1, MainGroup2: 55, a1: 34.57, a2: -0.5165, '1000a3': 4.0991, Tmin: 273.1, Tmax: 440.54 },
  { DataId: 106, MainGroup1: 55, MainGroup2: 1, a1: 167.3, a2: -0.4946, '1000a3': -1.6198, Tmin: 273.1, Tmax: 440.54 },
  { DataId: 107, MainGroup1: 1, MainGroup2: 56, a1: 203.22, a2: 1.7137, '1000a3': 0.0, Tmin: 288.15, Tmax: 545.11 },
  { DataId: 108, MainGroup1: 56, MainGroup2: 1, a1: 663.27, a2: -1.9942, '1000a3': 0.0, Tmin: 288.15, Tmax: 545.11 },
  { DataId: 109, MainGroup1: 1, MainGroup2: 57, a1: 309.8, a2: 0.7668, '1000a3': 0.0, Tmin: 298.14, Tmax: 439.51 },
  { DataId: 110, MainGroup1: 57, MainGroup2: 1, a1: 15.39, a2: 0.1534, '1000a3': 0.0, Tmin: 298.14, Tmax: 439.51 },
  { DataId: 111, MainGroup1: 1, MainGroup2: 59, a1: 107.57, a2: 0.0, '1000a3': 0.0, Tmin: 288.15, Tmax: 538.16 },
  { DataId: 112, MainGroup1: 59, MainGroup2: 1, a1: -319.3, a2: 0.0, '1000a3': 0.0, Tmin: 288.15, Tmax: 538.16 },
  { DataId: 113, MainGroup1: 1, MainGroup2: 60, a1: 110.77, a2: 1.251, '1000a3': 0.0, Tmin: 275.73, Tmax: 473.0 },
  { DataId: 114, MainGroup1: 60, MainGroup2: 1, a1: 405.55, a2: -1.4474, '1000a3': 0.0, Tmin: 275.73, Tmax: 473.0 },
  { DataId: 115, MainGroup1: 1, MainGroup2: 61, a1: 989.36, a2: -3.3719, '1000a3': 4.9126, Tmin: 175.0, Tmax: 413.12 },
  { DataId: 116, MainGroup1: 61, MainGroup2: 1, a1: 624.36, a2: -5.2447, '1000a3': 8.056, Tmin: 175.0, Tmax: 413.12 },
  { DataId: 117, MainGroup1: 1, MainGroup2: 62, a1: 17.26, a2: 3.1036, '1000a3': 0.0, Tmin: 298.09, Tmax: 423.15 },
  { DataId: 118, MainGroup1: 62, MainGroup2: 1, a1: -1042.35, a2: 12.1353, '1000a3': 0.0, Tmin: 298.09, Tmax: 423.15 },
  { DataId: 119, MainGroup1: 1, MainGroup2: 64, a1: -403.33, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 368.23 },
  { DataId: 120, MainGroup1: 64, MainGroup2: 1, a1: 4894.4, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 368.23 },
  { DataId: 121, MainGroup1: 1, MainGroup2: 65, a1: 926.48, a2: 0.0, '1000a3': 0.0, Tmin: 303.14, Tmax: 323.14 },
  { DataId: 122, MainGroup1: 65, MainGroup2: 1, a1: 31.72, a2: 0.0, '1000a3': 0.0, Tmin: 303.14, Tmax: 323.14 },
  { DataId: 123, MainGroup1: 1, MainGroup2: 75, a1: 232.17, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 484.16 },
  { DataId: 124, MainGroup1: 75, MainGroup2: 1, a1: -187.75, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 484.16 },
  { DataId: 125, MainGroup1: 1, MainGroup2: 76, a1: 385.6, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 472.85 },
  { DataId: 126, MainGroup1: 76, MainGroup2: 1, a1: -51.98, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 472.85 },
  { DataId: 127, MainGroup1: 1, MainGroup2: 77, a1: -17.57, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 403.15 },
  { DataId: 128, MainGroup1: 77, MainGroup2: 1, a1: 167.46, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 403.15 },
  { DataId: 129, MainGroup1: 1, MainGroup2: 78, a1: 358.68, a2: 0.0, '1000a3': 0.0, Tmin: 283.15, Tmax: 343.15 },
  { DataId: 130, MainGroup1: 78, MainGroup2: 1, a1: -38.18, a2: 0.0, '1000a3': 0.0, Tmin: 283.15, Tmax: 343.15 },
  { DataId: 131, MainGroup1: 1, MainGroup2: 79, a1: -337.56, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 343.15 },
  { DataId: 132, MainGroup1: 79, MainGroup2: 1, a1: -197.25, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 343.15 },
  { DataId: 133, MainGroup1: 1, MainGroup2: 80, a1: -223.3, a2: 0.0, '1000a3': 0.0, Tmin: 253.2, Tmax: 308.13 },
  { DataId: 134, MainGroup1: 80, MainGroup2: 1, a1: 84.22, a2: 0.0, '1000a3': 0.0, Tmin: 253.2, Tmax: 308.13 },
  { DataId: 135, MainGroup1: 1, MainGroup2: 81, a1: -980.5, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 136, MainGroup1: 81, MainGroup2: 1, a1: -15.89, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 137, MainGroup1: 1, MainGroup2: 82, a1: 498.75, a2: 0.6562, '1000a3': 0.0, Tmin: 293.14, Tmax: 383.73 },
  { DataId: 138, MainGroup1: 82, MainGroup2: 1, a1: -253.86, a2: 1.2476, '1000a3': 0.0, Tmin: 293.14, Tmax: 383.73 },
  { DataId: 139, MainGroup1: 1, MainGroup2: 83, a1: 338.61, a2: 0.0, '1000a3': 0.0, Tmin: 294.49, Tmax: 475.87 },
  { DataId: 140, MainGroup1: 83, MainGroup2: 1, a1: 312.0, a2: 0.0, '1000a3': 0.0, Tmin: 294.49, Tmax: 475.87 },
  { DataId: 141, MainGroup1: 1, MainGroup2: 86, a1: 58.22, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 393.72 },
  { DataId: 142, MainGroup1: 86, MainGroup2: 1, a1: -61.98, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 393.72 },
  { DataId: 143, MainGroup1: 1, MainGroup2: 92, a1: 441.89, a2: -0.1639, '1000a3': 0.0, Tmin: 293.15, Tmax: 403.65 },
  { DataId: 144, MainGroup1: 92, MainGroup2: 1, a1: 522.11, a2: 0.7502, '1000a3': 0.0, Tmin: 293.15, Tmax: 403.65 },
  { DataId: 145, MainGroup1: 1, MainGroup2: 94, a1: 800.97, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 643.51 },
  { DataId: 146, MainGroup1: 94, MainGroup2: 1, a1: -219.04, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 643.51 },
  { DataId: 147, MainGroup1: 1, MainGroup2: 95, a1: 91.36, a2: -0.4614, '1000a3': 0.0, Tmin: 293.15, Tmax: 446.61 },
  { DataId: 148, MainGroup1: 95, MainGroup2: 1, a1: 48.71, a2: -0.3481, '1000a3': 0.0, Tmin: 293.15, Tmax: 446.61 },
  { DataId: 149, MainGroup1: 1, MainGroup2: 96, a1: -3.79, a2: 3.3144, '1000a3': 0.0, Tmin: 298.09, Tmax: 493.0 },
  { DataId: 150, MainGroup1: 96, MainGroup2: 1, a1: 83.83, a2: -1.0238, '1000a3': 0.0, Tmin: 298.09, Tmax: 493.0 },
  { DataId: 151, MainGroup1: 2, MainGroup2: 3, a1: -116.5, a2: 0.2659, '1000a3': 0.0, Tmin: 279.99, Tmax: 363.13 },
  { DataId: 152, MainGroup1: 3, MainGroup2: 2, a1: 146.29, a2: -0.3015, '1000a3': 0.0, Tmin: 279.99, Tmax: 363.13 },
  { DataId: 153, MainGroup1: 2, MainGroup2: 4, a1: 2032.33, a2: -4.9773, '1000a3': 0.0, Tmin: 249.93, Tmax: 414.6 },
  { DataId: 154, MainGroup1: 4, MainGroup2: 2, a1: 0.25, a2: -0.2521, '1000a3': 0.0, Tmin: 249.93, Tmax: 414.6 },
  { DataId: 155, MainGroup1: 2, MainGroup2: 5, a1: 2529.96, a2: -5.9572, '1000a3': 4.8653, Tmin: 249.84, Tmax: 421.21 },
  { DataId: 156, MainGroup1: 5, MainGroup2: 2, a1: 1433.2, a2: -6.2819, '1000a3': 7.5645, Tmin: 249.84, Tmax: 421.21 },
  {
    DataId: 157,
    MainGroup1: 2,
    MainGroup2: 6,
    a1: -670.14,
    a2: 16.5414,
    '1000a3': -32.479,
    Tmin: 249.98,
    Tmax: 373.15,
  },
  { DataId: 158, MainGroup1: 6, MainGroup2: 2, a1: 26.12, a2: -0.2668, '1000a3': -0.27287, Tmin: 249.98, Tmax: 373.15 },
  { DataId: 159, MainGroup1: 2, MainGroup2: 7, a1: 615.44, a2: -1.1646, '1000a3': 0.0, Tmin: 262.15, Tmax: 410.63 },
  { DataId: 160, MainGroup1: 7, MainGroup2: 2, a1: 38.11, a2: 0.9518, '1000a3': 0.0, Tmin: 262.15, Tmax: 410.63 },
  { DataId: 161, MainGroup1: 2, MainGroup2: 8, a1: 4260.72, a2: 0.2571, '1000a3': 0.0, Tmin: 289.98, Tmax: 462.25 },
  { DataId: 162, MainGroup1: 8, MainGroup2: 2, a1: 1406.01, a2: -2.8678, '1000a3': 0.0, Tmin: 289.98, Tmax: 462.25 },
  { DataId: 163, MainGroup1: 2, MainGroup2: 9, a1: 330.13, a2: 0.3412, '1000a3': 0.0, Tmin: 273.1, Tmax: 392.75 },
  { DataId: 164, MainGroup1: 9, MainGroup2: 2, a1: 221.24, a2: -1.0604, '1000a3': 0.0, Tmin: 273.1, Tmax: 392.75 },
  { DataId: 165, MainGroup1: 2, MainGroup2: 10, a1: 496.71, a2: 0.4187, '1000a3': 0.0, Tmin: 293.15, Tmax: 373.17 },
  { DataId: 166, MainGroup1: 10, MainGroup2: 2, a1: 228.7, a2: 0.186, '1000a3': 0.0, Tmin: 293.15, Tmax: 373.17 },
  { DataId: 167, MainGroup1: 2, MainGroup2: 11, a1: 284.78, a2: -0.7037, '1000a3': 0.0, Tmin: 278.15, Tmax: 397.22 },
  { DataId: 168, MainGroup1: 11, MainGroup2: 2, a1: 77.02, a2: 0.1425, '1000a3': 0.0, Tmin: 278.15, Tmax: 397.22 },
  { DataId: 169, MainGroup1: 2, MainGroup2: 12, a1: -29.63, a2: 0.8242, '1000a3': 0.0, Tmin: 214.2, Tmax: 363.15 },
  { DataId: 170, MainGroup1: 12, MainGroup2: 2, a1: 322.48, a2: -1.0911, '1000a3': 0.0, Tmin: 214.2, Tmax: 363.15 },
  { DataId: 171, MainGroup1: 2, MainGroup2: 13, a1: -42.31, a2: -0.0428, '1000a3': 0.0, Tmin: 273.15, Tmax: 369.31 },
  { DataId: 172, MainGroup1: 13, MainGroup2: 2, a1: 193.05, a2: -0.1982, '1000a3': 0.0, Tmin: 273.15, Tmax: 369.31 },
  {
    DataId: 173,
    MainGroup1: 2,
    MainGroup2: 14,
    a1: 1953.46,
    a2: -7.0768,
    '1000a3': 12.222,
    Tmin: 333.12,
    Tmax: 361.19,
  },
  { DataId: 174, MainGroup1: 14, MainGroup2: 2, a1: 308.74, a2: -6.0972, '1000a3': 13.128, Tmin: 333.12, Tmax: 361.19 },
  { DataId: 175, MainGroup1: 2, MainGroup2: 15, a1: 224.8, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 176, MainGroup1: 15, MainGroup2: 2, a1: -124.32, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 177, MainGroup1: 2, MainGroup2: 16, a1: 165.3, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 178, MainGroup1: 16, MainGroup2: 2, a1: -131.5, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  {
    DataId: 179,
    MainGroup1: 2,
    MainGroup2: 17,
    a1: -951.71,
    a2: 14.4763,
    '1000a3': -29.467,
    Tmin: 273.0,
    Tmax: 363.15,
  },
  { DataId: 180, MainGroup1: 17, MainGroup2: 2, a1: 1905.79, a2: 3.4861, '1000a3': -7.0926, Tmin: 273.0, Tmax: 363.15 },
  { DataId: 181, MainGroup1: 2, MainGroup2: 18, a1: 38.56, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 298.15 },
  { DataId: 182, MainGroup1: 18, MainGroup2: 2, a1: 40.65, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 298.15 },
  { DataId: 183, MainGroup1: 2, MainGroup2: 19, a1: 1277.88, a2: 0.2094, '1000a3': 0.0, Tmin: 273.1, Tmax: 395.84 },
  { DataId: 184, MainGroup1: 19, MainGroup2: 2, a1: 93.34, a2: -1.0537, '1000a3': 0.0, Tmin: 273.1, Tmax: 395.84 },
  { DataId: 185, MainGroup1: 2, MainGroup2: 20, a1: 1701.95, a2: 19.6463, '1000a3': 0.0, Tmin: 313.2, Tmax: 413.15 },
  { DataId: 186, MainGroup1: 20, MainGroup2: 2, a1: 1239.81, a2: -3.9068, '1000a3': 0.0, Tmin: 313.2, Tmax: 413.15 },
  { DataId: 187, MainGroup1: 2, MainGroup2: 21, a1: 517.72, a2: -1.2257, '1000a3': 0.0, Tmin: 293.1, Tmax: 387.23 },
  { DataId: 188, MainGroup1: 21, MainGroup2: 2, a1: -377.25, a2: 0.9614, '1000a3': 0.0, Tmin: 293.1, Tmax: 387.23 },
  { DataId: 189, MainGroup1: 2, MainGroup2: 22, a1: 133.15, a2: -1.0507, '1000a3': 0.0, Tmin: 298.15, Tmax: 363.15 },
  { DataId: 190, MainGroup1: 22, MainGroup2: 2, a1: -290.11, a2: 1.8391, '1000a3': 0.0, Tmin: 298.15, Tmax: 363.15 },
  { DataId: 191, MainGroup1: 2, MainGroup2: 23, a1: -187.02, a2: 0.0, '1000a3': 0.0, Tmin: 333.12, Tmax: 333.12 },
  { DataId: 192, MainGroup1: 23, MainGroup2: 2, a1: 229.81, a2: 0.0, '1000a3': 0.0, Tmin: 333.12, Tmax: 333.12 },
  { DataId: 193, MainGroup1: 2, MainGroup2: 24, a1: 388.4, a2: -1.6075, '1000a3': 0.0, Tmin: 293.1, Tmax: 393.12 },
  { DataId: 194, MainGroup1: 24, MainGroup2: 2, a1: -570.92, a2: 2.3215, '1000a3': 0.0, Tmin: 293.1, Tmax: 393.12 },
  { DataId: 195, MainGroup1: 2, MainGroup2: 25, a1: -236.62, a2: -2.6996, '1000a3': 0.0, Tmin: 280.0, Tmax: 359.98 },
  { DataId: 196, MainGroup1: 25, MainGroup2: 2, a1: 1139.93, a2: -3.9021, '1000a3': 0.0, Tmin: 280.0, Tmax: 359.98 },
  { DataId: 197, MainGroup1: 2, MainGroup2: 26, a1: 460.98, a2: -0.6049, '1000a3': 0.0, Tmin: 293.15, Tmax: 363.88 },
  { DataId: 198, MainGroup1: 26, MainGroup2: 2, a1: 497.72, a2: -2.0038, '1000a3': 0.0, Tmin: 293.15, Tmax: 363.88 },
  { DataId: 199, MainGroup1: 2, MainGroup2: 27, a1: 31138.75, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 393.2 },
  { DataId: 200, MainGroup1: 27, MainGroup2: 2, a1: 9513.89, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 393.2 },
  { DataId: 201, MainGroup1: 2, MainGroup2: 28, a1: 105.34, a2: 1.362, '1000a3': 0.0, Tmin: 298.15, Tmax: 298.19 },
  { DataId: 202, MainGroup1: 28, MainGroup2: 2, a1: 53.0, a2: -1.034, '1000a3': 0.0, Tmin: 298.15, Tmax: 298.19 },
  { DataId: 203, MainGroup1: 2, MainGroup2: 30, a1: 171.67, a2: 5.288, '1000a3': 0.0, Tmin: 273.0, Tmax: 366.47 },
  { DataId: 204, MainGroup1: 30, MainGroup2: 2, a1: 388.06, a2: -1.9355, '1000a3': 0.0, Tmin: 273.0, Tmax: 366.47 },
  { DataId: 205, MainGroup1: 2, MainGroup2: 31, a1: -5.53, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 333.15 },
  { DataId: 206, MainGroup1: 31, MainGroup2: 2, a1: 402.1, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 333.15 },
  { DataId: 207, MainGroup1: 2, MainGroup2: 33, a1: 69.21, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 298.15 },
  { DataId: 208, MainGroup1: 33, MainGroup2: 2, a1: 25.67, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 298.15 },
  { DataId: 209, MainGroup1: 2, MainGroup2: 34, a1: 423.52, a2: -2.7549, '1000a3': 2.2164, Tmin: 298.15, Tmax: 403.37 },
  { DataId: 210, MainGroup1: 34, MainGroup2: 2, a1: 175.82, a2: 5.3428, '1000a3': 5.1961, Tmin: 298.15, Tmax: 403.37 },
  { DataId: 211, MainGroup1: 2, MainGroup2: 35, a1: -237.28, a2: 2.4268, '1000a3': 0.0, Tmin: 283.15, Tmax: 353.15 },
  { DataId: 212, MainGroup1: 35, MainGroup2: 2, a1: 52.88, a2: -1.0493, '1000a3': 0.0, Tmin: 283.15, Tmax: 353.15 },
  { DataId: 213, MainGroup1: 2, MainGroup2: 36, a1: 4530.97, a2: -13.9249, '1000a3': 0.0, Tmin: 288.25, Tmax: 344.85 },
  { DataId: 214, MainGroup1: 36, MainGroup2: 2, a1: -1571.53, a2: 5.1153, '1000a3': 0.0, Tmin: 288.25, Tmax: 344.85 },
  { DataId: 215, MainGroup1: 2, MainGroup2: 37, a1: -277.98, a2: -0.2458, '1000a3': 0.0, Tmin: 259.05, Tmax: 358.02 },
  { DataId: 216, MainGroup1: 37, MainGroup2: 2, a1: 172.57, a2: 2.8499, '1000a3': 0.0, Tmin: 259.05, Tmax: 358.02 },
  { DataId: 217, MainGroup1: 2, MainGroup2: 38, a1: 141.1, a2: -0.3025, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 218, MainGroup1: 38, MainGroup2: 2, a1: -139.5, a2: 0.9076, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 219, MainGroup1: 2, MainGroup2: 39, a1: -133.5, a2: 1.8647, '1000a3': 0.0, Tmin: 273.1, Tmax: 368.15 },
  { DataId: 220, MainGroup1: 39, MainGroup2: 2, a1: 120.21, a2: -0.9548, '1000a3': 0.0, Tmin: 273.1, Tmax: 368.15 },
  {
    DataId: 221,
    MainGroup1: 2,
    MainGroup2: 40,
    a1: -181.15,
    a2: 0.0138,
    '1000a3': 0.068952,
    Tmin: 250.99,
    Tmax: 345.8,
  },
  { DataId: 222, MainGroup1: 40, MainGroup2: 2, a1: 435.82, a2: 0.6974, '1000a3': -3.3228, Tmin: 250.99, Tmax: 345.8 },
  { DataId: 223, MainGroup1: 2, MainGroup2: 41, a1: -50.76, a2: 0.4625, '1000a3': 0.0, Tmin: 293.15, Tmax: 383.2 },
  { DataId: 224, MainGroup1: 41, MainGroup2: 2, a1: -65.8, a2: -0.2191, '1000a3': 0.0, Tmin: 293.15, Tmax: 383.2 },
  { DataId: 225, MainGroup1: 2, MainGroup2: 42, a1: -143.93, a2: 0.3097, '1000a3': -1.5527, Tmin: 298.1, Tmax: 413.13 },
  { DataId: 226, MainGroup1: 42, MainGroup2: 2, a1: -32.19, a2: 1.5306, '1000a3': -1.1471, Tmin: 298.1, Tmax: 413.13 },
  { DataId: 227, MainGroup1: 2, MainGroup2: 43, a1: -281.35, a2: -0.1869, '1000a3': 2.7673, Tmin: 250.0, Tmax: 360.72 },
  { DataId: 228, MainGroup1: 43, MainGroup2: 2, a1: 1218.86, a2: -5.2242, '1000a3': 5.373, Tmin: 250.0, Tmax: 360.72 },
  { DataId: 229, MainGroup1: 2, MainGroup2: 44, a1: 1632.01, a2: 0.0, '1000a3': 0.0, Tmin: 292.98, Tmax: 444.31 },
  { DataId: 230, MainGroup1: 44, MainGroup2: 2, a1: -46.47, a2: 0.0, '1000a3': 0.0, Tmin: 292.98, Tmax: 444.31 },
  { DataId: 231, MainGroup1: 2, MainGroup2: 45, a1: 308.81, a2: 0.0, '1000a3': 0.0, Tmin: 175.16, Tmax: 333.25 },
  { DataId: 232, MainGroup1: 45, MainGroup2: 2, a1: -191.6, a2: 0.0, '1000a3': 0.0, Tmin: 175.16, Tmax: 333.25 },
  { DataId: 233, MainGroup1: 2, MainGroup2: 46, a1: 111.48, a2: -0.1464, '1000a3': 0.0, Tmin: 245.27, Tmax: 416.29 },
  { DataId: 234, MainGroup1: 46, MainGroup2: 2, a1: 140.92, a2: -0.1639, '1000a3': 0.0, Tmin: 245.27, Tmax: 416.29 },
  { DataId: 235, MainGroup1: 2, MainGroup2: 47, a1: 579.79, a2: 2.6531, '1000a3': 0.0, Tmin: 297.1, Tmax: 398.6 },
  { DataId: 236, MainGroup1: 47, MainGroup2: 2, a1: 251.43, a2: -1.1265, '1000a3': 0.0, Tmin: 297.1, Tmax: 398.6 },
  { DataId: 237, MainGroup1: 2, MainGroup2: 48, a1: -307.2, a2: 10.4628, '1000a3': 0.0, Tmin: 273.1, Tmax: 354.63 },
  { DataId: 238, MainGroup1: 48, MainGroup2: 2, a1: 248.05, a2: -1.4533, '1000a3': 0.0, Tmin: 273.1, Tmax: 354.63 },
  { DataId: 239, MainGroup1: 2, MainGroup2: 49, a1: 1171.32, a2: 3.9213, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 240, MainGroup1: 49, MainGroup2: 2, a1: 90.01, a2: -0.92, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 241, MainGroup1: 2, MainGroup2: 52, a1: -210.49, a2: 0.3125, '1000a3': 0.0, Tmin: 308.11, Tmax: 336.85 },
  { DataId: 242, MainGroup1: 52, MainGroup2: 2, a1: -85.11, a2: 0.2172, '1000a3': 0.0, Tmin: 308.11, Tmax: 336.85 },
  { DataId: 243, MainGroup1: 2, MainGroup2: 53, a1: 109.69, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 348.18 },
  { DataId: 244, MainGroup1: 53, MainGroup2: 2, a1: -33.59, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 348.18 },
  { DataId: 245, MainGroup1: 2, MainGroup2: 55, a1: -197.46, a2: 1.2309, '1000a3': 0.0, Tmin: 273.1, Tmax: 373.15 },
  { DataId: 246, MainGroup1: 55, MainGroup2: 2, a1: 277.95, a2: -1.2635, '1000a3': 0.0, Tmin: 273.1, Tmax: 373.15 },
  { DataId: 247, MainGroup1: 2, MainGroup2: 57, a1: 12.23, a2: -0.1679, '1000a3': 0.0, Tmin: 298.14, Tmax: 439.51 },
  { DataId: 248, MainGroup1: 57, MainGroup2: 2, a1: 70.1, a2: 2.7565, '1000a3': 0.0, Tmin: 298.14, Tmax: 439.51 },
  { DataId: 249, MainGroup1: 2, MainGroup2: 60, a1: -1472.58, a2: 5.689, '1000a3': 0.0, Tmin: 298.09, Tmax: 384.92 },
  { DataId: 250, MainGroup1: 60, MainGroup2: 2, a1: 1104.03, a2: -3.3013, '1000a3': 0.0, Tmin: 298.09, Tmax: 384.92 },
  { DataId: 251, MainGroup1: 2, MainGroup2: 61, a1: 597.02, a2: 0.905, '1000a3': -7.1734, Tmin: 298.14, Tmax: 368.15 },
  { DataId: 252, MainGroup1: 61, MainGroup2: 2, a1: 122.06, a2: 3.4776, '1000a3': -13.2, Tmin: 298.14, Tmax: 368.15 },
  { DataId: 253, MainGroup1: 2, MainGroup2: 64, a1: 522.03, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 368.23 },
  { DataId: 254, MainGroup1: 64, MainGroup2: 2, a1: -191.02, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 368.23 },
  { DataId: 255, MainGroup1: 2, MainGroup2: 65, a1: 541.76, a2: 0.0, '1000a3': 0.0, Tmin: 303.14, Tmax: 303.14 },
  { DataId: 256, MainGroup1: 65, MainGroup2: 2, a1: -46.56, a2: 0.0, '1000a3': 0.0, Tmin: 303.14, Tmax: 303.14 },
  { DataId: 257, MainGroup1: 2, MainGroup2: 86, a1: -0.89, a2: -0.1812, '1000a3': 0.0, Tmin: 298.15, Tmax: 394.95 },
  { DataId: 258, MainGroup1: 86, MainGroup2: 2, a1: 117.21, a2: -0.1068, '1000a3': 0.0, Tmin: 298.15, Tmax: 394.95 },
  { DataId: 259, MainGroup1: 2, MainGroup2: 95, a1: 995.68, a2: 2.9005, '1000a3': -18.504, Tmin: 273.15, Tmax: 388.15 },
  { DataId: 260, MainGroup1: 95, MainGroup2: 2, a1: -65.3, a2: -1.3113, '1000a3': 2.5216, Tmin: 273.15, Tmax: 388.15 },
  {
    DataId: 261,
    MainGroup1: 3,
    MainGroup2: 4,
    a1: 215.61,
    a2: -0.9343,
    '1000a3': 0.054788,
    Tmin: 157.17,
    Tmax: 553.13,
  },
  { DataId: 262, MainGroup1: 4, MainGroup2: 3, a1: -89.38, a2: 0.3561, '1000a3': 0.72826, Tmin: 157.17, Tmax: 553.13 },
  { DataId: 263, MainGroup1: 3, MainGroup2: 5, a1: 3851.36, a2: -12.7673, '1000a3': 12.019, Tmin: 266.1, Tmax: 494.25 },
  { DataId: 264, MainGroup1: 5, MainGroup2: 3, a1: 2866.4, a2: -14.2748, '1000a3': 20.523, Tmin: 266.1, Tmax: 494.25 },
  { DataId: 265, MainGroup1: 3, MainGroup2: 6, a1: 1052.46, a2: 1.2728, '1000a3': -4.8313, Tmin: 248.65, Tmax: 493.06 },
  { DataId: 266, MainGroup1: 6, MainGroup2: 3, a1: -161.98, a2: 1.1169, '1000a3': -2.1209, Tmin: 248.65, Tmax: 493.06 },
  { DataId: 267, MainGroup1: 3, MainGroup2: 7, a1: 792.0, a2: -1.726, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 268, MainGroup1: 7, MainGroup2: 3, a1: 332.3, a2: 1.158, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 269, MainGroup1: 3, MainGroup2: 8, a1: 1435.9, a2: -2.4554, '1000a3': 0.0, Tmin: 276.35, Tmax: 482.25 },
  { DataId: 270, MainGroup1: 8, MainGroup2: 3, a1: 1358.84, a2: 8.8056, '1000a3': 0.0, Tmin: 276.35, Tmax: 482.25 },
  { DataId: 271, MainGroup1: 3, MainGroup2: 9, a1: 208.29, a2: -0.891, '1000a3': 0.82427, Tmin: 177.19, Tmax: 577.4 },
  { DataId: 272, MainGroup1: 9, MainGroup2: 3, a1: -225.48, a2: 1.4676, '1000a3': -0.87633, Tmin: 177.19, Tmax: 577.4 },
  { DataId: 273, MainGroup1: 3, MainGroup2: 10, a1: 26.89, a2: 1.2527, '1000a3': 0.0, Tmin: 298.15, Tmax: 393.12 },
  { DataId: 274, MainGroup1: 10, MainGroup2: 3, a1: -308.79, a2: 1.4571, '1000a3': 0.0, Tmin: 298.15, Tmax: 393.12 },
  { DataId: 275, MainGroup1: 3, MainGroup2: 11, a1: -436.58, a2: 2.5648, '1000a3': -3.1523, Tmin: 273.1, Tmax: 439.48 },
  { DataId: 276, MainGroup1: 11, MainGroup2: 3, a1: 834.28, a2: -3.3669, '1000a3': 3.0476, Tmin: 273.1, Tmax: 439.48 },
  { DataId: 277, MainGroup1: 3, MainGroup2: 12, a1: 113.93, a2: 0.3235, '1000a3': 0.0, Tmin: 293.15, Tmax: 412.92 },
  { DataId: 278, MainGroup1: 12, MainGroup2: 3, a1: 123.43, a2: -0.525, '1000a3': 0.0, Tmin: 293.15, Tmax: 412.92 },
  { DataId: 279, MainGroup1: 3, MainGroup2: 13, a1: -6.13, a2: -0.2221, '1000a3': 0.0, Tmin: 249.97, Tmax: 413.15 },
  { DataId: 280, MainGroup1: 13, MainGroup2: 3, a1: -125.0, a2: 0.7224, '1000a3': 0.0, Tmin: 249.97, Tmax: 413.15 },
  { DataId: 281, MainGroup1: 3, MainGroup2: 14, a1: 2036.0, a2: -8.729, '1000a3': 8.138, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 282, MainGroup1: 14, MainGroup2: 3, a1: -121.0, a2: -1.901, '1000a3': 6.999, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 283, MainGroup1: 3, MainGroup2: 15, a1: -371.5, a2: 1.3487, '1000a3': 0.0, Tmin: 293.1, Tmax: 351.53 },
  { DataId: 284, MainGroup1: 15, MainGroup2: 3, a1: 796.5, a2: -2.5016, '1000a3': 0.0, Tmin: 293.1, Tmax: 351.53 },
  { DataId: 285, MainGroup1: 3, MainGroup2: 16, a1: 1445.18, a2: -3.8918, '1000a3': 0.0, Tmin: 163.13, Tmax: 359.28 },
  { DataId: 286, MainGroup1: 16, MainGroup2: 3, a1: -320.92, a2: 0.4892, '1000a3': 0.0, Tmin: 163.13, Tmax: 359.28 },
  {
    DataId: 287,
    MainGroup1: 3,
    MainGroup2: 17,
    a1: 1317.49,
    a2: -2.6764,
    '1000a3': 0.54383,
    Tmin: 273.15,
    Tmax: 486.16,
  },
  { DataId: 288, MainGroup1: 17, MainGroup2: 3, a1: 2314.19, a2: 1.1647, '1000a3': 1.8606, Tmin: 273.15, Tmax: 486.16 },
  {
    DataId: 289,
    MainGroup1: 3,
    MainGroup2: 18,
    a1: 1264.99,
    a2: -3.8439,
    '1000a3': 3.6324,
    Tmin: 212.24,
    Tmax: 538.35,
  },
  { DataId: 290, MainGroup1: 18, MainGroup2: 3, a1: -150.54, a2: -1.0618, '1000a3': 1.766, Tmin: 212.24, Tmax: 538.35 },
  {
    DataId: 291,
    MainGroup1: 3,
    MainGroup2: 19,
    a1: -534.15,
    a2: 2.7758,
    '1000a3': -0.54005,
    Tmin: 177.39,
    Tmax: 397.83,
  },
  { DataId: 292, MainGroup1: 19, MainGroup2: 3, a1: 715.47, a2: -3.6278, '1000a3': 3.5521, Tmin: 177.39, Tmax: 397.83 },
  { DataId: 293, MainGroup1: 3, MainGroup2: 20, a1: 332.11, a2: 0.7276, '1000a3': 3.2011, Tmin: 269.85, Tmax: 413.92 },
  { DataId: 294, MainGroup1: 20, MainGroup2: 3, a1: -51.56, a2: 2.833, '1000a3': -7.2836, Tmin: 269.85, Tmax: 413.92 },
  { DataId: 295, MainGroup1: 3, MainGroup2: 21, a1: 55.28, a2: -0.1814, '1000a3': 0.79058, Tmin: 219.09, Tmax: 398.17 },
  {
    DataId: 296,
    MainGroup1: 21,
    MainGroup2: 3,
    a1: -75.94,
    a2: 0.2615,
    '1000a3': -0.95427,
    Tmin: 219.09,
    Tmax: 398.17,
  },
  { DataId: 297, MainGroup1: 3, MainGroup2: 22, a1: 129.85, a2: -0.5509, '1000a3': 0.0, Tmin: 283.15, Tmax: 408.42 },
  { DataId: 298, MainGroup1: 22, MainGroup2: 3, a1: -151.97, a2: 0.5179, '1000a3': 0.0, Tmin: 283.15, Tmax: 408.42 },
  { DataId: 299, MainGroup1: 3, MainGroup2: 23, a1: 75.6, a2: 0.4128, '1000a3': 0.0, Tmin: 215.21, Tmax: 352.08 },
  { DataId: 300, MainGroup1: 23, MainGroup2: 3, a1: -22.83, a2: -0.4436, '1000a3': 0.0, Tmin: 215.21, Tmax: 352.08 },
  { DataId: 301, MainGroup1: 3, MainGroup2: 24, a1: 268.39, a2: -1.8184, '1000a3': 2.8705, Tmin: 227.49, Tmax: 473.11 },
  {
    DataId: 302,
    MainGroup1: 24,
    MainGroup2: 3,
    a1: -269.59,
    a2: 1.9216,
    '1000a3': -3.0871,
    Tmin: 227.49,
    Tmax: 473.11,
  },
  { DataId: 303, MainGroup1: 3, MainGroup2: 25, a1: 619.4, a2: -1.2871, '1000a3': -28.486, Tmin: 211.7, Tmax: 399.53 },
  { DataId: 304, MainGroup1: 25, MainGroup2: 3, a1: 1977.16, a2: -8.2115, '1000a3': 18.545, Tmin: 211.7, Tmax: 399.53 },
  { DataId: 305, MainGroup1: 3, MainGroup2: 26, a1: 436.78, a2: -3.1285, '1000a3': 6.0325, Tmin: 239.68, Tmax: 373.15 },
  {
    DataId: 306,
    MainGroup1: 26,
    MainGroup2: 3,
    a1: -138.18,
    a2: 2.0005,
    '1000a3': -4.2637,
    Tmin: 239.68,
    Tmax: 373.15,
  },
  { DataId: 307, MainGroup1: 3, MainGroup2: 27, a1: 1707.48, a2: -0.0809, '1000a3': 0.0, Tmin: 245.64, Tmax: 462.65 },
  { DataId: 308, MainGroup1: 27, MainGroup2: 3, a1: -336.11, a2: -0.3527, '1000a3': 0.0, Tmin: 245.64, Tmax: 462.65 },
  {
    DataId: 309,
    MainGroup1: 3,
    MainGroup2: 28,
    a1: 2928.92,
    a2: -18.2982,
    '1000a3': 32.265,
    Tmin: 273.1,
    Tmax: 353.07,
  },
  { DataId: 310, MainGroup1: 28, MainGroup2: 3, a1: -153.35, a2: 0.6702, '1000a3': -2.7076, Tmin: 273.1, Tmax: 353.07 },
  { DataId: 311, MainGroup1: 3, MainGroup2: 29, a1: -69.08, a2: 0.2842, '1000a3': 0.0, Tmin: 283.15, Tmax: 338.15 },
  { DataId: 312, MainGroup1: 29, MainGroup2: 3, a1: 137.11, a2: -0.3674, '1000a3': 0.0, Tmin: 283.15, Tmax: 338.15 },
  { DataId: 313, MainGroup1: 3, MainGroup2: 30, a1: 310.6, a2: -0.1186, '1000a3': 0.0, Tmin: 278.15, Tmax: 427.85 },
  { DataId: 314, MainGroup1: 30, MainGroup2: 3, a1: -28.19, a2: -0.1636, '1000a3': 0.0, Tmin: 278.15, Tmax: 427.85 },
  { DataId: 315, MainGroup1: 3, MainGroup2: 31, a1: 1378.14, a2: -3.5132, '1000a3': 0.0, Tmin: 279.1, Tmax: 398.25 },
  { DataId: 316, MainGroup1: 31, MainGroup2: 3, a1: -544.9, a2: 2.0913, '1000a3': 0.0, Tmin: 279.1, Tmax: 398.25 },
  { DataId: 317, MainGroup1: 3, MainGroup2: 32, a1: 80.93, a2: 0.2894, '1000a3': 0.0, Tmin: 293.1, Tmax: 308.15 },
  { DataId: 318, MainGroup1: 32, MainGroup2: 3, a1: 117.69, a2: -0.2878, '1000a3': 0.0, Tmin: 293.1, Tmax: 308.15 },
  { DataId: 319, MainGroup1: 3, MainGroup2: 33, a1: -171.89, a2: 0.4463, '1000a3': 1.8848, Tmin: 152.67, Tmax: 396.75 },
  {
    DataId: 320,
    MainGroup1: 33,
    MainGroup2: 3,
    a1: 307.64,
    a2: -1.5803,
    '1000a3': 0.79403,
    Tmin: 152.67,
    Tmax: 396.75,
  },
  { DataId: 321, MainGroup1: 3, MainGroup2: 34, a1: 73.71, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 407.46 },
  { DataId: 322, MainGroup1: 34, MainGroup2: 3, a1: -59.67, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 407.46 },
  { DataId: 323, MainGroup1: 3, MainGroup2: 35, a1: -17.89, a2: 0.7819, '1000a3': 0.0, Tmin: 298.15, Tmax: 373.15 },
  { DataId: 324, MainGroup1: 35, MainGroup2: 3, a1: 58.36, a2: -0.6666, '1000a3': 0.0, Tmin: 298.15, Tmax: 373.15 },
  { DataId: 325, MainGroup1: 3, MainGroup2: 36, a1: 21.76, a2: 2.7885, '1000a3': 0.0, Tmin: 283.45, Tmax: 411.53 },
  { DataId: 326, MainGroup1: 36, MainGroup2: 3, a1: 43.53, a2: -0.815, '1000a3': 0.0, Tmin: 283.45, Tmax: 411.53 },
  {
    DataId: 327,
    MainGroup1: 3,
    MainGroup2: 37,
    a1: -168.39,
    a2: -0.3422,
    '1000a3': 0.0070739,
    Tmin: 298.0,
    Tmax: 359.82,
  },
  { DataId: 328, MainGroup1: 37, MainGroup2: 3, a1: 179.0, a2: 0.8628, '1000a3': 1.3402, Tmin: 298.0, Tmax: 359.82 },
  { DataId: 329, MainGroup1: 3, MainGroup2: 38, a1: 810.39, a2: -5.3251, '1000a3': 6.0932, Tmin: 161.17, Tmax: 383.15 },
  {
    DataId: 330,
    MainGroup1: 38,
    MainGroup2: 3,
    a1: -1372.21,
    a2: 7.8694,
    '1000a3': -7.8096,
    Tmin: 161.17,
    Tmax: 383.15,
  },
  { DataId: 331, MainGroup1: 3, MainGroup2: 39, a1: -81.69, a2: 1.1229, '1000a3': -1.05, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 332, MainGroup1: 39, MainGroup2: 3, a1: 58.76, a2: -0.6263, '1000a3': 0.668, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 333, MainGroup1: 3, MainGroup2: 41, a1: 148.86, a2: -0.5673, '1000a3': 0.0, Tmin: 293.15, Tmax: 383.2 },
  { DataId: 334, MainGroup1: 41, MainGroup2: 3, a1: 124.37, a2: -0.1629, '1000a3': 0.0, Tmin: 293.15, Tmax: 383.2 },
  { DataId: 335, MainGroup1: 3, MainGroup2: 42, a1: 51.48, a2: -1.0128, '1000a3': 1.5377, Tmin: 226.67, Tmax: 492.66 },
  { DataId: 336, MainGroup1: 42, MainGroup2: 3, a1: 157.87, a2: 0.4908, '1000a3': -1.1351, Tmin: 226.67, Tmax: 492.66 },
  { DataId: 337, MainGroup1: 3, MainGroup2: 43, a1: -118.44, a2: -0.1188, '1000a3': 0.0, Tmin: 247.17, Tmax: 386.55 },
  { DataId: 338, MainGroup1: 43, MainGroup2: 3, a1: 211.91, a2: 0.06, '1000a3': 0.0, Tmin: 247.17, Tmax: 386.55 },
  {
    DataId: 339,
    MainGroup1: 3,
    MainGroup2: 44,
    a1: -1366.87,
    a2: 10.5751,
    '1000a3': -13.93,
    Tmin: 276.95,
    Tmax: 346.55,
  },
  {
    DataId: 340,
    MainGroup1: 44,
    MainGroup2: 3,
    a1: -1113.41,
    a2: 8.4775,
    '1000a3': -15.256,
    Tmin: 276.95,
    Tmax: 346.55,
  },
  { DataId: 341, MainGroup1: 3, MainGroup2: 45, a1: 391.55, a2: -0.297, '1000a3': 0.0, Tmin: 193.18, Tmax: 392.4 },
  { DataId: 342, MainGroup1: 45, MainGroup2: 3, a1: -204.88, a2: -0.0445, '1000a3': 0.0, Tmin: 193.18, Tmax: 392.4 },
  { DataId: 343, MainGroup1: 3, MainGroup2: 46, a1: 441.59, a2: -1.7292, '1000a3': 0.0, Tmin: 215.15, Tmax: 448.45 },
  { DataId: 344, MainGroup1: 46, MainGroup2: 3, a1: -552.89, a2: 2.3801, '1000a3': 0.0, Tmin: 215.15, Tmax: 448.45 },
  { DataId: 345, MainGroup1: 3, MainGroup2: 47, a1: 1048.12, a2: 0.1361, '1000a3': 0.0, Tmin: 303.1, Tmax: 473.36 },
  { DataId: 346, MainGroup1: 47, MainGroup2: 3, a1: 75.52, a2: -0.5981, '1000a3': 0.0, Tmin: 303.1, Tmax: 473.36 },
  { DataId: 347, MainGroup1: 3, MainGroup2: 48, a1: 85.79, a2: 1.0001, '1000a3': 0.0, Tmin: 298.15, Tmax: 434.42 },
  { DataId: 348, MainGroup1: 48, MainGroup2: 3, a1: 15.36, a2: -0.1333, '1000a3': 0.0, Tmin: 298.15, Tmax: 434.42 },
  { DataId: 349, MainGroup1: 3, MainGroup2: 49, a1: 214.38, a2: 0.784, '1000a3': 0.0, Tmin: 298.14, Tmax: 363.15 },
  { DataId: 350, MainGroup1: 49, MainGroup2: 3, a1: 2.81, a2: -0.3703, '1000a3': 0.0, Tmin: 298.14, Tmax: 363.15 },
  { DataId: 351, MainGroup1: 3, MainGroup2: 50, a1: 38.66, a2: -0.2149, '1000a3': 0.0, Tmin: 293.15, Tmax: 413.15 },
  { DataId: 352, MainGroup1: 50, MainGroup2: 3, a1: 8.32, a2: 1.2133, '1000a3': 0.0, Tmin: 293.15, Tmax: 413.15 },
  { DataId: 353, MainGroup1: 3, MainGroup2: 51, a1: 169.21, a2: 0.0, '1000a3': 0.0, Tmin: 323.09, Tmax: 393.07 },
  { DataId: 354, MainGroup1: 51, MainGroup2: 3, a1: -193.41, a2: 0.0, '1000a3': 0.0, Tmin: 323.09, Tmax: 393.07 },
  { DataId: 355, MainGroup1: 3, MainGroup2: 52, a1: 45.85, a2: 0.0227, '1000a3': 0.0, Tmin: 298.15, Tmax: 348.15 },
  { DataId: 356, MainGroup1: 52, MainGroup2: 3, a1: -20.59, a2: -0.4106, '1000a3': 0.0, Tmin: 298.15, Tmax: 348.15 },
  { DataId: 357, MainGroup1: 3, MainGroup2: 53, a1: -79.2, a2: 0.0, '1000a3': 0.0, Tmin: 307.79, Tmax: 386.55 },
  { DataId: 358, MainGroup1: 53, MainGroup2: 3, a1: -129.82, a2: 0.0, '1000a3': 0.0, Tmin: 307.79, Tmax: 386.55 },
  { DataId: 359, MainGroup1: 3, MainGroup2: 54, a1: 579.42, a2: 0.0, '1000a3': 0.0, Tmin: 355.62, Tmax: 416.37 },
  { DataId: 360, MainGroup1: 54, MainGroup2: 3, a1: -64.83, a2: 0.0, '1000a3': 0.0, Tmin: 355.62, Tmax: 416.37 },
  { DataId: 361, MainGroup1: 3, MainGroup2: 55, a1: -25.42, a2: 0.7919, '1000a3': 0.0, Tmin: 283.15, Tmax: 400.75 },
  { DataId: 362, MainGroup1: 55, MainGroup2: 3, a1: 42.93, a2: -0.6321, '1000a3': 0.0, Tmin: 283.15, Tmax: 400.75 },
  {
    DataId: 363,
    MainGroup1: 3,
    MainGroup2: 56,
    a1: -41.15,
    a2: 1.1307,
    '1000a3': 0.020715,
    Tmin: 288.15,
    Tmax: 412.62,
  },
  { DataId: 364, MainGroup1: 56, MainGroup2: 3, a1: 46.52, a2: 0.324, '1000a3': -1.5682, Tmin: 288.15, Tmax: 412.62 },
  { DataId: 365, MainGroup1: 3, MainGroup2: 57, a1: 0.7, a2: -0.0781, '1000a3': 0.0, Tmin: 298.14, Tmax: 439.51 },
  { DataId: 366, MainGroup1: 57, MainGroup2: 3, a1: 941.65, a2: 9.3839, '1000a3': 0.0, Tmin: 298.14, Tmax: 439.51 },
  { DataId: 367, MainGroup1: 3, MainGroup2: 58, a1: 1490.17, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 476.7 },
  { DataId: 368, MainGroup1: 58, MainGroup2: 3, a1: -632.48, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 476.7 },
  {
    DataId: 369,
    MainGroup1: 3,
    MainGroup2: 59,
    a1: 220.23,
    a2: -0.5968,
    '1000a3': -1.3455,
    Tmin: 303.14,
    Tmax: 478.56,
  },
  { DataId: 370, MainGroup1: 59, MainGroup2: 3, a1: 192.36, a2: -2.0119, '1000a3': 2.1535, Tmin: 303.14, Tmax: 478.56 },
  { DataId: 371, MainGroup1: 3, MainGroup2: 60, a1: 473.29, a2: -1.2844, '1000a3': 0.0, Tmin: 298.14, Tmax: 442.51 },
  { DataId: 372, MainGroup1: 60, MainGroup2: 3, a1: -349.71, a2: 1.626, '1000a3': 0.0, Tmin: 298.14, Tmax: 442.51 },
  { DataId: 373, MainGroup1: 3, MainGroup2: 62, a1: 833.09, a2: 0.0, '1000a3': 0.0, Tmin: 303.15, Tmax: 391.22 },
  { DataId: 374, MainGroup1: 62, MainGroup2: 3, a1: 411.36, a2: 0.0, '1000a3': 0.0, Tmin: 303.15, Tmax: 391.22 },
  { DataId: 375, MainGroup1: 3, MainGroup2: 63, a1: -210.75, a2: 0.0, '1000a3': 0.0, Tmin: 387.5, Tmax: 442.3 },
  { DataId: 376, MainGroup1: 63, MainGroup2: 3, a1: 96.33, a2: 0.0, '1000a3': 0.0, Tmin: 387.5, Tmax: 442.3 },
  { DataId: 377, MainGroup1: 3, MainGroup2: 64, a1: -102.14, a2: 0.0, '1000a3': 0.0, Tmin: 308.96, Tmax: 341.49 },
  { DataId: 378, MainGroup1: 64, MainGroup2: 3, a1: 132.5, a2: 0.0, '1000a3': 0.0, Tmin: 308.96, Tmax: 341.49 },
  { DataId: 379, MainGroup1: 3, MainGroup2: 75, a1: 370.36, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 484.16 },
  { DataId: 380, MainGroup1: 75, MainGroup2: 3, a1: -237.95, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 484.16 },
  { DataId: 381, MainGroup1: 3, MainGroup2: 76, a1: 187.7, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 472.85 },
  { DataId: 382, MainGroup1: 76, MainGroup2: 3, a1: 29.67, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 472.85 },
  { DataId: 383, MainGroup1: 3, MainGroup2: 77, a1: 277.97, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 403.15 },
  { DataId: 384, MainGroup1: 77, MainGroup2: 3, a1: -136.73, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 403.15 },
  { DataId: 385, MainGroup1: 3, MainGroup2: 78, a1: 456.9, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 386.35 },
  { DataId: 386, MainGroup1: 78, MainGroup2: 3, a1: 905.52, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 386.35 },
  { DataId: 387, MainGroup1: 3, MainGroup2: 79, a1: -401.18, a2: 2.4597, '1000a3': 0.0, Tmin: 298.15, Tmax: 473.11 },
  { DataId: 388, MainGroup1: 79, MainGroup2: 3, a1: 141.24, a2: -1.0538, '1000a3': 0.0, Tmin: 298.15, Tmax: 473.11 },
  { DataId: 389, MainGroup1: 3, MainGroup2: 80, a1: -704.41, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 390, MainGroup1: 80, MainGroup2: 3, a1: 1322.84, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 391, MainGroup1: 3, MainGroup2: 81, a1: -952.63, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 392, MainGroup1: 81, MainGroup2: 3, a1: 58.31, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 393, MainGroup1: 3, MainGroup2: 82, a1: 539.6, a2: 0.0, '1000a3': 0.0, Tmin: 309.06, Tmax: 385.33 },
  { DataId: 394, MainGroup1: 82, MainGroup2: 3, a1: 90.86, a2: 0.0, '1000a3': 0.0, Tmin: 309.06, Tmax: 385.33 },
  { DataId: 395, MainGroup1: 3, MainGroup2: 83, a1: -566.02, a2: 0.0, '1000a3': 0.0, Tmin: 294.49, Tmax: 475.87 },
  { DataId: 396, MainGroup1: 83, MainGroup2: 3, a1: 114.91, a2: 0.0, '1000a3': 0.0, Tmin: 294.49, Tmax: 475.87 },
  { DataId: 397, MainGroup1: 3, MainGroup2: 86, a1: 167.0, a2: -0.946, '1000a3': 0.0, Tmin: 283.15, Tmax: 385.53 },
  { DataId: 398, MainGroup1: 86, MainGroup2: 3, a1: -170.88, a2: 1.1979, '1000a3': 0.0, Tmin: 283.15, Tmax: 385.53 },
  { DataId: 399, MainGroup1: 3, MainGroup2: 92, a1: 16.12, a2: 0.7628, '1000a3': 0.0, Tmin: 293.15, Tmax: 395.47 },
  { DataId: 400, MainGroup1: 92, MainGroup2: 3, a1: 409.2, a2: -0.2468, '1000a3': 0.0, Tmin: 293.15, Tmax: 395.47 },
  { DataId: 401, MainGroup1: 3, MainGroup2: 94, a1: 439.18, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 643.51 },
  { DataId: 402, MainGroup1: 94, MainGroup2: 3, a1: 94.46, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 643.51 },
  { DataId: 403, MainGroup1: 3, MainGroup2: 95, a1: -1344.2, a2: 7.133, '1000a3': -10.663, Tmin: 273.1, Tmax: 448.01 },
  { DataId: 404, MainGroup1: 95, MainGroup2: 3, a1: 1627.36, a2: -8.6813, '1000a3': 11.537, Tmin: 273.1, Tmax: 448.01 },
  { DataId: 405, MainGroup1: 4, MainGroup2: 5, a1: 4497.82, a2: -13.0418, '1000a3': 9.4091, Tmin: 266.1, Tmax: 494.25 },
  { DataId: 406, MainGroup1: 5, MainGroup2: 4, a1: 2293.54, a2: 0.1412, '1000a3': 0.69931, Tmin: 266.1, Tmax: 494.25 },
  {
    DataId: 407,
    MainGroup1: 4,
    MainGroup2: 6,
    a1: -1534.84,
    a2: 16.8809,
    '1000a3': -28.107,
    Tmin: 248.65,
    Tmax: 493.06,
  },
  { DataId: 408, MainGroup1: 6, MainGroup2: 4, a1: 134.01, a2: -0.661, '1000a3': 0.61199, Tmin: 248.65, Tmax: 493.06 },
  { DataId: 409, MainGroup1: 4, MainGroup2: 7, a1: 1050.2, a2: -1.9939, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 410, MainGroup1: 7, MainGroup2: 4, a1: 24.14, a2: 1.6504, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 411, MainGroup1: 4, MainGroup2: 8, a1: 1638.49, a2: -2.567, '1000a3': 0.0, Tmin: 276.35, Tmax: 482.25 },
  { DataId: 412, MainGroup1: 8, MainGroup2: 4, a1: 1538.3, a2: 12.8486, '1000a3': 0.0, Tmin: 276.35, Tmax: 482.25 },
  { DataId: 413, MainGroup1: 4, MainGroup2: 9, a1: 1018.23, a2: 0.5233, '1000a3': 0.58944, Tmin: 177.19, Tmax: 577.4 },
  { DataId: 414, MainGroup1: 9, MainGroup2: 4, a1: -57.75, a2: 0.4756, '1000a3': -2.5793, Tmin: 177.19, Tmax: 577.4 },
  { DataId: 415, MainGroup1: 4, MainGroup2: 10, a1: 900.31, a2: -1.11, '1000a3': 0.0, Tmin: 298.15, Tmax: 393.12 },
  { DataId: 416, MainGroup1: 10, MainGroup2: 4, a1: 5812.91, a2: 97.8575, '1000a3': 0.0, Tmin: 298.15, Tmax: 393.12 },
  { DataId: 417, MainGroup1: 4, MainGroup2: 11, a1: -242.5, a2: 2.72, '1000a3': -3.449, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 418, MainGroup1: 11, MainGroup2: 4, a1: 1624.0, a2: -9.409, '1000a3': 13.38, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 419, MainGroup1: 4, MainGroup2: 12, a1: 211.93, a2: -0.3958, '1000a3': 0.0, Tmin: 293.15, Tmax: 412.92 },
  { DataId: 420, MainGroup1: 12, MainGroup2: 4, a1: 389.08, a2: 2.5602, '1000a3': 0.0, Tmin: 293.15, Tmax: 412.92 },
  { DataId: 421, MainGroup1: 4, MainGroup2: 13, a1: 186.2, a2: 0.2496, '1000a3': 0.0, Tmin: 249.97, Tmax: 413.15 },
  { DataId: 422, MainGroup1: 13, MainGroup2: 4, a1: 49.94, a2: -0.4694, '1000a3': 0.0, Tmin: 249.97, Tmax: 413.15 },
  { DataId: 423, MainGroup1: 4, MainGroup2: 14, a1: 2977.0, a2: -19.16, '1000a3': 33.33, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 424, MainGroup1: 14, MainGroup2: 4, a1: -45.44, a2: 5.141, '1000a3': -14.2, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 425, MainGroup1: 4, MainGroup2: 15, a1: 405.01, a2: 0.0, '1000a3': 0.0, Tmin: 308.14, Tmax: 308.14 },
  { DataId: 426, MainGroup1: 15, MainGroup2: 4, a1: -162.23, a2: 0.0, '1000a3': 0.0, Tmin: 308.14, Tmax: 308.14 },
  { DataId: 427, MainGroup1: 4, MainGroup2: 16, a1: 111.56, a2: -0.4605, '1000a3': 2.4753, Tmin: 293.09, Tmax: 333.13 },
  { DataId: 428, MainGroup1: 16, MainGroup2: 4, a1: 96.26, a2: 2.403, '1000a3': -8.5058, Tmin: 293.09, Tmax: 333.13 },
  {
    DataId: 429,
    MainGroup1: 4,
    MainGroup2: 17,
    a1: 4206.45,
    a2: -8.2185,
    '1000a3': 27.256,
    Tmin: 273.15,
    Tmax: 486.16,
  },
  {
    DataId: 430,
    MainGroup1: 17,
    MainGroup2: 4,
    a1: 3658.82,
    a2: -3.6557,
    '1000a3': -7.4997,
    Tmin: 273.15,
    Tmax: 486.16,
  },
  { DataId: 431, MainGroup1: 4, MainGroup2: 18, a1: -1740.4, a2: 6.8801, '1000a3': 0.8683, Tmin: 292.15, Tmax: 353.15 },
  {
    DataId: 432,
    MainGroup1: 18,
    MainGroup2: 4,
    a1: 385.65,
    a2: -1.2539,
    '1000a3': -2.0895,
    Tmin: 292.15,
    Tmax: 353.15,
  },
  { DataId: 433, MainGroup1: 4, MainGroup2: 19, a1: -221.09, a2: 1.655, '1000a3': 0.0, Tmin: 293.1, Tmax: 410.65 },
  { DataId: 434, MainGroup1: 19, MainGroup2: 4, a1: 358.9, a2: -1.1203, '1000a3': 0.0, Tmin: 293.1, Tmax: 410.65 },
  { DataId: 435, MainGroup1: 4, MainGroup2: 20, a1: 2502.9, a2: -4.7676, '1000a3': 0.0, Tmin: 246.36, Tmax: 435.31 },
  { DataId: 436, MainGroup1: 20, MainGroup2: 4, a1: -184.09, a2: 1.9727, '1000a3': 0.0, Tmin: 246.36, Tmax: 435.31 },
  { DataId: 437, MainGroup1: 4, MainGroup2: 21, a1: -101.29, a2: 0.2088, '1000a3': 2.4868, Tmin: 249.97, Tmax: 427.65 },
  { DataId: 438, MainGroup1: 21, MainGroup2: 4, a1: 30.88, a2: 0.0366, '1000a3': -2.1426, Tmin: 249.97, Tmax: 427.65 },
  { DataId: 439, MainGroup1: 4, MainGroup2: 22, a1: 295.24, a2: -0.4104, '1000a3': 0.0, Tmin: 154.67, Tmax: 416.45 },
  { DataId: 440, MainGroup1: 22, MainGroup2: 4, a1: -142.13, a2: -0.0782, '1000a3': 0.0, Tmin: 154.67, Tmax: 416.45 },
  { DataId: 441, MainGroup1: 4, MainGroup2: 23, a1: 9.59, a2: 0.2394, '1000a3': 0.0, Tmin: 298.14, Tmax: 400.72 },
  { DataId: 442, MainGroup1: 23, MainGroup2: 4, a1: 89.13, a2: -0.5155, '1000a3': 0.0, Tmin: 298.14, Tmax: 400.72 },
  { DataId: 443, MainGroup1: 4, MainGroup2: 24, a1: -127.11, a2: 0.2971, '1000a3': 0.0, Tmin: 198.5, Tmax: 415.12 },
  { DataId: 444, MainGroup1: 24, MainGroup2: 4, a1: 166.04, a2: -0.5463, '1000a3': 0.0, Tmin: 198.5, Tmax: 415.12 },
  { DataId: 445, MainGroup1: 4, MainGroup2: 25, a1: -59.53, a2: 1.3667, '1000a3': 0.0, Tmin: 298.15, Tmax: 431.69 },
  { DataId: 446, MainGroup1: 25, MainGroup2: 4, a1: -124.41, a2: -0.0975, '1000a3': 0.0, Tmin: 298.15, Tmax: 431.69 },
  {
    DataId: 447,
    MainGroup1: 4,
    MainGroup2: 26,
    a1: -2624.9,
    a2: 18.3666,
    '1000a3': -30.542,
    Tmin: 240.16,
    Tmax: 396.64,
  },
  {
    DataId: 448,
    MainGroup1: 26,
    MainGroup2: 4,
    a1: 4193.9,
    a2: -27.3522,
    '1000a3': 44.562,
    Tmin: 240.16,
    Tmax: 396.64,
  },
  { DataId: 449, MainGroup1: 4, MainGroup2: 27, a1: -2.81, a2: 6.9475, '1000a3': 0.0, Tmin: 245.64, Tmax: 462.65 },
  { DataId: 450, MainGroup1: 27, MainGroup2: 4, a1: -146.56, a2: 1.6491, '1000a3': 0.0, Tmin: 245.64, Tmax: 462.65 },
  { DataId: 451, MainGroup1: 4, MainGroup2: 28, a1: 3464.85, a2: 81.8361, '1000a3': 273.95, Tmin: 213.2, Tmax: 366.62 },
  {
    DataId: 452,
    MainGroup1: 28,
    MainGroup2: 4,
    a1: -1820.64,
    a2: 11.0592,
    '1000a3': -20.783,
    Tmin: 213.2,
    Tmax: 366.62,
  },
  { DataId: 453, MainGroup1: 4, MainGroup2: 29, a1: 376.19, a2: -1.2113, '1000a3': 0.0, Tmin: 283.15, Tmax: 377.84 },
  { DataId: 454, MainGroup1: 29, MainGroup2: 4, a1: -504.97, a2: 2.0461, '1000a3': 0.0, Tmin: 283.15, Tmax: 377.84 },
  { DataId: 455, MainGroup1: 4, MainGroup2: 30, a1: 424.94, a2: 9.3058, '1000a3': 0.0, Tmin: 285.95, Tmax: 431.31 },
  { DataId: 456, MainGroup1: 30, MainGroup2: 4, a1: 125.49, a2: -0.7352, '1000a3': 0.0, Tmin: 285.95, Tmax: 431.31 },
  { DataId: 457, MainGroup1: 4, MainGroup2: 31, a1: 2604.86, a2: -4.4807, '1000a3': 0.0, Tmin: 279.1, Tmax: 398.25 },
  { DataId: 458, MainGroup1: 31, MainGroup2: 4, a1: 886.11, a2: -2.2136, '1000a3': 0.0, Tmin: 279.1, Tmax: 398.25 },
  { DataId: 459, MainGroup1: 4, MainGroup2: 32, a1: 176.43, a2: -0.629, '1000a3': 0.0, Tmin: 293.1, Tmax: 363.18 },
  { DataId: 460, MainGroup1: 32, MainGroup2: 4, a1: 375.46, a2: -0.681, '1000a3': 0.0, Tmin: 293.1, Tmax: 363.18 },
  { DataId: 461, MainGroup1: 4, MainGroup2: 33, a1: 263.79, a2: -0.458, '1000a3': 0.0, Tmin: 290.65, Tmax: 407.2 },
  { DataId: 462, MainGroup1: 33, MainGroup2: 4, a1: -136.59, a2: -0.035, '1000a3': 0.0, Tmin: 290.65, Tmax: 407.2 },
  { DataId: 463, MainGroup1: 4, MainGroup2: 34, a1: 309.57, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 407.46 },
  { DataId: 464, MainGroup1: 34, MainGroup2: 4, a1: 316.08, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 407.46 },
  { DataId: 465, MainGroup1: 4, MainGroup2: 35, a1: -5.87, a2: 0.6284, '1000a3': 0.0, Tmin: 298.15, Tmax: 373.15 },
  { DataId: 466, MainGroup1: 35, MainGroup2: 4, a1: 214.73, a2: -0.9658, '1000a3': 0.0, Tmin: 298.15, Tmax: 373.15 },
  { DataId: 467, MainGroup1: 4, MainGroup2: 36, a1: -18.72, a2: 0.9911, '1000a3': 0.0, Tmin: 283.45, Tmax: 411.53 },
  { DataId: 468, MainGroup1: 36, MainGroup2: 4, a1: 135.35, a2: -1.0181, '1000a3': 0.0, Tmin: 283.45, Tmax: 411.53 },
  { DataId: 469, MainGroup1: 4, MainGroup2: 37, a1: -501.55, a2: 0.5371, '1000a3': 0.0, Tmin: 321.34, Tmax: 405.75 },
  { DataId: 470, MainGroup1: 37, MainGroup2: 4, a1: 1388.96, a2: -2.6358, '1000a3': 0.0, Tmin: 321.34, Tmax: 405.75 },
  {
    DataId: 471,
    MainGroup1: 4,
    MainGroup2: 38,
    a1: -98.87,
    a2: -0.1688,
    '1000a3': -0.2455,
    Tmin: 161.17,
    Tmax: 383.15,
  },
  {
    DataId: 472,
    MainGroup1: 38,
    MainGroup2: 4,
    a1: -95.89,
    a2: 0.5539,
    '1000a3': -0.050916,
    Tmin: 161.17,
    Tmax: 383.15,
  },
  { DataId: 473, MainGroup1: 4, MainGroup2: 39, a1: 417.95, a2: 0.2694, '1000a3': 0.0, Tmin: 288.15, Tmax: 425.61 },
  { DataId: 474, MainGroup1: 39, MainGroup2: 4, a1: 110.85, a2: -0.8172, '1000a3': 0.0, Tmin: 288.15, Tmax: 425.61 },
  { DataId: 475, MainGroup1: 4, MainGroup2: 41, a1: 1115.57, a2: -2.827, '1000a3': 0.0, Tmin: 293.15, Tmax: 383.2 },
  { DataId: 476, MainGroup1: 41, MainGroup2: 4, a1: -350.7, a2: 1.4265, '1000a3': 0.0, Tmin: 293.15, Tmax: 383.2 },
  { DataId: 477, MainGroup1: 4, MainGroup2: 42, a1: -39.01, a2: 0.496, '1000a3': 0.0, Tmin: 255.4, Tmax: 503.11 },
  { DataId: 478, MainGroup1: 42, MainGroup2: 4, a1: 103.11, a2: -0.6489, '1000a3': 0.0, Tmin: 255.4, Tmax: 503.11 },
  { DataId: 479, MainGroup1: 4, MainGroup2: 43, a1: -10.39, a2: -0.0541, '1000a3': 0.0, Tmin: 293.15, Tmax: 421.32 },
  { DataId: 480, MainGroup1: 43, MainGroup2: 4, a1: 74.1, a2: 0.006, '1000a3': 0.0, Tmin: 293.15, Tmax: 421.32 },
  { DataId: 481, MainGroup1: 4, MainGroup2: 44, a1: 945.82, a2: -0.6504, '1000a3': 0.0, Tmin: 278.15, Tmax: 373.15 },
  { DataId: 482, MainGroup1: 44, MainGroup2: 4, a1: -159.94, a2: 0.3161, '1000a3': 0.0, Tmin: 278.15, Tmax: 373.15 },
  { DataId: 483, MainGroup1: 4, MainGroup2: 45, a1: 248.99, a2: 1.6272, '1000a3': 0.0, Tmin: 283.0, Tmax: 382.33 },
  { DataId: 484, MainGroup1: 45, MainGroup2: 4, a1: -123.68, a2: -0.597, '1000a3': 0.0, Tmin: 283.0, Tmax: 382.33 },
  { DataId: 485, MainGroup1: 4, MainGroup2: 46, a1: -103.9, a2: 0.8075, '1000a3': 0.0, Tmin: 215.15, Tmax: 448.45 },
  { DataId: 486, MainGroup1: 46, MainGroup2: 4, a1: 771.88, a2: -1.538, '1000a3': 0.0, Tmin: 215.15, Tmax: 448.45 },
  { DataId: 487, MainGroup1: 4, MainGroup2: 47, a1: -695.64, a2: 2.6949, '1000a3': 0.0, Tmin: 303.1, Tmax: 473.36 },
  { DataId: 488, MainGroup1: 47, MainGroup2: 4, a1: -863.48, a2: 13.7484, '1000a3': 0.0, Tmin: 303.1, Tmax: 473.36 },
  { DataId: 489, MainGroup1: 4, MainGroup2: 48, a1: 573.31, a2: -0.8779, '1000a3': 0.0, Tmin: 298.15, Tmax: 434.42 },
  { DataId: 490, MainGroup1: 48, MainGroup2: 4, a1: -9.15, a2: -0.7743, '1000a3': 0.0, Tmin: 298.15, Tmax: 434.42 },
  { DataId: 491, MainGroup1: 4, MainGroup2: 49, a1: 399.84, a2: 0.387, '1000a3': 0.0, Tmin: 298.14, Tmax: 353.15 },
  { DataId: 492, MainGroup1: 49, MainGroup2: 4, a1: 49.62, a2: -1.42, '1000a3': 0.0, Tmin: 298.14, Tmax: 353.15 },
  { DataId: 493, MainGroup1: 4, MainGroup2: 50, a1: 22.66, a2: -0.231, '1000a3': 0.0, Tmin: 205.24, Tmax: 449.21 },
  { DataId: 494, MainGroup1: 50, MainGroup2: 4, a1: -744.58, a2: 4.4795, '1000a3': 0.0, Tmin: 205.24, Tmax: 449.21 },
  { DataId: 495, MainGroup1: 4, MainGroup2: 51, a1: 354.49, a2: 0.0, '1000a3': 0.0, Tmin: 323.09, Tmax: 393.07 },
  { DataId: 496, MainGroup1: 51, MainGroup2: 4, a1: -177.25, a2: 0.0, '1000a3': 0.0, Tmin: 323.09, Tmax: 393.07 },
  { DataId: 497, MainGroup1: 4, MainGroup2: 52, a1: 826.34, a2: -0.3598, '1000a3': 0.0, Tmin: 354.74, Tmax: 381.35 },
  { DataId: 498, MainGroup1: 52, MainGroup2: 4, a1: 997.7, a2: -3.5035, '1000a3': 0.0, Tmin: 354.74, Tmax: 381.35 },
  { DataId: 499, MainGroup1: 4, MainGroup2: 53, a1: 2997.89, a2: 0.0, '1000a3': 0.0, Tmin: 307.79, Tmax: 386.55 },
  { DataId: 500, MainGroup1: 53, MainGroup2: 4, a1: 6076.18, a2: 0.0, '1000a3': 0.0, Tmin: 307.79, Tmax: 386.55 },
  { DataId: 501, MainGroup1: 4, MainGroup2: 54, a1: 364.6, a2: 0.0, '1000a3': 0.0, Tmin: 355.62, Tmax: 416.37 },
  { DataId: 502, MainGroup1: 54, MainGroup2: 4, a1: 915.55, a2: 0.0, '1000a3': 0.0, Tmin: 355.62, Tmax: 416.37 },
  { DataId: 503, MainGroup1: 4, MainGroup2: 55, a1: 256.14, a2: 0.9065, '1000a3': 0.0, Tmin: 283.15, Tmax: 400.75 },
  { DataId: 504, MainGroup1: 55, MainGroup2: 4, a1: 91.68, a2: -1.1198, '1000a3': 0.0, Tmin: 283.15, Tmax: 400.75 },
  { DataId: 505, MainGroup1: 4, MainGroup2: 56, a1: -43.45, a2: 2.0547, '1000a3': -2.4514, Tmin: 298.15, Tmax: 492.15 },
  { DataId: 506, MainGroup1: 56, MainGroup2: 4, a1: 92.68, a2: 0.762, '1000a3': -0.96573, Tmin: 298.15, Tmax: 492.15 },
  { DataId: 507, MainGroup1: 4, MainGroup2: 57, a1: 5326.43, a2: -3.9435, '1000a3': 0.0, Tmin: 298.14, Tmax: 439.51 },
  { DataId: 508, MainGroup1: 57, MainGroup2: 4, a1: 241.41, a2: -1.4825, '1000a3': 0.0, Tmin: 298.14, Tmax: 439.51 },
  { DataId: 509, MainGroup1: 4, MainGroup2: 58, a1: 2123.45, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 476.7 },
  { DataId: 510, MainGroup1: 58, MainGroup2: 4, a1: 3032.4, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 476.7 },
  {
    DataId: 511,
    MainGroup1: 4,
    MainGroup2: 59,
    a1: 336.15,
    a2: -1.3531,
    '1000a3': 0.073995,
    Tmin: 303.14,
    Tmax: 478.56,
  },
  {
    DataId: 512,
    MainGroup1: 59,
    MainGroup2: 4,
    a1: -319.33,
    a2: 1.475,
    '1000a3': -0.50847,
    Tmin: 303.14,
    Tmax: 478.56,
  },
  { DataId: 513, MainGroup1: 4, MainGroup2: 60, a1: 185.41, a2: 1.6455, '1000a3': 0.0, Tmin: 298.14, Tmax: 442.51 },
  { DataId: 514, MainGroup1: 60, MainGroup2: 4, a1: -14.76, a2: -0.0892, '1000a3': 0.0, Tmin: 298.14, Tmax: 442.51 },
  { DataId: 515, MainGroup1: 4, MainGroup2: 62, a1: 3825.85, a2: 0.0, '1000a3': 0.0, Tmin: 303.15, Tmax: 391.22 },
  { DataId: 516, MainGroup1: 62, MainGroup2: 4, a1: 13996.46, a2: 0.0, '1000a3': 0.0, Tmin: 303.15, Tmax: 391.22 },
  { DataId: 517, MainGroup1: 4, MainGroup2: 63, a1: 573.79, a2: 0.0, '1000a3': 0.0, Tmin: 387.5, Tmax: 442.3 },
  { DataId: 518, MainGroup1: 63, MainGroup2: 4, a1: 578.27, a2: 0.0, '1000a3': 0.0, Tmin: 387.5, Tmax: 442.3 },
  { DataId: 519, MainGroup1: 4, MainGroup2: 64, a1: 143.84, a2: 0.0, '1000a3': 0.0, Tmin: 308.96, Tmax: 341.49 },
  { DataId: 520, MainGroup1: 64, MainGroup2: 4, a1: -397.69, a2: 0.0, '1000a3': 0.0, Tmin: 308.96, Tmax: 341.49 },
  { DataId: 521, MainGroup1: 4, MainGroup2: 79, a1: 2.6, a2: -0.9829, '1000a3': 0.0, Tmin: 298.15, Tmax: 473.11 },
  { DataId: 522, MainGroup1: 79, MainGroup2: 4, a1: 283.48, a2: 0.8955, '1000a3': 0.0, Tmin: 298.15, Tmax: 473.11 },
  { DataId: 523, MainGroup1: 4, MainGroup2: 83, a1: 180.25, a2: 0.0, '1000a3': 0.0, Tmin: 294.49, Tmax: 475.87 },
  { DataId: 524, MainGroup1: 83, MainGroup2: 4, a1: 511.87, a2: 0.0, '1000a3': 0.0, Tmin: 294.49, Tmax: 475.87 },
  { DataId: 525, MainGroup1: 4, MainGroup2: 86, a1: 87.29, a2: -0.3607, '1000a3': 0.0, Tmin: 298.15, Tmax: 409.15 },
  { DataId: 526, MainGroup1: 86, MainGroup2: 4, a1: -87.5, a2: 0.2158, '1000a3': 0.0, Tmin: 298.15, Tmax: 409.15 },
  { DataId: 527, MainGroup1: 4, MainGroup2: 92, a1: 73.77, a2: 1.5009, '1000a3': 0.0, Tmin: 293.15, Tmax: 395.47 },
  { DataId: 528, MainGroup1: 92, MainGroup2: 4, a1: -373.6, a2: 3.3105, '1000a3': 0.0, Tmin: 293.15, Tmax: 395.47 },
  { DataId: 529, MainGroup1: 4, MainGroup2: 94, a1: 52.78, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 643.51 },
  { DataId: 530, MainGroup1: 94, MainGroup2: 4, a1: 257.56, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 643.51 },
  { DataId: 531, MainGroup1: 4, MainGroup2: 95, a1: -93.87, a2: 0.0, '1000a3': 0.0, Tmin: 273.1, Tmax: 448.01 },
  { DataId: 532, MainGroup1: 95, MainGroup2: 4, a1: 155.67, a2: 0.0, '1000a3': 0.0, Tmin: 273.1, Tmax: 448.01 },
  { DataId: 533, MainGroup1: 5, MainGroup2: 6, a1: 320.21, a2: -2.7166, '1000a3': 3.497, Tmin: 273.1, Tmax: 488.16 },
  { DataId: 534, MainGroup1: 6, MainGroup2: 5, a1: -1280.09, a2: 9.8879, '1000a3': -15.141, Tmin: 273.1, Tmax: 488.16 },
  { DataId: 535, MainGroup1: 5, MainGroup2: 7, a1: -513.48, a2: 1.25, '1000a3': -2.1501, Tmin: 273.15, Tmax: 548.1 },
  { DataId: 536, MainGroup1: 7, MainGroup2: 5, a1: 1651.05, a2: -8.9104, '1000a3': 14.679, Tmin: 273.15, Tmax: 548.1 },
  { DataId: 537, MainGroup1: 5, MainGroup2: 8, a1: 849.02, a2: -3.3964, '1000a3': 0.0, Tmin: 273.15, Tmax: 462.05 },
  { DataId: 538, MainGroup1: 8, MainGroup2: 5, a1: -482.18, a2: 0.8318, '1000a3': 0.0, Tmin: 273.15, Tmax: 462.05 },
  { DataId: 539, MainGroup1: 5, MainGroup2: 9, a1: -288.13, a2: 2.2923, '1000a3': -3.7772, Tmin: 204.05, Tmax: 422.56 },
  { DataId: 540, MainGroup1: 9, MainGroup2: 5, a1: 668.98, a2: -0.7227, '1000a3': -1.6635, Tmin: 204.05, Tmax: 422.56 },
  { DataId: 541, MainGroup1: 5, MainGroup2: 10, a1: -78.64, a2: 1.6703, '1000a3': -6.133, Tmin: 284.14, Tmax: 387.13 },
  {
    DataId: 542,
    MainGroup1: 10,
    MainGroup2: 5,
    a1: -1080.74,
    a2: -7.7991,
    '1000a3': 35.174,
    Tmin: 284.14,
    Tmax: 387.13,
  },
  {
    DataId: 543,
    MainGroup1: 5,
    MainGroup2: 11,
    a1: 1097.75,
    a2: -7.8816,
    '1000a3': 13.497,
    Tmin: 288.15,
    Tmax: 493.15,
  },
  { DataId: 544, MainGroup1: 11, MainGroup2: 5, a1: 252.79, a2: 2.6565, '1000a3': -7.9064, Tmin: 288.15, Tmax: 493.15 },
  { DataId: 545, MainGroup1: 5, MainGroup2: 12, a1: 292.25, a2: -0.7058, '1000a3': 0.10613, Tmin: 298.14, Tmax: 416.1 },
  { DataId: 546, MainGroup1: 12, MainGroup2: 5, a1: -233.71, a2: 5.3056, '1000a3': -9.9789, Tmin: 298.14, Tmax: 416.1 },
  { DataId: 547, MainGroup1: 5, MainGroup2: 13, a1: 816.7, a2: -5.092, '1000a3': 6.07, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 548, MainGroup1: 13, MainGroup2: 5, a1: 650.9, a2: -0.7132, '1000a3': 0.82, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 549, MainGroup1: 5, MainGroup2: 14, a1: -842.7, a2: 2.5595, '1000a3': -1.3147, Tmin: 288.15, Tmax: 390.37 },
  {
    DataId: 550,
    MainGroup1: 14,
    MainGroup2: 5,
    a1: -149.83,
    a2: -0.3414,
    '1000a3': 1.4055,
    Tmin: 288.15,
    Tmax: 390.37,
  },
  { DataId: 551, MainGroup1: 5, MainGroup2: 15, a1: -24.05, a2: -0.8206, '1000a3': 0.0, Tmin: 293.1, Tmax: 428.14 },
  { DataId: 552, MainGroup1: 15, MainGroup2: 5, a1: -758.38, a2: 2.2065, '1000a3': 0.0, Tmin: 293.1, Tmax: 428.14 },
  { DataId: 553, MainGroup1: 5, MainGroup2: 16, a1: -468.41, a2: -2.4112, '1000a3': 5.947, Tmin: 283.1, Tmax: 434.6 },
  { DataId: 554, MainGroup1: 16, MainGroup2: 5, a1: -6512.52, a2: -7.7888, '1000a3': 121.12, Tmin: 283.1, Tmax: 434.6 },
  { DataId: 555, MainGroup1: 5, MainGroup2: 17, a1: -1114.0, a2: 5.916, '1000a3': -7.126, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 556, MainGroup1: 17, MainGroup2: 5, a1: 1325.0, a2: -6.263, '1000a3': 7.584, Tmin: 300.0, Tmax: 400.0 },
  {
    DataId: 557,
    MainGroup1: 5,
    MainGroup2: 18,
    a1: -2060.01,
    a2: -0.872,
    '1000a3': 177.89,
    Tmin: 252.57,
    Tmax: 363.13,
  },
  {
    DataId: 558,
    MainGroup1: 18,
    MainGroup2: 5,
    a1: -426.11,
    a2: 1.4293,
    '1000a3': -2.7737,
    Tmin: 252.57,
    Tmax: 363.13,
  },
  { DataId: 559, MainGroup1: 5, MainGroup2: 19, a1: 164.64, a2: 1.1945, '1000a3': -2.5079, Tmin: 230.58, Tmax: 393.12 },
  { DataId: 560, MainGroup1: 19, MainGroup2: 5, a1: -22.12, a2: 4.2721, '1000a3': -10.224, Tmin: 230.58, Tmax: 393.12 },
  {
    DataId: 561,
    MainGroup1: 5,
    MainGroup2: 20,
    a1: -1547.97,
    a2: 9.2465,
    '1000a3': 3.6126,
    Tmin: 243.76,
    Tmax: 430.81,
  },
  { DataId: 562, MainGroup1: 20, MainGroup2: 5, a1: 995.65, a2: -8.4974, '1000a3': 11.205, Tmin: 243.76, Tmax: 430.81 },
  { DataId: 563, MainGroup1: 5, MainGroup2: 21, a1: 171.81, a2: -0.4551, '1000a3': 0.0, Tmin: 249.84, Tmax: 421.75 },
  { DataId: 564, MainGroup1: 21, MainGroup2: 5, a1: 1890.25, a2: -3.6547, '1000a3': 0.0, Tmin: 249.84, Tmax: 421.75 },
  { DataId: 565, MainGroup1: 5, MainGroup2: 22, a1: -210.24, a2: 0.3074, '1000a3': 0.0, Tmin: 293.1, Tmax: 425.15 },
  { DataId: 566, MainGroup1: 22, MainGroup2: 5, a1: 1289.62, a2: -1.3321, '1000a3': 0.0, Tmin: 293.1, Tmax: 425.15 },
  { DataId: 567, MainGroup1: 5, MainGroup2: 23, a1: 1389.82, a2: -2.8459, '1000a3': 0.0, Tmin: 298.15, Tmax: 393.32 },
  { DataId: 568, MainGroup1: 23, MainGroup2: 5, a1: 1015.06, a2: -0.2252, '1000a3': 0.0, Tmin: 298.15, Tmax: 393.32 },
  { DataId: 569, MainGroup1: 5, MainGroup2: 24, a1: 536.31, a2: -0.8087, '1000a3': 0.0, Tmin: 273.15, Tmax: 390.13 },
  { DataId: 570, MainGroup1: 24, MainGroup2: 5, a1: 3468.76, a2: -6.8797, '1000a3': 0.0, Tmin: 273.15, Tmax: 390.13 },
  { DataId: 571, MainGroup1: 5, MainGroup2: 25, a1: -2352.57, a2: 16.8828, '1000a3': 0.0, Tmin: 298.0, Tmax: 447.75 },
  { DataId: 572, MainGroup1: 25, MainGroup2: 5, a1: 844.03, a2: 27.934, '1000a3': 0.0, Tmin: 298.0, Tmax: 447.75 },
  { DataId: 573, MainGroup1: 5, MainGroup2: 26, a1: 412.41, a2: -3.2486, '1000a3': 7.204, Tmin: 252.15, Tmax: 398.14 },
  {
    DataId: 574,
    MainGroup1: 26,
    MainGroup2: 5,
    a1: 1300.18,
    a2: -0.8627,
    '1000a3': -3.9006,
    Tmin: 252.15,
    Tmax: 398.14,
  },
  { DataId: 575, MainGroup1: 5, MainGroup2: 27, a1: -881.33, a2: 71.3066, '1000a3': 0.0, Tmin: 293.1, Tmax: 480.85 },
  { DataId: 576, MainGroup1: 27, MainGroup2: 5, a1: 653.72, a2: -0.7702, '1000a3': 0.0, Tmin: 293.1, Tmax: 480.85 },
  { DataId: 577, MainGroup1: 5, MainGroup2: 28, a1: 2765.94, a2: 0.0, '1000a3': 0.0, Tmin: 273.47, Tmax: 318.6 },
  { DataId: 578, MainGroup1: 28, MainGroup2: 5, a1: 1752.87, a2: 0.0, '1000a3': 0.0, Tmin: 273.47, Tmax: 318.6 },
  { DataId: 579, MainGroup1: 5, MainGroup2: 30, a1: 647.64, a2: -1.4821, '1000a3': 0.0, Tmin: 293.15, Tmax: 407.15 },
  { DataId: 580, MainGroup1: 30, MainGroup2: 5, a1: 255.16, a2: 1.0838, '1000a3': 0.0, Tmin: 293.15, Tmax: 407.15 },
  { DataId: 581, MainGroup1: 5, MainGroup2: 31, a1: 427.57, a2: -1.93, '1000a3': 0.0, Tmin: 285.65, Tmax: 469.61 },
  { DataId: 582, MainGroup1: 31, MainGroup2: 5, a1: 114.91, a2: 1.3616, '1000a3': 0.0, Tmin: 285.65, Tmax: 469.61 },
  { DataId: 583, MainGroup1: 5, MainGroup2: 32, a1: 4397.54, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 413.63 },
  { DataId: 584, MainGroup1: 32, MainGroup2: 5, a1: 968.74, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 413.63 },
  { DataId: 585, MainGroup1: 5, MainGroup2: 33, a1: 394.56, a2: -0.8236, '1000a3': 0.0, Tmin: 278.15, Tmax: 401.8 },
  { DataId: 586, MainGroup1: 33, MainGroup2: 5, a1: 1475.39, a2: -1.4806, '1000a3': 0.0, Tmin: 278.15, Tmax: 401.8 },
  { DataId: 587, MainGroup1: 5, MainGroup2: 34, a1: -1234.37, a2: 5.1469, '1000a3': 0.0, Tmin: 298.14, Tmax: 412.45 },
  { DataId: 588, MainGroup1: 34, MainGroup2: 5, a1: 1220.43, a2: -2.659, '1000a3': 0.0, Tmin: 298.14, Tmax: 412.45 },
  { DataId: 589, MainGroup1: 5, MainGroup2: 35, a1: 59.12, a2: -0.5521, '1000a3': 0.0, Tmin: 288.15, Tmax: 458.68 },
  { DataId: 590, MainGroup1: 35, MainGroup2: 5, a1: -350.93, a2: 1.2858, '1000a3': 0.0, Tmin: 288.15, Tmax: 458.68 },
  { DataId: 591, MainGroup1: 5, MainGroup2: 36, a1: 179.75, a2: 0.7807, '1000a3': 0.0, Tmin: 299.15, Tmax: 406.45 },
  { DataId: 592, MainGroup1: 36, MainGroup2: 5, a1: 1232.81, a2: -2.3232, '1000a3': 0.0, Tmin: 299.15, Tmax: 406.45 },
  { DataId: 593, MainGroup1: 5, MainGroup2: 37, a1: -3989.26, a2: -6.2089, '1000a3': 74.68, Tmin: 298.0, Tmax: 419.35 },
  {
    DataId: 594,
    MainGroup1: 37,
    MainGroup2: 5,
    a1: 1983.18,
    a2: -0.1857,
    '1000a3': -6.3147,
    Tmin: 298.0,
    Tmax: 419.35,
  },
  { DataId: 595, MainGroup1: 5, MainGroup2: 38, a1: 1214.9, a2: 0.0, '1000a3': 0.0, Tmin: 288.15, Tmax: 318.15 },
  { DataId: 596, MainGroup1: 38, MainGroup2: 5, a1: 1283.1, a2: 0.0, '1000a3': 0.0, Tmin: 288.15, Tmax: 318.15 },
  {
    DataId: 597,
    MainGroup1: 5,
    MainGroup2: 39,
    a1: 1176.72,
    a2: -6.7434,
    '1000a3': 9.8261,
    Tmin: 293.14,
    Tmax: 444.75,
  },
  { DataId: 598, MainGroup1: 39, MainGroup2: 5, a1: 28.76, a2: -0.0767, '1000a3': 0.12289, Tmin: 293.14, Tmax: 444.75 },
  { DataId: 599, MainGroup1: 5, MainGroup2: 40, a1: 90.51, a2: 11.4481, '1000a3': 0.0, Tmin: 298.15, Tmax: 328.15 },
  { DataId: 600, MainGroup1: 40, MainGroup2: 5, a1: -164.31, a2: 6.6095, '1000a3': 0.0, Tmin: 298.15, Tmax: 328.15 },
  { DataId: 601, MainGroup1: 5, MainGroup2: 41, a1: 403.47, a2: -1.8371, '1000a3': 0.0, Tmin: 298.14, Tmax: 422.11 },
  { DataId: 602, MainGroup1: 41, MainGroup2: 5, a1: 752.02, a2: -1.0538, '1000a3': 0.0, Tmin: 298.14, Tmax: 422.11 },
  {
    DataId: 603,
    MainGroup1: 5,
    MainGroup2: 42,
    a1: 2896.87,
    a2: -14.9115,
    '1000a3': 20.408,
    Tmin: 273.1,
    Tmax: 472.25,
  },
  { DataId: 604, MainGroup1: 42, MainGroup2: 5, a1: 2251.89, a2: 1.3697, '1000a3': -11.248, Tmin: 273.1, Tmax: 472.25 },
  {
    DataId: 605,
    MainGroup1: 5,
    MainGroup2: 43,
    a1: -513.4,
    a2: 20.3899,
    '1000a3': -6.7306,
    Tmin: 283.15,
    Tmax: 412.72,
  },
  { DataId: 606, MainGroup1: 43, MainGroup2: 5, a1: 321.52, a2: 0.3827, '1000a3': -2.284, Tmin: 283.15, Tmax: 412.72 },
  { DataId: 607, MainGroup1: 5, MainGroup2: 44, a1: -41.16, a2: -1.8128, '1000a3': 0.0, Tmin: 293.15, Tmax: 354.45 },
  { DataId: 608, MainGroup1: 44, MainGroup2: 5, a1: -846.8, a2: 4.7834, '1000a3': 0.0, Tmin: 293.15, Tmax: 354.45 },
  { DataId: 609, MainGroup1: 5, MainGroup2: 45, a1: -88.71, a2: 0.1776, '1000a3': 0.0, Tmin: 233.18, Tmax: 424.54 },
  { DataId: 610, MainGroup1: 45, MainGroup2: 5, a1: 2960.6, a2: -5.8954, '1000a3': 0.0, Tmin: 233.18, Tmax: 424.54 },
  { DataId: 611, MainGroup1: 5, MainGroup2: 46, a1: -561.06, a2: 0.4243, '1000a3': 0.0, Tmin: 298.15, Tmax: 468.35 },
  { DataId: 612, MainGroup1: 46, MainGroup2: 5, a1: 1770.34, a2: -4.596, '1000a3': 0.0, Tmin: 298.15, Tmax: 468.35 },
  { DataId: 613, MainGroup1: 5, MainGroup2: 47, a1: 127.81, a2: 0.0, '1000a3': 0.0, Tmin: 303.08, Tmax: 333.34 },
  { DataId: 614, MainGroup1: 47, MainGroup2: 5, a1: -130.17, a2: 0.0, '1000a3': 0.0, Tmin: 303.08, Tmax: 333.34 },
  {
    DataId: 615,
    MainGroup1: 5,
    MainGroup2: 48,
    a1: 5444.34,
    a2: -21.9422,
    '1000a3': 21.668,
    Tmin: 298.15,
    Tmax: 363.13,
  },
  { DataId: 616, MainGroup1: 48, MainGroup2: 5, a1: 795.34, a2: -7.5178, '1000a3': 13.326, Tmin: 298.15, Tmax: 363.13 },
  { DataId: 617, MainGroup1: 5, MainGroup2: 49, a1: 123.5, a2: -0.2053, '1000a3': 0.0, Tmin: 298.15, Tmax: 333.15 },
  { DataId: 618, MainGroup1: 49, MainGroup2: 5, a1: 194.65, a2: -0.1964, '1000a3': 0.0, Tmin: 298.15, Tmax: 333.15 },
  { DataId: 619, MainGroup1: 5, MainGroup2: 52, a1: -2814.22, a2: 50.3575, '1000a3': 0.0, Tmin: 308.14, Tmax: 338.15 },
  { DataId: 620, MainGroup1: 52, MainGroup2: 5, a1: -880.27, a2: 46.9513, '1000a3': 0.0, Tmin: 308.14, Tmax: 338.15 },
  { DataId: 621, MainGroup1: 5, MainGroup2: 53, a1: 578.5, a2: -4.0748, '1000a3': 5.4227, Tmin: 298.15, Tmax: 413.15 },
  {
    DataId: 622,
    MainGroup1: 53,
    MainGroup2: 5,
    a1: 2175.78,
    a2: -10.1451,
    '1000a3': 15.396,
    Tmin: 298.15,
    Tmax: 413.15,
  },
  { DataId: 623, MainGroup1: 5, MainGroup2: 54, a1: 323.37, a2: -0.3044, '1000a3': 0.0, Tmin: 298.15, Tmax: 313.15 },
  { DataId: 624, MainGroup1: 54, MainGroup2: 5, a1: 774.07, a2: -1.48, '1000a3': 0.0, Tmin: 298.15, Tmax: 313.15 },
  { DataId: 625, MainGroup1: 5, MainGroup2: 55, a1: 649.99, a2: -1.296, '1000a3': 0.0, Tmin: 288.15, Tmax: 410.19 },
  { DataId: 626, MainGroup1: 55, MainGroup2: 5, a1: 1139.46, a2: -1.5289, '1000a3': 0.0, Tmin: 288.15, Tmax: 410.19 },
  { DataId: 627, MainGroup1: 5, MainGroup2: 56, a1: 782.07, a2: -1.7646, '1000a3': 0.0, Tmin: 288.15, Tmax: 333.6 },
  { DataId: 628, MainGroup1: 56, MainGroup2: 5, a1: 619.02, a2: -1.4359, '1000a3': 0.0, Tmin: 288.15, Tmax: 333.6 },
  { DataId: 629, MainGroup1: 5, MainGroup2: 57, a1: 4857.78, a2: 0.0, '1000a3': 0.0, Tmin: 357.78, Tmax: 475.11 },
  { DataId: 630, MainGroup1: 57, MainGroup2: 5, a1: 388.45, a2: 0.0, '1000a3': 0.0, Tmin: 357.78, Tmax: 475.11 },
  { DataId: 631, MainGroup1: 5, MainGroup2: 59, a1: -190.98, a2: 0.0, '1000a3': 0.0, Tmin: 288.15, Tmax: 538.16 },
  { DataId: 632, MainGroup1: 59, MainGroup2: 5, a1: 296.6, a2: 0.0, '1000a3': 0.0, Tmin: 288.15, Tmax: 538.16 },
  { DataId: 633, MainGroup1: 5, MainGroup2: 60, a1: 1179.16, a2: -3.2842, '1000a3': 0.0, Tmin: 298.14, Tmax: 433.85 },
  { DataId: 634, MainGroup1: 60, MainGroup2: 5, a1: -787.24, a2: 2.5181, '1000a3': 0.0, Tmin: 298.14, Tmax: 433.85 },
  { DataId: 635, MainGroup1: 5, MainGroup2: 61, a1: 280.52, a2: 0.0, '1000a3': 0.0, Tmin: 343.15, Tmax: 358.15 },
  { DataId: 636, MainGroup1: 61, MainGroup2: 5, a1: 615.17, a2: 0.0, '1000a3': 0.0, Tmin: 343.15, Tmax: 358.15 },
  { DataId: 637, MainGroup1: 5, MainGroup2: 62, a1: 632.23, a2: -3.2063, '1000a3': 0.0, Tmin: 298.15, Tmax: 333.15 },
  { DataId: 638, MainGroup1: 62, MainGroup2: 5, a1: -2727.31, a2: 10.3441, '1000a3': 0.0, Tmin: 298.15, Tmax: 333.15 },
  { DataId: 639, MainGroup1: 5, MainGroup2: 64, a1: -801.84, a2: 1.3669, '1000a3': 0.0, Tmin: 293.15, Tmax: 368.23 },
  { DataId: 640, MainGroup1: 64, MainGroup2: 5, a1: -1949.97, a2: 8.5304, '1000a3': 0.0, Tmin: 293.15, Tmax: 368.23 },
  { DataId: 641, MainGroup1: 5, MainGroup2: 65, a1: 3905.97, a2: 0.0, '1000a3': 0.0, Tmin: 288.05, Tmax: 308.23 },
  { DataId: 642, MainGroup1: 65, MainGroup2: 5, a1: 278.96, a2: 0.0, '1000a3': 0.0, Tmin: 288.05, Tmax: 308.23 },
  { DataId: 643, MainGroup1: 5, MainGroup2: 78, a1: -182.46, a2: 0.0, '1000a3': 0.0, Tmin: 313.15, Tmax: 333.35 },
  { DataId: 644, MainGroup1: 78, MainGroup2: 5, a1: -328.98, a2: 0.0, '1000a3': 0.0, Tmin: 313.15, Tmax: 333.35 },
  { DataId: 645, MainGroup1: 5, MainGroup2: 83, a1: 560.6, a2: 0.0, '1000a3': 0.0, Tmin: 294.49, Tmax: 475.87 },
  { DataId: 646, MainGroup1: 83, MainGroup2: 5, a1: -111.64, a2: 0.0, '1000a3': 0.0, Tmin: 294.49, Tmax: 475.87 },
  {
    DataId: 647,
    MainGroup1: 5,
    MainGroup2: 86,
    a1: 546.21,
    a2: 18.3169,
    '1000a3': -12.689,
    Tmin: 298.15,
    Tmax: 422.05,
  },
  {
    DataId: 648,
    MainGroup1: 86,
    MainGroup2: 5,
    a1: 1310.09,
    a2: 4.3165,
    '1000a3': -14.617,
    Tmin: 298.15,
    Tmax: 422.05,
  },
  { DataId: 649, MainGroup1: 5, MainGroup2: 92, a1: -7.48, a2: 0.0, '1000a3': 0.0, Tmin: 344.73, Tmax: 432.94 },
  { DataId: 650, MainGroup1: 92, MainGroup2: 5, a1: -242.3, a2: 0.0, '1000a3': 0.0, Tmin: 344.73, Tmax: 432.94 },
  { DataId: 651, MainGroup1: 5, MainGroup2: 94, a1: 434.58, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 643.51 },
  { DataId: 652, MainGroup1: 94, MainGroup2: 5, a1: -127.76, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 643.51 },
  { DataId: 653, MainGroup1: 5, MainGroup2: 95, a1: -235.9, a2: 9.4806, '1000a3': -2.6223, Tmin: 293.44, Tmax: 409.71 },
  { DataId: 654, MainGroup1: 95, MainGroup2: 5, a1: -619.38, a2: 2.5097, '1000a3': 8.0955, Tmin: 293.44, Tmax: 409.71 },
  { DataId: 655, MainGroup1: 5, MainGroup2: 96, a1: 559.52, a2: -1.7673, '1000a3': 0.0, Tmin: 298.09, Tmax: 493.0 },
  { DataId: 656, MainGroup1: 96, MainGroup2: 5, a1: -635.21, a2: 3.7558, '1000a3': 0.0, Tmin: 298.09, Tmax: 493.0 },
  { DataId: 657, MainGroup1: 6, MainGroup2: 7, a1: -196.91, a2: 0.8361, '1000a3': -2.557, Tmin: 157.77, Tmax: 442.15 },
  { DataId: 658, MainGroup1: 7, MainGroup2: 6, a1: -392.65, a2: 1.9092, '1000a3': 2.5363, Tmin: 157.77, Tmax: 442.15 },
  { DataId: 659, MainGroup1: 6, MainGroup2: 8, a1: -677.95, a2: -1.4529, '1000a3': 30.079, Tmin: 298.15, Tmax: 461.15 },
  { DataId: 660, MainGroup1: 8, MainGroup2: 6, a1: 372.63, a2: -1.1581, '1000a3': -5.0035, Tmin: 298.15, Tmax: 461.15 },
  { DataId: 661, MainGroup1: 6, MainGroup2: 9, a1: 37.69, a2: -0.5867, '1000a3': 0.60031, Tmin: 281.15, Tmax: 422.56 },
  { DataId: 662, MainGroup1: 9, MainGroup2: 6, a1: 384.75, a2: 0.2687, '1000a3': -1.6121, Tmin: 281.15, Tmax: 422.56 },
  { DataId: 663, MainGroup1: 6, MainGroup2: 10, a1: -1798.93, a2: 4.0755, '1000a3': 0.0, Tmin: 288.15, Tmax: 337.69 },
  { DataId: 664, MainGroup1: 10, MainGroup2: 6, a1: -9927.31, a2: 42.6877, '1000a3': 0.0, Tmin: 288.15, Tmax: 337.69 },
  { DataId: 665, MainGroup1: 6, MainGroup2: 11, a1: 161.85, a2: -0.6581, '1000a3': 0.0, Tmin: 291.05, Tmax: 439.45 },
  { DataId: 666, MainGroup1: 11, MainGroup2: 6, a1: 569.85, a2: -0.6599, '1000a3': 0.0, Tmin: 291.05, Tmax: 439.45 },
  { DataId: 667, MainGroup1: 6, MainGroup2: 12, a1: 218.0, a2: -0.7755, '1000a3': 0.0, Tmin: 298.14, Tmax: 358.94 },
  { DataId: 668, MainGroup1: 12, MainGroup2: 6, a1: 537.61, a2: -0.2171, '1000a3': 0.0, Tmin: 298.14, Tmax: 358.94 },
  { DataId: 669, MainGroup1: 6, MainGroup2: 13, a1: -98.37, a2: -0.4145, '1000a3': 0.0, Tmin: 249.99, Tmax: 417.82 },
  { DataId: 670, MainGroup1: 13, MainGroup2: 6, a1: 376.35, a2: 0.0763, '1000a3': 0.0, Tmin: 249.99, Tmax: 417.82 },
  { DataId: 671, MainGroup1: 6, MainGroup2: 14, a1: -392.43, a2: 1.1325, '1000a3': 0.0, Tmin: 293.15, Tmax: 393.1 },
  { DataId: 672, MainGroup1: 14, MainGroup2: 6, a1: -588.47, a2: 0.6302, '1000a3': 0.0, Tmin: 293.15, Tmax: 393.1 },
  { DataId: 673, MainGroup1: 6, MainGroup2: 15, a1: -571.35, a2: 1.4152, '1000a3': 0.0, Tmin: 293.0, Tmax: 398.55 },
  { DataId: 674, MainGroup1: 15, MainGroup2: 6, a1: -731.03, a2: 1.1565, '1000a3': 0.0, Tmin: 293.0, Tmax: 398.55 },
  {
    DataId: 675,
    MainGroup1: 6,
    MainGroup2: 16,
    a1: -4424.9,
    a2: 17.7321,
    '1000a3': -8.7254,
    Tmin: 283.15,
    Tmax: 356.22,
  },
  {
    DataId: 676,
    MainGroup1: 16,
    MainGroup2: 6,
    a1: 3787.91,
    a2: -25.1485,
    '1000a3': 37.691,
    Tmin: 283.15,
    Tmax: 356.22,
  },
  {
    DataId: 677,
    MainGroup1: 6,
    MainGroup2: 17,
    a1: -1689.57,
    a2: 13.7549,
    '1000a3': -22.263,
    Tmin: 293.1,
    Tmax: 385.12,
  },
  { DataId: 678, MainGroup1: 17, MainGroup2: 6, a1: 61.28, a2: -0.9526, '1000a3': 2.3761, Tmin: 293.1, Tmax: 385.12 },
  { DataId: 679, MainGroup1: 6, MainGroup2: 18, a1: 645.69, a2: -2.0751, '1000a3': 1.5218, Tmin: 293.1, Tmax: 413.17 },
  { DataId: 680, MainGroup1: 18, MainGroup2: 6, a1: -35.72, a2: -2.1754, '1000a3': 3.6591, Tmin: 293.1, Tmax: 413.17 },
  { DataId: 681, MainGroup1: 6, MainGroup2: 19, a1: 211.7, a2: -0.6737, '1000a3': 0.0, Tmin: 278.15, Tmax: 367.93 },
  { DataId: 682, MainGroup1: 19, MainGroup2: 6, a1: 464.8, a2: -0.262, '1000a3': 0.0, Tmin: 278.15, Tmax: 367.93 },
  { DataId: 683, MainGroup1: 6, MainGroup2: 20, a1: -362.79, a2: 0.6093, '1000a3': 0.0, Tmin: 242.86, Tmax: 413.15 },
  { DataId: 684, MainGroup1: 20, MainGroup2: 6, a1: 1031.26, a2: -2.7619, '1000a3': 0.0, Tmin: 242.86, Tmax: 413.15 },
  { DataId: 685, MainGroup1: 6, MainGroup2: 21, a1: -28.87, a2: -0.2243, '1000a3': 0.0, Tmin: 250.06, Tmax: 354.98 },
  { DataId: 686, MainGroup1: 21, MainGroup2: 6, a1: 1673.43, a2: -2.5736, '1000a3': 0.0, Tmin: 250.06, Tmax: 354.98 },
  { DataId: 687, MainGroup1: 6, MainGroup2: 22, a1: -102.19, a2: -0.2109, '1000a3': 0.0, Tmin: 298.0, Tmax: 398.18 },
  { DataId: 688, MainGroup1: 22, MainGroup2: 6, a1: 1909.55, a2: -2.9423, '1000a3': 0.0, Tmin: 298.0, Tmax: 398.18 },
  { DataId: 689, MainGroup1: 6, MainGroup2: 23, a1: -44.56, a2: 0.0, '1000a3': 0.0, Tmin: 327.15, Tmax: 335.25 },
  { DataId: 690, MainGroup1: 23, MainGroup2: 6, a1: 792.56, a2: 0.0, '1000a3': 0.0, Tmin: 327.15, Tmax: 335.25 },
  { DataId: 691, MainGroup1: 6, MainGroup2: 24, a1: -48.62, a2: -0.0192, '1000a3': 0.0, Tmin: 273.15, Tmax: 353.13 },
  { DataId: 692, MainGroup1: 24, MainGroup2: 6, a1: 2121.66, a2: -2.8757, '1000a3': 0.0, Tmin: 273.15, Tmax: 353.13 },
  { DataId: 693, MainGroup1: 6, MainGroup2: 25, a1: 2881.05, a2: -15.2352, '1000a3': 0.0, Tmin: 293.15, Tmax: 386.72 },
  { DataId: 694, MainGroup1: 25, MainGroup2: 6, a1: 2166.83, a2: 15.6402, '1000a3': 0.0, Tmin: 293.15, Tmax: 386.72 },
  { DataId: 695, MainGroup1: 6, MainGroup2: 26, a1: 96.88, a2: -0.4838, '1000a3': 0.20416, Tmin: 293.1, Tmax: 388.21 },
  { DataId: 696, MainGroup1: 26, MainGroup2: 6, a1: 579.66, a2: 1.7807, '1000a3': -5.0101, Tmin: 293.1, Tmax: 388.21 },
  { DataId: 697, MainGroup1: 6, MainGroup2: 27, a1: 155.45, a2: 0.062, '1000a3': 0.0, Tmin: 243.38, Tmax: 423.15 },
  { DataId: 698, MainGroup1: 27, MainGroup2: 6, a1: 1337.48, a2: -2.1071, '1000a3': 0.0, Tmin: 243.38, Tmax: 423.15 },
  { DataId: 699, MainGroup1: 6, MainGroup2: 28, a1: -203.51, a2: 3.8794, '1000a3': -7.2342, Tmin: 199.6, Tmax: 337.13 },
  {
    DataId: 700,
    MainGroup1: 28,
    MainGroup2: 6,
    a1: 4616.87,
    a2: -2.9639,
    '1000a3': -27.759,
    Tmin: 199.6,
    Tmax: 337.13,
  },
  { DataId: 701, MainGroup1: 6, MainGroup2: 29, a1: 108.09, a2: -0.5203, '1000a3': 0.0, Tmin: 263.15, Tmax: 313.14 },
  { DataId: 702, MainGroup1: 29, MainGroup2: 6, a1: 1311.37, a2: -1.6729, '1000a3': 0.0, Tmin: 263.15, Tmax: 313.14 },
  { DataId: 703, MainGroup1: 6, MainGroup2: 30, a1: -269.47, a2: 1.9217, '1000a3': 0.0, Tmin: 298.15, Tmax: 433.84 },
  { DataId: 704, MainGroup1: 30, MainGroup2: 6, a1: 583.04, a2: -1.6316, '1000a3': 0.0, Tmin: 298.15, Tmax: 433.84 },
  { DataId: 705, MainGroup1: 6, MainGroup2: 31, a1: -220.31, a2: 0.0438, '1000a3': 0.0, Tmin: 285.65, Tmax: 461.75 },
  { DataId: 706, MainGroup1: 31, MainGroup2: 6, a1: 697.69, a2: -0.6599, '1000a3': 0.0, Tmin: 285.65, Tmax: 461.75 },
  { DataId: 707, MainGroup1: 6, MainGroup2: 32, a1: 205.23, a2: 0.2456, '1000a3': 0.0, Tmin: 293.15, Tmax: 383.12 },
  { DataId: 708, MainGroup1: 32, MainGroup2: 6, a1: 1881.15, a2: -3.7345, '1000a3': 0.0, Tmin: 293.15, Tmax: 383.12 },
  { DataId: 709, MainGroup1: 6, MainGroup2: 33, a1: -51.29, a2: -0.2269, '1000a3': 0.0, Tmin: 298.0, Tmax: 337.13 },
  { DataId: 710, MainGroup1: 33, MainGroup2: 6, a1: 1055.75, a2: 0.0317, '1000a3': 0.0, Tmin: 298.0, Tmax: 337.13 },
  { DataId: 711, MainGroup1: 6, MainGroup2: 34, a1: 53.82, a2: -0.31, '1000a3': 0.0, Tmin: 298.15, Tmax: 374.85 },
  { DataId: 712, MainGroup1: 34, MainGroup2: 6, a1: 926.52, a2: -0.8568, '1000a3': 0.0, Tmin: 298.15, Tmax: 374.85 },
  { DataId: 713, MainGroup1: 6, MainGroup2: 35, a1: -222.9, a2: 0.1271, '1000a3': 0.0, Tmin: 293.15, Tmax: 442.83 },
  { DataId: 714, MainGroup1: 35, MainGroup2: 6, a1: 196.45, a2: -0.7575, '1000a3': 0.0, Tmin: 293.15, Tmax: 442.83 },
  { DataId: 715, MainGroup1: 6, MainGroup2: 36, a1: 175.92, a2: 0.0, '1000a3': 0.0, Tmin: 335.93, Tmax: 345.03 },
  { DataId: 716, MainGroup1: 36, MainGroup2: 6, a1: 129.61, a2: 0.0, '1000a3': 0.0, Tmin: 335.93, Tmax: 345.03 },
  { DataId: 717, MainGroup1: 6, MainGroup2: 37, a1: -87.81, a2: 0.0, '1000a3': 0.0, Tmin: 253.15, Tmax: 336.52 },
  { DataId: 718, MainGroup1: 37, MainGroup2: 6, a1: 2887.56, a2: 0.0, '1000a3': 0.0, Tmin: 253.15, Tmax: 336.52 },
  { DataId: 719, MainGroup1: 6, MainGroup2: 38, a1: 36.25, a2: -0.443, '1000a3': 0.0, Tmin: 288.15, Tmax: 328.14 },
  { DataId: 720, MainGroup1: 38, MainGroup2: 6, a1: 2145.02, a2: -2.767, '1000a3': 0.0, Tmin: 288.15, Tmax: 328.14 },
  { DataId: 721, MainGroup1: 6, MainGroup2: 39, a1: 137.96, a2: -1.7932, '1000a3': 1.6192, Tmin: 293.14, Tmax: 423.08 },
  {
    DataId: 722,
    MainGroup1: 39,
    MainGroup2: 6,
    a1: -316.91,
    a2: 2.9612,
    '1000a3': -3.1149,
    Tmin: 293.14,
    Tmax: 423.08,
  },
  { DataId: 723, MainGroup1: 6, MainGroup2: 41, a1: 251.8, a2: -1.1874, '1000a3': 0.0, Tmin: 298.09, Tmax: 369.72 },
  { DataId: 724, MainGroup1: 41, MainGroup2: 6, a1: 676.02, a2: -1.3251, '1000a3': 0.0, Tmin: 298.09, Tmax: 369.72 },
  { DataId: 725, MainGroup1: 6, MainGroup2: 42, a1: 61.7, a2: -0.3785, '1000a3': 0.0, Tmin: 246.78, Tmax: 370.07 },
  { DataId: 726, MainGroup1: 42, MainGroup2: 6, a1: 2834.27, a2: -4.6539, '1000a3': 0.0, Tmin: 246.78, Tmax: 370.07 },
  { DataId: 727, MainGroup1: 6, MainGroup2: 43, a1: -273.42, a2: 1.6357, '1000a3': -2.953, Tmin: 293.14, Tmax: 373.66 },
  { DataId: 728, MainGroup1: 43, MainGroup2: 6, a1: 914.38, a2: -3.5201, '1000a3': 5.8013, Tmin: 293.14, Tmax: 373.66 },
  {
    DataId: 729,
    MainGroup1: 6,
    MainGroup2: 45,
    a1: -377.09,
    a2: 1.4167,
    '1000a3': -2.3449,
    Tmin: 293.15,
    Tmax: 336.92,
  },
  {
    DataId: 730,
    MainGroup1: 45,
    MainGroup2: 6,
    a1: -759.52,
    a2: 13.4902,
    '1000a3': -24.205,
    Tmin: 293.15,
    Tmax: 336.92,
  },
  { DataId: 731, MainGroup1: 6, MainGroup2: 46, a1: 352.7, a2: 0.4129, '1000a3': 0.0, Tmin: 298.09, Tmax: 393.19 },
  { DataId: 732, MainGroup1: 46, MainGroup2: 6, a1: -103.91, a2: -0.5635, '1000a3': 0.0, Tmin: 298.09, Tmax: 393.19 },
  { DataId: 733, MainGroup1: 6, MainGroup2: 47, a1: -118.8, a2: 0.504, '1000a3': 0.0, Tmin: 303.15, Tmax: 398.6 },
  { DataId: 734, MainGroup1: 47, MainGroup2: 6, a1: -7.56, a2: -0.5763, '1000a3': 0.0, Tmin: 303.15, Tmax: 398.6 },
  { DataId: 735, MainGroup1: 6, MainGroup2: 48, a1: 210.91, a2: -2.4953, '1000a3': 4.8, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 736, MainGroup1: 48, MainGroup2: 6, a1: -132.93, a2: 0.6584, '1000a3': -2.08, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 737, MainGroup1: 6, MainGroup2: 49, a1: 218.42, a2: -1.0945, '1000a3': 0.0, Tmin: 198.75, Tmax: 333.15 },
  { DataId: 738, MainGroup1: 49, MainGroup2: 6, a1: -241.06, a2: 1.364, '1000a3': 0.0, Tmin: 198.75, Tmax: 333.15 },
  { DataId: 739, MainGroup1: 6, MainGroup2: 52, a1: -47.58, a2: -0.1061, '1000a3': 0.0, Tmin: 308.14, Tmax: 353.67 },
  { DataId: 740, MainGroup1: 52, MainGroup2: 6, a1: 2364.7, a2: -4.5222, '1000a3': 0.0, Tmin: 308.14, Tmax: 353.67 },
  { DataId: 741, MainGroup1: 6, MainGroup2: 53, a1: 338.52, a2: -1.9652, '1000a3': 0.0, Tmin: 298.15, Tmax: 413.15 },
  { DataId: 742, MainGroup1: 53, MainGroup2: 6, a1: 84.7, a2: 2.1806, '1000a3': 0.0, Tmin: 298.15, Tmax: 413.15 },
  { DataId: 743, MainGroup1: 6, MainGroup2: 55, a1: 82.48, a2: -0.4605, '1000a3': 0.0, Tmin: 298.15, Tmax: 428.15 },
  { DataId: 744, MainGroup1: 55, MainGroup2: 6, a1: 888.78, a2: -1.0623, '1000a3': 0.0, Tmin: 298.15, Tmax: 428.15 },
  { DataId: 745, MainGroup1: 6, MainGroup2: 56, a1: 545.5, a2: -1.1936, '1000a3': 0.0, Tmin: 288.15, Tmax: 333.74 },
  { DataId: 746, MainGroup1: 56, MainGroup2: 6, a1: 944.17, a2: -2.2776, '1000a3': 0.0, Tmin: 288.15, Tmax: 333.74 },
  { DataId: 747, MainGroup1: 6, MainGroup2: 60, a1: 310.13, a2: -1.0805, '1000a3': 0.0, Tmin: 298.15, Tmax: 399.52 },
  { DataId: 748, MainGroup1: 60, MainGroup2: 6, a1: -245.74, a2: 0.8364, '1000a3': 0.0, Tmin: 298.15, Tmax: 399.52 },
  { DataId: 749, MainGroup1: 6, MainGroup2: 61, a1: 247.69, a2: -0.8701, '1000a3': 0.0, Tmin: 263.15, Tmax: 335.95 },
  { DataId: 750, MainGroup1: 61, MainGroup2: 6, a1: 734.54, a2: 0.0144, '1000a3': 0.0, Tmin: 263.15, Tmax: 335.95 },
  { DataId: 751, MainGroup1: 6, MainGroup2: 62, a1: 12.18, a2: -0.036, '1000a3': 0.0, Tmin: 303.15, Tmax: 333.15 },
  { DataId: 752, MainGroup1: 62, MainGroup2: 6, a1: -334.86, a2: 0.9073, '1000a3': 0.0, Tmin: 303.15, Tmax: 333.15 },
  { DataId: 753, MainGroup1: 6, MainGroup2: 64, a1: -588.71, a2: 0.0, '1000a3': 0.0, Tmin: 302.92, Tmax: 313.64 },
  { DataId: 754, MainGroup1: 64, MainGroup2: 6, a1: 594.31, a2: 0.0, '1000a3': 0.0, Tmin: 302.92, Tmax: 313.64 },
  { DataId: 755, MainGroup1: 6, MainGroup2: 65, a1: 1510.37, a2: 0.0, '1000a3': 0.0, Tmin: 288.15, Tmax: 305.75 },
  { DataId: 756, MainGroup1: 65, MainGroup2: 6, a1: -285.73, a2: 0.0, '1000a3': 0.0, Tmin: 288.15, Tmax: 305.75 },
  { DataId: 757, MainGroup1: 6, MainGroup2: 75, a1: -47.99, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 341.03 },
  { DataId: 758, MainGroup1: 75, MainGroup2: 6, a1: 660.55, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 341.03 },
  { DataId: 759, MainGroup1: 6, MainGroup2: 77, a1: -895.41, a2: 3.3331, '1000a3': 0.0, Tmin: 298.15, Tmax: 336.42 },
  { DataId: 760, MainGroup1: 77, MainGroup2: 6, a1: 527.38, a2: -0.1161, '1000a3': 0.0, Tmin: 298.15, Tmax: 336.42 },
  { DataId: 761, MainGroup1: 6, MainGroup2: 78, a1: -558.93, a2: 1.3364, '1000a3': 0.0, Tmin: 298.14, Tmax: 318.14 },
  { DataId: 762, MainGroup1: 78, MainGroup2: 6, a1: -380.15, a2: 0.044, '1000a3': 0.0, Tmin: 298.14, Tmax: 318.14 },
  { DataId: 763, MainGroup1: 6, MainGroup2: 86, a1: -21.45, a2: 0.0, '1000a3': 0.0, Tmin: 335.15, Tmax: 390.32 },
  { DataId: 764, MainGroup1: 86, MainGroup2: 6, a1: 1306.14, a2: 0.0, '1000a3': 0.0, Tmin: 335.15, Tmax: 390.32 },
  { DataId: 765, MainGroup1: 6, MainGroup2: 92, a1: 538.44, a2: -1.5306, '1000a3': 0.0, Tmin: 298.14, Tmax: 392.17 },
  { DataId: 766, MainGroup1: 92, MainGroup2: 6, a1: -920.38, a2: 1.719, '1000a3': 0.0, Tmin: 298.14, Tmax: 392.17 },
  { DataId: 767, MainGroup1: 6, MainGroup2: 95, a1: -62.65, a2: 0.0, '1000a3': 0.0, Tmin: 301.47, Tmax: 345.93 },
  { DataId: 768, MainGroup1: 95, MainGroup2: 6, a1: 942.52, a2: 0.0, '1000a3': 0.0, Tmin: 301.47, Tmax: 345.93 },
  { DataId: 769, MainGroup1: 6, MainGroup2: 96, a1: 107.38, a2: -0.7463, '1000a3': 0.0, Tmin: 298.09, Tmax: 493.0 },
  { DataId: 770, MainGroup1: 96, MainGroup2: 6, a1: 171.29, a2: 0.7718, '1000a3': 0.0, Tmin: 298.09, Tmax: 493.0 },
  {
    DataId: 771,
    MainGroup1: 7,
    MainGroup2: 8,
    a1: -2747.89,
    a2: 19.2627,
    '1000a3': -23.488,
    Tmin: 273.15,
    Tmax: 443.64,
  },
  { DataId: 772, MainGroup1: 8, MainGroup2: 7, a1: 151.32, a2: -2.785, '1000a3': 2.0981, Tmin: 273.15, Tmax: 443.64 },
  { DataId: 773, MainGroup1: 7, MainGroup2: 9, a1: 206.26, a2: -3.9629, '1000a3': 9.306, Tmin: 278.15, Tmax: 515.37 },
  { DataId: 774, MainGroup1: 9, MainGroup2: 7, a1: 867.04, a2: -0.7877, '1000a3': -3.0305, Tmin: 278.15, Tmax: 515.37 },
  { DataId: 775, MainGroup1: 7, MainGroup2: 10, a1: -397.91, a2: 1.183, '1000a3': 6.3247, Tmin: 273.15, Tmax: 419.62 },
  { DataId: 776, MainGroup1: 10, MainGroup2: 7, a1: -119.4, a2: 0.4018, '1000a3': -1.8762, Tmin: 273.15, Tmax: 419.62 },
  { DataId: 777, MainGroup1: 7, MainGroup2: 11, a1: 3.28, a2: 2.1301, '1000a3': -0.56037, Tmin: 288.15, Tmax: 429.51 },
  {
    DataId: 778,
    MainGroup1: 11,
    MainGroup2: 7,
    a1: -12.36,
    a2: -0.408,
    '1000a3': -0.16248,
    Tmin: 288.15,
    Tmax: 429.51,
  },
  {
    DataId: 779,
    MainGroup1: 7,
    MainGroup2: 12,
    a1: -67.62,
    a2: -0.0932,
    '1000a3': -0.025332,
    Tmin: 272.15,
    Tmax: 363.75,
  },
  {
    DataId: 780,
    MainGroup1: 12,
    MainGroup2: 7,
    a1: 1274.05,
    a2: -0.2557,
    '1000a3': -3.0377,
    Tmin: 272.15,
    Tmax: 363.75,
  },
  { DataId: 781, MainGroup1: 7, MainGroup2: 13, a1: -46.96, a2: -0.3124, '1000a3': 1.7411, Tmin: 273.35, Tmax: 393.15 },
  { DataId: 782, MainGroup1: 13, MainGroup2: 7, a1: -44.29, a2: 0.5553, '1000a3': 0.96627, Tmin: 273.35, Tmax: 393.15 },
  {
    DataId: 783,
    MainGroup1: 7,
    MainGroup2: 14,
    a1: 2681.11,
    a2: -15.3399,
    '1000a3': 22.312,
    Tmin: 287.04,
    Tmax: 460.95,
  },
  {
    DataId: 784,
    MainGroup1: 14,
    MainGroup2: 7,
    a1: -1159.82,
    a2: 4.7895,
    '1000a3': -7.6644,
    Tmin: 287.04,
    Tmax: 460.95,
  },
  { DataId: 785, MainGroup1: 7, MainGroup2: 15, a1: 60.64, a2: 7.3537, '1000a3': -20.776, Tmin: 278.1, Tmax: 329.92 },
  { DataId: 786, MainGroup1: 15, MainGroup2: 7, a1: 369.65, a2: -7.2717, '1000a3': 14.669, Tmin: 278.1, Tmax: 329.92 },
  { DataId: 787, MainGroup1: 7, MainGroup2: 16, a1: 329.91, a2: -1.1284, '1000a3': 2.8117, Tmin: 273.1, Tmax: 372.72 },
  {
    DataId: 788,
    MainGroup1: 16,
    MainGroup2: 7,
    a1: -594.03,
    a2: 0.1992,
    '1000a3': 0.077003,
    Tmin: 273.1,
    Tmax: 372.72,
  },
  { DataId: 789, MainGroup1: 7, MainGroup2: 17, a1: 139.89, a2: 0.494, '1000a3': 0.0, Tmin: 284.14, Tmax: 441.09 },
  { DataId: 790, MainGroup1: 17, MainGroup2: 7, a1: -3.54, a2: -0.9412, '1000a3': 0.0, Tmin: 284.14, Tmax: 441.09 },
  {
    DataId: 791,
    MainGroup1: 7,
    MainGroup2: 18,
    a1: 2207.81,
    a2: -8.1398,
    '1000a3': 13.788,
    Tmin: 293.15,
    Tmax: 448.15,
  },
  {
    DataId: 792,
    MainGroup1: 18,
    MainGroup2: 7,
    a1: -450.99,
    a2: -0.0145,
    '1000a3': -1.5056,
    Tmin: 293.15,
    Tmax: 448.15,
  },
  {
    DataId: 793,
    MainGroup1: 7,
    MainGroup2: 19,
    a1: -615.44,
    a2: 3.5111,
    '1000a3': 0.87503,
    Tmin: 269.36,
    Tmax: 411.08,
  },
  {
    DataId: 794,
    MainGroup1: 19,
    MainGroup2: 7,
    a1: 484.32,
    a2: -1.9699,
    '1000a3': 0.14249,
    Tmin: 269.36,
    Tmax: 411.08,
  },
  { DataId: 795, MainGroup1: 7, MainGroup2: 20, a1: 82.33, a2: 1.0692, '1000a3': -1.5475, Tmin: 280.55, Tmax: 516.61 },
  { DataId: 796, MainGroup1: 20, MainGroup2: 7, a1: 195.87, a2: -1.9941, '1000a3': 2.4708, Tmin: 280.55, Tmax: 516.61 },
  { DataId: 797, MainGroup1: 7, MainGroup2: 21, a1: 264.45, a2: 0.8679, '1000a3': 0.0, Tmin: 273.15, Tmax: 423.2 },
  { DataId: 798, MainGroup1: 21, MainGroup2: 7, a1: 614.52, a2: -0.9472, '1000a3': 0.0, Tmin: 273.15, Tmax: 423.2 },
  { DataId: 799, MainGroup1: 7, MainGroup2: 22, a1: 320.42, a2: 1.3941, '1000a3': -4.1789, Tmin: 273.1, Tmax: 404.63 },
  { DataId: 800, MainGroup1: 22, MainGroup2: 7, a1: 555.41, a2: -2.2174, '1000a3': 4.2666, Tmin: 273.1, Tmax: 404.63 },
  { DataId: 801, MainGroup1: 7, MainGroup2: 23, a1: -115.28, a2: 2.1193, '1000a3': 0.0, Tmin: 273.15, Tmax: 344.65 },
  { DataId: 802, MainGroup1: 23, MainGroup2: 7, a1: 37.75, a2: 1.3459, '1000a3': 0.0, Tmin: 273.15, Tmax: 344.65 },
  { DataId: 803, MainGroup1: 7, MainGroup2: 24, a1: 1380.75, a2: -2.7429, '1000a3': 0.0, Tmin: 273.15, Tmax: 348.35 },
  { DataId: 804, MainGroup1: 24, MainGroup2: 7, a1: 1452.36, a2: -3.3678, '1000a3': 0.0, Tmin: 273.15, Tmax: 348.35 },
  { DataId: 805, MainGroup1: 7, MainGroup2: 25, a1: -1895.0, a2: 9.303, '1000a3': 0.0, Tmin: 273.15, Tmax: 398.95 },
  { DataId: 806, MainGroup1: 25, MainGroup2: 7, a1: 591.6, a2: -3.08, '1000a3': 0.0, Tmin: 273.15, Tmax: 398.95 },
  { DataId: 807, MainGroup1: 7, MainGroup2: 26, a1: -568.15, a2: 2.634, '1000a3': 0.0, Tmin: 273.15, Tmax: 443.65 },
  { DataId: 808, MainGroup1: 26, MainGroup2: 7, a1: 882.44, a2: -2.606, '1000a3': 0.0, Tmin: 273.15, Tmax: 443.65 },
  { DataId: 809, MainGroup1: 7, MainGroup2: 27, a1: 133.15, a2: 4.3181, '1000a3': 0.0, Tmin: 281.94, Tmax: 379.75 },
  { DataId: 810, MainGroup1: 27, MainGroup2: 7, a1: -606.04, a2: 1.2196, '1000a3': 0.0, Tmin: 281.94, Tmax: 379.75 },
  { DataId: 811, MainGroup1: 7, MainGroup2: 28, a1: 1731.98, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 322.15 },
  { DataId: 812, MainGroup1: 28, MainGroup2: 7, a1: 2675.12, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 322.15 },
  { DataId: 813, MainGroup1: 7, MainGroup2: 29, a1: 630.75, a2: 0.0, '1000a3': 0.0, Tmin: 288.15, Tmax: 333.15 },
  { DataId: 814, MainGroup1: 29, MainGroup2: 7, a1: 3778.71, a2: 0.0, '1000a3': 0.0, Tmin: 288.15, Tmax: 333.15 },
  { DataId: 815, MainGroup1: 7, MainGroup2: 30, a1: -214.92, a2: 4.0084, '1000a3': 0.0, Tmin: 298.14, Tmax: 497.16 },
  { DataId: 816, MainGroup1: 30, MainGroup2: 7, a1: 487.8, a2: -1.8616, '1000a3': 0.0, Tmin: 298.14, Tmax: 497.16 },
  { DataId: 817, MainGroup1: 7, MainGroup2: 31, a1: 67.45, a2: 0.0471, '1000a3': 0.0, Tmin: 285.65, Tmax: 470.71 },
  { DataId: 818, MainGroup1: 31, MainGroup2: 7, a1: -135.86, a2: -0.3313, '1000a3': 0.0, Tmin: 285.65, Tmax: 470.71 },
  { DataId: 819, MainGroup1: 7, MainGroup2: 32, a1: 1318.0, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 303.15 },
  { DataId: 820, MainGroup1: 32, MainGroup2: 7, a1: 2809.63, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 303.15 },
  { DataId: 821, MainGroup1: 7, MainGroup2: 33, a1: 998.54, a2: -1.799, '1000a3': 0.0, Tmin: 273.15, Tmax: 363.75 },
  { DataId: 822, MainGroup1: 33, MainGroup2: 7, a1: -121.09, a2: 0.8834, '1000a3': 0.0, Tmin: 273.15, Tmax: 363.75 },
  { DataId: 823, MainGroup1: 7, MainGroup2: 35, a1: 55.26, a2: -0.4555, '1000a3': 0.0, Tmin: 296.08, Tmax: 441.51 },
  { DataId: 824, MainGroup1: 35, MainGroup2: 7, a1: -361.72, a2: 0.07, '1000a3': 0.0, Tmin: 296.08, Tmax: 441.51 },
  { DataId: 825, MainGroup1: 7, MainGroup2: 36, a1: -704.1, a2: 6.0993, '1000a3': 0.0, Tmin: 263.15, Tmax: 371.82 },
  { DataId: 826, MainGroup1: 36, MainGroup2: 7, a1: 371.99, a2: -1.3469, '1000a3': 0.0, Tmin: 263.15, Tmax: 371.82 },
  { DataId: 827, MainGroup1: 7, MainGroup2: 39, a1: -421.74, a2: 2.7911, '1000a3': 0.0, Tmin: 289.45, Tmax: 424.27 },
  { DataId: 828, MainGroup1: 39, MainGroup2: 7, a1: -108.97, a2: -0.6835, '1000a3': 0.0, Tmin: 289.45, Tmax: 424.27 },
  { DataId: 829, MainGroup1: 7, MainGroup2: 41, a1: 481.37, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 368.85 },
  { DataId: 830, MainGroup1: 41, MainGroup2: 7, a1: -338.91, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 368.85 },
  { DataId: 831, MainGroup1: 7, MainGroup2: 42, a1: 274.37, a2: -0.5861, '1000a3': -0.3001, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 832, MainGroup1: 42, MainGroup2: 7, a1: 1632.9, a2: -2.8719, '1000a3': 3.455, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 833, MainGroup1: 7, MainGroup2: 43, a1: 0.0, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 834, MainGroup1: 43, MainGroup2: 7, a1: 0.0, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 835, MainGroup1: 7, MainGroup2: 44, a1: -122.47, a2: 0.5285, '1000a3': 0.0, Tmin: 278.87, Tmax: 417.1 },
  { DataId: 836, MainGroup1: 44, MainGroup2: 7, a1: 9.72, a2: -0.3228, '1000a3': 0.0, Tmin: 278.87, Tmax: 417.1 },
  { DataId: 837, MainGroup1: 7, MainGroup2: 45, a1: 656.86, a2: -0.6665, '1000a3': 0.0, Tmin: 285.65, Tmax: 403.2 },
  { DataId: 838, MainGroup1: 45, MainGroup2: 7, a1: -563.25, a2: 3.0279, '1000a3': 0.0, Tmin: 285.65, Tmax: 403.2 },
  { DataId: 839, MainGroup1: 7, MainGroup2: 46, a1: 516.07, a2: -1.6461, '1000a3': 0.0, Tmin: 273.1, Tmax: 471.3 },
  { DataId: 840, MainGroup1: 46, MainGroup2: 7, a1: -602.81, a2: 1.0506, '1000a3': 0.0, Tmin: 273.1, Tmax: 471.3 },
  { DataId: 841, MainGroup1: 7, MainGroup2: 47, a1: 785.53, a2: -3.5137, '1000a3': 4.955, Tmin: 308.15, Tmax: 478.31 },
  {
    DataId: 842,
    MainGroup1: 47,
    MainGroup2: 7,
    a1: -540.59,
    a2: 0.7622,
    '1000a3': -0.89957,
    Tmin: 308.15,
    Tmax: 478.31,
  },
  { DataId: 843, MainGroup1: 7, MainGroup2: 48, a1: 114.17, a2: -0.4265, '1000a3': 0.0, Tmin: 298.15, Tmax: 435.11 },
  { DataId: 844, MainGroup1: 48, MainGroup2: 7, a1: -447.61, a2: 0.5565, '1000a3': 0.0, Tmin: 298.15, Tmax: 435.11 },
  { DataId: 845, MainGroup1: 7, MainGroup2: 49, a1: -802.5, a2: 6.8837, '1000a3': -7.8127, Tmin: 235.11, Tmax: 467.91 },
  {
    DataId: 846,
    MainGroup1: 49,
    MainGroup2: 7,
    a1: -128.92,
    a2: -0.7165,
    '1000a3': -0.3067,
    Tmin: 235.11,
    Tmax: 467.91,
  },
  { DataId: 847, MainGroup1: 7, MainGroup2: 53, a1: -692.46, a2: 2.4782, '1000a3': 0.0, Tmin: 278.15, Tmax: 372.15 },
  { DataId: 848, MainGroup1: 53, MainGroup2: 7, a1: 821.67, a2: -2.1356, '1000a3': 0.0, Tmin: 278.15, Tmax: 372.15 },
  { DataId: 849, MainGroup1: 7, MainGroup2: 54, a1: 308.59, a2: 0.0, '1000a3': 0.0, Tmin: 353.12, Tmax: 353.15 },
  { DataId: 850, MainGroup1: 54, MainGroup2: 7, a1: -438.75, a2: 0.0, '1000a3': 0.0, Tmin: 353.12, Tmax: 353.15 },
  { DataId: 851, MainGroup1: 7, MainGroup2: 55, a1: 1672.47, a2: -3.4926, '1000a3': 0.0, Tmin: 273.35, Tmax: 370.13 },
  { DataId: 852, MainGroup1: 55, MainGroup2: 7, a1: -917.49, a2: 2.3069, '1000a3': 0.0, Tmin: 273.35, Tmax: 370.13 },
  { DataId: 853, MainGroup1: 7, MainGroup2: 56, a1: 24.15, a2: 0.1739, '1000a3': 0.0, Tmin: 288.15, Tmax: 454.23 },
  { DataId: 854, MainGroup1: 56, MainGroup2: 7, a1: 249.54, a2: -1.0959, '1000a3': 0.0, Tmin: 288.15, Tmax: 454.23 },
  { DataId: 855, MainGroup1: 7, MainGroup2: 60, a1: 654.7, a2: -0.4974, '1000a3': 0.0, Tmin: 278.1, Tmax: 433.15 },
  { DataId: 856, MainGroup1: 60, MainGroup2: 7, a1: -294.11, a2: -0.2165, '1000a3': 0.0, Tmin: 278.1, Tmax: 433.15 },
  { DataId: 857, MainGroup1: 7, MainGroup2: 62, a1: -96.68, a2: 0.0, '1000a3': 0.0, Tmin: 295.99, Tmax: 420.96 },
  { DataId: 858, MainGroup1: 62, MainGroup2: 7, a1: -450.55, a2: 0.0, '1000a3': 0.0, Tmin: 295.99, Tmax: 420.96 },
  { DataId: 859, MainGroup1: 7, MainGroup2: 63, a1: 39.18, a2: 0.0, '1000a3': 0.0, Tmin: 299.15, Tmax: 437.09 },
  { DataId: 860, MainGroup1: 63, MainGroup2: 7, a1: -368.65, a2: 0.0, '1000a3': 0.0, Tmin: 299.15, Tmax: 437.09 },
  { DataId: 861, MainGroup1: 7, MainGroup2: 77, a1: -299.2, a2: 0.0, '1000a3': 0.0, Tmin: 330.42, Tmax: 333.12 },
  { DataId: 862, MainGroup1: 77, MainGroup2: 7, a1: 761.56, a2: 0.0, '1000a3': 0.0, Tmin: 330.42, Tmax: 333.12 },
  { DataId: 863, MainGroup1: 7, MainGroup2: 78, a1: -466.06, a2: 0.5407, '1000a3': 0.0, Tmin: 297.15, Tmax: 471.95 },
  { DataId: 864, MainGroup1: 78, MainGroup2: 7, a1: 275.43, a2: -1.4619, '1000a3': 0.0, Tmin: 297.15, Tmax: 471.95 },
  { DataId: 865, MainGroup1: 7, MainGroup2: 79, a1: 163.44, a2: -1.5602, '1000a3': 0.0, Tmin: 374.13, Tmax: 471.3 },
  { DataId: 866, MainGroup1: 79, MainGroup2: 7, a1: 88.43, a2: -1.3624, '1000a3': 0.0, Tmin: 374.13, Tmax: 471.3 },
  { DataId: 867, MainGroup1: 7, MainGroup2: 92, a1: -510.87, a2: 5.1961, '1000a3': 0.0, Tmin: 298.0, Tmax: 403.54 },
  { DataId: 868, MainGroup1: 92, MainGroup2: 7, a1: -502.15, a2: 0.0403, '1000a3': 0.0, Tmin: 298.0, Tmax: 403.54 },
  { DataId: 869, MainGroup1: 7, MainGroup2: 96, a1: -2.84, a2: -0.2372, '1000a3': 0.48427, Tmin: 234.25, Tmax: 555.18 },
  {
    DataId: 870,
    MainGroup1: 96,
    MainGroup2: 7,
    a1: -143.48,
    a2: 0.2012,
    '1000a3': -1.2344,
    Tmin: 234.25,
    Tmax: 555.18,
  },
  { DataId: 871, MainGroup1: 8, MainGroup2: 9, a1: -585.91, a2: 0.7256, '1000a3': 0.0, Tmin: 293.15, Tmax: 465.15 },
  { DataId: 872, MainGroup1: 9, MainGroup2: 8, a1: 121.34, a2: -1.0716, '1000a3': 0.0, Tmin: 293.15, Tmax: 465.15 },
  { DataId: 873, MainGroup1: 8, MainGroup2: 11, a1: -858.29, a2: 1.5063, '1000a3': 0.0, Tmin: 317.53, Tmax: 469.94 },
  { DataId: 874, MainGroup1: 11, MainGroup2: 8, a1: -38.6, a2: -0.3273, '1000a3': 0.0, Tmin: 317.53, Tmax: 469.94 },
  { DataId: 875, MainGroup1: 8, MainGroup2: 12, a1: -135.72, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 320.0 },
  { DataId: 876, MainGroup1: 12, MainGroup2: 8, a1: 12.79, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 320.0 },
  { DataId: 877, MainGroup1: 8, MainGroup2: 13, a1: -329.3, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 878, MainGroup1: 13, MainGroup2: 8, a1: -80.58, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 879, MainGroup1: 8, MainGroup2: 14, a1: -442.15, a2: 0.0, '1000a3': 0.0, Tmin: 288.14, Tmax: 433.15 },
  { DataId: 880, MainGroup1: 14, MainGroup2: 8, a1: -1182.13, a2: 0.0, '1000a3': 0.0, Tmin: 288.14, Tmax: 433.15 },
  { DataId: 881, MainGroup1: 8, MainGroup2: 16, a1: 25115.89, a2: 0.0, '1000a3': 0.0, Tmin: 288.14, Tmax: 433.15 },
  { DataId: 882, MainGroup1: 16, MainGroup2: 8, a1: -4888.1, a2: 0.0, '1000a3': 0.0, Tmin: 288.14, Tmax: 433.15 },
  { DataId: 883, MainGroup1: 8, MainGroup2: 17, a1: 28.53, a2: -0.3213, '1000a3': 0.0, Tmin: 273.15, Tmax: 458.99 },
  { DataId: 884, MainGroup1: 17, MainGroup2: 8, a1: -469.21, a2: 0.4973, '1000a3': 0.0, Tmin: 273.15, Tmax: 458.99 },
  {
    DataId: 885,
    MainGroup1: 8,
    MainGroup2: 18,
    a1: -2124.74,
    a2: 39.7986,
    '1000a3': 35.768,
    Tmin: 273.15,
    Tmax: 467.96,
  },
  {
    DataId: 886,
    MainGroup1: 18,
    MainGroup2: 8,
    a1: -1597.32,
    a2: 4.5517,
    '1000a3': -7.7904,
    Tmin: 273.15,
    Tmax: 467.96,
  },
  { DataId: 887, MainGroup1: 8, MainGroup2: 19, a1: 71.73, a2: 0.1207, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 888, MainGroup1: 19, MainGroup2: 8, a1: -290.9, a2: 0.5037, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 889, MainGroup1: 8, MainGroup2: 20, a1: 169.17, a2: 0.0, '1000a3': 0.0, Tmin: 383.2, Tmax: 403.2 },
  { DataId: 890, MainGroup1: 20, MainGroup2: 8, a1: 57.95, a2: 0.0, '1000a3': 0.0, Tmin: 383.2, Tmax: 403.2 },
  { DataId: 891, MainGroup1: 8, MainGroup2: 21, a1: 5128.37, a2: 0.0, '1000a3': 0.0, Tmin: 289.97, Tmax: 451.55 },
  { DataId: 892, MainGroup1: 21, MainGroup2: 8, a1: 123.06, a2: 0.0, '1000a3': 0.0, Tmin: 289.97, Tmax: 451.55 },
  { DataId: 893, MainGroup1: 8, MainGroup2: 22, a1: -27.07, a2: 0.0, '1000a3': 0.0, Tmin: 323.15, Tmax: 469.85 },
  { DataId: 894, MainGroup1: 22, MainGroup2: 8, a1: 326.64, a2: 0.0, '1000a3': 0.0, Tmin: 323.15, Tmax: 469.85 },
  { DataId: 895, MainGroup1: 8, MainGroup2: 24, a1: 2521.53, a2: 27.3559, '1000a3': 0.0, Tmin: 282.05, Tmax: 373.15 },
  { DataId: 896, MainGroup1: 24, MainGroup2: 8, a1: 1585.91, a2: -1.7218, '1000a3': 0.0, Tmin: 282.05, Tmax: 373.15 },
  { DataId: 897, MainGroup1: 8, MainGroup2: 25, a1: 2344.78, a2: 0.0, '1000a3': 0.0, Tmin: 372.9, Tmax: 452.5 },
  { DataId: 898, MainGroup1: 25, MainGroup2: 8, a1: 13426.9, a2: 0.0, '1000a3': 0.0, Tmin: 372.9, Tmax: 452.5 },
  { DataId: 899, MainGroup1: 8, MainGroup2: 27, a1: 136.86, a2: 0.0, '1000a3': 0.0, Tmin: 383.2, Tmax: 480.55 },
  { DataId: 900, MainGroup1: 27, MainGroup2: 8, a1: 1009.42, a2: 0.0, '1000a3': 0.0, Tmin: 383.2, Tmax: 480.55 },
  { DataId: 901, MainGroup1: 8, MainGroup2: 28, a1: 10119.64, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 323.15 },
  { DataId: 902, MainGroup1: 28, MainGroup2: 8, a1: 9281.84, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 323.15 },
  { DataId: 903, MainGroup1: 8, MainGroup2: 31, a1: -981.15, a2: 2.7732, '1000a3': 0.0, Tmin: 378.15, Tmax: 473.45 },
  { DataId: 904, MainGroup1: 31, MainGroup2: 8, a1: -1657.62, a2: 3.9712, '1000a3': 0.0, Tmin: 378.15, Tmax: 473.45 },
  { DataId: 905, MainGroup1: 8, MainGroup2: 37, a1: 5860.39, a2: 0.0, '1000a3': 0.0, Tmin: 359.85, Tmax: 441.15 },
  { DataId: 906, MainGroup1: 37, MainGroup2: 8, a1: 1082.84, a2: 0.0, '1000a3': 0.0, Tmin: 359.85, Tmax: 441.15 },
  { DataId: 907, MainGroup1: 8, MainGroup2: 42, a1: 101.13, a2: 0.426, '1000a3': 0.0, Tmin: 283.14, Tmax: 462.34 },
  { DataId: 908, MainGroup1: 42, MainGroup2: 8, a1: 2491.4, a2: -3.2734, '1000a3': 0.0, Tmin: 283.14, Tmax: 462.34 },
  { DataId: 909, MainGroup1: 8, MainGroup2: 43, a1: -160.0, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 423.84 },
  { DataId: 910, MainGroup1: 43, MainGroup2: 8, a1: -352.94, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 423.84 },
  { DataId: 911, MainGroup1: 8, MainGroup2: 45, a1: 855.61, a2: 0.0, '1000a3': 0.0, Tmin: 288.14, Tmax: 323.15 },
  { DataId: 912, MainGroup1: 45, MainGroup2: 8, a1: 779.42, a2: 0.0, '1000a3': 0.0, Tmin: 288.14, Tmax: 323.15 },
  { DataId: 913, MainGroup1: 8, MainGroup2: 46, a1: -610.78, a2: 0.0, '1000a3': 0.0, Tmin: 393.52, Tmax: 393.52 },
  { DataId: 914, MainGroup1: 46, MainGroup2: 8, a1: -358.57, a2: 0.0, '1000a3': 0.0, Tmin: 393.52, Tmax: 393.52 },
  { DataId: 915, MainGroup1: 8, MainGroup2: 47, a1: -1312.72, a2: 2.6697, '1000a3': 0.0, Tmin: 413.15, Tmax: 413.54 },
  { DataId: 916, MainGroup1: 47, MainGroup2: 8, a1: 65.26, a2: -1.6375, '1000a3': 0.0, Tmin: 413.15, Tmax: 413.54 },
  { DataId: 917, MainGroup1: 8, MainGroup2: 53, a1: -631.64, a2: 0.0, '1000a3': 0.0, Tmin: 343.35, Tmax: 371.2 },
  { DataId: 918, MainGroup1: 53, MainGroup2: 8, a1: 594.95, a2: 0.0, '1000a3': 0.0, Tmin: 343.35, Tmax: 371.2 },
  { DataId: 919, MainGroup1: 8, MainGroup2: 57, a1: 3300.81, a2: 0.0, '1000a3': 0.0, Tmin: 348.68, Tmax: 395.32 },
  { DataId: 920, MainGroup1: 57, MainGroup2: 8, a1: -430.91, a2: 0.0, '1000a3': 0.0, Tmin: 348.68, Tmax: 395.32 },
  { DataId: 921, MainGroup1: 8, MainGroup2: 60, a1: -149.82, a2: 0.0, '1000a3': 0.0, Tmin: 363.15, Tmax: 383.15 },
  { DataId: 922, MainGroup1: 60, MainGroup2: 8, a1: -366.58, a2: 0.0, '1000a3': 0.0, Tmin: 363.15, Tmax: 383.15 },
  { DataId: 923, MainGroup1: 8, MainGroup2: 83, a1: -317.52, a2: 0.0, '1000a3': 0.0, Tmin: 294.49, Tmax: 475.87 },
  { DataId: 924, MainGroup1: 83, MainGroup2: 8, a1: -545.49, a2: 0.0, '1000a3': 0.0, Tmin: 294.49, Tmax: 475.87 },
  { DataId: 925, MainGroup1: 8, MainGroup2: 86, a1: 4978.5, a2: 0.0, '1000a3': 0.0, Tmin: 393.65, Tmax: 446.55 },
  { DataId: 926, MainGroup1: 86, MainGroup2: 8, a1: 893.36, a2: 0.0, '1000a3': 0.0, Tmin: 393.65, Tmax: 446.55 },
  { DataId: 927, MainGroup1: 9, MainGroup2: 10, a1: 170.17, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 388.15 },
  { DataId: 928, MainGroup1: 10, MainGroup2: 9, a1: -46.09, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 388.15 },
  { DataId: 929, MainGroup1: 9, MainGroup2: 11, a1: 61.56, a2: -0.8304, '1000a3': 0.0, Tmin: 289.15, Tmax: 410.79 },
  { DataId: 930, MainGroup1: 11, MainGroup2: 9, a1: -19.76, a2: 0.96, '1000a3': 0.0, Tmin: 289.15, Tmax: 410.79 },
  { DataId: 931, MainGroup1: 9, MainGroup2: 12, a1: -286.8, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 363.18 },
  { DataId: 932, MainGroup1: 12, MainGroup2: 9, a1: 501.24, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 363.18 },
  {
    DataId: 933,
    MainGroup1: 9,
    MainGroup2: 13,
    a1: 3646.91,
    a2: -26.9306,
    '1000a3': 46.438,
    Tmin: 273.15,
    Tmax: 393.12,
  },
  { DataId: 934, MainGroup1: 13, MainGroup2: 9, a1: 737.4, a2: -0.6434, '1000a3': -2.7909, Tmin: 273.15, Tmax: 393.12 },
  { DataId: 935, MainGroup1: 9, MainGroup2: 14, a1: -176.67, a2: 1.0645, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 936, MainGroup1: 14, MainGroup2: 9, a1: 589.37, a2: -2.145, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 937, MainGroup1: 9, MainGroup2: 15, a1: 49.66, a2: 0.0, '1000a3': 0.0, Tmin: 298.02, Tmax: 398.07 },
  { DataId: 938, MainGroup1: 15, MainGroup2: 9, a1: 18.37, a2: 0.0, '1000a3': 0.0, Tmin: 298.02, Tmax: 398.07 },
  { DataId: 939, MainGroup1: 9, MainGroup2: 16, a1: 761.35, a2: -5.7144, '1000a3': 7.985, Tmin: 293.15, Tmax: 373.12 },
  {
    DataId: 940,
    MainGroup1: 16,
    MainGroup2: 9,
    a1: 3278.54,
    a2: 1.1782,
    '1000a3': -12.675,
    Tmin: 293.15,
    Tmax: 373.12,
  },
  { DataId: 941, MainGroup1: 9, MainGroup2: 17, a1: -287.33, a2: 2.9191, '1000a3': -5.063, Tmin: 277.35, Tmax: 386.64 },
  { DataId: 942, MainGroup1: 17, MainGroup2: 9, a1: 389.92, a2: -4.6144, '1000a3': 8.7337, Tmin: 277.35, Tmax: 386.64 },
  { DataId: 943, MainGroup1: 9, MainGroup2: 18, a1: -28.94, a2: 0.3597, '1000a3': 0.0, Tmin: 293.14, Tmax: 375.62 },
  { DataId: 944, MainGroup1: 18, MainGroup2: 9, a1: 147.96, a2: -1.1734, '1000a3': 0.0, Tmin: 293.14, Tmax: 375.62 },
  { DataId: 945, MainGroup1: 9, MainGroup2: 19, a1: 9.42, a2: -0.6185, '1000a3': 0.0, Tmin: 278.15, Tmax: 400.05 },
  { DataId: 946, MainGroup1: 19, MainGroup2: 9, a1: -7.59, a2: 0.5892, '1000a3': 0.0, Tmin: 278.15, Tmax: 400.05 },
  { DataId: 947, MainGroup1: 9, MainGroup2: 20, a1: -561.05, a2: 3.3419, '1000a3': 0.0, Tmin: 183.62, Tmax: 434.91 },
  { DataId: 948, MainGroup1: 20, MainGroup2: 9, a1: 254.62, a2: -1.5161, '1000a3': 0.0, Tmin: 183.62, Tmax: 434.91 },
  { DataId: 949, MainGroup1: 9, MainGroup2: 21, a1: 3.37, a2: -0.6086, '1000a3': 0.0, Tmin: 273.15, Tmax: 398.14 },
  { DataId: 950, MainGroup1: 21, MainGroup2: 9, a1: -43.02, a2: 0.8131, '1000a3': 0.0, Tmin: 273.15, Tmax: 398.14 },
  { DataId: 951, MainGroup1: 9, MainGroup2: 22, a1: -24.44, a2: -0.5568, '1000a3': 0.0, Tmin: 273.15, Tmax: 402.95 },
  { DataId: 952, MainGroup1: 22, MainGroup2: 9, a1: -217.35, a2: 1.1158, '1000a3': 0.0, Tmin: 273.15, Tmax: 402.95 },
  { DataId: 953, MainGroup1: 9, MainGroup2: 23, a1: 446.34, a2: -2.2309, '1000a3': 1.8892, Tmin: 293.15, Tmax: 363.15 },
  { DataId: 954, MainGroup1: 23, MainGroup2: 9, a1: 323.6, a2: -1.6601, '1000a3': 3.4167, Tmin: 293.15, Tmax: 363.15 },
  { DataId: 955, MainGroup1: 9, MainGroup2: 24, a1: -154.97, a2: 0.578, '1000a3': -1.4213, Tmin: 171.13, Tmax: 423.11 },
  { DataId: 956, MainGroup1: 24, MainGroup2: 9, a1: 102.82, a2: 1.5665, '1000a3': -1.8812, Tmin: 171.13, Tmax: 423.11 },
  { DataId: 957, MainGroup1: 9, MainGroup2: 25, a1: 32.27, a2: 0.514, '1000a3': 0.0, Tmin: 298.15, Tmax: 412.72 },
  { DataId: 958, MainGroup1: 25, MainGroup2: 9, a1: -13.07, a2: -0.6389, '1000a3': 0.0, Tmin: 298.15, Tmax: 412.72 },
  { DataId: 959, MainGroup1: 9, MainGroup2: 26, a1: 9.63, a2: -0.2294, '1000a3': 0.0, Tmin: 298.15, Tmax: 397.16 },
  { DataId: 960, MainGroup1: 26, MainGroup2: 9, a1: -79.36, a2: 0.1858, '1000a3': 0.0, Tmin: 298.15, Tmax: 397.16 },
  { DataId: 961, MainGroup1: 9, MainGroup2: 27, a1: -108.19, a2: 9.9851, '1000a3': 0.0, Tmin: 239.95, Tmax: 420.31 },
  { DataId: 962, MainGroup1: 27, MainGroup2: 9, a1: -1056.4, a2: 1.3241, '1000a3': 0.0, Tmin: 239.95, Tmax: 420.31 },
  { DataId: 963, MainGroup1: 9, MainGroup2: 28, a1: 1530.39, a2: -2.5859, '1000a3': 0.0, Tmin: 190.74, Tmax: 328.7 },
  { DataId: 964, MainGroup1: 28, MainGroup2: 9, a1: 569.31, a2: -1.6986, '1000a3': 0.0, Tmin: 190.74, Tmax: 328.7 },
  { DataId: 965, MainGroup1: 9, MainGroup2: 29, a1: -78.99, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 313.14 },
  { DataId: 966, MainGroup1: 29, MainGroup2: 9, a1: 156.53, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 313.14 },
  { DataId: 967, MainGroup1: 9, MainGroup2: 30, a1: 145.86, a2: -0.5953, '1000a3': 0.0, Tmin: 331.02, Tmax: 383.12 },
  { DataId: 968, MainGroup1: 30, MainGroup2: 9, a1: 389.37, a2: -0.553, '1000a3': 0.0, Tmin: 331.02, Tmax: 383.12 },
  { DataId: 969, MainGroup1: 9, MainGroup2: 31, a1: 495.83, a2: -1.7397, '1000a3': 0.0, Tmin: 298.15, Tmax: 343.15 },
  { DataId: 970, MainGroup1: 31, MainGroup2: 9, a1: -133.42, a2: 1.2178, '1000a3': 0.0, Tmin: 298.15, Tmax: 343.15 },
  { DataId: 971, MainGroup1: 9, MainGroup2: 32, a1: 432.82, a2: -1.3119, '1000a3': 0.0, Tmin: 253.22, Tmax: 326.39 },
  { DataId: 972, MainGroup1: 32, MainGroup2: 9, a1: -339.34, a2: 1.6802, '1000a3': 0.0, Tmin: 253.22, Tmax: 326.39 },
  { DataId: 973, MainGroup1: 9, MainGroup2: 33, a1: 524.91, a2: -0.8309, '1000a3': 0.0, Tmin: 293.1, Tmax: 351.65 },
  { DataId: 974, MainGroup1: 33, MainGroup2: 9, a1: -241.49, a2: 0.2777, '1000a3': 0.0, Tmin: 293.1, Tmax: 351.65 },
  { DataId: 975, MainGroup1: 9, MainGroup2: 34, a1: 57.59, a2: -1.3299, '1000a3': 0.0, Tmin: 273.15, Tmax: 328.15 },
  { DataId: 976, MainGroup1: 34, MainGroup2: 9, a1: -284.9, a2: 7.8079, '1000a3': 0.0, Tmin: 273.15, Tmax: 328.15 },
  { DataId: 977, MainGroup1: 9, MainGroup2: 35, a1: -1156.26, a2: 3.733, '1000a3': 0.0, Tmin: 298.14, Tmax: 449.4 },
  { DataId: 978, MainGroup1: 35, MainGroup2: 9, a1: 1179.84, a2: -4.0045, '1000a3': 0.0, Tmin: 298.14, Tmax: 449.4 },
  { DataId: 979, MainGroup1: 9, MainGroup2: 36, a1: 115.26, a2: 0.0, '1000a3': 0.0, Tmin: 306.6, Tmax: 323.15 },
  { DataId: 980, MainGroup1: 36, MainGroup2: 9, a1: -185.43, a2: 0.0, '1000a3': 0.0, Tmin: 306.6, Tmax: 323.15 },
  { DataId: 981, MainGroup1: 9, MainGroup2: 37, a1: 62.64, a2: -0.8136, '1000a3': 0.0, Tmin: 273.15, Tmax: 408.52 },
  { DataId: 982, MainGroup1: 37, MainGroup2: 9, a1: -291.51, a2: 1.8846, '1000a3': 0.0, Tmin: 273.15, Tmax: 408.52 },
  { DataId: 983, MainGroup1: 9, MainGroup2: 39, a1: 40.79, a2: -4.3312, '1000a3': 14.433, Tmin: 293.14, Tmax: 416.82 },
  {
    DataId: 984,
    MainGroup1: 39,
    MainGroup2: 9,
    a1: 2153.12,
    a2: -10.5676,
    '1000a3': 10.982,
    Tmin: 293.14,
    Tmax: 416.82,
  },
  { DataId: 985, MainGroup1: 9, MainGroup2: 41, a1: -171.82, a2: 0.0, '1000a3': 0.0, Tmin: 331.73, Tmax: 364.83 },
  { DataId: 986, MainGroup1: 41, MainGroup2: 9, a1: -81.01, a2: 0.0, '1000a3': 0.0, Tmin: 331.73, Tmax: 364.83 },
  { DataId: 987, MainGroup1: 9, MainGroup2: 42, a1: 110.67, a2: -0.5791, '1000a3': 0.0, Tmin: 201.01, Tmax: 465.55 },
  { DataId: 988, MainGroup1: 42, MainGroup2: 9, a1: 606.13, a2: -0.2464, '1000a3': 0.0, Tmin: 201.01, Tmax: 465.55 },
  { DataId: 989, MainGroup1: 9, MainGroup2: 43, a1: 314.11, a2: -1.6582, '1000a3': 0.0, Tmin: 288.15, Tmax: 423.15 },
  { DataId: 990, MainGroup1: 43, MainGroup2: 9, a1: -537.32, a2: 2.6371, '1000a3': 0.0, Tmin: 288.15, Tmax: 423.15 },
  { DataId: 991, MainGroup1: 9, MainGroup2: 44, a1: -134.0, a2: 0.0, '1000a3': 0.0, Tmin: 337.93, Tmax: 373.92 },
  { DataId: 992, MainGroup1: 44, MainGroup2: 9, a1: -151.13, a2: 0.0, '1000a3': 0.0, Tmin: 337.93, Tmax: 373.92 },
  { DataId: 993, MainGroup1: 9, MainGroup2: 45, a1: -516.78, a2: 2.6048, '1000a3': -4.5634, Tmin: 273.1, Tmax: 433.11 },
  { DataId: 994, MainGroup1: 45, MainGroup2: 9, a1: 750.02, a2: -3.9571, '1000a3': 5.9947, Tmin: 273.1, Tmax: 433.11 },
  { DataId: 995, MainGroup1: 9, MainGroup2: 46, a1: 2521.79, a2: -6.5156, '1000a3': 0.0, Tmin: 200.58, Tmax: 459.25 },
  { DataId: 996, MainGroup1: 46, MainGroup2: 9, a1: -340.46, a2: 0.6226, '1000a3': 0.0, Tmin: 200.58, Tmax: 459.25 },
  { DataId: 997, MainGroup1: 9, MainGroup2: 47, a1: 502.1, a2: 0.3401, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 998, MainGroup1: 47, MainGroup2: 9, a1: 141.01, a2: -0.9829, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 999, MainGroup1: 9, MainGroup2: 48, a1: 48.25, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 417.46 },
  { DataId: 1000, MainGroup1: 48, MainGroup2: 9, a1: -104.38, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 417.46 },
  { DataId: 1001, MainGroup1: 9, MainGroup2: 49, a1: 395.05, a2: -0.9231, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1002, MainGroup1: 49, MainGroup2: 9, a1: -169.4, a2: 0.5128, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1003, MainGroup1: 9, MainGroup2: 53, a1: -148.89, a2: 0.0, '1000a3': 0.0, Tmin: 307.64, Tmax: 348.26 },
  { DataId: 1004, MainGroup1: 53, MainGroup2: 9, a1: 111.55, a2: 0.0, '1000a3': 0.0, Tmin: 307.64, Tmax: 348.26 },
  { DataId: 1005, MainGroup1: 9, MainGroup2: 54, a1: -14.94, a2: -0.1157, '1000a3': 0.0, Tmin: 295.14, Tmax: 392.13 },
  { DataId: 1006, MainGroup1: 54, MainGroup2: 9, a1: 0.72, a2: 0.4894, '1000a3': 0.0, Tmin: 295.14, Tmax: 392.13 },
  { DataId: 1007, MainGroup1: 9, MainGroup2: 55, a1: -190.35, a2: 0.2514, '1000a3': 0.0, Tmin: 298.15, Tmax: 398.42 },
  { DataId: 1008, MainGroup1: 55, MainGroup2: 9, a1: 354.23, a2: -0.561, '1000a3': 0.0, Tmin: 298.15, Tmax: 398.42 },
  { DataId: 1009, MainGroup1: 9, MainGroup2: 60, a1: 103.67, a2: 1.7096, '1000a3': 0.0, Tmin: 298.15, Tmax: 433.74 },
  { DataId: 1010, MainGroup1: 60, MainGroup2: 9, a1: 32.8, a2: -0.7151, '1000a3': 0.0, Tmin: 298.15, Tmax: 433.74 },
  { DataId: 1011, MainGroup1: 9, MainGroup2: 64, a1: -848.88, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 368.23 },
  { DataId: 1012, MainGroup1: 64, MainGroup2: 9, a1: 791.46, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 368.23 },
  { DataId: 1013, MainGroup1: 9, MainGroup2: 75, a1: 527.13, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 293.14 },
  { DataId: 1014, MainGroup1: 75, MainGroup2: 9, a1: -230.49, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 293.14 },
  { DataId: 1015, MainGroup1: 9, MainGroup2: 77, a1: -313.75, a2: 0.0, '1000a3': 0.0, Tmin: 337.92, Tmax: 403.15 },
  { DataId: 1016, MainGroup1: 77, MainGroup2: 9, a1: 2137.39, a2: 0.0, '1000a3': 0.0, Tmin: 337.92, Tmax: 403.15 },
  { DataId: 1017, MainGroup1: 9, MainGroup2: 79, a1: 2106.02, a2: 0.0, '1000a3': 0.0, Tmin: 320.25, Tmax: 358.25 },
  { DataId: 1018, MainGroup1: 79, MainGroup2: 9, a1: -15.86, a2: 0.0, '1000a3': 0.0, Tmin: 320.25, Tmax: 358.25 },
  { DataId: 1019, MainGroup1: 9, MainGroup2: 80, a1: -447.32, a2: 0.0, '1000a3': 0.0, Tmin: 253.2, Tmax: 308.13 },
  { DataId: 1020, MainGroup1: 80, MainGroup2: 9, a1: 2152.54, a2: 0.0, '1000a3': 0.0, Tmin: 253.2, Tmax: 308.13 },
  { DataId: 1021, MainGroup1: 9, MainGroup2: 82, a1: 22.58, a2: 0.6493, '1000a3': 0.0, Tmin: 283.15, Tmax: 368.08 },
  { DataId: 1022, MainGroup1: 82, MainGroup2: 9, a1: -1.1, a2: -0.0527, '1000a3': 0.0, Tmin: 283.15, Tmax: 368.08 },
  { DataId: 1023, MainGroup1: 9, MainGroup2: 83, a1: -473.72, a2: 0.0, '1000a3': 0.0, Tmin: 294.49, Tmax: 475.87 },
  { DataId: 1024, MainGroup1: 83, MainGroup2: 9, a1: -127.68, a2: 0.0, '1000a3': 0.0, Tmin: 294.49, Tmax: 475.87 },
  { DataId: 1025, MainGroup1: 9, MainGroup2: 86, a1: 108.62, a2: -0.2455, '1000a3': 0.0, Tmin: 273.15, Tmax: 385.33 },
  { DataId: 1026, MainGroup1: 86, MainGroup2: 9, a1: 373.97, a2: -0.2363, '1000a3': 0.0, Tmin: 273.15, Tmax: 385.33 },
  { DataId: 1027, MainGroup1: 9, MainGroup2: 95, a1: 260.0, a2: -1.8627, '1000a3': 0.0, Tmin: 293.15, Tmax: 405.89 },
  { DataId: 1028, MainGroup1: 95, MainGroup2: 9, a1: 442.73, a2: 0.5518, '1000a3': 0.0, Tmin: 293.15, Tmax: 405.89 },
  { DataId: 1029, MainGroup1: 10, MainGroup2: 11, a1: -203.44, a2: 0.0, '1000a3': 0.0, Tmin: 294.35, Tmax: 373.15 },
  { DataId: 1030, MainGroup1: 11, MainGroup2: 10, a1: 364.92, a2: 0.0, '1000a3': 0.0, Tmin: 294.35, Tmax: 373.15 },
  { DataId: 1031, MainGroup1: 10, MainGroup2: 12, a1: 38.83, a2: 0.0, '1000a3': 0.0, Tmin: 293.82, Tmax: 365.38 },
  { DataId: 1032, MainGroup1: 12, MainGroup2: 10, a1: 69.28, a2: 0.0, '1000a3': 0.0, Tmin: 293.82, Tmax: 365.38 },
  { DataId: 1033, MainGroup1: 10, MainGroup2: 13, a1: 205.56, a2: -0.3549, '1000a3': 0.0, Tmin: 292.8, Tmax: 304.29 },
  { DataId: 1034, MainGroup1: 13, MainGroup2: 10, a1: 256.83, a2: -0.1205, '1000a3': 0.0, Tmin: 292.8, Tmax: 304.29 },
  { DataId: 1035, MainGroup1: 10, MainGroup2: 14, a1: 174.34, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1036, MainGroup1: 14, MainGroup2: 10, a1: 97.69, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1037, MainGroup1: 10, MainGroup2: 20, a1: 203.44, a2: 0.0, '1000a3': 0.0, Tmin: 283.09, Tmax: 400.83 },
  { DataId: 1038, MainGroup1: 20, MainGroup2: 10, a1: -49.85, a2: 0.0, '1000a3': 0.0, Tmin: 283.09, Tmax: 400.83 },
  { DataId: 1039, MainGroup1: 10, MainGroup2: 21, a1: 2172.13, a2: -5.2664, '1000a3': 0.0, Tmin: 293.15, Tmax: 360.82 },
  { DataId: 1040, MainGroup1: 21, MainGroup2: 10, a1: -504.67, a2: 1.6569, '1000a3': 0.0, Tmin: 293.15, Tmax: 360.82 },
  { DataId: 1041, MainGroup1: 10, MainGroup2: 22, a1: 0.92, a2: 0.0, '1000a3': 0.0, Tmin: 300.14, Tmax: 306.14 },
  { DataId: 1042, MainGroup1: 22, MainGroup2: 10, a1: 367.11, a2: 0.0, '1000a3': 0.0, Tmin: 300.14, Tmax: 306.14 },
  { DataId: 1043, MainGroup1: 10, MainGroup2: 29, a1: -422.25, a2: 0.0, '1000a3': 0.0, Tmin: 313.14, Tmax: 313.14 },
  { DataId: 1044, MainGroup1: 29, MainGroup2: 10, a1: -362.25, a2: 0.0, '1000a3': 0.0, Tmin: 313.14, Tmax: 313.14 },
  { DataId: 1045, MainGroup1: 10, MainGroup2: 30, a1: 66.11, a2: 0.0, '1000a3': 0.0, Tmin: 309.13, Tmax: 422.63 },
  { DataId: 1046, MainGroup1: 30, MainGroup2: 10, a1: -803.04, a2: 0.0, '1000a3': 0.0, Tmin: 309.13, Tmax: 422.63 },
  { DataId: 1047, MainGroup1: 10, MainGroup2: 32, a1: 191.64, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 313.47 },
  { DataId: 1048, MainGroup1: 32, MainGroup2: 10, a1: 285.69, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 313.47 },
  { DataId: 1049, MainGroup1: 10, MainGroup2: 34, a1: 986.13, a2: 0.0, '1000a3': 0.0, Tmin: 313.89, Tmax: 361.7 },
  { DataId: 1050, MainGroup1: 34, MainGroup2: 10, a1: -163.08, a2: 0.0, '1000a3': 0.0, Tmin: 313.89, Tmax: 361.7 },
  { DataId: 1051, MainGroup1: 10, MainGroup2: 37, a1: -644.7, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 293.15 },
  { DataId: 1052, MainGroup1: 37, MainGroup2: 10, a1: 2924.91, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 293.15 },
  { DataId: 1053, MainGroup1: 10, MainGroup2: 39, a1: -133.49, a2: 0.0, '1000a3': 0.0, Tmin: 313.14, Tmax: 333.13 },
  { DataId: 1054, MainGroup1: 39, MainGroup2: 10, a1: 430.73, a2: 0.0, '1000a3': 0.0, Tmin: 313.14, Tmax: 333.13 },
  { DataId: 1055, MainGroup1: 10, MainGroup2: 42, a1: 2054.4, a2: -4.9377, '1000a3': 0.0, Tmin: 298.14, Tmax: 318.15 },
  { DataId: 1056, MainGroup1: 42, MainGroup2: 10, a1: 1055.48, a2: -0.2444, '1000a3': 0.0, Tmin: 298.14, Tmax: 318.15 },
  { DataId: 1057, MainGroup1: 10, MainGroup2: 53, a1: 26.43, a2: 0.0, '1000a3': 0.0, Tmin: 293.65, Tmax: 348.1 },
  { DataId: 1058, MainGroup1: 53, MainGroup2: 10, a1: 178.94, a2: 0.0, '1000a3': 0.0, Tmin: 293.65, Tmax: 348.1 },
  { DataId: 1059, MainGroup1: 10, MainGroup2: 54, a1: 35.66, a2: 0.0, '1000a3': 0.0, Tmin: 423.2, Tmax: 433.2 },
  { DataId: 1060, MainGroup1: 54, MainGroup2: 10, a1: -81.93, a2: 0.0, '1000a3': 0.0, Tmin: 423.2, Tmax: 433.2 },
  { DataId: 1061, MainGroup1: 10, MainGroup2: 60, a1: 3280.02, a2: 0.0, '1000a3': 0.0, Tmin: 313.15, Tmax: 333.15 },
  { DataId: 1062, MainGroup1: 60, MainGroup2: 10, a1: -193.12, a2: 0.0, '1000a3': 0.0, Tmin: 313.15, Tmax: 333.15 },
  { DataId: 1063, MainGroup1: 11, MainGroup2: 12, a1: -167.65, a2: 0.0, '1000a3': 0.0, Tmin: 327.94, Tmax: 416.52 },
  { DataId: 1064, MainGroup1: 12, MainGroup2: 11, a1: 101.8, a2: 0.0, '1000a3': 0.0, Tmin: 327.94, Tmax: 416.52 },
  {
    DataId: 1065,
    MainGroup1: 11,
    MainGroup2: 13,
    a1: 428.62,
    a2: -11.4456,
    '1000a3': 51.758,
    Tmin: 273.1,
    Tmax: 414.6,
  },
  { DataId: 1066, MainGroup1: 13, MainGroup2: 11, a1: 297.18, a2: -2.7538, '1000a3': 3.4779, Tmin: 273.1, Tmax: 414.6 },
  { DataId: 1067, MainGroup1: 11, MainGroup2: 14, a1: 870.7, a2: -0.1941, '1000a3': 0.0, Tmin: 273.15, Tmax: 398.0 },
  { DataId: 1068, MainGroup1: 14, MainGroup2: 11, a1: -394.81, a2: 0.3107, '1000a3': 0.0, Tmin: 273.15, Tmax: 398.0 },
  {
    DataId: 1069,
    MainGroup1: 11,
    MainGroup2: 15,
    a1: 3787.08,
    a2: -31.1174,
    '1000a3': 62.479,
    Tmin: 273.15,
    Tmax: 398.0,
  },
  {
    DataId: 1070,
    MainGroup1: 15,
    MainGroup2: 11,
    a1: 3294.81,
    a2: -17.9984,
    '1000a3': 22.402,
    Tmin: 273.15,
    Tmax: 398.0,
  },
  { DataId: 1071, MainGroup1: 11, MainGroup2: 16, a1: -113.96, a2: -0.4394, '1000a3': 0.0, Tmin: 273.15, Tmax: 398.0 },
  { DataId: 1072, MainGroup1: 16, MainGroup2: 11, a1: 310.83, a2: 5.3833, '1000a3': 0.0, Tmin: 273.15, Tmax: 398.0 },
  {
    DataId: 1073,
    MainGroup1: 11,
    MainGroup2: 17,
    a1: 3956.86,
    a2: -23.7931,
    '1000a3': 37.323,
    Tmin: 292.15,
    Tmax: 397.86,
  },
  {
    DataId: 1074,
    MainGroup1: 17,
    MainGroup2: 11,
    a1: -653.02,
    a2: 1.4126,
    '1000a3': -1.5804,
    Tmin: 292.15,
    Tmax: 397.86,
  },
  { DataId: 1075, MainGroup1: 11, MainGroup2: 18, a1: 688.44, a2: -1.8943, '1000a3': 0.0, Tmin: 290.15, Tmax: 423.15 },
  { DataId: 1076, MainGroup1: 18, MainGroup2: 11, a1: -273.05, a2: -0.2657, '1000a3': 0.0, Tmin: 290.15, Tmax: 423.15 },
  { DataId: 1077, MainGroup1: 11, MainGroup2: 19, a1: -44.56, a2: 1.7848, '1000a3': 0.0, Tmin: 293.1, Tmax: 453.1 },
  { DataId: 1078, MainGroup1: 19, MainGroup2: 11, a1: 97.05, a2: -1.2787, '1000a3': 0.0, Tmin: 293.1, Tmax: 453.1 },
  { DataId: 1079, MainGroup1: 11, MainGroup2: 20, a1: 565.9, a2: -1.0278, '1000a3': 0.0, Tmin: 253.17, Tmax: 439.12 },
  { DataId: 1080, MainGroup1: 20, MainGroup2: 11, a1: -250.76, a2: 0.5169, '1000a3': 0.0, Tmin: 253.17, Tmax: 439.12 },
  { DataId: 1081, MainGroup1: 11, MainGroup2: 21, a1: -46.34, a2: 0.5595, '1000a3': 0.0, Tmin: 293.1, Tmax: 403.05 },
  { DataId: 1082, MainGroup1: 21, MainGroup2: 11, a1: 110.82, a2: -0.5377, '1000a3': 0.0, Tmin: 293.1, Tmax: 403.05 },
  { DataId: 1083, MainGroup1: 11, MainGroup2: 22, a1: 633.92, a2: -2.4097, '1000a3': 0.0, Tmin: 276.15, Tmax: 398.14 },
  { DataId: 1084, MainGroup1: 22, MainGroup2: 11, a1: -1040.42, a2: 3.6728, '1000a3': 0.0, Tmin: 276.15, Tmax: 398.14 },
  { DataId: 1085, MainGroup1: 11, MainGroup2: 24, a1: 38.89, a2: -0.2833, '1000a3': 0.0, Tmin: 293.1, Tmax: 459.41 },
  { DataId: 1086, MainGroup1: 24, MainGroup2: 11, a1: 259.15, a2: 0.0345, '1000a3': 0.0, Tmin: 293.1, Tmax: 459.41 },
  { DataId: 1087, MainGroup1: 11, MainGroup2: 25, a1: 29.36, a2: 0.0, '1000a3': 0.0, Tmin: 313.14, Tmax: 403.82 },
  { DataId: 1088, MainGroup1: 25, MainGroup2: 11, a1: -303.6, a2: 0.0, '1000a3': 0.0, Tmin: 313.14, Tmax: 403.82 },
  { DataId: 1089, MainGroup1: 11, MainGroup2: 26, a1: -65.23, a2: -0.4344, '1000a3': 0.0, Tmin: 298.15, Tmax: 398.14 },
  { DataId: 1090, MainGroup1: 26, MainGroup2: 11, a1: 79.48, a2: 0.2144, '1000a3': 0.0, Tmin: 298.15, Tmax: 398.14 },
  {
    DataId: 1091,
    MainGroup1: 11,
    MainGroup2: 27,
    a1: -5214.61,
    a2: 20.0452,
    '1000a3': 0.0,
    Tmin: 293.14,
    Tmax: 298.15,
  },
  { DataId: 1092, MainGroup1: 27, MainGroup2: 11, a1: 436.92, a2: -3.1202, '1000a3': 0.0, Tmin: 293.14, Tmax: 298.15 },
  {
    DataId: 1093,
    MainGroup1: 11,
    MainGroup2: 28,
    a1: 2306.48,
    a2: -2.2155,
    '1000a3': -11.756,
    Tmin: 160.91,
    Tmax: 327.24,
  },
  {
    DataId: 1094,
    MainGroup1: 28,
    MainGroup2: 11,
    a1: 2313.36,
    a2: -15.7877,
    '1000a3': 26.49,
    Tmin: 160.91,
    Tmax: 327.24,
  },
  { DataId: 1095, MainGroup1: 11, MainGroup2: 30, a1: 161.66, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 432.34 },
  { DataId: 1096, MainGroup1: 30, MainGroup2: 11, a1: -166.05, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 432.34 },
  { DataId: 1097, MainGroup1: 11, MainGroup2: 31, a1: 858.89, a2: -2.7274, '1000a3': 0.0, Tmin: 273.15, Tmax: 462.01 },
  { DataId: 1098, MainGroup1: 31, MainGroup2: 11, a1: -461.18, a2: 1.963, '1000a3': 0.0, Tmin: 273.15, Tmax: 462.01 },
  { DataId: 1099, MainGroup1: 11, MainGroup2: 32, a1: 0.79, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 343.88 },
  { DataId: 1100, MainGroup1: 32, MainGroup2: 11, a1: 121.52, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 343.88 },
  { DataId: 1101, MainGroup1: 11, MainGroup2: 33, a1: -349.84, a2: 1.3813, '1000a3': 0.0, Tmin: 293.1, Tmax: 371.8 },
  { DataId: 1102, MainGroup1: 33, MainGroup2: 11, a1: 561.59, a2: -1.7731, '1000a3': 0.0, Tmin: 293.1, Tmax: 371.8 },
  { DataId: 1103, MainGroup1: 11, MainGroup2: 34, a1: -73.0, a2: 0.383, '1000a3': 0.0, Tmin: 298.15, Tmax: 404.45 },
  { DataId: 1104, MainGroup1: 34, MainGroup2: 11, a1: 31.02, a2: -0.4937, '1000a3': 0.0, Tmin: 298.15, Tmax: 404.45 },
  { DataId: 1105, MainGroup1: 11, MainGroup2: 35, a1: -49.6, a2: -0.3743, '1000a3': 0.0, Tmin: 298.14, Tmax: 318.14 },
  { DataId: 1106, MainGroup1: 35, MainGroup2: 11, a1: 149.52, a2: -0.1905, '1000a3': 0.0, Tmin: 298.14, Tmax: 318.14 },
  { DataId: 1107, MainGroup1: 11, MainGroup2: 36, a1: 315.92, a2: 0.0, '1000a3': 0.0, Tmin: 352.53, Tmax: 387.72 },
  { DataId: 1108, MainGroup1: 36, MainGroup2: 11, a1: -235.44, a2: 0.0, '1000a3': 0.0, Tmin: 352.53, Tmax: 387.72 },
  { DataId: 1109, MainGroup1: 11, MainGroup2: 37, a1: -1776.98, a2: 10.7644, '1000a3': 0.0, Tmin: 298.0, Tmax: 398.25 },
  { DataId: 1110, MainGroup1: 37, MainGroup2: 11, a1: -211.51, a2: 0.1827, '1000a3': 0.0, Tmin: 298.0, Tmax: 398.25 },
  { DataId: 1111, MainGroup1: 11, MainGroup2: 39, a1: 255.69, a2: 0.0019, '1000a3': 0.0, Tmin: 298.15, Tmax: 424.26 },
  { DataId: 1112, MainGroup1: 39, MainGroup2: 11, a1: 73.22, a2: -0.6766, '1000a3': 0.0, Tmin: 298.15, Tmax: 424.26 },
  { DataId: 1113, MainGroup1: 11, MainGroup2: 41, a1: -25.88, a2: 0.4538, '1000a3': 0.0, Tmin: 298.2, Tmax: 371.45 },
  { DataId: 1114, MainGroup1: 41, MainGroup2: 11, a1: 122.59, a2: -1.6415, '1000a3': 0.0, Tmin: 298.2, Tmax: 371.45 },
  { DataId: 1115, MainGroup1: 11, MainGroup2: 42, a1: 483.2, a2: -2.218, '1000a3': 1.8974, Tmin: 293.1, Tmax: 396.63 },
  {
    DataId: 1116,
    MainGroup1: 42,
    MainGroup2: 11,
    a1: 516.11,
    a2: -0.2411,
    '1000a3': -0.21344,
    Tmin: 293.1,
    Tmax: 396.63,
  },
  { DataId: 1117, MainGroup1: 11, MainGroup2: 43, a1: -262.33, a2: 0.0, '1000a3': 0.0, Tmin: 311.45, Tmax: 370.5 },
  { DataId: 1118, MainGroup1: 43, MainGroup2: 11, a1: 525.98, a2: 0.0, '1000a3': 0.0, Tmin: 311.45, Tmax: 370.5 },
  { DataId: 1119, MainGroup1: 11, MainGroup2: 44, a1: 10.95, a2: 0.0, '1000a3': 0.0, Tmin: 330.25, Tmax: 373.34 },
  { DataId: 1120, MainGroup1: 44, MainGroup2: 11, a1: -88.85, a2: 0.0, '1000a3': 0.0, Tmin: 330.25, Tmax: 373.34 },
  { DataId: 1121, MainGroup1: 11, MainGroup2: 45, a1: -420.05, a2: 0.4774, '1000a3': 0.0, Tmin: 276.15, Tmax: 402.2 },
  { DataId: 1122, MainGroup1: 45, MainGroup2: 11, a1: 476.27, a2: -0.5866, '1000a3': 0.0, Tmin: 276.15, Tmax: 402.2 },
  { DataId: 1123, MainGroup1: 11, MainGroup2: 47, a1: 758.13, a2: -0.2593, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1124, MainGroup1: 47, MainGroup2: 11, a1: 32.71, a2: -0.8641, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  {
    DataId: 1125,
    MainGroup1: 11,
    MainGroup2: 48,
    a1: -3562.43,
    a2: 51.3635,
    '1000a3': 0.0,
    Tmin: 298.15,
    Tmax: 427.55,
  },
  { DataId: 1126, MainGroup1: 48, MainGroup2: 11, a1: 5.43, a2: -1.0709, '1000a3': 0.0, Tmin: 298.15, Tmax: 427.55 },
  { DataId: 1127, MainGroup1: 11, MainGroup2: 49, a1: 2973.58, a2: -5.4555, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1128, MainGroup1: 49, MainGroup2: 11, a1: 81.64, a2: -1.3111, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1129, MainGroup1: 11, MainGroup2: 53, a1: -44.42, a2: 0.0, '1000a3': 0.0, Tmin: 298.1, Tmax: 348.15 },
  { DataId: 1130, MainGroup1: 53, MainGroup2: 11, a1: 76.6, a2: 0.0, '1000a3': 0.0, Tmin: 298.1, Tmax: 348.15 },
  { DataId: 1131, MainGroup1: 11, MainGroup2: 54, a1: 159.69, a2: 0.0, '1000a3': 0.0, Tmin: 313.15, Tmax: 439.09 },
  { DataId: 1132, MainGroup1: 54, MainGroup2: 11, a1: -87.36, a2: 0.0, '1000a3': 0.0, Tmin: 313.15, Tmax: 439.09 },
  { DataId: 1133, MainGroup1: 11, MainGroup2: 55, a1: 275.66, a2: 0.0, '1000a3': 0.0, Tmin: 364.95, Tmax: 403.53 },
  { DataId: 1134, MainGroup1: 55, MainGroup2: 11, a1: -219.21, a2: 0.0, '1000a3': 0.0, Tmin: 364.95, Tmax: 403.53 },
  { DataId: 1135, MainGroup1: 11, MainGroup2: 60, a1: 165.33, a2: -0.2476, '1000a3': 0.0, Tmin: 298.15, Tmax: 406.98 },
  { DataId: 1136, MainGroup1: 60, MainGroup2: 11, a1: 92.38, a2: -0.2476, '1000a3': 0.0, Tmin: 298.15, Tmax: 406.98 },
  { DataId: 1137, MainGroup1: 11, MainGroup2: 75, a1: 581.06, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 293.14 },
  { DataId: 1138, MainGroup1: 75, MainGroup2: 11, a1: -292.82, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 293.14 },
  { DataId: 1139, MainGroup1: 11, MainGroup2: 82, a1: 278.88, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 298.14 },
  { DataId: 1140, MainGroup1: 82, MainGroup2: 11, a1: -56.26, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 298.14 },
  { DataId: 1141, MainGroup1: 12, MainGroup2: 13, a1: 15.66, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 353.15 },
  { DataId: 1142, MainGroup1: 13, MainGroup2: 12, a1: -81.0, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 353.15 },
  { DataId: 1143, MainGroup1: 12, MainGroup2: 14, a1: 166.74, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1144, MainGroup1: 14, MainGroup2: 12, a1: 71.88, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1145, MainGroup1: 12, MainGroup2: 18, a1: 3372.67, a2: 0.0, '1000a3': 0.0, Tmin: 380.55, Tmax: 386.45 },
  { DataId: 1146, MainGroup1: 18, MainGroup2: 12, a1: -342.99, a2: 0.0, '1000a3': 0.0, Tmin: 380.55, Tmax: 386.45 },
  { DataId: 1147, MainGroup1: 12, MainGroup2: 19, a1: -62.84, a2: 0.0, '1000a3': 0.0, Tmin: 323.14, Tmax: 326.45 },
  { DataId: 1148, MainGroup1: 19, MainGroup2: 12, a1: 92.69, a2: 0.0, '1000a3': 0.0, Tmin: 323.14, Tmax: 326.45 },
  { DataId: 1149, MainGroup1: 12, MainGroup2: 24, a1: -106.15, a2: 0.3475, '1000a3': 0.0, Tmin: 298.15, Tmax: 327.95 },
  { DataId: 1150, MainGroup1: 24, MainGroup2: 12, a1: 450.76, a2: -0.6088, '1000a3': 0.0, Tmin: 298.15, Tmax: 327.95 },
  { DataId: 1151, MainGroup1: 12, MainGroup2: 25, a1: 2040.09, a2: 0.0, '1000a3': 0.0, Tmin: 323.14, Tmax: 323.14 },
  { DataId: 1152, MainGroup1: 25, MainGroup2: 12, a1: 332.85, a2: 0.0, '1000a3': 0.0, Tmin: 323.14, Tmax: 323.14 },
  { DataId: 1153, MainGroup1: 12, MainGroup2: 29, a1: 117.27, a2: 0.0, '1000a3': 0.0, Tmin: 313.15, Tmax: 313.15 },
  { DataId: 1154, MainGroup1: 29, MainGroup2: 12, a1: 29.91, a2: 0.0, '1000a3': 0.0, Tmin: 313.15, Tmax: 313.15 },
  { DataId: 1155, MainGroup1: 12, MainGroup2: 37, a1: -250.44, a2: 0.0, '1000a3': 0.0, Tmin: 321.65, Tmax: 333.45 },
  { DataId: 1156, MainGroup1: 37, MainGroup2: 12, a1: 383.08, a2: 0.0, '1000a3': 0.0, Tmin: 321.65, Tmax: 333.45 },
  { DataId: 1157, MainGroup1: 12, MainGroup2: 39, a1: -25.11, a2: 0.0, '1000a3': 0.0, Tmin: 264.06, Tmax: 423.53 },
  { DataId: 1158, MainGroup1: 39, MainGroup2: 12, a1: 4.43, a2: 0.0, '1000a3': 0.0, Tmin: 264.06, Tmax: 423.53 },
  { DataId: 1159, MainGroup1: 12, MainGroup2: 42, a1: 357.04, a2: -1.2227, '1000a3': 0.0, Tmin: 298.15, Tmax: 353.15 },
  { DataId: 1160, MainGroup1: 42, MainGroup2: 12, a1: 245.06, a2: 0.5452, '1000a3': 0.0, Tmin: 298.15, Tmax: 353.15 },
  { DataId: 1161, MainGroup1: 12, MainGroup2: 44, a1: 85.56, a2: 0.0, '1000a3': 0.0, Tmin: 306.74, Tmax: 393.12 },
  { DataId: 1162, MainGroup1: 44, MainGroup2: 12, a1: -161.36, a2: 0.0, '1000a3': 0.0, Tmin: 306.74, Tmax: 393.12 },
  { DataId: 1163, MainGroup1: 12, MainGroup2: 45, a1: 236.71, a2: -1.3375, '1000a3': 0.0, Tmin: 298.15, Tmax: 322.65 },
  { DataId: 1164, MainGroup1: 45, MainGroup2: 12, a1: -573.55, a2: 2.6039, '1000a3': 0.0, Tmin: 298.15, Tmax: 322.65 },
  { DataId: 1165, MainGroup1: 12, MainGroup2: 53, a1: -174.88, a2: 0.0, '1000a3': 0.0, Tmin: 323.19, Tmax: 363.18 },
  { DataId: 1166, MainGroup1: 53, MainGroup2: 12, a1: 122.53, a2: 0.0, '1000a3': 0.0, Tmin: 323.19, Tmax: 363.18 },
  { DataId: 1167, MainGroup1: 12, MainGroup2: 60, a1: 232.94, a2: 0.0, '1000a3': 0.0, Tmin: 308.15, Tmax: 353.15 },
  { DataId: 1168, MainGroup1: 60, MainGroup2: 12, a1: -53.52, a2: 0.0, '1000a3': 0.0, Tmin: 308.15, Tmax: 353.15 },
  { DataId: 1169, MainGroup1: 13, MainGroup2: 14, a1: -115.76, a2: 0.9716, '1000a3': 0.0, Tmin: 273.15, Tmax: 363.21 },
  { DataId: 1170, MainGroup1: 14, MainGroup2: 13, a1: 111.63, a2: -0.992, '1000a3': 0.0, Tmin: 273.15, Tmax: 363.21 },
  { DataId: 1171, MainGroup1: 13, MainGroup2: 15, a1: 21.09, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 323.15 },
  { DataId: 1172, MainGroup1: 15, MainGroup2: 13, a1: 398.64, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 323.15 },
  { DataId: 1173, MainGroup1: 13, MainGroup2: 16, a1: 43.69, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 298.15 },
  { DataId: 1174, MainGroup1: 16, MainGroup2: 13, a1: -169.43, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 298.15 },
  { DataId: 1175, MainGroup1: 13, MainGroup2: 17, a1: 1654.94, a2: -3.6944, '1000a3': 0.0, Tmin: 293.14, Tmax: 363.15 },
  { DataId: 1176, MainGroup1: 17, MainGroup2: 13, a1: -373.08, a2: -0.398, '1000a3': 0.0, Tmin: 293.14, Tmax: 363.15 },
  { DataId: 1177, MainGroup1: 13, MainGroup2: 18, a1: -93.85, a2: 0.0, '1000a3': 0.0, Tmin: 290.15, Tmax: 343.15 },
  { DataId: 1178, MainGroup1: 18, MainGroup2: 13, a1: -277.88, a2: 0.0, '1000a3': 0.0, Tmin: 290.15, Tmax: 343.15 },
  { DataId: 1179, MainGroup1: 13, MainGroup2: 19, a1: 1987.0, a2: -8.022, '1000a3': 10.65, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1180, MainGroup1: 19, MainGroup2: 13, a1: -588.8, a2: 1.481, '1000a3': -2.636, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1181, MainGroup1: 13, MainGroup2: 20, a1: -210.79, a2: 2.1044, '1000a3': 0.0, Tmin: 243.17, Tmax: 389.83 },
  { DataId: 1182, MainGroup1: 20, MainGroup2: 13, a1: 115.14, a2: -1.4055, '1000a3': 0.0, Tmin: 243.17, Tmax: 389.83 },
  {
    DataId: 1183,
    MainGroup1: 13,
    MainGroup2: 21,
    a1: -387.22,
    a2: 0.0155,
    '1000a3': 9.1663,
    Tmin: 255.69,
    Tmax: 369.98,
  },
  { DataId: 1184, MainGroup1: 21, MainGroup2: 13, a1: 527.3, a2: -3.819, '1000a3': 3.261, Tmin: 255.69, Tmax: 369.98 },
  {
    DataId: 1185,
    MainGroup1: 13,
    MainGroup2: 22,
    a1: -748.34,
    a2: -5.8018,
    '1000a3': 36.716,
    Tmin: 276.15,
    Tmax: 353.24,
  },
  {
    DataId: 1186,
    MainGroup1: 22,
    MainGroup2: 13,
    a1: 159.48,
    a2: -2.3995,
    '1000a3': 2.0583,
    Tmin: 276.15,
    Tmax: 353.24,
  },
  { DataId: 1187, MainGroup1: 13, MainGroup2: 23, a1: -558.05, a2: 1.1771, '1000a3': 0.0, Tmin: 283.15, Tmax: 353.53 },
  { DataId: 1188, MainGroup1: 23, MainGroup2: 13, a1: 1266.83, a2: -2.8613, '1000a3': 0.0, Tmin: 283.15, Tmax: 353.53 },
  { DataId: 1189, MainGroup1: 13, MainGroup2: 24, a1: -380.21, a2: 1.168, '1000a3': 0.0, Tmin: 293.1, Tmax: 386.53 },
  { DataId: 1190, MainGroup1: 24, MainGroup2: 13, a1: 378.79, a2: -0.9524, '1000a3': 0.0, Tmin: 293.1, Tmax: 386.53 },
  {
    DataId: 1191,
    MainGroup1: 13,
    MainGroup2: 25,
    a1: 49.05,
    a2: -0.4437,
    '1000a3': 0.26171,
    Tmin: 298.14,
    Tmax: 396.65,
  },
  {
    DataId: 1192,
    MainGroup1: 25,
    MainGroup2: 13,
    a1: -242.58,
    a2: 0.0798,
    '1000a3': 1.1737,
    Tmin: 298.14,
    Tmax: 396.65,
  },
  { DataId: 1193, MainGroup1: 13, MainGroup2: 26, a1: -356.63, a2: 0.9557, '1000a3': 0.0, Tmin: 293.64, Tmax: 298.15 },
  { DataId: 1194, MainGroup1: 26, MainGroup2: 13, a1: 331.65, a2: -1.4815, '1000a3': 0.0, Tmin: 293.64, Tmax: 298.15 },
  { DataId: 1195, MainGroup1: 13, MainGroup2: 27, a1: 490.39, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 293.14 },
  { DataId: 1196, MainGroup1: 27, MainGroup2: 13, a1: -610.9, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 293.14 },
  {
    DataId: 1197,
    MainGroup1: 13,
    MainGroup2: 28,
    a1: -73.99,
    a2: 1.9664,
    '1000a3': -0.5049,
    Tmin: 289.67,
    Tmax: 308.3,
  },
  {
    DataId: 1198,
    MainGroup1: 28,
    MainGroup2: 13,
    a1: 247.99,
    a2: -1.5101,
    '1000a3': 0.025228,
    Tmin: 289.67,
    Tmax: 308.3,
  },
  { DataId: 1199, MainGroup1: 13, MainGroup2: 29, a1: -51.99, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 313.14 },
  { DataId: 1200, MainGroup1: 29, MainGroup2: 13, a1: 72.4, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 313.14 },
  { DataId: 1201, MainGroup1: 13, MainGroup2: 30, a1: 383.74, a2: 0.0, '1000a3': 0.0, Tmin: 368.13, Tmax: 388.15 },
  { DataId: 1202, MainGroup1: 30, MainGroup2: 13, a1: -238.83, a2: 0.0, '1000a3': 0.0, Tmin: 368.13, Tmax: 388.15 },
  { DataId: 1203, MainGroup1: 13, MainGroup2: 32, a1: -72.46, a2: 0.0, '1000a3': 0.0, Tmin: 308.13, Tmax: 308.13 },
  { DataId: 1204, MainGroup1: 32, MainGroup2: 13, a1: 685.28, a2: 0.0, '1000a3': 0.0, Tmin: 308.13, Tmax: 308.13 },
  { DataId: 1205, MainGroup1: 13, MainGroup2: 33, a1: 719.14, a2: 0.0, '1000a3': 0.0, Tmin: 276.15, Tmax: 303.15 },
  { DataId: 1206, MainGroup1: 33, MainGroup2: 13, a1: -444.76, a2: 0.0, '1000a3': 0.0, Tmin: 276.15, Tmax: 303.15 },
  { DataId: 1207, MainGroup1: 13, MainGroup2: 34, a1: -117.96, a2: 0.1874, '1000a3': 0.0, Tmin: 298.14, Tmax: 406.08 },
  { DataId: 1208, MainGroup1: 34, MainGroup2: 13, a1: 202.99, a2: -0.7018, '1000a3': 0.0, Tmin: 298.14, Tmax: 406.08 },
  { DataId: 1209, MainGroup1: 13, MainGroup2: 35, a1: 620.88, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 333.15 },
  { DataId: 1210, MainGroup1: 35, MainGroup2: 13, a1: -471.41, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 333.15 },
  { DataId: 1211, MainGroup1: 13, MainGroup2: 37, a1: -501.1, a2: 1.2324, '1000a3': 0.0, Tmin: 276.15, Tmax: 360.22 },
  { DataId: 1212, MainGroup1: 37, MainGroup2: 13, a1: -425.22, a2: 1.3205, '1000a3': 0.0, Tmin: 276.15, Tmax: 360.22 },
  { DataId: 1213, MainGroup1: 13, MainGroup2: 38, a1: 219.14, a2: -1.7244, '1000a3': 0.0, Tmin: 283.15, Tmax: 298.15 },
  { DataId: 1214, MainGroup1: 38, MainGroup2: 13, a1: -769.64, a2: 3.6361, '1000a3': 0.0, Tmin: 283.15, Tmax: 298.15 },
  { DataId: 1215, MainGroup1: 13, MainGroup2: 39, a1: 4726.7, a2: 0.0, '1000a3': 0.0, Tmin: 340.95, Tmax: 423.99 },
  { DataId: 1216, MainGroup1: 39, MainGroup2: 13, a1: -414.64, a2: 0.0, '1000a3': 0.0, Tmin: 340.95, Tmax: 423.99 },
  {
    DataId: 1217,
    MainGroup1: 13,
    MainGroup2: 40,
    a1: 27.35,
    a2: -0.6974,
    '1000a3': 0.57798,
    Tmin: 283.12,
    Tmax: 385.84,
  },
  { DataId: 1218, MainGroup1: 40, MainGroup2: 13, a1: -88.8, a2: -0.6903, '1000a3': 2.435, Tmin: 283.12, Tmax: 385.84 },
  { DataId: 1219, MainGroup1: 13, MainGroup2: 41, a1: -498.54, a2: 0.0, '1000a3': 0.0, Tmin: 303.15, Tmax: 343.15 },
  { DataId: 1220, MainGroup1: 41, MainGroup2: 13, a1: 3596.64, a2: 0.0, '1000a3': 0.0, Tmin: 303.15, Tmax: 343.15 },
  { DataId: 1221, MainGroup1: 13, MainGroup2: 42, a1: 179.92, a2: -0.0812, '1000a3': 0.0, Tmin: 266.51, Tmax: 443.15 },
  { DataId: 1222, MainGroup1: 42, MainGroup2: 13, a1: 118.36, a2: -0.353, '1000a3': 0.0, Tmin: 266.51, Tmax: 443.15 },
  { DataId: 1223, MainGroup1: 13, MainGroup2: 43, a1: -466.45, a2: 1.183, '1000a3': 0.0, Tmin: 250.0, Tmax: 353.24 },
  { DataId: 1224, MainGroup1: 43, MainGroup2: 13, a1: 1033.53, a2: -2.8439, '1000a3': 0.0, Tmin: 250.0, Tmax: 353.24 },
  { DataId: 1225, MainGroup1: 13, MainGroup2: 44, a1: -335.44, a2: 0.0, '1000a3': 0.0, Tmin: 342.72, Tmax: 381.43 },
  { DataId: 1226, MainGroup1: 44, MainGroup2: 13, a1: 345.03, a2: 0.0, '1000a3': 0.0, Tmin: 342.72, Tmax: 381.43 },
  { DataId: 1227, MainGroup1: 13, MainGroup2: 45, a1: -139.68, a2: -0.6728, '1000a3': 0.0, Tmin: 273.1, Tmax: 393.2 },
  { DataId: 1228, MainGroup1: 45, MainGroup2: 13, a1: 614.1, a2: -0.3569, '1000a3': 0.0, Tmin: 273.1, Tmax: 393.2 },
  { DataId: 1229, MainGroup1: 13, MainGroup2: 46, a1: -1.19, a2: 0.0, '1000a3': 0.0, Tmin: 219.63, Tmax: 373.15 },
  { DataId: 1230, MainGroup1: 46, MainGroup2: 13, a1: -112.87, a2: 0.0, '1000a3': 0.0, Tmin: 219.63, Tmax: 373.15 },
  { DataId: 1231, MainGroup1: 13, MainGroup2: 48, a1: 1321.52, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1232, MainGroup1: 48, MainGroup2: 13, a1: -361.25, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1233, MainGroup1: 13, MainGroup2: 49, a1: 3858.07, a2: -9.278, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1234, MainGroup1: 49, MainGroup2: 13, a1: -224.78, a2: -0.4106, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1235, MainGroup1: 13, MainGroup2: 50, a1: 48.93, a2: 0.0, '1000a3': 0.0, Tmin: 205.24, Tmax: 457.15 },
  { DataId: 1236, MainGroup1: 50, MainGroup2: 13, a1: -67.5, a2: 0.0, '1000a3': 0.0, Tmin: 205.24, Tmax: 457.15 },
  { DataId: 1237, MainGroup1: 13, MainGroup2: 53, a1: 585.7, a2: 0.0, '1000a3': 0.0, Tmin: 303.14, Tmax: 363.13 },
  { DataId: 1238, MainGroup1: 53, MainGroup2: 13, a1: -298.34, a2: 0.0, '1000a3': 0.0, Tmin: 303.14, Tmax: 363.13 },
  { DataId: 1239, MainGroup1: 13, MainGroup2: 55, a1: 792.41, a2: -2.433, '1000a3': 0.0, Tmin: 293.0, Tmax: 373.0 },
  { DataId: 1240, MainGroup1: 55, MainGroup2: 13, a1: -698.33, a2: 1.9691, '1000a3': 0.0, Tmin: 293.0, Tmax: 373.0 },
  { DataId: 1241, MainGroup1: 59, MainGroup2: 13, a1: 678.23, a2: 0.0, '1000a3': 0.0, Tmin: 288.15, Tmax: 538.16 },
  { DataId: 1242, MainGroup1: 13, MainGroup2: 60, a1: 346.23, a2: 0.0473, '1000a3': 0.0, Tmin: 298.14, Tmax: 400.36 },
  { DataId: 1243, MainGroup1: 60, MainGroup2: 13, a1: -66.65, a2: -0.4492, '1000a3': 0.0, Tmin: 298.14, Tmax: 400.36 },
  { DataId: 1244, MainGroup1: 13, MainGroup2: 78, a1: 198.77, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 343.15 },
  { DataId: 1245, MainGroup1: 78, MainGroup2: 13, a1: -96.61, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 343.15 },
  { DataId: 1246, MainGroup1: 13, MainGroup2: 95, a1: 268.79, a2: 0.0, '1000a3': 0.0, Tmin: 343.71, Tmax: 353.0 },
  { DataId: 1247, MainGroup1: 95, MainGroup2: 13, a1: -134.25, a2: 0.0, '1000a3': 0.0, Tmin: 343.71, Tmax: 353.0 },
  {
    DataId: 1248,
    MainGroup1: 14,
    MainGroup2: 15,
    a1: 1477.73,
    a2: -12.7877,
    '1000a3': 25.641,
    Tmin: 281.15,
    Tmax: 353.63,
  },
  {
    DataId: 1249,
    MainGroup1: 15,
    MainGroup2: 14,
    a1: -953.52,
    a2: 9.1815,
    '1000a3': -19.353,
    Tmin: 281.15,
    Tmax: 353.63,
  },
  {
    DataId: 1250,
    MainGroup1: 14,
    MainGroup2: 16,
    a1: -632.05,
    a2: -0.1115,
    '1000a3': 10.58,
    Tmin: 223.15,
    Tmax: 422.65,
  },
  {
    DataId: 1251,
    MainGroup1: 16,
    MainGroup2: 14,
    a1: 1166.33,
    a2: -5.9524,
    '1000a3': 4.801,
    Tmin: 223.15,
    Tmax: 422.65,
  },
  { DataId: 1252, MainGroup1: 14, MainGroup2: 19, a1: 74.91, a2: 0.0, '1000a3': 0.0, Tmin: 301.15, Tmax: 423.13 },
  { DataId: 1253, MainGroup1: 19, MainGroup2: 14, a1: 74.61, a2: 0.0, '1000a3': 0.0, Tmin: 301.15, Tmax: 423.13 },
  { DataId: 1254, MainGroup1: 14, MainGroup2: 21, a1: 377.41, a2: 0.0, '1000a3': 0.0, Tmin: 328.15, Tmax: 363.65 },
  { DataId: 1255, MainGroup1: 21, MainGroup2: 14, a1: -183.85, a2: 0.0, '1000a3': 0.0, Tmin: 328.15, Tmax: 363.65 },
  { DataId: 1256, MainGroup1: 14, MainGroup2: 24, a1: -68.27, a2: -0.3247, '1000a3': 0.0, Tmin: 253.15, Tmax: 363.21 },
  { DataId: 1257, MainGroup1: 24, MainGroup2: 14, a1: 294.85, a2: 0.0152, '1000a3': 0.0, Tmin: 253.15, Tmax: 363.21 },
  { DataId: 1258, MainGroup1: 14, MainGroup2: 25, a1: 513.7, a2: -7.6298, '1000a3': 0.0, Tmin: 297.99, Tmax: 353.15 },
  {
    DataId: 1259,
    MainGroup1: 25,
    MainGroup2: 14,
    a1: 7099.13,
    a2: -17.3588,
    '1000a3': 0.0,
    Tmin: 297.99,
    Tmax: 353.15,
  },
  { DataId: 1260, MainGroup1: 14, MainGroup2: 29, a1: -220.03, a2: 0.0, '1000a3': 0.0, Tmin: 313.15, Tmax: 313.15 },
  { DataId: 1261, MainGroup1: 29, MainGroup2: 14, a1: 276.69, a2: 0.0, '1000a3': 0.0, Tmin: 313.15, Tmax: 313.15 },
  { DataId: 1262, MainGroup1: 14, MainGroup2: 35, a1: -157.6, a2: 0.0, '1000a3': 0.0, Tmin: 353.13, Tmax: 353.13 },
  { DataId: 1263, MainGroup1: 35, MainGroup2: 14, a1: 158.76, a2: 0.0, '1000a3': 0.0, Tmin: 353.13, Tmax: 353.13 },
  { DataId: 1264, MainGroup1: 14, MainGroup2: 39, a1: -61.38, a2: 0.0, '1000a3': 0.0, Tmin: 318.03, Tmax: 393.45 },
  { DataId: 1265, MainGroup1: 39, MainGroup2: 14, a1: -1.07, a2: 0.0, '1000a3': 0.0, Tmin: 318.03, Tmax: 393.45 },
  { DataId: 1266, MainGroup1: 14, MainGroup2: 42, a1: 22.47, a2: -0.631, '1000a3': 0.0, Tmin: 288.15, Tmax: 373.15 },
  { DataId: 1267, MainGroup1: 42, MainGroup2: 14, a1: 819.32, a2: -1.0439, '1000a3': 0.0, Tmin: 288.15, Tmax: 373.15 },
  { DataId: 1268, MainGroup1: 14, MainGroup2: 43, a1: -96.64, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 347.25 },
  { DataId: 1269, MainGroup1: 43, MainGroup2: 14, a1: 103.93, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 347.25 },
  { DataId: 1270, MainGroup1: 14, MainGroup2: 46, a1: -28.41, a2: 0.0, '1000a3': 0.0, Tmin: 323.1, Tmax: 423.15 },
  { DataId: 1271, MainGroup1: 46, MainGroup2: 14, a1: 110.19, a2: 0.0, '1000a3': 0.0, Tmin: 323.1, Tmax: 423.15 },
  { DataId: 1272, MainGroup1: 14, MainGroup2: 47, a1: 413.11, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1273, MainGroup1: 47, MainGroup2: 14, a1: -293.93, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1274, MainGroup1: 14, MainGroup2: 60, a1: -248.19, a2: 0.0, '1000a3': 0.0, Tmin: 333.15, Tmax: 353.15 },
  { DataId: 1275, MainGroup1: 60, MainGroup2: 14, a1: 9.62, a2: 0.0, '1000a3': 0.0, Tmin: 333.15, Tmax: 353.15 },
  { DataId: 1276, MainGroup1: 14, MainGroup2: 78, a1: 2321.26, a2: -4.8118, '1000a3': 0.0, Tmin: 298.15, Tmax: 423.15 },
  { DataId: 1277, MainGroup1: 78, MainGroup2: 14, a1: 298.64, a2: -1.5848, '1000a3': 0.0, Tmin: 298.15, Tmax: 423.15 },
  { DataId: 1278, MainGroup1: 14, MainGroup2: 79, a1: -311.53, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 343.15 },
  { DataId: 1279, MainGroup1: 79, MainGroup2: 14, a1: 397.33, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 343.15 },
  { DataId: 1280, MainGroup1: 14, MainGroup2: 96, a1: -2367.71, a2: 6.8183, '1000a3': 0.0, Tmin: 293.0, Tmax: 342.98 },
  { DataId: 1281, MainGroup1: 96, MainGroup2: 14, a1: 1572.07, a2: -5.5233, '1000a3': 0.0, Tmin: 293.0, Tmax: 342.98 },
  { DataId: 1282, MainGroup1: 15, MainGroup2: 16, a1: -28.43, a2: 0.0478, '1000a3': 0.0, Tmin: 287.09, Tmax: 364.22 },
  { DataId: 1283, MainGroup1: 16, MainGroup2: 15, a1: 37.75, a2: 0.0083, '1000a3': 0.0, Tmin: 287.09, Tmax: 364.22 },
  { DataId: 1284, MainGroup1: 15, MainGroup2: 18, a1: -1147.4, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1285, MainGroup1: 18, MainGroup2: 15, a1: -326.24, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1286, MainGroup1: 15, MainGroup2: 19, a1: 120.4, a2: 0.5206, '1000a3': 0.0, Tmin: 297.99, Tmax: 398.3 },
  { DataId: 1287, MainGroup1: 19, MainGroup2: 15, a1: 296.41, a2: -1.5384, '1000a3': 0.0, Tmin: 297.99, Tmax: 398.3 },
  { DataId: 1288, MainGroup1: 15, MainGroup2: 24, a1: 111.27, a2: 0.0, '1000a3': 0.0, Tmin: 253.17, Tmax: 313.13 },
  { DataId: 1289, MainGroup1: 24, MainGroup2: 15, a1: -42.1, a2: 0.0, '1000a3': 0.0, Tmin: 253.17, Tmax: 313.13 },
  { DataId: 1290, MainGroup1: 15, MainGroup2: 25, a1: 222.3, a2: 4.1534, '1000a3': 0.0, Tmin: 298.15, Tmax: 333.15 },
  { DataId: 1291, MainGroup1: 25, MainGroup2: 15, a1: -694.66, a2: 1.9226, '1000a3': 0.0, Tmin: 298.15, Tmax: 333.15 },
  { DataId: 1292, MainGroup1: 15, MainGroup2: 38, a1: -448.78, a2: 0.0, '1000a3': 0.0, Tmin: 308.14, Tmax: 308.14 },
  { DataId: 1293, MainGroup1: 38, MainGroup2: 15, a1: 1017.74, a2: 0.0, '1000a3': 0.0, Tmin: 308.14, Tmax: 308.14 },
  { DataId: 1294, MainGroup1: 15, MainGroup2: 39, a1: -88.49, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1295, MainGroup1: 39, MainGroup2: 15, a1: -230.55, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1296, MainGroup1: 15, MainGroup2: 42, a1: -286.18, a2: 0.6545, '1000a3': 0.0, Tmin: 302.23, Tmax: 343.15 },
  { DataId: 1297, MainGroup1: 42, MainGroup2: 15, a1: 1172.1, a2: -2.7669, '1000a3': 0.0, Tmin: 302.23, Tmax: 343.15 },
  { DataId: 1298, MainGroup1: 15, MainGroup2: 43, a1: -186.98, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1299, MainGroup1: 43, MainGroup2: 15, a1: 295.07, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1300, MainGroup1: 15, MainGroup2: 47, a1: 716.01, a2: 0.0818, '1000a3': 0.0, Tmin: 398.15, Tmax: 398.6 },
  { DataId: 1301, MainGroup1: 47, MainGroup2: 15, a1: -73.28, a2: -0.6408, '1000a3': 0.0, Tmin: 398.15, Tmax: 398.6 },
  { DataId: 1302, MainGroup1: 15, MainGroup2: 78, a1: 29.3, a2: 0.0, '1000a3': 0.0, Tmin: 283.15, Tmax: 343.15 },
  { DataId: 1303, MainGroup1: 78, MainGroup2: 15, a1: 58.67, a2: 0.0, '1000a3': 0.0, Tmin: 283.15, Tmax: 343.15 },
  { DataId: 1304, MainGroup1: 16, MainGroup2: 19, a1: 3327.52, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 359.35 },
  { DataId: 1305, MainGroup1: 19, MainGroup2: 16, a1: -402.88, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 359.35 },
  { DataId: 1306, MainGroup1: 16, MainGroup2: 21, a1: 146.94, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 360.46 },
  { DataId: 1307, MainGroup1: 21, MainGroup2: 16, a1: -224.18, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 360.46 },
  { DataId: 1308, MainGroup1: 16, MainGroup2: 22, a1: -99.14, a2: 0.0, '1000a3': 0.0, Tmin: 283.15, Tmax: 323.15 },
  { DataId: 1309, MainGroup1: 22, MainGroup2: 16, a1: -142.32, a2: 0.0, '1000a3': 0.0, Tmin: 283.15, Tmax: 323.15 },
  { DataId: 1310, MainGroup1: 16, MainGroup2: 24, a1: -261.18, a2: 1.0309, '1000a3': 0.0, Tmin: 253.15, Tmax: 347.08 },
  { DataId: 1311, MainGroup1: 24, MainGroup2: 16, a1: -509.33, a2: 1.4746, '1000a3': 0.0, Tmin: 253.15, Tmax: 347.08 },
  { DataId: 1312, MainGroup1: 16, MainGroup2: 25, a1: 333.09, a2: 0.0777, '1000a3': 0.0, Tmin: 297.99, Tmax: 363.15 },
  { DataId: 1313, MainGroup1: 25, MainGroup2: 16, a1: -473.86, a2: 1.043, '1000a3': 0.0, Tmin: 297.99, Tmax: 363.15 },
  { DataId: 1314, MainGroup1: 16, MainGroup2: 38, a1: -7.53, a2: -0.8077, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1315, MainGroup1: 38, MainGroup2: 16, a1: 35.16, a2: 0.0972, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1316, MainGroup1: 16, MainGroup2: 39, a1: 1071.57, a2: -1.0273, '1000a3': 0.0, Tmin: 298.15, Tmax: 353.13 },
  { DataId: 1317, MainGroup1: 39, MainGroup2: 16, a1: 103.93, a2: -1.209, '1000a3': 0.0, Tmin: 298.15, Tmax: 353.13 },
  { DataId: 1318, MainGroup1: 16, MainGroup2: 42, a1: 280.98, a2: -1.4248, '1000a3': 0.0, Tmin: 298.1, Tmax: 343.15 },
  { DataId: 1319, MainGroup1: 42, MainGroup2: 16, a1: 149.32, a2: 0.5156, '1000a3': 0.0, Tmin: 298.1, Tmax: 343.15 },
  { DataId: 1320, MainGroup1: 16, MainGroup2: 43, a1: 155.98, a2: -1.037, '1000a3': 0.0, Tmin: 298.15, Tmax: 323.15 },
  { DataId: 1321, MainGroup1: 43, MainGroup2: 16, a1: -43.48, a2: 0.8626, '1000a3': 0.0, Tmin: 298.15, Tmax: 323.15 },
  { DataId: 1322, MainGroup1: 16, MainGroup2: 45, a1: 231.84, a2: -1.5701, '1000a3': 0.0, Tmin: 283.14, Tmax: 323.15 },
  { DataId: 1323, MainGroup1: 45, MainGroup2: 16, a1: -645.45, a2: 1.1383, '1000a3': 0.0, Tmin: 283.14, Tmax: 323.15 },
  { DataId: 1324, MainGroup1: 16, MainGroup2: 47, a1: -579.15, a2: 12.7052, '1000a3': 0.0, Tmin: 398.15, Tmax: 398.6 },
  { DataId: 1325, MainGroup1: 47, MainGroup2: 16, a1: 364.48, a2: -2.0309, '1000a3': 0.0, Tmin: 398.15, Tmax: 398.6 },
  { DataId: 1326, MainGroup1: 16, MainGroup2: 48, a1: 2193.66, a2: 0.0, '1000a3': 0.0, Tmin: 323.14, Tmax: 353.13 },
  { DataId: 1327, MainGroup1: 48, MainGroup2: 16, a1: -200.45, a2: 0.0, '1000a3': 0.0, Tmin: 323.14, Tmax: 353.13 },
  { DataId: 1328, MainGroup1: 16, MainGroup2: 78, a1: 95.66, a2: 0.0, '1000a3': 0.0, Tmin: 283.15, Tmax: 343.15 },
  { DataId: 1329, MainGroup1: 78, MainGroup2: 16, a1: -38.44, a2: 0.0, '1000a3': 0.0, Tmin: 283.15, Tmax: 343.15 },
  {
    DataId: 1330,
    MainGroup1: 17,
    MainGroup2: 18,
    a1: 352.72,
    a2: -2.6904,
    '1000a3': 3.4041,
    Tmin: 298.15,
    Tmax: 500.36,
  },
  {
    DataId: 1331,
    MainGroup1: 18,
    MainGroup2: 17,
    a1: -743.54,
    a2: 1.8055,
    '1000a3': 3.3698,
    Tmin: 298.15,
    Tmax: 500.36,
  },
  { DataId: 1332, MainGroup1: 17, MainGroup2: 19, a1: 60.38, a2: 1.995, '1000a3': -5.3136, Tmin: 293.15, Tmax: 393.12 },
  {
    DataId: 1333,
    MainGroup1: 19,
    MainGroup2: 17,
    a1: -243.17,
    a2: 0.3714,
    '1000a3': 2.5352,
    Tmin: 293.15,
    Tmax: 393.12,
  },
  {
    DataId: 1334,
    MainGroup1: 17,
    MainGroup2: 20,
    a1: -512.05,
    a2: -2.1758,
    '1000a3': 8.6728,
    Tmin: 273.15,
    Tmax: 333.15,
  },
  {
    DataId: 1335,
    MainGroup1: 20,
    MainGroup2: 17,
    a1: -1464.58,
    a2: 10.3896,
    '1000a3': -16.438,
    Tmin: 273.15,
    Tmax: 333.15,
  },
  { DataId: 1336, MainGroup1: 17, MainGroup2: 21, a1: 141.41, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 333.15 },
  { DataId: 1337, MainGroup1: 21, MainGroup2: 17, a1: 261.26, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 333.15 },
  {
    DataId: 1338,
    MainGroup1: 17,
    MainGroup2: 24,
    a1: 3173.44,
    a2: 20.1634,
    '1000a3': -54.557,
    Tmin: 290.15,
    Tmax: 343.45,
  },
  {
    DataId: 1339,
    MainGroup1: 24,
    MainGroup2: 17,
    a1: 1344.58,
    a2: -1.7152,
    '1000a3': -0.046685,
    Tmin: 290.15,
    Tmax: 343.45,
  },
  { DataId: 1340, MainGroup1: 17, MainGroup2: 25, a1: 2344.78, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 429.84 },
  { DataId: 1341, MainGroup1: 25, MainGroup2: 17, a1: 13426.9, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 429.84 },
  { DataId: 1342, MainGroup1: 17, MainGroup2: 27, a1: -11.87, a2: 1.4611, '1000a3': 0.0, Tmin: 288.03, Tmax: 482.15 },
  { DataId: 1343, MainGroup1: 27, MainGroup2: 17, a1: 208.89, a2: -1.1125, '1000a3': 0.0, Tmin: 288.03, Tmax: 482.15 },
  { DataId: 1344, MainGroup1: 17, MainGroup2: 31, a1: -130.31, a2: 0.2599, '1000a3': 0.0, Tmin: 293.15, Tmax: 418.13 },
  { DataId: 1345, MainGroup1: 31, MainGroup2: 17, a1: 195.34, a2: 0.7746, '1000a3': 0.0, Tmin: 293.15, Tmax: 418.13 },
  { DataId: 1346, MainGroup1: 17, MainGroup2: 39, a1: -1199.2, a2: 3.7778, '1000a3': 0.0, Tmin: 298.15, Tmax: 454.35 },
  { DataId: 1347, MainGroup1: 39, MainGroup2: 17, a1: 193.31, a2: -0.9866, '1000a3': 0.0, Tmin: 298.15, Tmax: 454.35 },
  {
    DataId: 1348,
    MainGroup1: 17,
    MainGroup2: 42,
    a1: 1472.9,
    a2: -3.8236,
    '1000a3': 1.5082,
    Tmin: 275.15,
    Tmax: 398.23,
  },
  {
    DataId: 1349,
    MainGroup1: 42,
    MainGroup2: 17,
    a1: 1817.22,
    a2: -2.8872,
    '1000a3': 1.5293,
    Tmin: 275.15,
    Tmax: 398.23,
  },
  { DataId: 1350, MainGroup1: 17, MainGroup2: 43, a1: -384.26, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 308.15 },
  { DataId: 1351, MainGroup1: 43, MainGroup2: 17, a1: 154.97, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 308.15 },
  { DataId: 1352, MainGroup1: 17, MainGroup2: 45, a1: -279.52, a2: 7.4954, '1000a3': 0.0, Tmin: 293.1, Tmax: 308.15 },
  { DataId: 1353, MainGroup1: 45, MainGroup2: 17, a1: 614.6, a2: -1.2737, '1000a3': 0.0, Tmin: 293.1, Tmax: 308.15 },
  { DataId: 1354, MainGroup1: 17, MainGroup2: 46, a1: 708.28, a2: 0.0, '1000a3': 0.0, Tmin: 423.2, Tmax: 423.2 },
  { DataId: 1355, MainGroup1: 46, MainGroup2: 17, a1: 1346.95, a2: 0.0, '1000a3': 0.0, Tmin: 423.2, Tmax: 423.2 },
  { DataId: 1356, MainGroup1: 17, MainGroup2: 47, a1: 9.82, a2: -0.3108, '1000a3': 0.0, Tmin: 333.15, Tmax: 413.54 },
  { DataId: 1357, MainGroup1: 47, MainGroup2: 17, a1: -915.52, a2: 2.8674, '1000a3': 0.0, Tmin: 333.15, Tmax: 413.54 },
  { DataId: 1358, MainGroup1: 17, MainGroup2: 75, a1: 488.31, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 484.16 },
  { DataId: 1359, MainGroup1: 75, MainGroup2: 17, a1: 516.11, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 484.16 },
  { DataId: 1360, MainGroup1: 17, MainGroup2: 76, a1: 301.8, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 472.85 },
  { DataId: 1361, MainGroup1: 76, MainGroup2: 17, a1: 53.34, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 472.85 },
  { DataId: 1362, MainGroup1: 18, MainGroup2: 19, a1: -660.42, a2: 2.0188, '1000a3': 0.0, Tmin: 298.09, Tmax: 386.85 },
  { DataId: 1363, MainGroup1: 19, MainGroup2: 18, a1: 938.82, a2: -2.8191, '1000a3': 0.0, Tmin: 298.09, Tmax: 386.85 },
  { DataId: 1364, MainGroup1: 18, MainGroup2: 20, a1: -961.33, a2: 0.8635, '1000a3': 0.0, Tmin: 290.15, Tmax: 411.55 },
  { DataId: 1365, MainGroup1: 20, MainGroup2: 18, a1: 349.3, a2: -2.0813, '1000a3': 0.0, Tmin: 290.15, Tmax: 411.55 },
  {
    DataId: 1366,
    MainGroup1: 18,
    MainGroup2: 21,
    a1: -40.5,
    a2: -1.0463,
    '1000a3': 1.1306,
    Tmin: 198.16,
    Tmax: 354.28,
  },
  {
    DataId: 1367,
    MainGroup1: 21,
    MainGroup2: 18,
    a1: -54.99,
    a2: 0.6059,
    '1000a3': -3.1052,
    Tmin: 198.16,
    Tmax: 354.28,
  },
  { DataId: 1368, MainGroup1: 18, MainGroup2: 22, a1: -756.71, a2: 1.131, '1000a3': 0.0, Tmin: 276.15, Tmax: 303.15 },
  { DataId: 1369, MainGroup1: 22, MainGroup2: 18, a1: 1652.15, a2: -5.0074, '1000a3': 0.0, Tmin: 276.15, Tmax: 303.15 },
  { DataId: 1370, MainGroup1: 18, MainGroup2: 23, a1: -306.97, a2: 0.0, '1000a3': 0.0, Tmin: 298.1, Tmax: 298.1 },
  { DataId: 1371, MainGroup1: 23, MainGroup2: 18, a1: 229.77, a2: 0.0, '1000a3': 0.0, Tmin: 298.1, Tmax: 298.1 },
  { DataId: 1372, MainGroup1: 18, MainGroup2: 24, a1: -159.73, a2: -0.7966, '1000a3': 0.0, Tmin: 292.15, Tmax: 353.15 },
  { DataId: 1373, MainGroup1: 24, MainGroup2: 18, a1: 723.95, a2: 0.1792, '1000a3': 0.0, Tmin: 292.15, Tmax: 353.15 },
  { DataId: 1374, MainGroup1: 18, MainGroup2: 33, a1: -80.91, a2: -0.6851, '1000a3': 0.0, Tmin: 298.09, Tmax: 313.15 },
  { DataId: 1375, MainGroup1: 33, MainGroup2: 18, a1: -121.11, a2: 0.737, '1000a3': 0.0, Tmin: 298.09, Tmax: 313.15 },
  { DataId: 1376, MainGroup1: 18, MainGroup2: 37, a1: 50.53, a2: -0.798, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1377, MainGroup1: 37, MainGroup2: 18, a1: -110.08, a2: 2.1903, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1378, MainGroup1: 18, MainGroup2: 38, a1: 197.95, a2: -0.3316, '1000a3': -0.4, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1379, MainGroup1: 38, MainGroup2: 18, a1: -1052.5, a2: 1.6854, '1000a3': 2.7, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1380, MainGroup1: 18, MainGroup2: 39, a1: -205.18, a2: -0.0376, '1000a3': 0.0, Tmin: 308.15, Tmax: 423.87 },
  { DataId: 1381, MainGroup1: 39, MainGroup2: 18, a1: 290.64, a2: -0.5345, '1000a3': 0.0, Tmin: 308.15, Tmax: 423.87 },
  { DataId: 1382, MainGroup1: 18, MainGroup2: 42, a1: 672.16, a2: -2.0081, '1000a3': 0.0, Tmin: 223.19, Tmax: 413.21 },
  { DataId: 1383, MainGroup1: 42, MainGroup2: 18, a1: -14.03, a2: 0.1419, '1000a3': 0.0, Tmin: 223.19, Tmax: 413.21 },
  { DataId: 1384, MainGroup1: 18, MainGroup2: 45, a1: -298.68, a2: -0.4906, '1000a3': 0.0, Tmin: 293.15, Tmax: 386.65 },
  { DataId: 1385, MainGroup1: 45, MainGroup2: 18, a1: -45.04, a2: 1.3453, '1000a3': 0.0, Tmin: 293.15, Tmax: 386.65 },
  { DataId: 1386, MainGroup1: 18, MainGroup2: 47, a1: 489.93, a2: -0.5591, '1000a3': -1.02, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1387, MainGroup1: 47, MainGroup2: 18, a1: -2250.64, a2: 5.4638, '1000a3': -0.15, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1388, MainGroup1: 18, MainGroup2: 54, a1: -248.31, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 407.83 },
  { DataId: 1389, MainGroup1: 54, MainGroup2: 18, a1: 759.16, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 407.83 },
  { DataId: 1390, MainGroup1: 18, MainGroup2: 77, a1: -170.3, a2: 0.0, '1000a3': 0.0, Tmin: 303.15, Tmax: 393.15 },
  { DataId: 1391, MainGroup1: 77, MainGroup2: 18, a1: 184.8, a2: 0.0, '1000a3': 0.0, Tmin: 303.15, Tmax: 393.15 },
  { DataId: 1392, MainGroup1: 18, MainGroup2: 78, a1: 366.11, a2: -1.9407, '1000a3': 0.0, Tmin: 283.15, Tmax: 386.35 },
  { DataId: 1393, MainGroup1: 78, MainGroup2: 18, a1: -30.75, a2: 6.0544, '1000a3': 0.0, Tmin: 283.15, Tmax: 386.35 },
  { DataId: 1394, MainGroup1: 18, MainGroup2: 82, a1: -410.93, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 298.15 },
  { DataId: 1395, MainGroup1: 82, MainGroup2: 18, a1: 44.05, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 298.15 },
  { DataId: 1396, MainGroup1: 18, MainGroup2: 86, a1: -174.08, a2: 0.0, '1000a3': 0.0, Tmin: 323.14, Tmax: 373.12 },
  { DataId: 1397, MainGroup1: 86, MainGroup2: 18, a1: 335.63, a2: 0.0, '1000a3': 0.0, Tmin: 323.14, Tmax: 373.12 },
  { DataId: 1398, MainGroup1: 18, MainGroup2: 94, a1: -403.63, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 643.51 },
  { DataId: 1399, MainGroup1: 94, MainGroup2: 18, a1: -232.56, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 643.51 },
  { DataId: 1400, MainGroup1: 19, MainGroup2: 20, a1: 117.33, a2: 0.9744, '1000a3': 0.0, Tmin: 298.15, Tmax: 419.95 },
  { DataId: 1401, MainGroup1: 20, MainGroup2: 19, a1: 368.09, a2: -1.1603, '1000a3': 0.0, Tmin: 298.15, Tmax: 419.95 },
  { DataId: 1402, MainGroup1: 19, MainGroup2: 21, a1: 67.28, a2: -0.9875, '1000a3': 0.0, Tmin: 293.1, Tmax: 398.15 },
  { DataId: 1403, MainGroup1: 21, MainGroup2: 19, a1: -182.16, a2: 1.6318, '1000a3': 0.0, Tmin: 293.1, Tmax: 398.15 },
  { DataId: 1404, MainGroup1: 19, MainGroup2: 22, a1: -143.84, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 398.1 },
  { DataId: 1405, MainGroup1: 22, MainGroup2: 19, a1: 106.57, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 398.1 },
  { DataId: 1406, MainGroup1: 19, MainGroup2: 23, a1: 20.47, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 346.05 },
  { DataId: 1407, MainGroup1: 23, MainGroup2: 19, a1: 177.0, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 346.05 },
  { DataId: 1408, MainGroup1: 19, MainGroup2: 24, a1: 51.9, a2: -0.5853, '1000a3': 0.0, Tmin: 293.1, Tmax: 352.58 },
  { DataId: 1409, MainGroup1: 24, MainGroup2: 19, a1: -47.33, a2: 2.0651, '1000a3': 0.0, Tmin: 293.1, Tmax: 352.58 },
  { DataId: 1410, MainGroup1: 19, MainGroup2: 25, a1: -501.29, a2: 15.2279, '1000a3': 0.0, Tmin: 293.15, Tmax: 402.63 },
  { DataId: 1411, MainGroup1: 25, MainGroup2: 19, a1: -719.43, a2: 44.9234, '1000a3': 0.0, Tmin: 293.15, Tmax: 402.63 },
  { DataId: 1412, MainGroup1: 19, MainGroup2: 26, a1: 125.93, a2: -0.6946, '1000a3': 0.0, Tmin: 293.15, Tmax: 398.17 },
  { DataId: 1413, MainGroup1: 26, MainGroup2: 19, a1: -177.66, a2: 0.9418, '1000a3': 0.0, Tmin: 293.15, Tmax: 398.17 },
  { DataId: 1414, MainGroup1: 19, MainGroup2: 28, a1: 532.89, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 293.65 },
  { DataId: 1415, MainGroup1: 28, MainGroup2: 19, a1: 364.87, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 293.65 },
  { DataId: 1416, MainGroup1: 19, MainGroup2: 29, a1: -127.16, a2: 0.0, '1000a3': 0.0, Tmin: 313.14, Tmax: 313.14 },
  { DataId: 1417, MainGroup1: 29, MainGroup2: 19, a1: 233.24, a2: 0.0, '1000a3': 0.0, Tmin: 313.14, Tmax: 313.14 },
  { DataId: 1418, MainGroup1: 19, MainGroup2: 31, a1: 380.1, a2: -1.1223, '1000a3': 0.0, Tmin: 301.13, Tmax: 323.14 },
  { DataId: 1419, MainGroup1: 31, MainGroup2: 19, a1: -9.18, a2: 1.099, '1000a3': 0.0, Tmin: 301.13, Tmax: 323.14 },
  { DataId: 1420, MainGroup1: 19, MainGroup2: 33, a1: -334.7, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 351.97 },
  { DataId: 1421, MainGroup1: 33, MainGroup2: 19, a1: 920.06, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 351.97 },
  { DataId: 1422, MainGroup1: 19, MainGroup2: 34, a1: -26.47, a2: -0.8055, '1000a3': 0.0, Tmin: 273.15, Tmax: 368.89 },
  { DataId: 1423, MainGroup1: 34, MainGroup2: 19, a1: 629.26, a2: -0.5256, '1000a3': 0.0, Tmin: 273.15, Tmax: 368.89 },
  { DataId: 1424, MainGroup1: 19, MainGroup2: 35, a1: 325.2, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 439.01 },
  { DataId: 1425, MainGroup1: 35, MainGroup2: 19, a1: -67.9, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 439.01 },
  { DataId: 1426, MainGroup1: 19, MainGroup2: 36, a1: 188.74, a2: 0.0, '1000a3': 0.0, Tmin: 312.83, Tmax: 369.81 },
  { DataId: 1427, MainGroup1: 36, MainGroup2: 19, a1: -104.5, a2: 0.0, '1000a3': 0.0, Tmin: 312.83, Tmax: 369.81 },
  { DataId: 1428, MainGroup1: 19, MainGroup2: 37, a1: -87.13, a2: -0.1347, '1000a3': 0.0, Tmin: 293.15, Tmax: 373.12 },
  { DataId: 1429, MainGroup1: 37, MainGroup2: 19, a1: -132.14, a2: 3.0168, '1000a3': 0.0, Tmin: 293.15, Tmax: 373.12 },
  { DataId: 1430, MainGroup1: 19, MainGroup2: 39, a1: 57.08, a2: -0.7417, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1431, MainGroup1: 39, MainGroup2: 19, a1: -160.48, a2: 1.0644, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1432, MainGroup1: 19, MainGroup2: 41, a1: -14.1, a2: 0.0, '1000a3': 0.0, Tmin: 354.4, Tmax: 370.74 },
  { DataId: 1433, MainGroup1: 41, MainGroup2: 19, a1: -34.75, a2: 0.0, '1000a3': 0.0, Tmin: 354.4, Tmax: 370.74 },
  {
    DataId: 1434,
    MainGroup1: 19,
    MainGroup2: 42,
    a1: 179.53,
    a2: -1.3591,
    '1000a3': 1.1999,
    Tmin: 293.1,
    Tmax: 373.12,
  },
  {
    DataId: 1435,
    MainGroup1: 42,
    MainGroup2: 19,
    a1: 1704.72,
    a2: -3.3048,
    '1000a3': 1.6451,
    Tmin: 293.1,
    Tmax: 373.12,
  },
  { DataId: 1436, MainGroup1: 19, MainGroup2: 43, a1: 130.48, a2: -0.7887, '1000a3': 0.0, Tmin: 298.15, Tmax: 333.15 },
  { DataId: 1437, MainGroup1: 43, MainGroup2: 19, a1: -231.01, a2: 1.2154, '1000a3': 0.0, Tmin: 298.15, Tmax: 333.15 },
  { DataId: 1438, MainGroup1: 19, MainGroup2: 44, a1: -315.9, a2: 0.0, '1000a3': 0.0, Tmin: 355.85, Tmax: 373.75 },
  { DataId: 1439, MainGroup1: 44, MainGroup2: 19, a1: 247.72, a2: 0.0, '1000a3': 0.0, Tmin: 355.85, Tmax: 373.75 },
  { DataId: 1440, MainGroup1: 19, MainGroup2: 45, a1: 128.6, a2: -0.9365, '1000a3': 0.0, Tmin: 181.74, Tmax: 333.15 },
  { DataId: 1441, MainGroup1: 45, MainGroup2: 19, a1: -492.94, a2: 2.3534, '1000a3': 0.0, Tmin: 181.74, Tmax: 333.15 },
  { DataId: 1442, MainGroup1: 19, MainGroup2: 49, a1: 304.24, a2: -0.71, '1000a3': 0.0, Tmin: 283.15, Tmax: 363.15 },
  { DataId: 1443, MainGroup1: 49, MainGroup2: 19, a1: -76.76, a2: 0.4413, '1000a3': 0.0, Tmin: 283.15, Tmax: 363.15 },
  { DataId: 1444, MainGroup1: 19, MainGroup2: 55, a1: 22.87, a2: 0.0, '1000a3': 0.0, Tmin: 313.15, Tmax: 333.15 },
  { DataId: 1445, MainGroup1: 55, MainGroup2: 19, a1: 4.54, a2: 0.0, '1000a3': 0.0, Tmin: 313.15, Tmax: 333.15 },
  { DataId: 1446, MainGroup1: 19, MainGroup2: 60, a1: 63.81, a2: 0.0, '1000a3': 0.0, Tmin: 321.34, Tmax: 395.12 },
  { DataId: 1447, MainGroup1: 60, MainGroup2: 19, a1: 87.94, a2: 0.0, '1000a3': 0.0, Tmin: 321.34, Tmax: 395.12 },
  { DataId: 1448, MainGroup1: 19, MainGroup2: 86, a1: -71.33, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 358.15 },
  { DataId: 1449, MainGroup1: 86, MainGroup2: 19, a1: 528.62, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 358.15 },
  { DataId: 1450, MainGroup1: 19, MainGroup2: 95, a1: -73.69, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 333.15 },
  { DataId: 1451, MainGroup1: 95, MainGroup2: 19, a1: 410.87, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 333.15 },
  { DataId: 1452, MainGroup1: 20, MainGroup2: 21, a1: -441.61, a2: 1.3453, '1000a3': 0.0, Tmin: 273.15, Tmax: 416.15 },
  { DataId: 1453, MainGroup1: 21, MainGroup2: 20, a1: 2010.14, a2: -3.6478, '1000a3': 0.0, Tmin: 273.15, Tmax: 416.15 },
  { DataId: 1454, MainGroup1: 20, MainGroup2: 22, a1: -168.99, a2: 0.0, '1000a3': 0.0, Tmin: 314.05, Tmax: 373.53 },
  { DataId: 1455, MainGroup1: 22, MainGroup2: 20, a1: 897.14, a2: 0.0, '1000a3': 0.0, Tmin: 314.05, Tmax: 373.53 },
  { DataId: 1456, MainGroup1: 20, MainGroup2: 24, a1: 1154.67, a2: -2.7982, '1000a3': 0.0, Tmin: 246.36, Tmax: 416.15 },
  { DataId: 1457, MainGroup1: 24, MainGroup2: 20, a1: 148.71, a2: 2.0335, '1000a3': 0.0, Tmin: 246.36, Tmax: 416.15 },
  { DataId: 1458, MainGroup1: 20, MainGroup2: 25, a1: -1276.77, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 417.85 },
  { DataId: 1459, MainGroup1: 25, MainGroup2: 20, a1: 374.57, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 417.85 },
  { DataId: 1460, MainGroup1: 20, MainGroup2: 26, a1: -119.23, a2: 0.0, '1000a3': 0.0, Tmin: 264.26, Tmax: 416.15 },
  { DataId: 1461, MainGroup1: 26, MainGroup2: 20, a1: 385.08, a2: 0.0, '1000a3': 0.0, Tmin: 264.26, Tmax: 416.15 },
  { DataId: 1462, MainGroup1: 20, MainGroup2: 30, a1: 235.81, a2: 0.0, '1000a3': 0.0, Tmin: 369.12, Tmax: 422.33 },
  { DataId: 1463, MainGroup1: 30, MainGroup2: 20, a1: -38.2, a2: 0.0, '1000a3': 0.0, Tmin: 369.12, Tmax: 422.33 },
  { DataId: 1464, MainGroup1: 20, MainGroup2: 31, a1: -242.06, a2: 0.0, '1000a3': 0.0, Tmin: 363.15, Tmax: 363.15 },
  { DataId: 1465, MainGroup1: 31, MainGroup2: 20, a1: 143.39, a2: 0.0, '1000a3': 0.0, Tmin: 363.15, Tmax: 363.15 },
  { DataId: 1466, MainGroup1: 20, MainGroup2: 32, a1: 266.02, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 398.12 },
  { DataId: 1467, MainGroup1: 32, MainGroup2: 20, a1: 657.7, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 398.12 },
  { DataId: 1468, MainGroup1: 20, MainGroup2: 33, a1: 40.1, a2: 0.0, '1000a3': 0.0, Tmin: 344.73, Tmax: 411.29 },
  { DataId: 1469, MainGroup1: 33, MainGroup2: 20, a1: 655.86, a2: 0.0, '1000a3': 0.0, Tmin: 344.73, Tmax: 411.29 },
  { DataId: 1470, MainGroup1: 20, MainGroup2: 35, a1: -296.06, a2: -0.6339, '1000a3': 0.0, Tmin: 293.14, Tmax: 398.65 },
  { DataId: 1471, MainGroup1: 35, MainGroup2: 20, a1: -376.15, a2: 0.6168, '1000a3': 0.0, Tmin: 293.14, Tmax: 398.65 },
  { DataId: 1472, MainGroup1: 20, MainGroup2: 37, a1: 974.75, a2: -2.139, '1000a3': 0.0, Tmin: 281.65, Tmax: 377.92 },
  { DataId: 1473, MainGroup1: 37, MainGroup2: 20, a1: 709.6, a2: 3.176, '1000a3': 0.0, Tmin: 281.65, Tmax: 377.92 },
  { DataId: 1474, MainGroup1: 20, MainGroup2: 39, a1: -421.59, a2: 0.0, '1000a3': 0.0, Tmin: 257.15, Tmax: 428.54 },
  { DataId: 1475, MainGroup1: 39, MainGroup2: 20, a1: 80.66, a2: 0.0, '1000a3': 0.0, Tmin: 257.15, Tmax: 428.54 },
  { DataId: 1476, MainGroup1: 20, MainGroup2: 41, a1: -736.41, a2: 0.0, '1000a3': 0.0, Tmin: 373.62, Tmax: 387.12 },
  { DataId: 1477, MainGroup1: 41, MainGroup2: 20, a1: 3073.19, a2: 0.0, '1000a3': 0.0, Tmin: 373.62, Tmax: 387.12 },
  { DataId: 1478, MainGroup1: 20, MainGroup2: 42, a1: 2053.05, a2: -5.2317, '1000a3': 0.0, Tmin: 252.15, Tmax: 483.69 },
  { DataId: 1479, MainGroup1: 42, MainGroup2: 20, a1: 557.46, a2: 1.1761, '1000a3': 0.0, Tmin: 252.15, Tmax: 483.69 },
  { DataId: 1480, MainGroup1: 20, MainGroup2: 43, a1: 215.3, a2: -2.2721, '1000a3': 0.0, Tmin: 165.25, Tmax: 376.55 },
  { DataId: 1481, MainGroup1: 43, MainGroup2: 20, a1: -2061.7, a2: 9.5658, '1000a3': 0.0, Tmin: 165.25, Tmax: 376.55 },
  { DataId: 1482, MainGroup1: 20, MainGroup2: 44, a1: 0.6, a2: -0.6722, '1000a3': 0.0, Tmin: 291.15, Tmax: 444.31 },
  { DataId: 1483, MainGroup1: 44, MainGroup2: 20, a1: 25.68, a2: 0.9946, '1000a3': 0.0, Tmin: 291.15, Tmax: 444.31 },
  { DataId: 1484, MainGroup1: 20, MainGroup2: 45, a1: 2354.76, a2: -6.3516, '1000a3': 0.0, Tmin: 243.96, Tmax: 416.15 },
  { DataId: 1485, MainGroup1: 45, MainGroup2: 20, a1: -747.76, a2: 3.6849, '1000a3': 0.0, Tmin: 243.96, Tmax: 416.15 },
  { DataId: 1486, MainGroup1: 20, MainGroup2: 47, a1: -3997.32, a2: 11.6942, '1000a3': 0.0, Tmin: 363.15, Tmax: 475.6 },
  { DataId: 1487, MainGroup1: 47, MainGroup2: 20, a1: 1555.25, a2: -5.3898, '1000a3': 0.0, Tmin: 363.15, Tmax: 475.6 },
  { DataId: 1488, MainGroup1: 20, MainGroup2: 48, a1: -500.73, a2: 0.0, '1000a3': 0.0, Tmin: 363.33, Tmax: 443.91 },
  { DataId: 1489, MainGroup1: 48, MainGroup2: 20, a1: -134.69, a2: 0.0, '1000a3': 0.0, Tmin: 363.33, Tmax: 443.91 },
  { DataId: 1490, MainGroup1: 20, MainGroup2: 54, a1: 69.34, a2: -0.5326, '1000a3': 0.0, Tmin: 298.15, Tmax: 428.15 },
  { DataId: 1491, MainGroup1: 54, MainGroup2: 20, a1: 235.92, a2: 0.8694, '1000a3': 0.0, Tmin: 298.15, Tmax: 428.15 },
  { DataId: 1492, MainGroup1: 20, MainGroup2: 60, a1: -350.18, a2: 0.0, '1000a3': 0.0, Tmin: 353.15, Tmax: 373.15 },
  { DataId: 1493, MainGroup1: 60, MainGroup2: 20, a1: 342.85, a2: 0.0, '1000a3': 0.0, Tmin: 353.15, Tmax: 373.15 },
  { DataId: 1494, MainGroup1: 20, MainGroup2: 75, a1: -222.28, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 434.14 },
  { DataId: 1495, MainGroup1: 75, MainGroup2: 20, a1: 583.58, a2: 0.0, '1000a3': 0.0, Tmin: 273.15, Tmax: 434.14 },
  { DataId: 1496, MainGroup1: 21, MainGroup2: 22, a1: 311.58, a2: -0.9206, '1000a3': 0.0, Tmin: 293.15, Tmax: 416.35 },
  { DataId: 1497, MainGroup1: 22, MainGroup2: 21, a1: -315.89, a2: 0.9131, '1000a3': 0.0, Tmin: 293.15, Tmax: 416.35 },
  {
    DataId: 1498,
    MainGroup1: 21,
    MainGroup2: 23,
    a1: 44.5,
    a2: 0.0392,
    '1000a3': -0.26975,
    Tmin: 283.15,
    Tmax: 355.53,
  },
  {
    DataId: 1499,
    MainGroup1: 23,
    MainGroup2: 21,
    a1: 28.59,
    a2: -0.6151,
    '1000a3': 0.78305,
    Tmin: 283.15,
    Tmax: 355.53,
  },
  { DataId: 1500, MainGroup1: 21, MainGroup2: 24, a1: 162.1, a2: -0.886, '1000a3': 0.0, Tmin: 283.15, Tmax: 354.98 },
  { DataId: 1501, MainGroup1: 24, MainGroup2: 21, a1: -190.39, a2: 1.2997, '1000a3': 0.0, Tmin: 283.15, Tmax: 354.98 },
  {
    DataId: 1502,
    MainGroup1: 21,
    MainGroup2: 25,
    a1: 4431.72,
    a2: -21.1473,
    '1000a3': 0.0,
    Tmin: 298.15,
    Tmax: 398.18,
  },
  { DataId: 1503, MainGroup1: 25, MainGroup2: 21, a1: -78.1, a2: -0.2599, '1000a3': 0.0, Tmin: 298.15, Tmax: 398.18 },
  { DataId: 1504, MainGroup1: 21, MainGroup2: 26, a1: -130.96, a2: 0.241, '1000a3': 0.0, Tmin: 293.15, Tmax: 398.13 },
  { DataId: 1505, MainGroup1: 26, MainGroup2: 21, a1: 253.92, a2: -0.6458, '1000a3': 0.0, Tmin: 293.15, Tmax: 398.13 },
  { DataId: 1506, MainGroup1: 21, MainGroup2: 27, a1: 3396.36, a2: -9.5587, '1000a3': 0.0, Tmin: 273.47, Tmax: 347.15 },
  { DataId: 1507, MainGroup1: 27, MainGroup2: 21, a1: 385.48, a2: 0.526, '1000a3': 0.0, Tmin: 273.47, Tmax: 347.15 },
  { DataId: 1508, MainGroup1: 21, MainGroup2: 28, a1: 407.36, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 293.15 },
  { DataId: 1509, MainGroup1: 28, MainGroup2: 21, a1: -173.59, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 293.15 },
  { DataId: 1510, MainGroup1: 21, MainGroup2: 30, a1: 576.05, a2: 0.0, '1000a3': 0.0, Tmin: 361.55, Tmax: 427.75 },
  { DataId: 1511, MainGroup1: 30, MainGroup2: 21, a1: -370.41, a2: 0.0, '1000a3': 0.0, Tmin: 361.55, Tmax: 427.75 },
  { DataId: 1512, MainGroup1: 21, MainGroup2: 33, a1: 37.68, a2: 0.3187, '1000a3': 0.0, Tmin: 135.1, Tmax: 368.45 },
  { DataId: 1513, MainGroup1: 33, MainGroup2: 21, a1: -16.95, a2: -0.4286, '1000a3': 0.0, Tmin: 135.1, Tmax: 368.45 },
  { DataId: 1514, MainGroup1: 21, MainGroup2: 35, a1: -87.94, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 436.85 },
  { DataId: 1515, MainGroup1: 35, MainGroup2: 21, a1: -245.49, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 436.85 },
  { DataId: 1516, MainGroup1: 21, MainGroup2: 36, a1: -87.03, a2: 0.0, '1000a3': 0.0, Tmin: 349.15, Tmax: 354.05 },
  { DataId: 1517, MainGroup1: 36, MainGroup2: 21, a1: -12.95, a2: 0.0, '1000a3': 0.0, Tmin: 349.15, Tmax: 354.05 },
  { DataId: 1518, MainGroup1: 21, MainGroup2: 37, a1: -73.54, a2: -0.7297, '1000a3': 0.0, Tmin: 293.15, Tmax: 373.12 },
  { DataId: 1519, MainGroup1: 37, MainGroup2: 21, a1: -27.68, a2: 2.3972, '1000a3': 0.0, Tmin: 293.15, Tmax: 373.12 },
  { DataId: 1520, MainGroup1: 21, MainGroup2: 39, a1: -990.49, a2: 3.5217, '1000a3': 0.0, Tmin: 293.15, Tmax: 432.25 },
  { DataId: 1521, MainGroup1: 39, MainGroup2: 21, a1: 761.04, a2: -2.8639, '1000a3': 0.0, Tmin: 293.15, Tmax: 432.25 },
  { DataId: 1522, MainGroup1: 21, MainGroup2: 42, a1: 20.99, a2: -0.4626, '1000a3': 0.0, Tmin: 279.65, Tmax: 368.43 },
  { DataId: 1523, MainGroup1: 42, MainGroup2: 21, a1: 292.12, a2: 0.023, '1000a3': 0.0, Tmin: 279.65, Tmax: 368.43 },
  { DataId: 1524, MainGroup1: 21, MainGroup2: 43, a1: -236.92, a2: 0.3828, '1000a3': 0.0, Tmin: 250.0, Tmax: 405.51 },
  { DataId: 1525, MainGroup1: 43, MainGroup2: 21, a1: 109.83, a2: -0.2406, '1000a3': 0.0, Tmin: 250.0, Tmax: 405.51 },
  { DataId: 1526, MainGroup1: 21, MainGroup2: 44, a1: 481.54, a2: 0.0, '1000a3': 0.0, Tmin: 303.08, Tmax: 363.72 },
  { DataId: 1527, MainGroup1: 44, MainGroup2: 21, a1: 51.82, a2: 0.0, '1000a3': 0.0, Tmin: 303.08, Tmax: 363.72 },
  { DataId: 1528, MainGroup1: 21, MainGroup2: 45, a1: -476.77, a2: 1.1818, '1000a3': 0.0, Tmin: 293.1, Tmax: 353.9 },
  { DataId: 1529, MainGroup1: 45, MainGroup2: 21, a1: 715.73, a2: -1.9129, '1000a3': 0.0, Tmin: 293.1, Tmax: 353.9 },
  { DataId: 1530, MainGroup1: 21, MainGroup2: 46, a1: -297.85, a2: 0.279, '1000a3': 0.0, Tmin: 293.15, Tmax: 463.65 },
  { DataId: 1531, MainGroup1: 46, MainGroup2: 21, a1: 421.83, a2: -1.3447, '1000a3': 0.0, Tmin: 293.15, Tmax: 463.65 },
  { DataId: 1532, MainGroup1: 21, MainGroup2: 48, a1: -479.54, a2: 0.8793, '1000a3': 0.0, Tmin: 298.15, Tmax: 323.15 },
  { DataId: 1533, MainGroup1: 48, MainGroup2: 21, a1: 1066.84, a2: -2.7528, '1000a3': 0.0, Tmin: 298.15, Tmax: 323.15 },
  { DataId: 1534, MainGroup1: 21, MainGroup2: 49, a1: 955.83, a2: -1.0001, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1535, MainGroup1: 49, MainGroup2: 21, a1: -151.04, a2: -0.4588, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1536, MainGroup1: 21, MainGroup2: 51, a1: 178.31, a2: 0.0, '1000a3': 0.0, Tmin: 318.04, Tmax: 353.23 },
  { DataId: 1537, MainGroup1: 51, MainGroup2: 21, a1: -309.49, a2: 0.0, '1000a3': 0.0, Tmin: 318.04, Tmax: 353.23 },
  { DataId: 1538, MainGroup1: 21, MainGroup2: 53, a1: -80.99, a2: 0.0, '1000a3': 0.0, Tmin: 308.09, Tmax: 368.33 },
  { DataId: 1539, MainGroup1: 53, MainGroup2: 21, a1: 29.8, a2: 0.0, '1000a3': 0.0, Tmin: 308.09, Tmax: 368.33 },
  { DataId: 1540, MainGroup1: 21, MainGroup2: 86, a1: 173.89, a2: -1.0337, '1000a3': 0.0, Tmin: 298.15, Tmax: 390.92 },
  { DataId: 1541, MainGroup1: 86, MainGroup2: 21, a1: -130.61, a2: 1.2076, '1000a3': 0.0, Tmin: 298.15, Tmax: 390.92 },
  { DataId: 1542, MainGroup1: 21, MainGroup2: 95, a1: -191.82, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 356.78 },
  { DataId: 1543, MainGroup1: 95, MainGroup2: 21, a1: -43.84, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 356.78 },
  { DataId: 1544, MainGroup1: 22, MainGroup2: 23, a1: -36.87, a2: 0.2392, '1000a3': 0.0, Tmin: 298.15, Tmax: 432.4 },
  { DataId: 1545, MainGroup1: 23, MainGroup2: 22, a1: 46.95, a2: -0.4153, '1000a3': 0.0, Tmin: 298.15, Tmax: 432.4 },
  { DataId: 1546, MainGroup1: 22, MainGroup2: 24, a1: 49.99, a2: -0.1517, '1000a3': 0.0, Tmin: 283.15, Tmax: 348.28 },
  { DataId: 1547, MainGroup1: 24, MainGroup2: 22, a1: 50.86, a2: -0.0487, '1000a3': 0.0, Tmin: 283.15, Tmax: 348.28 },
  {
    DataId: 1548,
    MainGroup1: 22,
    MainGroup2: 25,
    a1: 3396.56,
    a2: -18.1752,
    '1000a3': 0.0,
    Tmin: 230.68,
    Tmax: 398.07,
  },
  { DataId: 1549, MainGroup1: 25, MainGroup2: 22, a1: 435.72, a2: -1.96, '1000a3': 0.0, Tmin: 230.68, Tmax: 398.07 },
  { DataId: 1550, MainGroup1: 22, MainGroup2: 26, a1: 472.69, a2: -0.9976, '1000a3': 0.0, Tmin: 293.15, Tmax: 398.1 },
  { DataId: 1551, MainGroup1: 26, MainGroup2: 22, a1: -246.73, a2: 0.2628, '1000a3': 0.0, Tmin: 293.15, Tmax: 398.1 },
  { DataId: 1552, MainGroup1: 22, MainGroup2: 28, a1: -231.48, a2: 2.245, '1000a3': 0.0, Tmin: 283.15, Tmax: 308.14 },
  { DataId: 1553, MainGroup1: 28, MainGroup2: 22, a1: 285.62, a2: -1.7941, '1000a3': 0.0, Tmin: 283.15, Tmax: 308.14 },
  { DataId: 1554, MainGroup1: 22, MainGroup2: 30, a1: -109.88, a2: 0.0, '1000a3': 0.0, Tmin: 317.95, Tmax: 427.05 },
  { DataId: 1555, MainGroup1: 30, MainGroup2: 22, a1: 6.8, a2: 0.0, '1000a3': 0.0, Tmin: 317.95, Tmax: 427.05 },
  { DataId: 1556, MainGroup1: 22, MainGroup2: 32, a1: 169.98, a2: -0.3465, '1000a3': 0.0, Tmin: 283.15, Tmax: 303.15 },
  { DataId: 1557, MainGroup1: 32, MainGroup2: 22, a1: -21.39, a2: 0.0065, '1000a3': 0.0, Tmin: 283.15, Tmax: 303.15 },
  { DataId: 1558, MainGroup1: 22, MainGroup2: 33, a1: -116.44, a2: 1.4565, '1000a3': 0.0, Tmin: 293.15, Tmax: 409.85 },
  { DataId: 1559, MainGroup1: 33, MainGroup2: 22, a1: 53.13, a2: -0.9666, '1000a3': 0.0, Tmin: 293.15, Tmax: 409.85 },
  { DataId: 1560, MainGroup1: 22, MainGroup2: 35, a1: 102.17, a2: -0.3202, '1000a3': 0.0, Tmin: 298.14, Tmax: 454.55 },
  { DataId: 1561, MainGroup1: 35, MainGroup2: 22, a1: -482.48, a2: 0.7006, '1000a3': 0.0, Tmin: 298.14, Tmax: 454.55 },
  { DataId: 1562, MainGroup1: 22, MainGroup2: 36, a1: -227.03, a2: 0.0, '1000a3': 0.0, Tmin: 350.75, Tmax: 410.45 },
  { DataId: 1563, MainGroup1: 36, MainGroup2: 22, a1: 263.25, a2: 0.0, '1000a3': 0.0, Tmin: 350.75, Tmax: 410.45 },
  { DataId: 1564, MainGroup1: 22, MainGroup2: 37, a1: -179.97, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 414.1 },
  { DataId: 1565, MainGroup1: 37, MainGroup2: 22, a1: 315.26, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 414.1 },
  { DataId: 1566, MainGroup1: 22, MainGroup2: 39, a1: -234.09, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 422.85 },
  { DataId: 1567, MainGroup1: 39, MainGroup2: 22, a1: 160.11, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 422.85 },
  { DataId: 1568, MainGroup1: 22, MainGroup2: 42, a1: 3.91, a2: -0.1671, '1000a3': 0.0, Tmin: 283.15, Tmax: 415.12 },
  { DataId: 1569, MainGroup1: 42, MainGroup2: 22, a1: 183.27, a2: -0.2172, '1000a3': 0.0, Tmin: 283.15, Tmax: 415.12 },
  { DataId: 1570, MainGroup1: 22, MainGroup2: 43, a1: 617.37, a2: -2.317, '1000a3': 0.0, Tmin: 283.15, Tmax: 406.55 },
  { DataId: 1571, MainGroup1: 43, MainGroup2: 22, a1: -979.06, a2: 2.746, '1000a3': 0.0, Tmin: 283.15, Tmax: 406.55 },
  { DataId: 1572, MainGroup1: 22, MainGroup2: 45, a1: -21.54, a2: 0.9162, '1000a3': 0.0, Tmin: 297.85, Tmax: 408.4 },
  { DataId: 1573, MainGroup1: 45, MainGroup2: 22, a1: 12.95, a2: -0.6695, '1000a3': 0.0, Tmin: 297.85, Tmax: 408.4 },
  { DataId: 1574, MainGroup1: 22, MainGroup2: 46, a1: 367.1, a2: 0.0, '1000a3': 0.0, Tmin: 310.0, Tmax: 469.35 },
  { DataId: 1575, MainGroup1: 46, MainGroup2: 22, a1: -391.04, a2: 0.0, '1000a3': 0.0, Tmin: 310.0, Tmax: 469.35 },
  { DataId: 1576, MainGroup1: 22, MainGroup2: 48, a1: 102.8, a2: -0.0879, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1577, MainGroup1: 48, MainGroup2: 22, a1: -446.86, a2: 0.4276, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1578, MainGroup1: 22, MainGroup2: 49, a1: -1017.26, a2: 3.0909, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1579, MainGroup1: 49, MainGroup2: 22, a1: 894.51, a2: -2.9316, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1580, MainGroup1: 22, MainGroup2: 60, a1: -207.31, a2: 0.0, '1000a3': 0.0, Tmin: 303.15, Tmax: 418.62 },
  { DataId: 1581, MainGroup1: 60, MainGroup2: 22, a1: 484.36, a2: 0.0, '1000a3': 0.0, Tmin: 303.15, Tmax: 418.62 },
  { DataId: 1582, MainGroup1: 22, MainGroup2: 77, a1: 40.16, a2: -0.9151, '1000a3': 0.0, Tmin: 298.15, Tmax: 318.15 },
  { DataId: 1583, MainGroup1: 77, MainGroup2: 22, a1: -76.45, a2: 0.9335, '1000a3': 0.0, Tmin: 298.15, Tmax: 318.15 },
  { DataId: 1584, MainGroup1: 22, MainGroup2: 86, a1: -101.68, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 417.43 },
  { DataId: 1585, MainGroup1: 86, MainGroup2: 22, a1: 150.38, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 417.43 },
  { DataId: 1586, MainGroup1: 23, MainGroup2: 24, a1: -250.5, a2: 0.6042, '1000a3': 0.0, Tmin: 298.14, Tmax: 328.15 },
  { DataId: 1587, MainGroup1: 24, MainGroup2: 23, a1: 402.09, a2: -1.0991, '1000a3': 0.0, Tmin: 298.14, Tmax: 328.15 },
  { DataId: 1588, MainGroup1: 23, MainGroup2: 33, a1: 460.9, a2: 0.0, '1000a3': 0.0, Tmin: 333.13, Tmax: 367.85 },
  { DataId: 1589, MainGroup1: 33, MainGroup2: 23, a1: -308.83, a2: 0.0, '1000a3': 0.0, Tmin: 333.13, Tmax: 367.85 },
  { DataId: 1590, MainGroup1: 23, MainGroup2: 35, a1: 22.32, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 428.15 },
  { DataId: 1591, MainGroup1: 35, MainGroup2: 23, a1: -118.33, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 428.15 },
  { DataId: 1592, MainGroup1: 23, MainGroup2: 37, a1: 227.69, a2: 0.0, '1000a3': 0.0, Tmin: 321.74, Tmax: 346.23 },
  { DataId: 1593, MainGroup1: 37, MainGroup2: 23, a1: -93.92, a2: 0.0, '1000a3': 0.0, Tmin: 321.74, Tmax: 346.23 },
  { DataId: 1594, MainGroup1: 23, MainGroup2: 42, a1: -119.92, a2: -0.0055, '1000a3': 0.0, Tmin: 298.14, Tmax: 349.95 },
  { DataId: 1595, MainGroup1: 42, MainGroup2: 23, a1: 341.46, a2: -0.5895, '1000a3': 0.0, Tmin: 298.14, Tmax: 349.95 },
  { DataId: 1596, MainGroup1: 23, MainGroup2: 43, a1: 1416.43, a2: -4.2597, '1000a3': 0.0, Tmin: 298.14, Tmax: 344.95 },
  { DataId: 1597, MainGroup1: 43, MainGroup2: 23, a1: -803.43, a2: 2.2731, '1000a3': 0.0, Tmin: 298.14, Tmax: 344.95 },
  { DataId: 1598, MainGroup1: 23, MainGroup2: 46, a1: -82.7, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 439.35 },
  { DataId: 1599, MainGroup1: 46, MainGroup2: 23, a1: 30.05, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 439.35 },
  { DataId: 1600, MainGroup1: 23, MainGroup2: 56, a1: 370.58, a2: 0.0, '1000a3': 0.0, Tmin: 338.15, Tmax: 338.15 },
  { DataId: 1601, MainGroup1: 56, MainGroup2: 23, a1: -10.47, a2: 0.0, '1000a3': 0.0, Tmin: 338.15, Tmax: 338.15 },
  {
    DataId: 1602,
    MainGroup1: 24,
    MainGroup2: 25,
    a1: -185.41,
    a2: 6.0447,
    '1000a3': -39.905,
    Tmin: 293.15,
    Tmax: 400.05,
  },
  {
    DataId: 1603,
    MainGroup1: 25,
    MainGroup2: 24,
    a1: 933.74,
    a2: -6.6625,
    '1000a3': 11.186,
    Tmin: 293.15,
    Tmax: 400.05,
  },
  { DataId: 1604, MainGroup1: 24, MainGroup2: 26, a1: 438.45, a2: -0.5617, '1000a3': 0.0, Tmin: 293.1, Tmax: 349.08 },
  { DataId: 1605, MainGroup1: 26, MainGroup2: 24, a1: -66.7, a2: 0.1141, '1000a3': 0.0, Tmin: 293.1, Tmax: 349.08 },
  { DataId: 1606, MainGroup1: 24, MainGroup2: 27, a1: 3442.51, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 298.14 },
  { DataId: 1607, MainGroup1: 27, MainGroup2: 24, a1: -290.82, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 298.14 },
  { DataId: 1608, MainGroup1: 24, MainGroup2: 28, a1: 21.02, a2: 1.157, '1000a3': 0.0, Tmin: 283.15, Tmax: 348.05 },
  { DataId: 1609, MainGroup1: 28, MainGroup2: 24, a1: 51.11, a2: -0.9574, '1000a3': 0.0, Tmin: 283.15, Tmax: 348.05 },
  { DataId: 1610, MainGroup1: 24, MainGroup2: 30, a1: 886.96, a2: 0.3486, '1000a3': 0.0, Tmin: 300.15, Tmax: 397.63 },
  { DataId: 1611, MainGroup1: 30, MainGroup2: 24, a1: 118.8, a2: -0.8707, '1000a3': 0.0, Tmin: 300.15, Tmax: 397.63 },
  { DataId: 1612, MainGroup1: 24, MainGroup2: 32, a1: 219.36, a2: 0.358, '1000a3': 0.0, Tmin: 293.1, Tmax: 323.12 },
  { DataId: 1613, MainGroup1: 32, MainGroup2: 24, a1: 60.5, a2: -0.4736, '1000a3': 0.0, Tmin: 293.1, Tmax: 323.12 },
  { DataId: 1614, MainGroup1: 24, MainGroup2: 33, a1: 214.56, a2: -1.3077, '1000a3': 0.0, Tmin: 283.15, Tmax: 388.15 },
  { DataId: 1615, MainGroup1: 33, MainGroup2: 24, a1: -1328.49, a2: 6.6931, '1000a3': 0.0, Tmin: 283.15, Tmax: 388.15 },
  { DataId: 1616, MainGroup1: 24, MainGroup2: 35, a1: 317.97, a2: 0.7498, '1000a3': 0.0, Tmin: 249.26, Tmax: 313.15 },
  { DataId: 1617, MainGroup1: 35, MainGroup2: 24, a1: -41.82, a2: -0.7709, '1000a3': 0.0, Tmin: 249.26, Tmax: 313.15 },
  { DataId: 1618, MainGroup1: 24, MainGroup2: 36, a1: 933.26, a2: 0.0, '1000a3': 0.0, Tmin: 303.14, Tmax: 323.14 },
  { DataId: 1619, MainGroup1: 36, MainGroup2: 24, a1: -187.86, a2: 0.0, '1000a3': 0.0, Tmin: 303.14, Tmax: 323.14 },
  { DataId: 1620, MainGroup1: 24, MainGroup2: 37, a1: -269.86, a2: 0.0641, '1000a3': 0.0, Tmin: 293.15, Tmax: 359.35 },
  { DataId: 1621, MainGroup1: 37, MainGroup2: 24, a1: 759.32, a2: -0.9355, '1000a3': 0.0, Tmin: 293.15, Tmax: 359.35 },
  { DataId: 1622, MainGroup1: 24, MainGroup2: 38, a1: 207.96, a2: -0.6049, '1000a3': 0.0, Tmin: 278.68, Tmax: 323.06 },
  { DataId: 1623, MainGroup1: 38, MainGroup2: 24, a1: -109.44, a2: 0.0824, '1000a3': 0.0, Tmin: 278.68, Tmax: 323.06 },
  { DataId: 1624, MainGroup1: 24, MainGroup2: 39, a1: 535.71, a2: 0.0719, '1000a3': 0.0, Tmin: 298.15, Tmax: 333.12 },
  { DataId: 1625, MainGroup1: 39, MainGroup2: 24, a1: -159.06, a2: -0.1839, '1000a3': 0.0, Tmin: 298.15, Tmax: 333.12 },
  { DataId: 1626, MainGroup1: 24, MainGroup2: 42, a1: -59.04, a2: 0.653, '1000a3': 0.0, Tmin: 193.95, Tmax: 373.35 },
  { DataId: 1627, MainGroup1: 42, MainGroup2: 24, a1: 49.24, a2: -0.5087, '1000a3': 0.0, Tmin: 193.95, Tmax: 373.35 },
  { DataId: 1628, MainGroup1: 24, MainGroup2: 43, a1: 282.83, a2: 0.7084, '1000a3': 0.0, Tmin: 283.15, Tmax: 323.14 },
  { DataId: 1629, MainGroup1: 43, MainGroup2: 24, a1: -131.02, a2: -0.4775, '1000a3': 0.0, Tmin: 283.15, Tmax: 323.14 },
  {
    DataId: 1630,
    MainGroup1: 24,
    MainGroup2: 45,
    a1: 62.41,
    a2: -0.2215,
    '1000a3': 0.57988,
    Tmin: 283.15,
    Tmax: 347.82,
  },
  {
    DataId: 1631,
    MainGroup1: 45,
    MainGroup2: 24,
    a1: 3.12,
    a2: -0.0306,
    '1000a3': -0.25497,
    Tmin: 283.15,
    Tmax: 347.82,
  },
  { DataId: 1632, MainGroup1: 24, MainGroup2: 46, a1: -143.01, a2: 0.0, '1000a3': 0.0, Tmin: 310.0, Tmax: 328.15 },
  { DataId: 1633, MainGroup1: 46, MainGroup2: 24, a1: 957.44, a2: 0.0, '1000a3': 0.0, Tmin: 310.0, Tmax: 328.15 },
  {
    DataId: 1634,
    MainGroup1: 24,
    MainGroup2: 49,
    a1: 461.42,
    a2: -0.2226,
    '1000a3': 2.5038,
    Tmin: 298.14,
    Tmax: 328.09,
  },
  {
    DataId: 1635,
    MainGroup1: 49,
    MainGroup2: 24,
    a1: -191.7,
    a2: 0.4532,
    '1000a3': -1.3078,
    Tmin: 298.14,
    Tmax: 328.09,
  },
  { DataId: 1636, MainGroup1: 24, MainGroup2: 51, a1: -258.99, a2: 0.0, '1000a3': 0.0, Tmin: 311.84, Tmax: 343.08 },
  { DataId: 1637, MainGroup1: 51, MainGroup2: 24, a1: 358.5, a2: 0.0, '1000a3': 0.0, Tmin: 311.84, Tmax: 343.08 },
  { DataId: 1638, MainGroup1: 24, MainGroup2: 52, a1: 15.78, a2: 0.084, '1000a3': 0.0, Tmin: 298.14, Tmax: 343.15 },
  { DataId: 1639, MainGroup1: 52, MainGroup2: 24, a1: 51.96, a2: -0.6646, '1000a3': 0.0, Tmin: 298.14, Tmax: 343.15 },
  { DataId: 1640, MainGroup1: 24, MainGroup2: 53, a1: -158.67, a2: 0.917, '1000a3': 0.0, Tmin: 283.15, Tmax: 319.99 },
  { DataId: 1641, MainGroup1: 53, MainGroup2: 24, a1: 234.35, a2: -0.9326, '1000a3': 0.0, Tmin: 283.15, Tmax: 319.99 },
  { DataId: 1642, MainGroup1: 24, MainGroup2: 54, a1: 622.78, a2: 0.0, '1000a3': 0.0, Tmin: 313.81, Tmax: 398.42 },
  { DataId: 1643, MainGroup1: 54, MainGroup2: 24, a1: 119.82, a2: 0.0, '1000a3': 0.0, Tmin: 313.81, Tmax: 398.42 },
  { DataId: 1644, MainGroup1: 24, MainGroup2: 55, a1: -794.1, a2: 2.9082, '1000a3': 0.0, Tmin: 298.14, Tmax: 298.64 },
  { DataId: 1645, MainGroup1: 55, MainGroup2: 24, a1: 724.17, a2: -2.6004, '1000a3': 0.0, Tmin: 298.14, Tmax: 298.64 },
  { DataId: 1646, MainGroup1: 24, MainGroup2: 56, a1: 515.1, a2: 0.0284, '1000a3': 0.0, Tmin: 303.15, Tmax: 345.55 },
  { DataId: 1647, MainGroup1: 56, MainGroup2: 24, a1: -74.63, a2: 0.1344, '1000a3': 0.0, Tmin: 303.15, Tmax: 345.55 },
  { DataId: 1648, MainGroup1: 24, MainGroup2: 60, a1: 158.23, a2: 0.8419, '1000a3': 0.0, Tmin: 298.14, Tmax: 394.32 },
  { DataId: 1649, MainGroup1: 60, MainGroup2: 24, a1: 69.56, a2: -0.3408, '1000a3': 0.0, Tmin: 298.14, Tmax: 394.32 },
  { DataId: 1650, MainGroup1: 24, MainGroup2: 61, a1: -145.37, a2: 0.9883, '1000a3': 0.0, Tmin: 278.15, Tmax: 298.15 },
  { DataId: 1651, MainGroup1: 61, MainGroup2: 24, a1: 5.96, a2: -0.5825, '1000a3': 0.0, Tmin: 278.15, Tmax: 298.15 },
  { DataId: 1652, MainGroup1: 24, MainGroup2: 76, a1: 792.7, a2: -1.7069, '1000a3': 0.0, Tmin: 298.14, Tmax: 323.14 },
  { DataId: 1653, MainGroup1: 76, MainGroup2: 24, a1: -218.87, a2: 0.579, '1000a3': 0.0, Tmin: 298.14, Tmax: 323.14 },
  { DataId: 1654, MainGroup1: 24, MainGroup2: 77, a1: 111.96, a2: 0.8538, '1000a3': 0.0, Tmin: 283.15, Tmax: 303.14 },
  { DataId: 1655, MainGroup1: 77, MainGroup2: 24, a1: 7.3, a2: -0.8306, '1000a3': 0.0, Tmin: 283.15, Tmax: 303.14 },
  { DataId: 1656, MainGroup1: 24, MainGroup2: 80, a1: -299.41, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 1657, MainGroup1: 80, MainGroup2: 24, a1: 1865.3, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 1658, MainGroup1: 24, MainGroup2: 81, a1: -1144.73, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 1659, MainGroup1: 81, MainGroup2: 24, a1: 321.14, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 1660, MainGroup1: 24, MainGroup2: 82, a1: 1057.53, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 303.13 },
  { DataId: 1661, MainGroup1: 82, MainGroup2: 24, a1: -49.49, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 303.13 },
  { DataId: 1662, MainGroup1: 24, MainGroup2: 86, a1: 27.91, a2: 0.386, '1000a3': 0.0, Tmin: 283.15, Tmax: 393.11 },
  { DataId: 1663, MainGroup1: 86, MainGroup2: 24, a1: -9.88, a2: -0.3857, '1000a3': 0.0, Tmin: 283.15, Tmax: 393.11 },
  { DataId: 1664, MainGroup1: 24, MainGroup2: 95, a1: -9.1, a2: -0.3517, '1000a3': 0.0, Tmin: 298.14, Tmax: 356.03 },
  { DataId: 1665, MainGroup1: 95, MainGroup2: 24, a1: -12.28, a2: -0.0371, '1000a3': 0.0, Tmin: 298.14, Tmax: 356.03 },
  { DataId: 1666, MainGroup1: 25, MainGroup2: 26, a1: 1248.93, a2: -2.6419, '1000a3': 0.0, Tmin: 298.15, Tmax: 398.26 },
  { DataId: 1667, MainGroup1: 26, MainGroup2: 25, a1: 5034.67, a2: -20.754, '1000a3': 0.0, Tmin: 298.15, Tmax: 398.26 },
  { DataId: 1668, MainGroup1: 25, MainGroup2: 27, a1: 626.97, a2: 0.0, '1000a3': 0.0, Tmin: 352.98, Tmax: 475.61 },
  { DataId: 1669, MainGroup1: 27, MainGroup2: 25, a1: 110.1, a2: 0.0, '1000a3': 0.0, Tmin: 352.98, Tmax: 475.61 },
  { DataId: 1670, MainGroup1: 25, MainGroup2: 33, a1: 57.72, a2: 0.0, '1000a3': 0.0, Tmin: 290.65, Tmax: 373.12 },
  { DataId: 1671, MainGroup1: 33, MainGroup2: 25, a1: 1032.92, a2: 0.0, '1000a3': 0.0, Tmin: 290.65, Tmax: 373.12 },
  { DataId: 1672, MainGroup1: 25, MainGroup2: 35, a1: 916.67, a2: -2.2551, '1000a3': 0.0, Tmin: 298.15, Tmax: 457.39 },
  { DataId: 1673, MainGroup1: 35, MainGroup2: 25, a1: 253.49, a2: -1.3071, '1000a3': 0.0, Tmin: 298.15, Tmax: 457.39 },
  { DataId: 1674, MainGroup1: 25, MainGroup2: 36, a1: 112.5, a2: 0.0, '1000a3': 0.0, Tmin: 350.15, Tmax: 384.55 },
  { DataId: 1675, MainGroup1: 36, MainGroup2: 25, a1: 181.25, a2: 0.0, '1000a3': 0.0, Tmin: 350.15, Tmax: 384.55 },
  { DataId: 1676, MainGroup1: 25, MainGroup2: 37, a1: -323.22, a2: 0.0, '1000a3': 0.0, Tmin: 298.0, Tmax: 401.52 },
  { DataId: 1677, MainGroup1: 37, MainGroup2: 25, a1: -2115.25, a2: 0.0, '1000a3': 0.0, Tmin: 298.0, Tmax: 401.52 },
  { DataId: 1678, MainGroup1: 25, MainGroup2: 38, a1: 604.73, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 371.8 },
  { DataId: 1679, MainGroup1: 38, MainGroup2: 25, a1: 1262.0, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 371.8 },
  { DataId: 1680, MainGroup1: 25, MainGroup2: 39, a1: -287.39, a2: 1.1365, '1000a3': 0.0, Tmin: 194.32, Tmax: 419.45 },
  { DataId: 1681, MainGroup1: 39, MainGroup2: 25, a1: 36.53, a2: -3.659, '1000a3': 0.0, Tmin: 194.32, Tmax: 419.45 },
  {
    DataId: 1682,
    MainGroup1: 25,
    MainGroup2: 42,
    a1: 1751.31,
    a2: -4.0879,
    '1000a3': -2.6182,
    Tmin: 293.15,
    Tmax: 404.02,
  },
  {
    DataId: 1683,
    MainGroup1: 42,
    MainGroup2: 25,
    a1: 2685.61,
    a2: -16.7755,
    '1000a3': 1.1707,
    Tmin: 293.15,
    Tmax: 404.02,
  },
  { DataId: 1684, MainGroup1: 25, MainGroup2: 43, a1: -549.69, a2: 0.7018, '1000a3': 0.0, Tmin: 293.15, Tmax: 402.34 },
  {
    DataId: 1685,
    MainGroup1: 43,
    MainGroup2: 25,
    a1: 3705.15,
    a2: -10.9242,
    '1000a3': 0.0,
    Tmin: 293.15,
    Tmax: 402.34,
  },
  { DataId: 1686, MainGroup1: 25, MainGroup2: 45, a1: 2396.96, a2: -5.6261, '1000a3': 0.0, Tmin: 290.65, Tmax: 432.6 },
  { DataId: 1687, MainGroup1: 45, MainGroup2: 25, a1: 3082.14, a2: -17.6127, '1000a3': 0.0, Tmin: 290.65, Tmax: 432.6 },
  { DataId: 1688, MainGroup1: 25, MainGroup2: 49, a1: 240.7, a2: 0.8653, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1689, MainGroup1: 49, MainGroup2: 25, a1: 4118.74, a2: -15.161, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1690, MainGroup1: 25, MainGroup2: 51, a1: 237.5, a2: 0.0, '1000a3': 0.0, Tmin: 323.14, Tmax: 393.07 },
  { DataId: 1691, MainGroup1: 51, MainGroup2: 25, a1: 100.0, a2: 0.0, '1000a3': 0.0, Tmin: 323.14, Tmax: 393.07 },
  { DataId: 1692, MainGroup1: 25, MainGroup2: 60, a1: 13426.9, a2: 0.0, '1000a3': 0.0, Tmin: 391.32, Tmax: 401.42 },
  { DataId: 1693, MainGroup1: 60, MainGroup2: 25, a1: 2344.78, a2: 0.0, '1000a3': 0.0, Tmin: 391.32, Tmax: 401.42 },
  { DataId: 1694, MainGroup1: 26, MainGroup2: 27, a1: 363.26, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 459.15 },
  { DataId: 1695, MainGroup1: 27, MainGroup2: 26, a1: -369.29, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 459.15 },
  { DataId: 1696, MainGroup1: 26, MainGroup2: 28, a1: 158.07, a2: 3.1843, '1000a3': 0.0, Tmin: 293.15, Tmax: 318.6 },
  { DataId: 1697, MainGroup1: 28, MainGroup2: 26, a1: 657.39, a2: -2.2633, '1000a3': 0.0, Tmin: 293.15, Tmax: 318.6 },
  { DataId: 1698, MainGroup1: 26, MainGroup2: 32, a1: 415.37, a2: 0.2842, '1000a3': 0.0, Tmin: 284.15, Tmax: 338.15 },
  { DataId: 1699, MainGroup1: 32, MainGroup2: 26, a1: 2017.76, a2: -6.3766, '1000a3': 0.0, Tmin: 284.15, Tmax: 338.15 },
  { DataId: 1700, MainGroup1: 26, MainGroup2: 33, a1: 1097.58, a2: -3.0246, '1000a3': 0.0, Tmin: 293.1, Tmax: 393.08 },
  { DataId: 1701, MainGroup1: 33, MainGroup2: 26, a1: -1140.77, a2: 3.4676, '1000a3': 0.0, Tmin: 293.1, Tmax: 393.08 },
  { DataId: 1702, MainGroup1: 26, MainGroup2: 34, a1: -100.51, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 343.12 },
  { DataId: 1703, MainGroup1: 34, MainGroup2: 26, a1: 197.84, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 343.12 },
  { DataId: 1704, MainGroup1: 26, MainGroup2: 37, a1: -96.07, a2: 0.0, '1000a3': 0.0, Tmin: 353.42, Tmax: 369.42 },
  { DataId: 1705, MainGroup1: 37, MainGroup2: 26, a1: 1385.39, a2: 0.0, '1000a3': 0.0, Tmin: 353.42, Tmax: 369.42 },
  { DataId: 1706, MainGroup1: 26, MainGroup2: 39, a1: 611.97, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 313.2 },
  { DataId: 1707, MainGroup1: 39, MainGroup2: 26, a1: -343.93, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 313.2 },
  { DataId: 1708, MainGroup1: 26, MainGroup2: 42, a1: 139.99, a2: -0.6953, '1000a3': 0.0, Tmin: 291.15, Tmax: 413.21 },
  { DataId: 1709, MainGroup1: 42, MainGroup2: 26, a1: 579.1, a2: -0.3192, '1000a3': 0.0, Tmin: 291.15, Tmax: 413.21 },
  { DataId: 1710, MainGroup1: 26, MainGroup2: 43, a1: 53.75, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1711, MainGroup1: 43, MainGroup2: 26, a1: -47.09, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1712, MainGroup1: 26, MainGroup2: 47, a1: 324.62, a2: -1.1828, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1713, MainGroup1: 47, MainGroup2: 26, a1: 11.44, a2: 0.7866, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1714, MainGroup1: 26, MainGroup2: 52, a1: 7.62, a2: 0.0, '1000a3': 0.0, Tmin: 318.14, Tmax: 318.15 },
  { DataId: 1715, MainGroup1: 52, MainGroup2: 26, a1: 136.02, a2: 0.0, '1000a3': 0.0, Tmin: 318.14, Tmax: 318.15 },
  { DataId: 1716, MainGroup1: 26, MainGroup2: 60, a1: 656.36, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 353.1 },
  { DataId: 1717, MainGroup1: 60, MainGroup2: 26, a1: -230.85, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 353.1 },
  { DataId: 1718, MainGroup1: 26, MainGroup2: 86, a1: 30.56, a2: 0.0, '1000a3': 0.0, Tmin: 290.05, Tmax: 293.15 },
  { DataId: 1719, MainGroup1: 86, MainGroup2: 26, a1: 274.19, a2: 0.0, '1000a3': 0.0, Tmin: 290.05, Tmax: 293.15 },
  { DataId: 1720, MainGroup1: 27, MainGroup2: 32, a1: 3337.57, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 293.15 },
  { DataId: 1721, MainGroup1: 32, MainGroup2: 27, a1: 422.5, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 293.15 },
  { DataId: 1722, MainGroup1: 27, MainGroup2: 33, a1: -548.15, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 478.25 },
  { DataId: 1723, MainGroup1: 33, MainGroup2: 27, a1: 1024.68, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 478.25 },
  { DataId: 1724, MainGroup1: 27, MainGroup2: 35, a1: 177.27, a2: 0.0, '1000a3': 0.0, Tmin: 458.15, Tmax: 479.05 },
  { DataId: 1725, MainGroup1: 35, MainGroup2: 27, a1: -172.24, a2: 0.0, '1000a3': 0.0, Tmin: 458.15, Tmax: 479.05 },
  { DataId: 1726, MainGroup1: 27, MainGroup2: 36, a1: -423.04, a2: 0.0, '1000a3': 0.0, Tmin: 350.45, Tmax: 423.15 },
  { DataId: 1727, MainGroup1: 36, MainGroup2: 27, a1: 902.29, a2: 0.0, '1000a3': 0.0, Tmin: 350.45, Tmax: 423.15 },
  {
    DataId: 1728,
    MainGroup1: 27,
    MainGroup2: 42,
    a1: 3871.31,
    a2: -11.5364,
    '1000a3': 0.0,
    Tmin: 292.85,
    Tmax: 367.15,
  },
  {
    DataId: 1729,
    MainGroup1: 42,
    MainGroup2: 27,
    a1: 13569.05,
    a2: -32.7188,
    '1000a3': 0.0,
    Tmin: 292.85,
    Tmax: 367.15,
  },
  { DataId: 1730, MainGroup1: 28, MainGroup2: 32, a1: 242.9, a2: -0.5617, '1000a3': 0.0, Tmin: 283.15, Tmax: 318.93 },
  { DataId: 1731, MainGroup1: 32, MainGroup2: 28, a1: 222.24, a2: -0.2435, '1000a3': 0.0, Tmin: 283.15, Tmax: 318.93 },
  { DataId: 1732, MainGroup1: 28, MainGroup2: 37, a1: -93.31, a2: -0.1286, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1733, MainGroup1: 37, MainGroup2: 28, a1: 319.4, a2: 0.0342, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1734, MainGroup1: 28, MainGroup2: 42, a1: 27.18, a2: -0.89, '1000a3': 0.0, Tmin: 288.15, Tmax: 308.14 },
  { DataId: 1735, MainGroup1: 42, MainGroup2: 28, a1: 96.46, a2: 1.131, '1000a3': 0.0, Tmin: 288.15, Tmax: 308.14 },
  { DataId: 1736, MainGroup1: 28, MainGroup2: 43, a1: 166.56, a2: -1.0407, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1737, MainGroup1: 43, MainGroup2: 28, a1: 1.09, a2: 1.5927, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1738, MainGroup1: 28, MainGroup2: 45, a1: 29.11, a2: -0.7328, '1000a3': 0.0, Tmin: 273.15, Tmax: 353.15 },
  { DataId: 1739, MainGroup1: 45, MainGroup2: 28, a1: 220.69, a2: 0.3944, '1000a3': 0.0, Tmin: 273.15, Tmax: 353.15 },
  { DataId: 1740, MainGroup1: 28, MainGroup2: 86, a1: -145.93, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 318.14 },
  { DataId: 1741, MainGroup1: 86, MainGroup2: 28, a1: 318.1, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 318.14 },
  { DataId: 1742, MainGroup1: 29, MainGroup2: 35, a1: 581.79, a2: 0.0, '1000a3': 0.0, Tmin: 313.14, Tmax: 313.14 },
  { DataId: 1743, MainGroup1: 35, MainGroup2: 29, a1: -391.38, a2: 0.0, '1000a3': 0.0, Tmin: 313.14, Tmax: 313.14 },
  { DataId: 1744, MainGroup1: 29, MainGroup2: 39, a1: 362.17, a2: 0.0, '1000a3': 0.0, Tmin: 313.14, Tmax: 313.14 },
  { DataId: 1745, MainGroup1: 39, MainGroup2: 29, a1: -250.27, a2: 0.0, '1000a3': 0.0, Tmin: 313.14, Tmax: 313.14 },
  { DataId: 1746, MainGroup1: 29, MainGroup2: 42, a1: 12.47, a2: -0.4564, '1000a3': 0.0, Tmin: 283.15, Tmax: 343.15 },
  { DataId: 1747, MainGroup1: 42, MainGroup2: 29, a1: 407.08, a2: -0.2156, '1000a3': 0.0, Tmin: 283.15, Tmax: 343.15 },
  { DataId: 1748, MainGroup1: 29, MainGroup2: 46, a1: 34.49, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 323.09 },
  { DataId: 1749, MainGroup1: 46, MainGroup2: 29, a1: 80.63, a2: 0.0, '1000a3': 0.0, Tmin: 293.1, Tmax: 323.09 },
  { DataId: 1750, MainGroup1: 29, MainGroup2: 61, a1: 1226.89, a2: -2.919, '1000a3': 0.0, Tmin: 263.15, Tmax: 373.15 },
  { DataId: 1751, MainGroup1: 61, MainGroup2: 29, a1: -677.06, a2: 1.4184, '1000a3': 0.0, Tmin: 263.15, Tmax: 373.15 },
  { DataId: 1752, MainGroup1: 30, MainGroup2: 37, a1: -144.58, a2: 0.0, '1000a3': 0.0, Tmin: 360.33, Tmax: 431.61 },
  { DataId: 1753, MainGroup1: 37, MainGroup2: 30, a1: 1543.72, a2: 0.0, '1000a3': 0.0, Tmin: 360.33, Tmax: 431.61 },
  { DataId: 1754, MainGroup1: 30, MainGroup2: 42, a1: 109.8, a2: -0.7671, '1000a3': 0.0, Tmin: 293.15, Tmax: 421.05 },
  { DataId: 1755, MainGroup1: 42, MainGroup2: 30, a1: 867.4, a2: 0.1618, '1000a3': 0.0, Tmin: 293.15, Tmax: 421.05 },
  { DataId: 1756, MainGroup1: 30, MainGroup2: 44, a1: 778.78, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1757, MainGroup1: 44, MainGroup2: 30, a1: -384.29, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1758, MainGroup1: 30, MainGroup2: 45, a1: 72.26, a2: 0.0, '1000a3': 0.0, Tmin: 336.02, Tmax: 424.33 },
  { DataId: 1759, MainGroup1: 45, MainGroup2: 30, a1: -87.32, a2: 0.0, '1000a3': 0.0, Tmin: 336.02, Tmax: 424.33 },
  { DataId: 1760, MainGroup1: 30, MainGroup2: 77, a1: -271.09, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 403.15 },
  { DataId: 1761, MainGroup1: 77, MainGroup2: 30, a1: 2555.83, a2: 0.0, '1000a3': 0.0, Tmin: 293.14, Tmax: 403.15 },
  { DataId: 1762, MainGroup1: 30, MainGroup2: 86, a1: -203.84, a2: 0.0, '1000a3': 0.0, Tmin: 393.85, Tmax: 431.85 },
  { DataId: 1763, MainGroup1: 86, MainGroup2: 30, a1: 817.18, a2: 0.0, '1000a3': 0.0, Tmin: 393.85, Tmax: 431.85 },
  { DataId: 1764, MainGroup1: 31, MainGroup2: 35, a1: -100.86, a2: 0.092, '1000a3': 0.0, Tmin: 308.15, Tmax: 373.12 },
  { DataId: 1765, MainGroup1: 35, MainGroup2: 31, a1: -108.5, a2: 0.1069, '1000a3': 0.0, Tmin: 308.15, Tmax: 373.12 },
  { DataId: 1766, MainGroup1: 31, MainGroup2: 39, a1: 485.08, a2: 0.0, '1000a3': 0.0, Tmin: 373.12, Tmax: 373.12 },
  { DataId: 1767, MainGroup1: 39, MainGroup2: 31, a1: -292.57, a2: 0.0, '1000a3': 0.0, Tmin: 373.12, Tmax: 373.12 },
  { DataId: 1768, MainGroup1: 31, MainGroup2: 47, a1: 11.14, a2: 0.8931, '1000a3': 0.0, Tmin: 405.65, Tmax: 413.15 },
  { DataId: 1769, MainGroup1: 47, MainGroup2: 31, a1: 99.44, a2: -0.9771, '1000a3': 0.0, Tmin: 405.65, Tmax: 413.15 },
  { DataId: 1770, MainGroup1: 31, MainGroup2: 60, a1: -2434.21, a2: 7.1502, '1000a3': 0.0, Tmin: 334.65, Tmax: 458.55 },
  { DataId: 1771, MainGroup1: 60, MainGroup2: 31, a1: 1174.12, a2: -3.647, '1000a3': 0.0, Tmin: 334.65, Tmax: 458.55 },
  { DataId: 1772, MainGroup1: 31, MainGroup2: 75, a1: -975.5, a2: 2.4197, '1000a3': 0.0, Tmin: 368.12, Tmax: 418.13 },
  { DataId: 1773, MainGroup1: 75, MainGroup2: 31, a1: 1964.99, a2: 5.1674, '1000a3': 0.0, Tmin: 368.12, Tmax: 418.13 },
  { DataId: 1774, MainGroup1: 31, MainGroup2: 76, a1: 60.15, a2: 0.3575, '1000a3': 0.0, Tmin: 338.12, Tmax: 418.13 },
  { DataId: 1775, MainGroup1: 76, MainGroup2: 31, a1: -515.06, a2: 1.7993, '1000a3': 0.0, Tmin: 338.12, Tmax: 418.13 },
  { DataId: 1776, MainGroup1: 32, MainGroup2: 33, a1: -37.87, a2: -0.4366, '1000a3': 0.0, Tmin: 217.14, Tmax: 303.15 },
  { DataId: 1777, MainGroup1: 33, MainGroup2: 32, a1: 298.62, a2: 0.0727, '1000a3': 0.0, Tmin: 217.14, Tmax: 303.15 },
  { DataId: 1778, MainGroup1: 32, MainGroup2: 42, a1: 631.27, a2: -1.6164, '1000a3': 0.0, Tmin: 298.14, Tmax: 298.15 },
  { DataId: 1779, MainGroup1: 42, MainGroup2: 32, a1: 221.32, a2: 0.1997, '1000a3': 0.0, Tmin: 298.14, Tmax: 298.15 },
  { DataId: 1780, MainGroup1: 32, MainGroup2: 45, a1: -516.09, a2: 1.7838, '1000a3': 0.0, Tmin: 298.09, Tmax: 308.13 },
  { DataId: 1781, MainGroup1: 45, MainGroup2: 32, a1: 554.33, a2: -1.6052, '1000a3': 0.0, Tmin: 298.09, Tmax: 308.13 },
  { DataId: 1782, MainGroup1: 32, MainGroup2: 54, a1: 289.95, a2: 0.0, '1000a3': 0.0, Tmin: 313.14, Tmax: 413.12 },
  { DataId: 1783, MainGroup1: 54, MainGroup2: 32, a1: 323.21, a2: 0.0, '1000a3': 0.0, Tmin: 313.14, Tmax: 413.12 },
  { DataId: 1784, MainGroup1: 33, MainGroup2: 35, a1: -105.44, a2: 0.2154, '1000a3': 0.0, Tmin: 233.46, Tmax: 308.14 },
  { DataId: 1785, MainGroup1: 35, MainGroup2: 33, a1: -106.52, a2: -0.1308, '1000a3': 0.0, Tmin: 233.46, Tmax: 308.14 },
  { DataId: 1786, MainGroup1: 33, MainGroup2: 37, a1: -364.06, a2: 0.0, '1000a3': 0.0, Tmin: 361.15, Tmax: 371.45 },
  { DataId: 1787, MainGroup1: 37, MainGroup2: 33, a1: 572.17, a2: 0.0, '1000a3': 0.0, Tmin: 361.15, Tmax: 371.45 },
  { DataId: 1788, MainGroup1: 33, MainGroup2: 41, a1: 549.91, a2: -1.7055, '1000a3': 0.0, Tmin: 303.15, Tmax: 370.82 },
  { DataId: 1789, MainGroup1: 41, MainGroup2: 33, a1: 60.62, a2: -0.7018, '1000a3': 0.0, Tmin: 303.15, Tmax: 370.82 },
  { DataId: 1790, MainGroup1: 33, MainGroup2: 42, a1: 25.78, a2: -0.4237, '1000a3': 0.0, Tmin: 249.77, Tmax: 352.5 },
  { DataId: 1791, MainGroup1: 42, MainGroup2: 33, a1: 214.43, a2: 0.2738, '1000a3': 0.0, Tmin: 249.77, Tmax: 352.5 },
  { DataId: 1792, MainGroup1: 33, MainGroup2: 43, a1: -186.4, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1793, MainGroup1: 43, MainGroup2: 33, a1: 265.42, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1794, MainGroup1: 33, MainGroup2: 45, a1: 43.09, a2: -0.4089, '1000a3': 0.0, Tmin: 298.15, Tmax: 400.8 },
  { DataId: 1795, MainGroup1: 45, MainGroup2: 33, a1: -79.14, a2: 0.594, '1000a3': 0.0, Tmin: 298.15, Tmax: 400.8 },
  { DataId: 1796, MainGroup1: 34, MainGroup2: 39, a1: -336.86, a2: 9.6962, '1000a3': 0.0, Tmin: 283.15, Tmax: 343.12 },
  { DataId: 1797, MainGroup1: 39, MainGroup2: 34, a1: -3.25, a2: -1.24, '1000a3': 0.0, Tmin: 283.15, Tmax: 343.12 },
  { DataId: 1798, MainGroup1: 35, MainGroup2: 37, a1: 856.1, a2: -3.4752, '1000a3': 0.0, Tmin: 298.0, Tmax: 445.85 },
  { DataId: 1799, MainGroup1: 37, MainGroup2: 35, a1: -2214.44, a2: 8.5047, '1000a3': 0.0, Tmin: 298.0, Tmax: 445.85 },
  { DataId: 1800, MainGroup1: 35, MainGroup2: 39, a1: -135.75, a2: 0.0841, '1000a3': 0.0, Tmin: 298.15, Tmax: 373.15 },
  { DataId: 1801, MainGroup1: 39, MainGroup2: 35, a1: 230.69, a2: -0.4981, '1000a3': 0.0, Tmin: 298.15, Tmax: 373.15 },
  { DataId: 1802, MainGroup1: 35, MainGroup2: 45, a1: -281.08, a2: -0.088, '1000a3': 0.0, Tmin: 293.15, Tmax: 333.15 },
  { DataId: 1803, MainGroup1: 45, MainGroup2: 35, a1: -35.79, a2: 0.3782, '1000a3': 0.0, Tmin: 293.15, Tmax: 333.15 },
  { DataId: 1804, MainGroup1: 35, MainGroup2: 49, a1: 203.2, a2: 0.0, '1000a3': 0.0, Tmin: 323.14, Tmax: 323.14 },
  { DataId: 1805, MainGroup1: 49, MainGroup2: 35, a1: -177.48, a2: 0.0, '1000a3': 0.0, Tmin: 323.14, Tmax: 323.14 },
  { DataId: 1806, MainGroup1: 35, MainGroup2: 82, a1: -1301.69, a2: 4.431, '1000a3': 0.0, Tmin: 323.14, Tmax: 323.14 },
  { DataId: 1807, MainGroup1: 82, MainGroup2: 35, a1: 62.35, a2: -0.9957, '1000a3': 0.0, Tmin: 323.14, Tmax: 323.14 },
  { DataId: 1808, MainGroup1: 35, MainGroup2: 86, a1: -364.64, a2: 1.3459, '1000a3': 0.0, Tmin: 298.0, Tmax: 444.95 },
  { DataId: 1809, MainGroup1: 86, MainGroup2: 35, a1: 415.61, a2: -1.385, '1000a3': 0.0, Tmin: 298.0, Tmax: 444.95 },
  { DataId: 1810, MainGroup1: 36, MainGroup2: 37, a1: -309.25, a2: 0.0, '1000a3': 0.0, Tmin: 288.24, Tmax: 344.83 },
  { DataId: 1811, MainGroup1: 37, MainGroup2: 36, a1: 4283.26, a2: 0.0, '1000a3': 0.0, Tmin: 288.24, Tmax: 344.83 },
  { DataId: 1812, MainGroup1: 36, MainGroup2: 39, a1: -198.85, a2: 0.0, '1000a3': 0.0, Tmin: 350.77, Tmax: 418.16 },
  { DataId: 1813, MainGroup1: 39, MainGroup2: 36, a1: 178.21, a2: 0.0, '1000a3': 0.0, Tmin: 350.77, Tmax: 418.16 },
  { DataId: 1814, MainGroup1: 36, MainGroup2: 86, a1: -97.16, a2: 0.0, '1000a3': 0.0, Tmin: 303.14, Tmax: 323.14 },
  { DataId: 1815, MainGroup1: 86, MainGroup2: 36, a1: 696.94, a2: 0.0, '1000a3': 0.0, Tmin: 303.14, Tmax: 323.14 },
  { DataId: 1816, MainGroup1: 37, MainGroup2: 39, a1: -1116.2, a2: 5.7463, '1000a3': 0.0, Tmin: 298.0, Tmax: 415.5 },
  { DataId: 1817, MainGroup1: 39, MainGroup2: 37, a1: -584.44, a2: 0.7055, '1000a3': 0.0, Tmin: 298.0, Tmax: 415.5 },
  { DataId: 1818, MainGroup1: 37, MainGroup2: 41, a1: -211.1, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1819, MainGroup1: 41, MainGroup2: 37, a1: 516.5, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1820, MainGroup1: 37, MainGroup2: 42, a1: 93.18, a2: -1.0623, '1000a3': 2.7695, Tmin: 298.0, Tmax: 359.62 },
  { DataId: 1821, MainGroup1: 42, MainGroup2: 37, a1: -3.2, a2: 0.3238, '1000a3': -1.1202, Tmin: 298.0, Tmax: 359.62 },
  { DataId: 1822, MainGroup1: 37, MainGroup2: 43, a1: -2460.67, a2: 8.1707, '1000a3': 0.0, Tmin: 298.15, Tmax: 356.55 },
  { DataId: 1823, MainGroup1: 43, MainGroup2: 37, a1: 2318.16, a2: -7.9936, '1000a3': 0.0, Tmin: 298.15, Tmax: 356.55 },
  { DataId: 1824, MainGroup1: 37, MainGroup2: 45, a1: 185.82, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1825, MainGroup1: 45, MainGroup2: 37, a1: -139.0, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1826, MainGroup1: 37, MainGroup2: 46, a1: 256.19, a2: -1.7037, '1000a3': 0.0, Tmin: 263.15, Tmax: 458.55 },
  { DataId: 1827, MainGroup1: 46, MainGroup2: 37, a1: -235.96, a2: 1.8651, '1000a3': 0.0, Tmin: 263.15, Tmax: 458.55 },
  { DataId: 1828, MainGroup1: 37, MainGroup2: 60, a1: 803.54, a2: -0.6225, '1000a3': 0.0, Tmin: 298.15, Tmax: 357.98 },
  { DataId: 1829, MainGroup1: 60, MainGroup2: 37, a1: -203.28, a2: 0.2437, '1000a3': 0.0, Tmin: 298.15, Tmax: 357.98 },
  { DataId: 1830, MainGroup1: 37, MainGroup2: 86, a1: -911.04, a2: 4.8273, '1000a3': 0.0, Tmin: 298.15, Tmax: 392.73 },
  { DataId: 1831, MainGroup1: 86, MainGroup2: 37, a1: -106.41, a2: -0.5523, '1000a3': 0.0, Tmin: 298.15, Tmax: 392.73 },
  { DataId: 1832, MainGroup1: 38, MainGroup2: 39, a1: -31.45, a2: 0.0, '1000a3': 0.0, Tmin: 360.95, Tmax: 416.95 },
  { DataId: 1833, MainGroup1: 39, MainGroup2: 38, a1: 12.79, a2: 0.0, '1000a3': 0.0, Tmin: 360.95, Tmax: 416.95 },
  { DataId: 1834, MainGroup1: 38, MainGroup2: 40, a1: -57.38, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1835, MainGroup1: 40, MainGroup2: 38, a1: 110.4, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1836, MainGroup1: 38, MainGroup2: 42, a1: -86.47, a2: 0.1388, '1000a3': 0.0, Tmin: 283.1, Tmax: 371.89 },
  { DataId: 1837, MainGroup1: 42, MainGroup2: 38, a1: 206.0, a2: -0.6056, '1000a3': 0.0, Tmin: 283.1, Tmax: 371.89 },
  { DataId: 1838, MainGroup1: 38, MainGroup2: 43, a1: -178.31, a2: 0.2229, '1000a3': 0.0, Tmin: 288.15, Tmax: 333.13 },
  { DataId: 1839, MainGroup1: 43, MainGroup2: 38, a1: 242.57, a2: -0.4865, '1000a3': 0.0, Tmin: 288.15, Tmax: 333.13 },
  { DataId: 1840, MainGroup1: 38, MainGroup2: 46, a1: -16.92, a2: 0.0, '1000a3': 0.0, Tmin: 360.75, Tmax: 425.15 },
  { DataId: 1841, MainGroup1: 46, MainGroup2: 38, a1: 4217.65, a2: 0.0, '1000a3': 0.0, Tmin: 360.75, Tmax: 425.15 },
  { DataId: 1842, MainGroup1: 39, MainGroup2: 42, a1: 127.33, a2: -0.7975, '1000a3': 0.0, Tmin: 283.15, Tmax: 423.5 },
  { DataId: 1843, MainGroup1: 42, MainGroup2: 39, a1: 312.53, a2: 0.831, '1000a3': 0.0, Tmin: 283.15, Tmax: 423.5 },
  { DataId: 1844, MainGroup1: 39, MainGroup2: 43, a1: -186.5, a2: 0.447, '1000a3': 0.0, Tmin: 294.15, Tmax: 387.45 },
  { DataId: 1845, MainGroup1: 43, MainGroup2: 39, a1: 195.05, a2: -0.3646, '1000a3': 0.0, Tmin: 294.15, Tmax: 387.45 },
  { DataId: 1846, MainGroup1: 39, MainGroup2: 44, a1: 759.86, a2: -3.0047, '1000a3': 0.0, Tmin: 298.14, Tmax: 436.35 },
  { DataId: 1847, MainGroup1: 44, MainGroup2: 39, a1: -896.46, a2: 1.5408, '1000a3': 0.0, Tmin: 298.14, Tmax: 436.35 },
  { DataId: 1848, MainGroup1: 39, MainGroup2: 45, a1: -318.65, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 408.9 },
  { DataId: 1849, MainGroup1: 45, MainGroup2: 39, a1: 733.32, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 408.9 },
  { DataId: 1850, MainGroup1: 39, MainGroup2: 49, a1: -18.38, a2: -0.4503, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1851, MainGroup1: 49, MainGroup2: 39, a1: -2.28, a2: 0.6011, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1852, MainGroup1: 39, MainGroup2: 52, a1: 136.36, a2: -1.4648, '1000a3': 0.0, Tmin: 358.83, Tmax: 406.85 },
  {
    DataId: 1853,
    MainGroup1: 52,
    MainGroup2: 39,
    a1: 32576.11,
    a2: -49.7076,
    '1000a3': 0.0,
    Tmin: 358.83,
    Tmax: 406.85,
  },
  { DataId: 1854, MainGroup1: 39, MainGroup2: 57, a1: -130.99, a2: 0.0, '1000a3': 0.0, Tmin: 378.07, Tmax: 398.07 },
  { DataId: 1855, MainGroup1: 57, MainGroup2: 39, a1: 503.19, a2: 0.0, '1000a3': 0.0, Tmin: 378.07, Tmax: 398.07 },
  { DataId: 1856, MainGroup1: 39, MainGroup2: 92, a1: 295.76, a2: 0.0, '1000a3': 0.0, Tmin: 373.12, Tmax: 393.07 },
  { DataId: 1857, MainGroup1: 92, MainGroup2: 39, a1: 137.1, a2: 0.0, '1000a3': 0.0, Tmin: 373.12, Tmax: 393.07 },
  { DataId: 1858, MainGroup1: 40, MainGroup2: 42, a1: -28.73, a2: -0.1121, '1000a3': 0.0, Tmin: 297.6, Tmax: 357.0 },
  { DataId: 1859, MainGroup1: 42, MainGroup2: 40, a1: 53.87, a2: 0.3669, '1000a3': 0.0, Tmin: 297.6, Tmax: 357.0 },
  { DataId: 1860, MainGroup1: 41, MainGroup2: 42, a1: -1389.68, a2: 5.3983, '1000a3': 0.0, Tmin: 298.15, Tmax: 413.72 },
  { DataId: 1861, MainGroup1: 42, MainGroup2: 41, a1: 1663.51, a2: -4.8623, '1000a3': 0.0, Tmin: 298.15, Tmax: 413.72 },
  { DataId: 1862, MainGroup1: 41, MainGroup2: 55, a1: -4120.61, a2: 17.519, '1000a3': 0.0, Tmin: 298.15, Tmax: 428.65 },
  { DataId: 1863, MainGroup1: 55, MainGroup2: 41, a1: 181.24, a2: -1.397, '1000a3': 0.0, Tmin: 298.15, Tmax: 428.65 },
  {
    DataId: 1864,
    MainGroup1: 42,
    MainGroup2: 43,
    a1: 119.59,
    a2: 0.1237,
    '1000a3': 0.22023,
    Tmin: 262.16,
    Tmax: 382.14,
  },
  {
    DataId: 1865,
    MainGroup1: 43,
    MainGroup2: 42,
    a1: 90.32,
    a2: -0.4718,
    '1000a3': -0.0014371,
    Tmin: 262.16,
    Tmax: 382.14,
  },
  { DataId: 1866, MainGroup1: 42, MainGroup2: 45, a1: 167.84, a2: -0.1349, '1000a3': 0.0, Tmin: 198.2, Tmax: 347.05 },
  { DataId: 1867, MainGroup1: 45, MainGroup2: 42, a1: -52.81, a2: -0.0505, '1000a3': 0.0, Tmin: 198.2, Tmax: 347.05 },
  { DataId: 1868, MainGroup1: 42, MainGroup2: 46, a1: 171.22, a2: 0.1324, '1000a3': 0.0, Tmin: 253.11, Tmax: 463.8 },
  { DataId: 1869, MainGroup1: 46, MainGroup2: 42, a1: 407.87, a2: -1.2285, '1000a3': 0.0, Tmin: 253.11, Tmax: 463.8 },
  { DataId: 1870, MainGroup1: 42, MainGroup2: 47, a1: 3008.8, a2: -2.5544, '1000a3': 0.0, Tmin: 298.15, Tmax: 398.15 },
  { DataId: 1871, MainGroup1: 47, MainGroup2: 42, a1: 190.65, a2: -1.0042, '1000a3': 0.0, Tmin: 298.15, Tmax: 398.15 },
  { DataId: 1872, MainGroup1: 42, MainGroup2: 48, a1: 501.05, a2: 0.4831, '1000a3': 0.0, Tmin: 298.1, Tmax: 375.45 },
  { DataId: 1873, MainGroup1: 48, MainGroup2: 42, a1: 194.82, a2: -1.1672, '1000a3': 0.0, Tmin: 298.1, Tmax: 375.45 },
  { DataId: 1874, MainGroup1: 42, MainGroup2: 49, a1: -263.6, a2: 2.3746, '1000a3': 0.0, Tmin: 298.1, Tmax: 338.08 },
  { DataId: 1875, MainGroup1: 49, MainGroup2: 42, a1: 155.16, a2: -0.8647, '1000a3': 0.0, Tmin: 298.1, Tmax: 338.08 },
  { DataId: 1876, MainGroup1: 42, MainGroup2: 52, a1: 277.22, a2: 0.4697, '1000a3': 0.0, Tmin: 318.14, Tmax: 348.15 },
  { DataId: 1877, MainGroup1: 52, MainGroup2: 42, a1: -37.34, a2: -0.8395, '1000a3': 0.0, Tmin: 318.14, Tmax: 348.15 },
  { DataId: 1878, MainGroup1: 42, MainGroup2: 54, a1: 835.79, a2: 0.0, '1000a3': 0.0, Tmin: 300.04, Tmax: 374.62 },
  { DataId: 1879, MainGroup1: 54, MainGroup2: 42, a1: 116.48, a2: 0.0, '1000a3': 0.0, Tmin: 300.04, Tmax: 374.62 },
  { DataId: 1880, MainGroup1: 42, MainGroup2: 55, a1: 110.7, a2: 0.9656, '1000a3': 0.0, Tmin: 283.15, Tmax: 398.09 },
  { DataId: 1881, MainGroup1: 55, MainGroup2: 42, a1: 131.93, a2: -0.9816, '1000a3': 0.0, Tmin: 283.15, Tmax: 398.09 },
  { DataId: 1882, MainGroup1: 42, MainGroup2: 56, a1: 1219.92, a2: -0.9706, '1000a3': 0.0, Tmin: 288.15, Tmax: 545.11 },
  { DataId: 1883, MainGroup1: 56, MainGroup2: 42, a1: 328.01, a2: -1.1028, '1000a3': 0.0, Tmin: 288.15, Tmax: 545.11 },
  { DataId: 1884, MainGroup1: 42, MainGroup2: 60, a1: -126.54, a2: 2.1436, '1000a3': 0.0, Tmin: 293.15, Tmax: 421.13 },
  { DataId: 1885, MainGroup1: 60, MainGroup2: 42, a1: 366.36, a2: -1.3157, '1000a3': 0.0, Tmin: 293.15, Tmax: 421.13 },
  { DataId: 1886, MainGroup1: 42, MainGroup2: 62, a1: 3971.74, a2: 6.1859, '1000a3': 0.0, Tmin: 298.09, Tmax: 423.15 },
  { DataId: 1887, MainGroup1: 62, MainGroup2: 42, a1: -690.28, a2: 2.5175, '1000a3': 0.0, Tmin: 298.09, Tmax: 423.15 },
  { DataId: 1888, MainGroup1: 42, MainGroup2: 63, a1: -691.82, a2: 0.0, '1000a3': 0.0, Tmin: 387.5, Tmax: 442.3 },
  { DataId: 1889, MainGroup1: 63, MainGroup2: 42, a1: 1422.27, a2: 0.0, '1000a3': 0.0, Tmin: 387.5, Tmax: 442.3 },
  { DataId: 1890, MainGroup1: 42, MainGroup2: 76, a1: 211.29, a2: 0.4534, '1000a3': 0.0, Tmin: 298.34, Tmax: 331.15 },
  { DataId: 1891, MainGroup1: 76, MainGroup2: 42, a1: 273.75, a2: -1.0527, '1000a3': 0.0, Tmin: 298.34, Tmax: 331.15 },
  { DataId: 1892, MainGroup1: 42, MainGroup2: 77, a1: 175.99, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 403.15 },
  { DataId: 1893, MainGroup1: 77, MainGroup2: 42, a1: 112.49, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 403.15 },
  { DataId: 1894, MainGroup1: 42, MainGroup2: 78, a1: 943.99, a2: -1.4037, '1000a3': 0.0, Tmin: 283.15, Tmax: 333.15 },
  { DataId: 1895, MainGroup1: 78, MainGroup2: 42, a1: 128.59, a2: -0.562, '1000a3': 0.0, Tmin: 283.15, Tmax: 333.15 },
  { DataId: 1896, MainGroup1: 42, MainGroup2: 79, a1: 130.39, a2: -1.9708, '1000a3': 0.0, Tmin: 283.15, Tmax: 298.14 },
  { DataId: 1897, MainGroup1: 79, MainGroup2: 42, a1: 246.51, a2: -0.4133, '1000a3': 0.0, Tmin: 283.15, Tmax: 298.14 },
  { DataId: 1898, MainGroup1: 42, MainGroup2: 80, a1: 861.08, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 1899, MainGroup1: 80, MainGroup2: 42, a1: -745.6, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 1900, MainGroup1: 42, MainGroup2: 81, a1: -799.48, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 1901, MainGroup1: 81, MainGroup2: 42, a1: -136.75, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 1902, MainGroup1: 42, MainGroup2: 82, a1: 685.21, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 298.15 },
  { DataId: 1903, MainGroup1: 82, MainGroup2: 42, a1: 133.35, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 298.15 },
  { DataId: 1904, MainGroup1: 42, MainGroup2: 86, a1: 407.4, a2: -1.1845, '1000a3': 0.0, Tmin: 283.15, Tmax: 323.15 },
  { DataId: 1905, MainGroup1: 86, MainGroup2: 42, a1: -312.73, a2: 0.912, '1000a3': 0.0, Tmin: 283.15, Tmax: 323.15 },
  { DataId: 1906, MainGroup1: 42, MainGroup2: 92, a1: 212.68, a2: 0.5145, '1000a3': 0.0, Tmin: 293.15, Tmax: 403.65 },
  { DataId: 1907, MainGroup1: 92, MainGroup2: 42, a1: 390.9, a2: 1.6847, '1000a3': 0.0, Tmin: 293.15, Tmax: 403.65 },
  { DataId: 1908, MainGroup1: 42, MainGroup2: 95, a1: 139.07, a2: -0.4767, '1000a3': 0.0, Tmin: 293.15, Tmax: 446.61 },
  { DataId: 1909, MainGroup1: 95, MainGroup2: 42, a1: 9.65, a2: -0.1997, '1000a3': 0.0, Tmin: 293.15, Tmax: 446.61 },
  { DataId: 1910, MainGroup1: 43, MainGroup2: 45, a1: -523.16, a2: 0.4594, '1000a3': 0.0, Tmin: 293.15, Tmax: 337.28 },
  { DataId: 1911, MainGroup1: 45, MainGroup2: 43, a1: 1438.91, a2: -2.988, '1000a3': 0.0, Tmin: 293.15, Tmax: 337.28 },
  { DataId: 1912, MainGroup1: 43, MainGroup2: 56, a1: 372.56, a2: 0.0, '1000a3': 0.0, Tmin: 303.1, Tmax: 353.15 },
  { DataId: 1913, MainGroup1: 56, MainGroup2: 43, a1: -206.03, a2: 0.0, '1000a3': 0.0, Tmin: 303.1, Tmax: 353.15 },
  { DataId: 1914, MainGroup1: 43, MainGroup2: 63, a1: 1077.55, a2: 0.0, '1000a3': 0.0, Tmin: 340.23, Tmax: 451.27 },
  { DataId: 1915, MainGroup1: 63, MainGroup2: 43, a1: 363.77, a2: 0.0, '1000a3': 0.0, Tmin: 340.23, Tmax: 451.27 },
  { DataId: 1916, MainGroup1: 43, MainGroup2: 76, a1: -158.47, a2: 0.0, '1000a3': 0.0, Tmin: 308.15, Tmax: 308.15 },
  { DataId: 1917, MainGroup1: 76, MainGroup2: 43, a1: -208.0, a2: 0.0, '1000a3': 0.0, Tmin: 308.15, Tmax: 308.15 },
  { DataId: 1918, MainGroup1: 43, MainGroup2: 77, a1: -210.09, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 403.15 },
  { DataId: 1919, MainGroup1: 77, MainGroup2: 43, a1: 74.05, a2: 0.0, '1000a3': 0.0, Tmin: 298.14, Tmax: 403.15 },
  { DataId: 1920, MainGroup1: 43, MainGroup2: 78, a1: 283.2, a2: -1.194, '1000a3': 0.0, Tmin: 293.14, Tmax: 343.15 },
  { DataId: 1921, MainGroup1: 78, MainGroup2: 43, a1: 726.19, a2: -0.1283, '1000a3': 0.0, Tmin: 293.14, Tmax: 343.15 },
  { DataId: 1922, MainGroup1: 43, MainGroup2: 86, a1: 24.23, a2: -0.3527, '1000a3': 0.0, Tmin: 283.15, Tmax: 378.65 },
  { DataId: 1923, MainGroup1: 86, MainGroup2: 43, a1: 22.06, a2: 0.4177, '1000a3': 0.0, Tmin: 283.15, Tmax: 378.65 },
  { DataId: 1924, MainGroup1: 44, MainGroup2: 45, a1: -44.96, a2: 0.0, '1000a3': 0.0, Tmin: 332.22, Tmax: 352.62 },
  { DataId: 1925, MainGroup1: 45, MainGroup2: 44, a1: 669.63, a2: 0.0, '1000a3': 0.0, Tmin: 332.22, Tmax: 352.62 },
  { DataId: 1926, MainGroup1: 45, MainGroup2: 46, a1: -256.02, a2: 0.0, '1000a3': 0.0, Tmin: 310.0, Tmax: 423.15 },
  { DataId: 1927, MainGroup1: 46, MainGroup2: 45, a1: -21.62, a2: 0.0, '1000a3': 0.0, Tmin: 310.0, Tmax: 423.15 },
  { DataId: 1928, MainGroup1: 45, MainGroup2: 53, a1: 60.27, a2: 0.0, '1000a3': 0.0, Tmin: 288.15, Tmax: 313.15 },
  { DataId: 1929, MainGroup1: 53, MainGroup2: 45, a1: -191.26, a2: 0.0, '1000a3': 0.0, Tmin: 288.15, Tmax: 313.15 },
  { DataId: 1930, MainGroup1: 45, MainGroup2: 60, a1: -166.42, a2: 0.0, '1000a3': 0.0, Tmin: 303.15, Tmax: 303.15 },
  { DataId: 1931, MainGroup1: 60, MainGroup2: 45, a1: 344.01, a2: 0.0, '1000a3': 0.0, Tmin: 303.15, Tmax: 303.15 },
  { DataId: 1932, MainGroup1: 45, MainGroup2: 61, a1: -886.67, a2: 4.5194, '1000a3': 0.0, Tmin: 278.15, Tmax: 339.75 },
  { DataId: 1933, MainGroup1: 61, MainGroup2: 45, a1: -9.82, a2: -1.2456, '1000a3': 0.0, Tmin: 278.15, Tmax: 339.75 },
  { DataId: 1934, MainGroup1: 45, MainGroup2: 78, a1: -66.44, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 333.15 },
  { DataId: 1935, MainGroup1: 78, MainGroup2: 45, a1: -223.41, a2: 0.0, '1000a3': 0.0, Tmin: 298.15, Tmax: 333.15 },
  { DataId: 1936, MainGroup1: 45, MainGroup2: 79, a1: 185.96, a2: 0.0, '1000a3': 0.0, Tmin: 323.14, Tmax: 373.12 },
  { DataId: 1937, MainGroup1: 79, MainGroup2: 45, a1: -499.79, a2: 0.0, '1000a3': 0.0, Tmin: 323.14, Tmax: 373.12 },
  { DataId: 1938, MainGroup1: 46, MainGroup2: 47, a1: -1423.7, a2: 3.9162, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1939, MainGroup1: 47, MainGroup2: 46, a1: 1738.5, a2: -3.9729, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1940, MainGroup1: 46, MainGroup2: 60, a1: 32.16, a2: 0.0, '1000a3': 0.0, Tmin: 353.15, Tmax: 373.15 },
  { DataId: 1941, MainGroup1: 60, MainGroup2: 46, a1: -234.83, a2: 0.0, '1000a3': 0.0, Tmin: 353.15, Tmax: 373.15 },
  { DataId: 1942, MainGroup1: 46, MainGroup2: 86, a1: 243.79, a2: -1.1452, '1000a3': 0.0, Tmin: 298.15, Tmax: 451.55 },
  { DataId: 1943, MainGroup1: 86, MainGroup2: 46, a1: -31.44, a2: 1.3003, '1000a3': 0.0, Tmin: 298.15, Tmax: 451.55 },
  { DataId: 1944, MainGroup1: 46, MainGroup2: 95, a1: 130.17, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 363.57 },
  { DataId: 1945, MainGroup1: 95, MainGroup2: 46, a1: 4050.35, a2: 0.0, '1000a3': 0.0, Tmin: 293.15, Tmax: 363.57 },
  { DataId: 1946, MainGroup1: 47, MainGroup2: 48, a1: -91.65, a2: -0.3411, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1947, MainGroup1: 48, MainGroup2: 47, a1: 117.57, a2: 0.7114, '1000a3': 0.0, Tmin: 300.0, Tmax: 400.0 },
  { DataId: 1948, MainGroup1: 47, MainGroup2: 49, a1: 56.63, a2: 0.0, '1000a3': 0.0, Tmin: 469.61, Tmax: 477.61 },
  { DataId: 1949, MainGroup1: 49, MainGroup2: 47, a1: 83.66, a2: 0.0, '1000a3': 0.0, Tmin: 469.61, Tmax: 477.61 },
  { DataId: 1950, MainGroup1: 48, MainGroup2: 96, a1: -317.0, a2: 0.0, '1000a3': 0.0, Tmin: 360.58, Tmax: 421.26 },
  { DataId: 1951, MainGroup1: 96, MainGroup2: 48, a1: 545.75, a2: 0.0, '1000a3': 0.0, Tmin: 360.58, Tmax: 421.26 },
  { DataId: 1952, MainGroup1: 50, MainGroup2: 58, a1: 1163.16, a2: 0.0, '1000a3': 0.0, Tmin: 423.11, Tmax: 473.11 },
  { DataId: 1953, MainGroup1: 58, MainGroup2: 50, a1: 1254.82, a2: 0.0, '1000a3': 0.0, Tmin: 423.11, Tmax: 473.11 },
  { DataId: 1954, MainGroup1: 53, MainGroup2: 83, a1: -261.1, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 305.15 },
  { DataId: 1955, MainGroup1: 83, MainGroup2: 53, a1: 4262.66, a2: 0.0, '1000a3': 0.0, Tmin: 300.0, Tmax: 305.15 },
  { DataId: 1956, MainGroup1: 55, MainGroup2: 60, a1: 693.38, a2: -1.6033, '1000a3': 0.0, Tmin: 298.15, Tmax: 400.16 },
  { DataId: 1957, MainGroup1: 60, MainGroup2: 55, a1: -198.57, a2: 0.7227, '1000a3': 0.0, Tmin: 298.15, Tmax: 400.16 },
  { DataId: 1958, MainGroup1: 56, MainGroup2: 60, a1: -315.58, a2: 0.0, '1000a3': 0.0, Tmin: 296.94, Tmax: 376.92 },
  { DataId: 1959, MainGroup1: 60, MainGroup2: 56, a1: 1108.43, a2: 0.0, '1000a3': 0.0, Tmin: 296.94, Tmax: 376.92 },
  { DataId: 1960, MainGroup1: 57, MainGroup2: 58, a1: 45750.33, a2: 0.0, '1000a3': 0.0, Tmin: 428.05, Tmax: 509.0 },
  { DataId: 1961, MainGroup1: 58, MainGroup2: 57, a1: 15084.19, a2: 0.0, '1000a3': 0.0, Tmin: 428.05, Tmax: 509.0 },
  { DataId: 1962, MainGroup1: 57, MainGroup2: 59, a1: 435.7, a2: 0.0, '1000a3': 0.0, Tmin: 360.08, Tmax: 516.96 },
  { DataId: 1963, MainGroup1: 59, MainGroup2: 57, a1: -195.74, a2: 0.0, '1000a3': 0.0, Tmin: 360.08, Tmax: 516.96 },
  { DataId: 1964, MainGroup1: 60, MainGroup2: 86, a1: 185.35, a2: -0.6259, '1000a3': 0.0, Tmin: 298.15, Tmax: 405.12 },
  { DataId: 1965, MainGroup1: 86, MainGroup2: 60, a1: 426.43, a2: 0.1011, '1000a3': 0.0, Tmin: 298.15, Tmax: 405.12 },
  { DataId: 1966, MainGroup1: 75, MainGroup2: 76, a1: 89.91, a2: -0.3901, '1000a3': 0.0, Tmin: 293.14, Tmax: 487.76 },
  { DataId: 1967, MainGroup1: 76, MainGroup2: 75, a1: 176.33, a2: 0.0061, '1000a3': 0.0, Tmin: 293.14, Tmax: 487.76 },
  { DataId: 1968, MainGroup1: 80, MainGroup2: 81, a1: 1554.69, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
  { DataId: 1969, MainGroup1: 81, MainGroup2: 80, a1: -678.13, a2: 0.0, '1000a3': 0.0, Tmin: 291.15, Tmax: 443.84 },
];
export default function UNIFACParameters() {
  return (
    <div className="content space-y-8">
      <h2 className="mb-4 text-2xl font-bold">UNIFAC Parameters</h2>
      <div>
        <h3 className="text-xl font-semibold">References</h3>
        <a
          href="https://pubs.acs.org/doi/full/10.1021/ie010861w"
          target="_blank"
          className="mb-1 mt-2 flex gap-x-2 pl-4 hover:text-blue-600"
        >
          <div>1.</div>
          <div>
            Kang, Jeong Won, et al. "Estimation of mixture properties from first-and second-order group contributions
            with the UNIFAC model." Industrial & engineering chemistry research 41.13 &#40;2002&#41;: 3260-3273.
          </div>
        </a>
        <a
          href="https://www.sciencedirect.com/science/article/pii/S0378381214007353"
          target="_blank"
          className="flex gap-x-2 pl-4 hover:text-blue-600"
        >
          <div>2.</div>
          <div>
            Kang, Jeong Won, Vladimir Diky, and Michael Frenkel. "New modified UNIFAC parameters using critically
            evaluated phase equilibrium data." Fluid Phase Equilibria 388 &#40;2015&#41;: 128-141.
          </div>
        </a>
      </div>
      <div className="w-full max-w-screen-md">
        <div className="grid grid-cols-7 font-semibold">
          <div className="col-span-1">Main Group</div>
          <div className="col-span-4">Sub Group</div>
          <div className="col-span-1">Ri</div>
          <div className="col-span-1">Qi</div>
        </div>
        {singleParameters.map(parameter => (
          <div key={parameter.DataId}>
            <div className="grid grid-cols-7">
              <div className="col-span-1">{parameter.MainGroupId}</div>
              <div className="col-span-4">{parameter.SubGroup}</div>
              <div className="col-span-1">{parameter.Ri}</div>
              <div className="col-span-1">{parameter.Qi}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="w-full max-w-screen-md">
        <h3 className="text-xl font-semibold">Interaction Parameters</h3>
        <div className="grid grid-cols-5 font-semibold">
          <div>n</div>
          <div>m</div>
          <div>
            a1<sub>n,m</sub>
          </div>
          <div>
            a2<sub>n,m</sub>
          </div>
          <div>
            1000a3<sub>n,m</sub>
          </div>
        </div>

        {interactionParameters.map(parameter => (
          <div key={parameter.DataId}>
            <div className="grid grid-cols-5">
              <div>{parameter.MainGroup1}</div>
              <div>{parameter.MainGroup2}</div>
              <div>{parameter.a1}</div>
              <div>{parameter.a2}</div>
              <div>{parameter['1000a3']}</div>
            </div>
          </div>
        ))}
      </div>

      <Link className="mt-8 flex items-center gap-x-1 hover:text-blue-600" to="/">
        <FiChevronsLeft />
        <div>Go Back</div>
      </Link>
    </div>
  );
}
