import { forwardRef } from 'react';
import { StandaloneStructServiceProvider } from 'ketcher-standalone';
import { Editor } from 'ketcher-react';
import { twMerge } from 'tailwind-merge';

import 'styles/ketcher.css';

const KetcherEditor = forwardRef(({ className }, ref) => {
  const structServiceProvider = new StandaloneStructServiceProvider();

  return (
    <div className={twMerge(className, 'border-2')}>
      <Editor
        staticResourcesUrl=""
        structServiceProvider={structServiceProvider}
        onInit={ketcher => {
          window.ketcher = ketcher;
          ref.current = ketcher;
        }}
      />
    </div>
  );
});

export default KetcherEditor;
