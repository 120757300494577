// Modules
import { Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

// Layouts
import { MainApp, HomeLayout } from 'layouts/MainApp';

// Pages
import ErrorPage from 'routes/error';
import PageNotFound from 'routes/404';
import Test from 'routes/test';
import Home from 'routes/home';
import SearchPure from 'routes/search/pure';
import SearchPureResult from 'routes/search/pure/_id';
import UNIFACParameters from 'routes/search/KT-UNIFAC';
import CalculatePure from 'routes/calculate/pure';
import ResearchBooks from 'routes/research/books';
import ResearchJournals from 'routes/research/journals';
import ResearchThesis from 'routes/research/thesis';

export default function Router() {
  return (
    // <ErrorBoundary fallback={<ErrorPage />}>
    <Routes>
      <Route element={<MainApp />}>
        <Route element={<HomeLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/search">
            <Route path="pure" element={<SearchPure />} />
            <Route path="pure/:id" element={<SearchPureResult />} />
            <Route path="unifac-parameters" element={<UNIFACParameters />} />
          </Route>
          <Route path="/calculate">
            <Route path="pure" element={<CalculatePure />} />
            <Route path="pure/:id" element={<SearchPureResult />} />
            <Route path="unifac-parameters" element={<UNIFACParameters />} />
          </Route>
          <Route path="/research">
            <Route path="books" element={<ResearchBooks />} />
            <Route path="journals" element={<ResearchJournals />} />
            <Route path="thesis" element={<ResearchThesis />} />
          </Route>
        </Route>
        <Route path="/test-page" element={<Test />}></Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Routes>
    // </ErrorBoundary>
  );
}
