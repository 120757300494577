import { useEffect, useRef, useState } from 'react';
import { VscSearch, VscChromeClose } from 'react-icons/vsc';

import Modal from 'components/Modal';
import KetcherEditor from 'components/Ketcher';
import ComponentInput from 'components/ComponentInput';

export default function CalculatePure() {
  const containerRef = useRef();
  const ketcherRef = useRef();
  const [showKetcher, setShowKetcher] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const modalClick = () => {
    ketcherRef.current.getSmiles().then(smiles => {
      setSearchValue(smiles);
      setShowKetcher(false);
      ketcherRef.current.setMolecule('');
    });
  };

  const onSubmit = e => {
    e.preventDefault();
    console.log(searchValue);
  };

  useEffect(() => {
    containerRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [location]);

  return (
    <>
      <div className="content" ref={containerRef}>
        <div className="mb-4 text-2xl font-bold">Calculate Pure Properties</div>
        <form className="border bg-gray-100 p-4" onSubmit={onSubmit}>
          <h3 className="mb-2 text-xl font-semibold">Component Structure</h3>
          <div className="flex">
            <ComponentInput
              className="h-10 w-full max-w-96"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
            />
            <button type="submit" className="h-10 w-10 min-w-10 bg-gray-300 p-2">
              <VscSearch className="h-full w-full" />
            </button>
          </div>

          <div className="mt-1 flex flex-wrap gap-x-2 gap-y-1 text-xs font-light text-gray-700">
            <div>Examples &#41;</div>
            <div>CCO</div>
            <div>InChI=1S/C2H6O/c1-2-3/h3H,2H2,1H3</div>
          </div>
          <button className="mt-2 hover:text-blue-600" type="button" onClick={() => setShowKetcher(true)}>
            - Draw Structure
          </button>
        </form>

        <h3 className="mt-8 text-xl font-semibold">Documents</h3>
      </div>

      <Modal open={showKetcher} setOpen={setShowKetcher} className="m-2 w-full border-2 bg-white p-2 xl:w-[36rem]">
        <VscChromeClose
          className="mb-2 ml-auto cursor-pointer text-xl"
          onClick={() => {
            setShowKetcher(false);
          }}
        />
        <KetcherEditor ref={ketcherRef} className="w-full" />
        <div className="ml-auto mt-2 w-fit">
          <button onClick={modalClick} className="text-lg font-medium text-gray-400 hover:text-black">
            Submit
          </button>
        </div>
      </Modal>
    </>
  );
}
