import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Router from 'routes/router';

import 'react-toastify/dist/ReactToastify.css';
import 'styles/global.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      retry: 0,
      throwOnError: false,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Router />
    </BrowserRouter>
    <ToastContainer pauseOnFocusLoss closeOnClick position="top-right" autoClose={3000} pauseOnHover={false} />
  </QueryClientProvider>,
);
