import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { toStringCASRN } from 'utils/casrn';
import { treatData } from 'utils/pure_utils';
import { useIdSearch } from 'hooks/query-hooks';
import AsyncPage from 'components/AsyncPage';
import Base64Image from 'components/base/Base64Image';

import 'styles/pure-properties.css';

export default function SearchPureResult() {
  return (
    <div className="content space-y-8">
      <div>
        <h3 className="text-lg font-semibold text-red-700">&#91; Instruction &#93;</h3>
        <div>NA</div>
        <div>ML</div>
        <div>GC</div>
      </div>
      <div>
        <h3 className="mb-2 text-xl font-bold text-red-700">Component Names and Formula</h3>
        <div className="grid grid-cols-2">
          <label>Name</label>
          <div></div>
          <label className="property-label">Synonyms</label>
          <div></div>
          <label>CAS No.</label>
          <div></div>
          <label>Molecular Weight</label>
          <div></div>
        </div>
      </div>
      <div>
        <h3 className="property-h3">Basic Properties</h3>
        <div className="property-container">
          <label className="property-label">Normal Boiling Point Temperature</label>
          <div className="property-value">300 ± 10 K</div>
          <label className="property-label">Melting Point Temperature &#40;K&#41;</label>
          <div></div>
          <label className="property-label">Triple Point Temperature &#40;K&#41;</label>
          <div></div>
          <label className="property-label">Triple Poinrt Pressure &#40;kPa&#41;</label>
          <div></div>
          <label className="property-label">Critical Pressure &#40;kPa&#41;</label>
          <div></div>
          <label className="property-label">Critical Volume &#40;&#41;</label>
          <div></div>
          <label className="property-label">Critical Compressibility</label>
          <div></div>
          <label className="property-label">Accentric Factor</label>
          <div></div>
        </div>
      </div>
      <div>
        <h3 className="text-xl font-bold text-red-700">Temperature Dependent Properties</h3>
        <div className="grid grid-cols-[20rem_minmax(0,_1fr)]">
          <label>Vapor Pressure at 1atm</label>
          <div></div>
          <label>Heat Capacity &#40;Ideal Gas&#41;</label>
          <div></div>
          <label>Heat Capacity &#40;Liquid&#41;</label>
          <div></div>
          <label>Viscosity &#40;Gas, Low Pressure&#41;</label>
          <div></div>
          <label>Viscosity &#40;Liquid&#41;</label>
          <div></div>
          <label>Thermal Conductivity &#40;Gas, Low Pressure&#41;</label>
          <div></div>
          <label>Thermal Conductivity &#40;Liquid&#41;</label>
          <div></div>
          <label>Surface Tension</label>
          <div></div>
        </div>
      </div>
      <div>
        <h3 className="text-xl font-bold text-red-700">Liquid Properties</h3>
        <label>Partial Molar Volume</label>
        <div></div>
        <label>Solubility Parameters</label>
        <div></div>
        <label>SRK Accentric Factor</label>
        <div></div>
        <label>COSTALD Characteristic Volume</label>
        <div></div>
        <label>Rackett Parameter</label>
        <div></div>
        <label>Aniline Point</label>
        <div></div>
      </div>
      <div>
        <h3 className="text-xl font-bold text-red-700">Enthalpy Data</h3>
        <label>Ideal Gas Heat of Formation at 25℃</label>
        <div></div>
        <label>Ideal Gas Gibbs Energy of Formation at 25℃</label>
        <div></div>
        <label>Heat of Combustion, Gross Form</label>
        <div></div>
        <label>Heat of Combustion, Net Form</label>
        <div></div>
      </div>
      <div>
        <h3 className="text-xl font-bold text-red-700">Molecular Porperties</h3>
        <label>Van der Waals Volume</label>
        <div></div>
        <label>Van der Waals Area</label>
        <div></div>
        <label>UNIQUAC Ri Parameter</label>
        <div></div>
        <label>UNIQUAC Qi Parameter</label>
        <div></div>
        <label>Dipole Moment</label>
        <div></div>
        <label>Radius of Gryation</label>
        <div></div>
      </div>
      <div>
        <h3 className="text-xl font-bold text-red-700">Hazardous Data</h3>
        <label>Low Flammability Limit</label>
        <div></div>
        <label>Upper Flammability Limit</label>
        <div></div>
        <label>Flash Point &#40;Open Cup Method&#41;</label>
        <div></div>
        <label>Flash Point &#40;Closed Cup Method&#41;</label>
        <div></div>
        <label>Autoignition Temperature</label>
        <div></div>
        <label>NFPA Rating &#40;Health&#41;</label>
        <div></div>
        <label>NFPA Rating &#40;Fire&#41;</label>
        <div></div>
        <label>NFPA Rating &#40;Safety&#41;</label>
        <div></div>
      </div>
    </div>
  );
}

function OldSearchPureResult() {
  const containerRef = useRef();
  const params = useParams();
  const queryResult = useIdSearch(params.id);
  const [properties, setProperties] = useState({
    TBN: null,
    TMN: null,
    HFUS: null,
    TC: null,
    PC: null,
    VC: null,
    ACEN: null,
    HFORM: null,
    SFORM: null,
    UFORM: null,
    AFORM: null,
    GFORM: null,
    HFSTD: null,
    SFSTD: null,
    UFSTD: null,
    AFSTD: null,
    GFSTD: null,
  });

  useEffect(() => {
    containerRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (queryResult.data?.data != undefined) {
      queryResult.data?.data.properties.map(expData => {
        if (expData.property === 'TBN') {
          setProperties(current => ({ ...current, TBN: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'TMN') {
          setProperties(current => ({ ...current, TMN: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'TC') {
          setProperties(current => ({ ...current, TC: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'PC') {
          setProperties(current => ({ ...current, PC: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'VC') {
          setProperties(current => ({ ...current, VC: { val: expData.val * 1000000, unc: expData.unc * 1000000 } }));
        } else if (expData.property === 'VDC') {
          setProperties(current => ({ ...current, VDC: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'ACEN') {
          setProperties(current => ({ ...current, ACEN: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'HFORM') {
          setProperties(current => ({ ...current, HFORM: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'SFORM') {
          setProperties(current => ({ ...current, SFORM: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'UFORM') {
          setProperties(current => ({ ...current, UFORM: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'AFORM') {
          setProperties(current => ({ ...current, AFORM: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'GFORM') {
          setProperties(current => ({ ...current, GFORM: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'HFSTD') {
          setProperties(current => ({ ...current, HFSTD: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'SFSTD') {
          setProperties(current => ({ ...current, SFSTD: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'UFSTD') {
          setProperties(current => ({ ...current, UFSTD: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'AFSTD') {
          setProperties(current => ({ ...current, AFSTD: { val: expData.val, unc: expData.unc } }));
        } else if (expData.property === 'GFSTD') {
          setProperties(current => ({ ...current, GFSTD: { val: expData.val, unc: expData.unc } }));
        }
      });
    }
  }, [queryResult.data?.data]);

  return (
    <AsyncPage query={queryResult} className="content" ref={containerRef}>
      <div className="text-3xl font-bold">Pure Component Properties</div>
      <div className="mt-4 text-sm font-semibold">&#91; Instruction &#93; </div>
      <div className="instruction-row">
        &#42;<div className="instruction-index">EXP</div>: obtained by experiment
      </div>
      <div className="instruction-row">
        &#42;<div className="instruction-index">ML</div>: predicted by Machine Learning
      </div>
      <div className="instruction-row">
        &#42;<div className="instruction-index">PR</div>: predicted by conventional method
      </div>
      <div className="instruction-row">
        &#42;<div className="ml-1 mr-2 w-5 min-w-5 font-semibold">NA</div>: not Available &#40;Neither data can't be
        obtained&#41;
      </div>

      <h3 className="table-name">Compound Summary</h3>
      <div className="table-basic">
        <Base64Image
          className="mx-auto mb-8 aspect-square w-72 border-2 bg-gray-100"
          src={queryResult.data?.data.compound.image}
        />
        <h4 className="table-index">Name</h4>
        <p className="table-data">{queryResult.data?.data.compound.compound_name}</p>
        <h4 className="table-index">Synonyms</h4>
        <p className="table-data">NA</p>
        <h4 className="table-index">Formula</h4>
        <p className="table-data">{queryResult.data?.data.compound.formula}</p>
        <h4 className="table-index">CAS Registry Number</h4>
        <p className="table-data">{toStringCASRN(queryResult.data?.data?.compound?.casrn)}</p>
        <h4 className="table-index">Molecular Weight</h4>
        <p className="table-data">NA</p>
      </div>

      <h3 className="table-name">Phase Properties</h3>
      <div className="table-basic">
        <h4 className="table-index">Normal Boiling Temperature</h4>
        <p className="table-data">{treatData({ data: properties.TBN, round: 1, unit: 'K' })}</p>
        <h4 className="table-index">Melting Temperature</h4>
        <p className="table-data">{treatData({ data: properties.TMN, round: 1, unit: 'K' })}</p>
        <h4 className="table-index">Heat of Fusion</h4>
        <p className="table-data">{treatData({ data: properties.HFUS, round: 1, unit: 'kJ/mol' })}</p>
        <h4 className="table-index">Critical Temperature</h4>
        <p className="table-data">{treatData({ data: properties.TC, round: 1, unit: 'K' })}</p>
        <h4 className="table-index">Critical Pressure</h4>
        <p className="table-data">{treatData({ data: properties.PC, round: 0, unit: 'kPa' })}</p>
        <h4 className="table-index">Critical Volume</h4>
        <p className="table-data">{treatData({ data: properties.VC, round: 2, unit: '㎤/mol' })}</p>
        <h4 className="table-index">Accentric Factor</h4>
        <p className="table-data">{treatData({ data: properties.ACEN, round: 3 })}</p>
      </div>

      <h3 className="table-name">Ideal Gas Standard State Formation Energy</h3>
      <div className="table-basic">
        <h4 className="table-index">Enthalphy of Formation</h4>
        <p className="table-data">{treatData({ data: properties.HFORM, round: 1, unit: 'kJ/mol' })}</p>
        <h4 className="table-index">Absolute Entropy of Formation</h4>
        <p className="table-data">{treatData({ data: properties.SFORM, round: 1, unit: 'J/(mol K)' })}</p>
        <h4 className="table-index">Internal Energy of Formation</h4>
        <p className="table-data">{treatData({ data: properties.UFORM, round: 1, unit: 'kJ/mol' })}</p>
        <h4 className="table-index">Helmholtz Energy of formation</h4>
        <p className="table-data">{treatData({ data: properties.AFORM, round: 0, unit: 'kJ/mol' })}</p>
        <h4 className="table-index">Gibbs Energy of Formation</h4>
        <p className="table-data">{treatData({ data: properties.GFORM, round: 1, unit: 'kJ/mol' })}</p>
      </div>

      <h3 className="table-name">Standard State Formation Energy</h3>
      <div className="table-basic">
        <h4 className="table-index">Enthalphy of Formation</h4>
        <p className="table-data">{treatData({ data: properties.HFSTD, round: 1, unit: 'kJ/mol' })}</p>
        <h4 className="table-index">Absolute Entropy of Formation</h4>
        <p className="table-data">{treatData({ data: properties.SFSTD, round: 1, unit: 'J/(mol K)' })}</p>
        <h4 className="table-index">Internal Energy of Formation</h4>
        <p className="table-data">{treatData({ data: properties.UFSTD, round: 1, unit: 'kJ/mol' })}</p>
        <h4 className="table-index">Helmholtz Energy of formation</h4>
        <p className="table-data">{treatData({ data: properties.AFSTD, round: 0, unit: 'kJ/mol' })}</p>
        <h4 className="table-index">Gibbs Energy of Formation</h4>
        <p className="table-data">{treatData({ data: properties.GFSTD, round: 1, unit: 'kJ/mol' })}</p>
      </div>

      {/* <h3 className="table-name">Hazard Data</h3>
      <div className="table-basic">
        <h4 className="table-index">Enthalphy of Formation</h4>
        <p className="table-data">{treatData({ data: properties.TBN, round: 1, unit: 'kJ/mol' })}</p>
        <h4 className="table-index">Absolute Entropy of Formation</h4>
        <p className="table-data">{treatData({ data: properties.TC, round: 1, unit: 'J/(mol K)' })}</p>
        <h4 className="table-index">Internal Energy of Formation</h4>
        <p className="table-data">{treatData({ data: properties.TC, round: 1, unit: 'kJ/mol' })}</p>
        <h4 className="table-index">Helmholtz Energy of formation</h4>
        <p className="table-data">{treatData({ data: properties.PC, round: 0, unit: 'kJ/mol' })}</p>
        <h4 className="table-index">Gibbs Energy of Formation</h4>
        <p className="table-data">{treatData({ data: properties.TMN, round: 1, unit: 'kJ/mol' })}</p>
      </div> */}
    </AsyncPage>
  );
}
