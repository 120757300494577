import { Outlet } from 'react-router-dom';

import { Navigation } from 'components/Navigation';

export function MainApp({ children = <Outlet /> }) {
  return <div className="relative flex min-h-screen flex-col">{children}</div>;
}

export function HomeLayout({ children = <Outlet /> }) {
  return (
    <>
      <div className="flex-auto 2xl:grid 2xl:grid-cols-2">
        <Navigation />
        <main>{children}</main>
      </div>
    </>
  );
}
