import { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { VscBook, VscGithubInverted, VscMail, VscSearch, VscChromeClose } from 'react-icons/vsc';
import { MdOutlineDraw } from 'react-icons/md';
import { twMerge } from 'tailwind-merge';
import { Switch } from '@headlessui/react';

import BaseButton from 'components/base/Button';
import Show from 'components/Show';
import ComponentInput from 'components/ComponentInput';
import KetcherEditor from 'components/Ketcher';
import Modal from 'components/Modal';
import change_SMILES from 'utils/encode_smiles';

export function Navigation() {
  return (
    <nav className="bg-slate-800">
      <div className="ml-auto max-w-2xl p-10 max-2xl:hidden">
        <Link className="text-4xl font-bold text-white" to="/">
          KDB
        </Link>
        <h1 className="font-display mt-8 text-4xl/tight font-light text-white">
          Open-Database <span className="text-sky-300">for Chemical Engineer</span>
        </h1>
        <p className="mt-4 text-sm/6 text-gray-300">
          Provides useful inforamtion about thermophysical properties and calculation methods for hydrocarbons, light
          gases, polymers and electrolyte solutions commonly encountered in chemical engineering practices.
        </p>
      </div>
      <div className="mx-auto max-w-xl px-4 py-10 sm:py-20 2xl:hidden">
        <Link className="text-4xl font-bold text-white" to="/">
          KDB
        </Link>
        <h1 className="font-display mt-8 text-4xl/tight font-light text-white">
          Open-Database <span className="text-sky-300">for Chemical Engineer</span>
        </h1>
        <p className="mt-4 text-sm/6 text-gray-300">
          Provides useful inforamtion about thermophysical properties and calculation methods for hydrocarbons, light
          gases, polymers and electrolyte solutions commonly encountered in chemical engineering practices.
        </p>
      </div>
    </nav>
  );
}

export function OldNavigation() {
  const ketcherRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const [isEstimationMode, setIsEstimationMode] = useState(false);
  const [isBinaryMode, setIsBinaryMode] = useState(false);
  const [isStructureSearch, setIsStructureSearch] = useState(false);
  const [showKetcher, setShowKetcher] = useState(false);
  const [currentInput, setCurrentInput] = useState();
  const [compound1InputValue, setCompound1InputValue] = useState();
  const [compound2InputValue, setCompound2InputValue] = useState();
  const [temperatureInputValue, setTemperatureInputValue] = useState();

  const modalClick = () => {
    ketcherRef.current.getSmiles().then(result => {
      if (currentInput === 'compound1') {
        setCompound1InputValue(result);
      } else {
        setCompound2InputValue(result);
      }
      setShowKetcher(false);
      ketcherRef.current.setMolecule('');
    });
  };

  const onSubmit = async () => {
    let estimationPart = '';
    let namePart = '';

    if (isEstimationMode) estimationPart = 'estimation';
    else estimationPart = 'search';
    if (isStructureSearch) namePart = 'smiles';
    else namePart = 'name';

    if (isBinaryMode) {
      navigate(
        `/${estimationPart}/binary-VLE?compound1=${change_SMILES(compound1InputValue)}&compound2=${change_SMILES(compound2InputValue)}&temperature=${temperatureInputValue}&type=${namePart}`,
      );
    } else {
      navigate(`/${estimationPart}/pure-constant?compound=${change_SMILES(compound1InputValue)}&type=${namePart}`);
    }
  };

  // Default Setting
  useEffect(() => {
    if (location.pathname === '/') {
      setIsBinaryMode(false);
      setIsEstimationMode(false);
    } else {
      if (location.pathname.split('/')[0] === 'search') {
        setIsEstimationMode(false);
      } else {
        setIsEstimationMode(true);
      }
      if (location.pathname.split('/')[1] === 'binary-VLE') {
        setIsBinaryMode(false);
      } else {
        setIsBinaryMode(true);
      }
    }
  }, []);

  return (
    <>
      <nav className="bg-slate-800">
        <div className="scrollbar-hide p-4 sm:p-6 md:h-full md:py-10 xl:sticky xl:top-0 xl:max-h-screen xl:overflow-scroll xl:p-32">
          <div className="ml-auto mr-auto w-full max-w-xl xl:mr-0">
            <Link className="text-4xl font-bold text-white" to="/">
              KDB
            </Link>
            <h1 className="font-display mt-8 text-4xl/tight font-light text-white">
              Open-Database <span className="text-sky-300">for Chemical Engineer</span>
            </h1>
            <p className="mt-4 text-sm/6 text-gray-300">
              Provides useful inforamtion about thermophysical properties and calculation methods for hydrocarbons,
              light gases, polymers and electrolyte solutions commonly encountered in chemical engineering practices.
            </p>
            <nav className="my-6 flex gap-x-1 rounded-lg bg-slate-700">
              <div className={twMerge(!isEstimationMode ? 'bg-white' : '', 'w-1/2 flex-auto rounded-lg py-1')}>
                <button
                  className={twMerge(
                    !isEstimationMode ? 'text-black' : 'text-gray-500 hover:bg-slate-600',
                    'w-full rounded-lg py-2 text-center text-lg font-semibold',
                  )}
                  onClick={() => setIsEstimationMode(false)}
                >
                  Search
                </button>
              </div>
              <div className={twMerge(isEstimationMode ? 'bg-white' : '', 'w-1/2 flex-auto rounded-lg py-1')}>
                <button
                  className={twMerge(
                    isEstimationMode ? 'text-black' : 'text-gray-500 hover:bg-slate-600',
                    'w-full rounded-lg py-2 text-center text-lg font-semibold',
                  )}
                  onClick={() => setIsEstimationMode(true)}
                >
                  Estimation
                </button>
              </div>
            </nav>
            <nav className="my-6 flex gap-x-1 rounded-lg bg-slate-700">
              <div className={twMerge(!isBinaryMode ? 'bg-white' : '', 'w-1/2 flex-auto rounded-lg py-1')}>
                <button
                  className={twMerge(
                    !isBinaryMode ? 'text-black' : 'text-gray-500 hover:bg-slate-600',
                    'w-full rounded-lg py-2 text-center text-lg font-semibold',
                  )}
                  onClick={() => setIsBinaryMode(false)}
                >
                  Pure
                </button>
              </div>
              <div className={twMerge(isBinaryMode ? 'bg-white' : '', 'w-1/2 flex-auto rounded-lg py-1')}>
                <button
                  className={twMerge(
                    isBinaryMode ? 'text-black' : 'text-gray-500 hover:bg-slate-600',
                    'w-full rounded-lg py-2 text-center text-lg font-semibold',
                  )}
                  onClick={() => setIsBinaryMode(true)}
                >
                  Binary VLE
                </button>
              </div>
            </nav>
            <div className="mb-2 text-xl text-white">Search with</div>
            <div className="flex space-x-4 text-white">
              <div className="flex-wrap">Name</div>
              <Switch
                checked={isStructureSearch}
                onChange={setIsStructureSearch}
                className="group relative flex h-7 w-14 cursor-pointer rounded-full bg-white/10 p-1 transition-colors duration-200 ease-in-out focus:outline-none data-[checked]:bg-white/10 data-[focus]:outline-1 data-[focus]:outline-white"
              >
                <span
                  aria-hidden="true"
                  className="pointer-events-none inline-block size-5 translate-x-0 rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-7"
                />
              </Switch>
              <div>SMILES</div>
            </div>
            <div className="mt-4">
              <Show show={!isBinaryMode}>
                <div className="text-3xl font-light text-gray-300">Compound</div>
                <form
                  className="mt-2 flex h-10 items-center"
                  onSubmit={e => {
                    e.preventDefault();
                  }}
                >
                  <BaseButton
                    containerClassName="mr-2 aspect-square h-full bg-gray-200 p-1"
                    buttonClassName={twMerge('h-full w-full', isStructureSearch ? '' : 'cursor-no-drop')}
                    type="button"
                    disabled={!isStructureSearch}
                    onClick={() => {
                      setCurrentInput('compound1');
                      setShowKetcher(true);
                    }}
                  >
                    <MdOutlineDraw className="h-full w-full" />
                  </BaseButton>
                  <ComponentInput
                    className="h-full w-full"
                    value={compound1InputValue}
                    onChange={e => setCompound1InputValue(e.target.value)}
                  />
                  <BaseButton
                    containerClassName="aspect-square h-full border bg-gray-200"
                    buttonClassName="h-full w-full flex items-center justify-center"
                    type="submit"
                    onClick={onSubmit}
                  >
                    <VscSearch />
                  </BaseButton>
                </form>
              </Show>
              <Show show={isBinaryMode}>
                <div className="text-3xl font-light text-gray-300">Compound 1</div>
                <div className="mb-4 mt-2 flex h-10 items-center">
                  <BaseButton
                    containerClassName="mr-2 aspect-square h-full bg-gray-200 p-1"
                    buttonClassName={twMerge('h-full w-full', isStructureSearch ? '' : 'cursor-no-drop')}
                    type="button"
                    disabled={!isStructureSearch}
                    onClick={() => {
                      setCurrentInput('compound1');
                      setShowKetcher(true);
                    }}
                  >
                    <MdOutlineDraw className="h-full w-full" />
                  </BaseButton>
                  <ComponentInput
                    className="h-full w-full"
                    value={compound1InputValue}
                    onChange={e => setCompound1InputValue(e.target.value)}
                  />
                </div>
                <div className="text-3xl font-light text-gray-300">Compound 2</div>
                <div className="mb-4 mt-2 flex h-10 items-center">
                  <BaseButton
                    containerClassName="mr-2 aspect-square h-full bg-gray-200 p-1"
                    buttonClassName={twMerge('h-full w-full', isStructureSearch ? '' : 'cursor-no-drop')}
                    type="button"
                    disabled={!isStructureSearch}
                    onClick={() => {
                      setCurrentInput('compound2');
                      setShowKetcher(true);
                    }}
                  >
                    <MdOutlineDraw className="h-full w-full" />
                  </BaseButton>
                  <ComponentInput
                    className="h-full w-full"
                    value={compound2InputValue}
                    onChange={e => setCompound2InputValue(e.target.value)}
                  />
                </div>
                <div className="flex items-center gap-4">
                  <div className="text-3xl font-light text-gray-300">Temperature</div>
                  <ComponentInput
                    className="h-full w-full"
                    value={temperatureInputValue}
                    onChange={e => setTemperatureInputValue(e.target.value)}
                  />
                  <div className="text-2xl text-gray-300">K</div>
                </div>
                <BaseButton
                  containerClassName="ml-auto font-light mt-4 text-gray-400 font-semibold text-xl hover:text-gray-200"
                  onClick={onSubmit}
                >
                  Submit
                </BaseButton>
              </Show>
            </div>
            <div className="mt-12 flex flex-wrap gap-x-8 gap-y-2 text-slate-300">
              <div className="flex cursor-no-drop items-center gap-x-2 rounded-xl py-0.5 hover:text-white">
                <VscBook className="mt-0.5" />
                Documentation
              </div>
              <div className="flex cursor-no-drop items-center gap-x-2 rounded-xl py-0.5 hover:text-white">
                <VscGithubInverted />
                GitHub
              </div>
              <div className="flex cursor-no-drop items-center gap-x-2 rounded-xl py-0.5 hover:text-white">
                <VscMail className="mt-0.5" />
                Contact
              </div>
            </div>
          </div>
          <footer className="text-/6 ml-auto mr-auto mt-8 w-full max-w-xl text-gray-400 xl:mr-0">
            Copy Rights by <span href="#">MDL</span>
          </footer>
        </div>
      </nav>
      <Modal open={showKetcher} setOpen={setShowKetcher} className="m-2 w-full border-2 bg-white p-2 xl:w-[36rem]">
        <VscChromeClose
          className="mb-2 ml-auto cursor-pointer text-xl"
          onClick={() => {
            setShowKetcher(false);
          }}
        />
        <KetcherEditor ref={ketcherRef} className="w-full" />
        <div className="ml-auto mt-2 w-fit">
          <button onClick={modalClick} className="text-lg font-medium text-gray-400 hover:text-black">
            제출
          </button>
        </div>
      </Modal>
    </>
  );
}
