import { Link } from 'react-router-dom';
import { VscTriangleRight } from 'react-icons/vsc';

export default function Home() {
  return (
    <div className="content">
      <h1 className="text-3xl font-bold">Online Services</h1>
      <ol className="mb-10 mt-4 space-y-2">
        <h3 className="text-2xl font-bold text-slate-600">Property Search</h3>
        <Link className="flex h-8 w-fit items-center gap-1 text-lg hover:text-sky-900" to="/search/pure">
          <VscTriangleRight />
          <p>Pure Component Properties</p>
        </Link>
        <Link className="flex h-8 w-fit items-center gap-1 text-lg hover:text-sky-900" to="/search/binary">
          <VscTriangleRight />
          <p>Binary Vapor-Liquid Equilibrium Data</p>
        </Link>
        <Link className="flex h-8 w-fit items-center gap-1 text-lg hover:text-sky-900" to="/search/unifac-parameters">
          <VscTriangleRight />
          <p>KT-UNIFAC parameters</p>
        </Link>
      </ol>
      <ol className="my-10 space-y-2">
        <h3 className="text-2xl font-bold text-slate-600">Calculation Modules</h3>
        <Link className="flex h-8 w-fit items-center gap-1 text-lg hover:text-sky-900" to="/calculate/pure">
          <VscTriangleRight />
          <p>Pure Component Properties</p>
        </Link>
        <Link className="flex h-8 w-fit items-center gap-1 text-lg hover:text-sky-900" to="/calculate/binary">
          <VscTriangleRight />
          <p>Binary Vapor-Liquid Equilibrium Data</p>
        </Link>
      </ol>
      <h1 className="text-3xl font-bold">Documents</h1>
      <ol className="mb-10 mt-4 space-y-2">
        <h3 className="text-2xl font-bold text-slate-600">KDB page contains</h3>
        <li className="text-lg">
          - Themophysical properties and equilbrium data for 2000 hydrocarbons and light gases.
        </li>
        <li className="text-lg">- Themophysical properties and equilbrium data for 200 polymers and solvents.</li>
        <li className="text-lg">- Themophysical properties and equilbrium data for 2000 electrolyte solutions.</li>
      </ol>
      <ol className="my-10 space-y-2">
        <h3 className="text-2xl font-bold text-slate-600">
          Laboratories in Korea collaborated to develop the database
        </h3>
        <li className="text-lg">- Molecular Design LAB , Korea University &#40;Prof. Jeong Won Kang&#41; </li>
      </ol>
      <ol className="my-10 space-y-2">
        <h3 className="text-2xl font-bold text-slate-600">KDB project is supported by</h3>
        <li className="text-lg">- Ministry of Trade, Industry and Energy, Republic of Korea &#40;MOTIE&#41;</li>
        <li className="text-lg">- Chemical Engineering & Material Research Infomation Center&#40;CHERIC&#41;</li>
      </ol>
      <ol className="my-10 space-y-2">
        <h3 className="text-2xl font-bold text-slate-600">Research Articles Citing KDB</h3>
        <Link className="flex h-8 w-fit items-center gap-1 text-lg hover:text-sky-900" to="/research/journals">
          <VscTriangleRight />
          <p>Journal Articles</p>
        </Link>
        <Link className="flex h-8 w-fit items-center gap-1 text-lg hover:text-sky-900" to="/research/thesis">
          <VscTriangleRight />
          <p>Thesis or dissertation</p>
        </Link>
        <Link className="flex h-8 w-fit items-center gap-1 text-lg hover:text-sky-900" to="/research/books">
          <VscTriangleRight />
          <p>Book</p>
        </Link>
      </ol>
      <div className="my-10 border-2 bg-gray-100 p-4 text-lg">
        We do our best to provide high quality data and try to remove wrong data entry. However,&nbsp;
        <span className="font-semibold">we make no warranties for our data and other calculation programs.</span>
        &nbsp;KDB project team shall not be liable for any damage tha may result from errors or ommisions in the
        database.
      </div>
      <ol className="my-10 space-y-2">
        <h3 className="text-2xl font-bold text-slate-600">Contact</h3>
        <li className="text-lg">####</li>
      </ol>
    </div>
  );
}
